import styled from "styled-components";

import { shade } from "polished";

export const Container = styled.div``;

export const ContainerSquare = styled.div`
    height: 85vh;
    display: flex;
    align-items: stretch;
    margin-top: -25px;
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    justify-content: center;
`;

export const Square = styled.div`
    border-radius: 10px;
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const AvatarInput = styled.div`
    img {
        margin-top: 10px;
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }
`;

export const ButtonWhats = styled.div`
    button {
        background: #32da7f;
        border-radius: 5px;
        color: #ffffff;
        border: 0;
        height: 40px;
        width: 90%;
        padding: 0 16px;
        font-weight: 500;
        margin-top: 10px;

        &:hover {
            background: ${shade(0.2, "#32da7f")};
        }
    }
`;

export const ButtonMenu = styled.div`
    button {
        background: #846cf9;
        border-radius: 5px;
        color: #ffffff;
        border: 0;
        height: 38px;
        width: 100%;
        padding: 0 10px;
        font-weight: 500;

        &:hover {
            background: ${shade(0.2, "#846cf9")};
        }
    }
`;
