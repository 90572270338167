import styled, { css } from "styled-components";

import Tooltip from "../Tooltip";

interface ContainerProps {
    isFocused: boolean;
    isFilled: boolean;
    isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
    background: #ffffff;
    border-radius: 8px;
    padding: 6px;
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid gray;
    padding-left: 15px;
    color: grey;

    div + & {
        margin-top: 15px;
    }

    & + div {
        margin-top: 8px;
    }
    ${props =>
        props.isErrored &&
        css`
            border-color: red;
        `}
    ${props =>
        props.isFocused &&
        css`
            border-color: gray;
            color: gray;
        `}
    ${props =>
        props.isFilled &&
        css`
            color: gray;
            border-color: gray;
        `}
    input {
        color: gray;
        background: transparent;
        flex: 1;
        border: 0;
        &::placeholder {
            color: #666360;
        }
    }
    svg {
        margin-right: 20px;
    }
`;

export const Error = styled(Tooltip)`
    height: 20px;
    margin-left: 16px;
    svg {
        margin: 0;
    }
    span {
        background: #c53030;
        color: #fff;
        &::before {
            border-color: #c53030 transparent;
        }
    }
`;
