import styled from "styled-components";

import { shade } from "polished";

export const Container = styled.button`
    background: #ccf2f8;
    border-radius: 20px;
    border-width: 1px;
    border-color: #bbd6da;
    border-style: solid;
    color: #2d2d2d;
    font-size: 16;
    margin-inline: 5px;
    margin-top: 5;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
        background: ${shade(0.2, "#ccf2f8")};
    }
`;
