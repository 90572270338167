import styled from "styled-components";

import { shade } from "polished";

export const Container = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 105px;
    background: #846cf9;
    border-radius: 10px;
    color: #ffffff;
    border: 0;
    padding: 0 16px;
    height: 50px;
    width: 100%;
    font-weight: 500;
    margin-top: 16px;
    transition: background-color 0.2s;

    &:hover {
        background: ${shade(0.2, "#846cf9")};
    }
`;
