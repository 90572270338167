/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback, useRef } from "react";
import {
    FiUser,
    FiInstagram,
    FiFacebook,
    FiPackage,
    FiPower,
    FiLinkedin,
} from "react-icons/fi";
import { MDBRow, MDBCol, MDBContainer, MDBFooter } from "mdb-react-ui-kit";
import { Form } from "@unform/web";
// eslint-disable-next-line no-unused-vars
import { FormHandles } from "@unform/core";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Nav, Navbar, NavDropdown } from "react-bootstrap";

import Select from "react-select";
import parse from "html-react-parser";
import getValidationErrors from "../../utils/getValidationErrors";
import api from "../../services/api";

import { useAuth } from "../../hooks/auth";
import {
    Container,
    Content,
    ButtonMenu,
    FundoCinza,
    EspacoFundoCinza,
} from "./styles";

import imgLogo from "../../assets/Logo.png";
import imgNossoMovimento01 from "../../assets/nossoMovimento01.png";
import imgNossoMovimento02 from "../../assets/nossoMovimento02.png";
import imgLogoBranca from "../../assets/LogoBranca.png";
import ButtonIcon from "../../components/ButtonIcon";
import Input from "../../components/Input";
import Inputmask from "../../components/Inputmask";
import BorderSelect from "../../components/BorderSelect";
import ButtonAzulEscuro from "../../components/ButtonAzulEscuro";

interface Categoria {
    id: string;
    nome: string;
    idCategoriaPai: string;
}

interface Outros {
    id: string;
    chave: string;
    valor: string;
}

interface FabriqueMalhaFormData {
    nome: string;
    email: string;
    telefone: string;
    idCidade: string;
    idEstado: string;
    descricao: string;
}

interface Estado {
    nome: string;
    sigla: string;
}

interface Cidade {
    id: string;
    nome: string;
}

const FabriqueMalha: React.FC = () => {
    const formRef = useRef<FormHandles>(null);
    const { cliente, signOut } = useAuth();
    const history = useHistory();
    const [outros, setOutros] = useState<Outros[]>([]);

    const [categorias, setCategorias] = useState<Categoria[]>([]);

    useEffect(() => {
        api.get("/categorias/categorias").then(response =>
            setCategorias(response.data),
        );
        api.get("/controle").then(response => setOutros(response.data));
    }, []);

    const [estados, setEstados] = useState<Estado[]>([]);
    useEffect(() => {
        api.get("/estados").then(response => setEstados(response.data));
    }, []);

    const [notShowCidade, setNotShowCidade] = useState<boolean>(true);

    const [selectEstado, setSelectEstado] = useState([]);
    const [idSelectEstado, setIdSelectEstado] = useState<string>("");
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChangeEstado = (e: any) => {
        setIdSelectEstado(e.id);
        setSelectEstado(e.sigla);
        setNotShowCidade(false);
    };

    const [cidades, setCidades] = useState<Cidade[]>([]);
    useEffect(() => {
        api.get(`/cidades/${selectEstado}`).then(response =>
            setCidades(response.data),
        );
    }, [selectEstado]);

    const [selectCidade, setSelectCidade] = useState([]);
    const [idSelectCidade, setIdSelectCidade] = useState<string>("");
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChangeCidade = (e: any) => {
        setIdSelectCidade(e.id);
        setSelectCidade(e.nome);
    };

    const clickCategoria = (nomeCategoria: string) => {
        if (cliente) {
            const insertPesquisa = {
                idCliente: cliente.id,
                nomeCliente: cliente.nome,
                pesquisa: nomeCategoria,
            };

            api.post("/pesquisa", insertPesquisa);
        } else {
            const insertPesquisa = {
                pesquisa: nomeCategoria,
            };

            api.post("/pesquisa", insertPesquisa);
        }
        localStorage.setItem("@dbwetextil:nomeCategoria", nomeCategoria);
        history.push("/pesquisa");
    };

    const handleSubmit = useCallback(
        async (data: FabriqueMalhaFormData) => {
            try {
                formRef.current?.setErrors({});
                const schema = Yup.object().shape({
                    nome: Yup.string().required("Campo obrigatório"),
                    telefone: Yup.string()
                        .required("Campo obrigatório")
                        .length(15, "Campo obrigatório"),
                    descricao: Yup.string().required("Campo obrigatório"),
                    email: Yup.string()
                        .required("Campo obrigatório")
                        .email("Digite um e-mail válido"),
                    SelectEstado: Yup.string().required("Campo obrigatório"),
                    SelectCidade: Yup.string().required("Campo obrigatório"),
                });

                await schema.validate(data, {
                    // Faz com que o Yup não pare no primeiro erro. Por padrão é true.
                    abortEarly: false,
                });

                const { nome, email, telefone, descricao } = data;

                const celularSemMask = telefone
                    .replace(/\)/g, "")
                    .replace(/\(/g, "")
                    .replace(/-/g, "")
                    .replace(/ /g, "");

                const formData = {
                    nome,
                    email,
                    telefone: celularSemMask,
                    descricao,
                    idCidade: idSelectCidade,
                    idEstado: idSelectEstado,
                };
                await api
                    .post("/fabriqueMalha", formData)
                    .then(() => {
                        toast.success(
                            `Dados enviados! Nossa equipe entrará em contato em breve`,
                            {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: true,
                                progress: undefined,
                            },
                        );
                    })
                    .catch(retorno => {
                        toast.error(
                            `Erro no cadastro! ${
                                JSON.parse(retorno.request.responseText).message
                            }`,
                            {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: true,
                                progress: undefined,
                            },
                        );
                    });
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);
                    return;
                }

                toast.error("Erro no servidor! Tente novamente!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            }
        },
        [idSelectCidade, idSelectEstado],
    );

    return (
        <Container>
            <Navbar
                collapseOnSelect
                expand="lg"
                style={{
                    background: "#ffffff",
                }}
            >
                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    style={{ border: "none" }}
                />
                <Navbar.Brand style={{ marginLeft: "2%" }}>
                    <Link to="/">
                        <img width="125px" src={imgLogo} alt="WeTextil" />
                    </Link>
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto text-center" />
                    <Nav>
                        <Nav.Link
                            href="/"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                início
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/nossoMovimento"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                sobre
                            </span>
                        </Nav.Link>

                        <Nav.Link
                            href="/planos"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                planos
                            </span>
                        </Nav.Link>

                        <Nav.Link
                            href="#"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#CAC9C8",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                fabrique sua malha
                            </span>
                        </Nav.Link>

                        <Nav.Link
                            href="#"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#CAC9C8",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                mapeie seu mercado
                            </span>
                        </Nav.Link>

                        <NavDropdown
                            title="categorias"
                            id="basic-nav-dropdown"
                            style={{
                                padding: "0px 20px 0px 20px",
                                textAlign: "center",
                                marginTop: 8,
                                fontWeight: "bold",
                                color: "#2d2d2d",
                            }}
                        >
                            {categorias.map(categoria => (
                                <NavDropdown.Item
                                    onClick={
                                        () => clickCategoria(categoria.nome)
                                        // eslint-disable-next-line react/jsx-curly-newline
                                    }
                                >
                                    {categoria.nome}
                                </NavDropdown.Item>
                            ))}
                        </NavDropdown>
                        {!cliente && (
                            <Nav.Link href="/cliente">
                                <ButtonMenu>
                                    <button type="button">
                                        <span
                                            style={{
                                                padding: "0px 20px 0px 20px",
                                            }}
                                        >
                                            cadastre-se
                                        </span>
                                    </button>
                                </ButtonMenu>
                            </Nav.Link>
                        )}
                        {!cliente && (
                            <Nav.Link
                                href="/login"
                                style={{
                                    textAlign: "center",
                                    marginTop: 8,
                                    color: "#2d2d2d",
                                }}
                            >
                                <span style={{ padding: "0px 20px 0px 30px" }}>
                                    login
                                </span>
                            </Nav.Link>
                        )}

                        {cliente && cliente?.controleFluxo !== "IS_ADMIN" && (
                            <Nav.Link
                                href="/minhaVitrine"
                                style={{
                                    textAlign: "center",
                                    marginTop: 8,
                                    color: "#2d2d2d",
                                }}
                            >
                                <span style={{ padding: "0px 20px 0px 20px" }}>
                                    minha vitrine
                                </span>
                            </Nav.Link>
                        )}

                        {cliente && cliente?.controleFluxo !== "IS_ADMIN" && (
                            <NavDropdown
                                title="meu perfil"
                                id="basic-nav-dropdown"
                                style={{
                                    padding: "0px 30px 0px 20px",
                                    textAlign: "center",
                                    marginTop: 8,
                                    fontWeight: "bold",
                                    color: "#2d2d2d",
                                }}
                            >
                                <NavDropdown.Item href="/minhaConta">
                                    minha conta
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={signOut}>
                                    sair
                                </NavDropdown.Item>
                            </NavDropdown>
                        )}

                        {cliente && cliente?.controleFluxo === "IS_ADMIN" && (
                            <Nav.Link href="/dashboardClientes">
                                <ButtonMenu>
                                    <button type="button" disabled>
                                        <span
                                            style={{
                                                padding: "0px 20px 0px 10px",
                                            }}
                                        >
                                            Dashboard
                                        </span>
                                    </button>
                                </ButtonMenu>
                            </Nav.Link>
                        )}

                        {cliente && cliente?.controleFluxo === "IS_ADMIN" && (
                            <Nav.Link
                                onClick={signOut}
                                style={{
                                    textAlign: "center",
                                    marginTop: 10,
                                    color: "#2d2d2d",
                                }}
                            >
                                <span style={{ padding: "0px 30px 0px 20px" }}>
                                    sair
                                </span>
                            </Nav.Link>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <Content>
                <ToastContainer />
                <MDBRow center>
                    <MDBCol
                        md="12"
                        style={{
                            color: "#00d1e6",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <span style={{ fontSize: 40 }}>
                            Fabricação de malha
                        </span>
                    </MDBCol>
                </MDBRow>

                <MDBRow start style={{ marginTop: 20 }}>
                    <MDBCol
                        md="12"
                        style={{
                            color: "black",
                        }}
                    >
                        {parse(
                            outros.find(
                                saida => saida.chave === "textoFabriqueMalha",
                            )?.valor || "Loading...",
                        )}
                    </MDBCol>
                </MDBRow>
                <MDBRow start style={{ marginTop: 10 }}>
                    <MDBCol
                        md="12"
                        style={{
                            color: "black",
                        }}
                    >
                        <h5>
                            Preencha o formulário abaixo e nossa equipe entrará
                            em contato.
                        </h5>
                    </MDBCol>
                </MDBRow>

                <Form
                    ref={formRef}
                    initialData={{
                        nome: cliente?.nome,
                        email: cliente?.email,
                    }}
                    onSubmit={handleSubmit}
                    style={{ marginTop: 20 }}
                >
                    <MDBRow>
                        <MDBCol md="6">
                            <Input name="nome" placeholder="Nome" type="text" />
                        </MDBCol>
                        <MDBCol md="6">
                            <Inputmask
                                name="telefone"
                                mask="celular"
                                placeholder="Celular - (xx) 9xxxx-xxxx"
                                type="text"
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow style={{ marginTop: 10 }}>
                        <MDBCol md="6">
                            <Input
                                name="email"
                                placeholder="Email"
                                type="text"
                            />
                        </MDBCol>
                        <MDBCol md="6">
                            <Input
                                name="descricao"
                                placeholder="Qual o tipo de malha você gostaria de fabricar?"
                                type="text"
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow style={{ marginTop: 15 }}>
                        <MDBCol md="6">
                            <BorderSelect
                                name="SelectEstado"
                                value={selectEstado}
                                hidden
                            >
                                <Select
                                    className="dropdown"
                                    placeholder="Estado"
                                    onChange={handleChangeEstado}
                                    options={estados}
                                    getOptionLabel={option => option.nome}
                                    getOptionValue={option => option.sigla}
                                    maxMenuHeight={160}
                                    theme={theme => ({
                                        ...theme,
                                        height: 100,
                                        borderRadius: 10,
                                    })}
                                />
                            </BorderSelect>
                        </MDBCol>
                        <MDBCol md="6">
                            <BorderSelect
                                name="SelectCidade"
                                value={selectCidade}
                                hidden
                                style={{ display: "none" }}
                            >
                                <Select
                                    className="dropdown"
                                    placeholder="Cidade"
                                    onChange={handleChangeCidade}
                                    options={cidades}
                                    getOptionLabel={option => option.nome}
                                    getOptionValue={option => option.id}
                                    maxMenuHeight={160}
                                    theme={theme => ({
                                        ...theme,
                                        height: 100,
                                        borderRadius: 10,
                                    })}
                                    isDisabled={notShowCidade}
                                />
                            </BorderSelect>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow center style={{ marginTop: 10 }}>
                        <MDBCol md="2" className="col-example">
                            <ButtonAzulEscuro
                                type="submit"
                                style={{ height: 40 }}
                            >
                                <strong>Enviar</strong>
                            </ButtonAzulEscuro>
                        </MDBCol>
                    </MDBRow>
                </Form>
            </Content>
            <EspacoFundoCinza>
                <FundoCinza>
                    <MDBRow
                        style={{
                            marginLeft: "1%",
                            marginRight: "3%",
                            marginBottom: "10%",
                        }}
                    >
                        <MDBCol
                            md="1"
                            className="text-center align-items-center"
                            style={{
                                marginTop: "2%",
                                marginBottom: "2%",
                                alignItems: "center",
                            }}
                        >
                            <img
                                width="100px"
                                src={imgLogoBranca}
                                alt="WeTextil"
                            />
                        </MDBCol>
                        <MDBCol
                            md="6"
                            style={{ marginTop: "2%", marginBottom: "2%" }}
                        >
                            <MDBRow className="row-cols-1 row-cols-md-1 g-1 text-center">
                                <strong>CATEGORIAS</strong>
                            </MDBRow>
                            <MDBRow className="row-cols-1 row-cols-md-3 g-3 text-center">
                                {categorias.map(categoria => (
                                    <MDBCol
                                        key={categoria.id}
                                        onClick={
                                            () => clickCategoria(categoria.nome)
                                            // eslint-disable-next-line react/jsx-curly-newline
                                        }
                                        style={{ cursor: "pointer" }}
                                    >
                                        <small>{categoria.nome}⠀</small>
                                    </MDBCol>
                                ))}
                            </MDBRow>
                        </MDBCol>
                        <MDBCol
                            md="3"
                            style={{ marginTop: "2%", marginBottom: "2%" }}
                        >
                            <MDBRow className="row-cols-1 row-cols-md-1 g-1 text-center">
                                <strong>AJUDA</strong>
                            </MDBRow>
                            <MDBRow
                                className="row-cols-1 row-cols-md-1 g-1 text-center"
                                style={{ marginTop: "2%" }}
                            >
                                <small>16 99787-1671</small>
                            </MDBRow>
                            <MDBRow
                                className="row-cols-1 row-cols-md-1 g-1 text-center"
                                style={{ marginTop: "1%" }}
                            >
                                <small>contato@wetextil.com</small>
                            </MDBRow>
                            <MDBRow
                                className="row-cols-1 row-cols-md-1 g-1 text-center"
                                style={{ marginTop: "1%" }}
                            >
                                <small>
                                    <a
                                        href="/termosDeUso"
                                        target="_blank"
                                        style={{ color: "white" }}
                                    >
                                        Política de Privacidade e Termos de Uso
                                    </a>
                                </small>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol
                            md="2"
                            style={{ marginTop: "2%", marginBottom: "2%" }}
                        >
                            <MDBRow className="row-cols-1 row-cols-md-1 g-1 text-center">
                                <strong>REDES SOCIAIS</strong>
                            </MDBRow>
                            <MDBRow
                                style={{
                                    marginTop: "4%",
                                    marginBottom: "2%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Link
                                    to={{
                                        pathname: `${
                                            outros.find(
                                                saida =>
                                                    saida.chave ===
                                                    "linkFacebook",
                                            )?.valor
                                        }`,
                                    }}
                                    target="_blank"
                                >
                                    <ButtonIcon type="submit">
                                        <FiFacebook />
                                    </ButtonIcon>
                                </Link>
                                <Link
                                    to={{
                                        pathname: `${
                                            outros.find(
                                                saida =>
                                                    saida.chave ===
                                                    "linkInstagram",
                                            )?.valor
                                        }`,
                                    }}
                                    target="_blank"
                                >
                                    <ButtonIcon type="submit">
                                        <FiInstagram />
                                    </ButtonIcon>
                                </Link>
                                <Link
                                    to={{
                                        pathname: `${
                                            outros.find(
                                                saida =>
                                                    saida.chave ===
                                                    "linkLinkedin",
                                            )?.valor
                                        }`,
                                    }}
                                    target="_blank"
                                >
                                    <ButtonIcon type="submit">
                                        <FiLinkedin />
                                    </ButtonIcon>
                                </Link>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow
                        style={{
                            marginLeft: "1%",
                            marginRight: "3%",
                        }}
                    >
                        <MDBCol
                            md="12"
                            className="text-center align-items-center"
                            style={{
                                marginTop: "2%",
                                marginBottom: "2%",
                                alignItems: "center",
                            }}
                        >
                            Copyright ©️ 2021 WeTêxtil. All trademarks and logos
                            are the property of their respective owners. All
                            rights reserved.
                        </MDBCol>
                    </MDBRow>
                </FundoCinza>
            </EspacoFundoCinza>
        </Container>
    );
};

export default FabriqueMalha;
