/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
    FiUser,
    FiInstagram,
    FiFacebook,
    FiPackage,
    FiPower,
    FiLinkedin,
} from "react-icons/fi";
import {
    MDBRow,
    MDBCol,
    MDBContainer,
    MDBFooter,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
} from "mdb-react-ui-kit";

import { Link, useHistory, Redirect } from "react-router-dom";

import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import api from "../../services/api";

import { useAuth } from "../../hooks/auth";
import {
    Container,
    Content,
    ButtonMenu,
    FundoCinza,
    EspacoFundoCinza,
} from "./styles";

import imgLogo from "../../assets/Logo.png";
import imgNossoMovimento01 from "../../assets/nossoMovimento01.png";
import imgNossoMovimento02 from "../../assets/nossoMovimento02.png";
import imgLogoBranca from "../../assets/LogoBranca.png";
import ButtonIcon from "../../components/ButtonIcon";

interface Categoria {
    id: string;
    nome: string;
    idCategoriaPai: string;
}

interface Tabela {
    id: string;
    nome: string;
    largura: string;
    peso: string;
    precoMinimoFabrica: string;
    precoMedioFabrica: string;
    precoMaximoFabrica: string;
    precoMinimoAtacado: string;
    precoMedioAtacado: string;
    precoMaximoAtacado: string;
}

interface Outros {
    id: string;
    chave: string;
    valor: string;
}

const Tabela: React.FC = () => {
    const { cliente, signOut } = useAuth();
    const history = useHistory();

    const [categorias, setCategorias] = useState<Categoria[]>([]);
    const [tabela, setTabela] = useState<Tabela[]>([]);
    const [outros, setOutros] = useState<Outros[]>([]);

    useEffect(() => {
        api.get("/categorias/categorias").then(response =>
            setCategorias(response.data),
        );
        api.get("/controle").then(response => setOutros(response.data));
        api.get("/tabela").then(response => setTabela(response.data));
    }, []);

    const clickCategoria = (nomeCategoria: string) => {
        const insertPesquisa = {
            idCliente: cliente.id,
            nomeCliente: cliente.nome,
            pesquisa: nomeCategoria,
        };

        api.post("/pesquisa", insertPesquisa);

        localStorage.setItem("@dbwetextil:nomeCategoria", nomeCategoria);
        history.push("/pesquisa");
    };

    // Redirecionamentos de páginas privadas
    if (cliente.controleFluxo === "FALTA_PAGAMENTO") {
        return <Redirect to={`/planosPagamento/${cliente.id}`} />;
    }
    if (cliente.controleFluxo === "FALTA_CONFIRMAR_CELULAR") {
        return <Redirect to="/confirmaSMS" />;
    }

    return (
        <Container>
            <Navbar
                collapseOnSelect
                expand="lg"
                style={{
                    background: "#ffffff",
                }}
            >
                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    style={{ border: "none" }}
                />
                <Navbar.Brand style={{ marginLeft: "2%" }}>
                    <Link to="/">
                        <img width="125px" src={imgLogo} alt="WeTextil" />
                    </Link>
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto text-center" />
                    <Nav>
                        <Nav.Link
                            href="/"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                início
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/nossoMovimento"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                sobre
                            </span>
                        </Nav.Link>

                        <Nav.Link
                            href="/planos"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                planos
                            </span>
                        </Nav.Link>

                        <Nav.Link
                            href="#"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#CAC9C8",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                fabrique sua malha
                            </span>
                        </Nav.Link>

                        <Nav.Link
                            href="#"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#CAC9C8",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                mapeie seu mercado
                            </span>
                        </Nav.Link>

                        <NavDropdown
                            title="categorias"
                            id="basic-nav-dropdown"
                            style={{
                                padding: "0px 20px 0px 20px",
                                textAlign: "center",
                                marginTop: 8,
                                fontWeight: "bold",
                                color: "#2d2d2d",
                            }}
                        >
                            {categorias.map(categoria => (
                                <NavDropdown.Item
                                    onClick={
                                        () => clickCategoria(categoria.nome)
                                        // eslint-disable-next-line react/jsx-curly-newline
                                    }
                                >
                                    {categoria.nome}
                                </NavDropdown.Item>
                            ))}
                        </NavDropdown>
                        {!cliente && (
                            <Nav.Link href="/cliente">
                                <ButtonMenu>
                                    <button type="button">
                                        <span
                                            style={{
                                                padding: "0px 20px 0px 20px",
                                            }}
                                        >
                                            cadastre-se
                                        </span>
                                    </button>
                                </ButtonMenu>
                            </Nav.Link>
                        )}
                        {!cliente && (
                            <Nav.Link
                                href="/login"
                                style={{
                                    textAlign: "center",
                                    marginTop: 8,
                                    color: "#2d2d2d",
                                }}
                            >
                                <span style={{ padding: "0px 20px 0px 30px" }}>
                                    login
                                </span>
                            </Nav.Link>
                        )}

                        {cliente && cliente?.controleFluxo !== "IS_ADMIN" && (
                            <Nav.Link
                                href="/minhaVitrine"
                                style={{
                                    textAlign: "center",
                                    marginTop: 8,
                                    color: "#2d2d2d",
                                }}
                            >
                                <span style={{ padding: "0px 20px 0px 20px" }}>
                                    minha vitrine
                                </span>
                            </Nav.Link>
                        )}

                        {cliente && cliente?.controleFluxo !== "IS_ADMIN" && (
                            <NavDropdown
                                title="meu perfil"
                                id="basic-nav-dropdown"
                                style={{
                                    padding: "0px 30px 0px 20px",
                                    textAlign: "center",
                                    marginTop: 8,
                                    fontWeight: "bold",
                                    color: "#2d2d2d",
                                }}
                            >
                                <NavDropdown.Item href="/minhaConta">
                                    minha conta
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={signOut}>
                                    sair
                                </NavDropdown.Item>
                            </NavDropdown>
                        )}

                        {cliente && cliente?.controleFluxo === "IS_ADMIN" && (
                            <Nav.Link href="/dashboardClientes">
                                <ButtonMenu>
                                    <button type="button" disabled>
                                        <span
                                            style={{
                                                padding: "0px 20px 0px 10px",
                                            }}
                                        >
                                            Dashboard
                                        </span>
                                    </button>
                                </ButtonMenu>
                            </Nav.Link>
                        )}

                        {cliente && cliente?.controleFluxo === "IS_ADMIN" && (
                            <Nav.Link
                                onClick={signOut}
                                style={{
                                    textAlign: "center",
                                    marginTop: 10,
                                    color: "#2d2d2d",
                                }}
                            >
                                <span style={{ padding: "0px 30px 0px 20px" }}>
                                    sair
                                </span>
                            </Nav.Link>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <Content>
                <MDBTable responsive bordered striped>
                    <MDBTableHead className="text-center">
                        <tr>
                            <th
                                scope="col"
                                colSpan={9}
                                style={{
                                    verticalAlign: "middle",
                                }}
                            >
                                <strong>
                                    TABELA WETÊXTIL - CAMPEÕES DE BUSCAS -
                                    MALHAS ***
                                </strong>
                            </th>
                        </tr>
                        <tr>
                            <th
                                scope="col"
                                colSpan={9}
                                style={{
                                    verticalAlign: "middle",
                                    textAlign: "right",
                                }}
                            >
                                <small>
                                    MÊS DE REFERÊNCIA -{" "}
                                    {
                                        outros.find(
                                            saida =>
                                                saida.chave === "mesTabela",
                                        )?.valor
                                    }
                                    /
                                    {
                                        outros.find(
                                            saida =>
                                                saida.chave === "anoTabela",
                                        )?.valor
                                    }
                                </small>
                            </th>
                        </tr>
                        <tr>
                            <th
                                scope="col"
                                rowSpan={2}
                                style={{
                                    verticalAlign: "middle",
                                }}
                            >
                                PRODUTO
                            </th>
                            <th
                                scope="col"
                                colSpan={3}
                                // style={{ background: "#00d1e6" }}
                            >
                                FÁBRICA
                            </th>
                        </tr>
                        <tr>
                            <th scope="col">PREÇO MÉDIO</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {tabela.map(registro => (
                            <tr>
                                <th scope="row">{registro.nome}</th>
                                <td className="text-center">
                                    R$ {registro.precoMedioFabrica}/
                                    <small>kg</small>
                                </td>
                            </tr>
                        ))}
                    </MDBTableBody>
                    <tfoot>
                        <tr>
                            <td colSpan={9}>
                                <small>
                                    * Esta tabela é apenas uma referência, as
                                    cotações de preços são feitas com base em
                                    parceiros da WeTêxtil.
                                </small>
                                <br />
                                <small>
                                    * Os produtos são selecionados a partir da
                                    nossa visão dos produtos mais buscados.
                                </small>
                                <br />
                                <small>
                                    * Os preços são coletados em um determinado
                                    dia do mês e podem variar ao longo do
                                    período.
                                </small>
                                <br />
                                <small>
                                    * As diferenças de impostos por região não
                                    estão consideradas.
                                </small>
                            </td>
                        </tr>
                    </tfoot>
                </MDBTable>
            </Content>

            <EspacoFundoCinza>
                <FundoCinza>
                    <MDBRow
                        style={{
                            marginLeft: "1%",
                            marginRight: "3%",
                            marginBottom: "10%",
                        }}
                    >
                        <MDBCol
                            md="1"
                            className="text-center align-items-center"
                            style={{
                                marginTop: "2%",
                                marginBottom: "2%",
                                alignItems: "center",
                            }}
                        >
                            <img
                                width="100px"
                                src={imgLogoBranca}
                                alt="WeTextil"
                            />
                        </MDBCol>
                        <MDBCol
                            md="6"
                            style={{ marginTop: "2%", marginBottom: "2%" }}
                        >
                            <MDBRow className="row-cols-1 row-cols-md-1 g-1 text-center">
                                <strong>CATEGORIAS</strong>
                            </MDBRow>
                            <MDBRow className="row-cols-1 row-cols-md-3 g-3 text-center">
                                {categorias.map(categoria => (
                                    <MDBCol
                                        key={categoria.id}
                                        onClick={
                                            () => clickCategoria(categoria.nome)
                                            // eslint-disable-next-line react/jsx-curly-newline
                                        }
                                        style={{ cursor: "pointer" }}
                                    >
                                        <small>{categoria.nome}⠀</small>
                                    </MDBCol>
                                ))}
                            </MDBRow>
                        </MDBCol>
                        <MDBCol
                            md="3"
                            style={{ marginTop: "2%", marginBottom: "2%" }}
                        >
                            <MDBRow className="row-cols-1 row-cols-md-1 g-1 text-center">
                                <strong>AJUDA</strong>
                            </MDBRow>
                            <MDBRow
                                className="row-cols-1 row-cols-md-1 g-1 text-center"
                                style={{ marginTop: "2%" }}
                            >
                                <small>16 99787-1671</small>
                            </MDBRow>
                            <MDBRow
                                className="row-cols-1 row-cols-md-1 g-1 text-center"
                                style={{ marginTop: "1%" }}
                            >
                                <small>contato@wetextil.com</small>
                            </MDBRow>
                            <MDBRow
                                className="row-cols-1 row-cols-md-1 g-1 text-center"
                                style={{ marginTop: "1%" }}
                            >
                                <small>
                                    <a
                                        href="/termosDeUso"
                                        target="_blank"
                                        style={{ color: "white" }}
                                    >
                                        Política de Privacidade e Termos de Uso
                                    </a>
                                </small>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol
                            md="2"
                            style={{ marginTop: "2%", marginBottom: "2%" }}
                        >
                            <MDBRow className="row-cols-1 row-cols-md-1 g-1 text-center">
                                <strong>REDES SOCIAIS</strong>
                            </MDBRow>
                            <MDBRow
                                style={{
                                    marginTop: "4%",
                                    marginBottom: "2%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Link
                                    to={{
                                        pathname: `${
                                            outros.find(
                                                saida =>
                                                    saida.chave ===
                                                    "linkFacebook",
                                            )?.valor
                                        }`,
                                    }}
                                    target="_blank"
                                >
                                    <ButtonIcon type="submit">
                                        <FiFacebook />
                                    </ButtonIcon>
                                </Link>
                                <Link
                                    to={{
                                        pathname: `${
                                            outros.find(
                                                saida =>
                                                    saida.chave ===
                                                    "linkInstagram",
                                            )?.valor
                                        }`,
                                    }}
                                    target="_blank"
                                >
                                    <ButtonIcon type="submit">
                                        <FiInstagram />
                                    </ButtonIcon>
                                </Link>
                                <Link
                                    to={{
                                        pathname: `${
                                            outros.find(
                                                saida =>
                                                    saida.chave ===
                                                    "linkLinkedin",
                                            )?.valor
                                        }`,
                                    }}
                                    target="_blank"
                                >
                                    <ButtonIcon type="submit">
                                        <FiLinkedin />
                                    </ButtonIcon>
                                </Link>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow
                        style={{
                            marginLeft: "1%",
                            marginRight: "3%",
                        }}
                    >
                        <MDBCol
                            md="12"
                            className="text-center align-items-center"
                            style={{
                                marginTop: "2%",
                                marginBottom: "2%",
                                alignItems: "center",
                            }}
                        >
                            Copyright ©️ 2021 WeTêxtil. All trademarks and logos
                            are the property of their respective owners. All
                            rights reserved.
                        </MDBCol>
                    </MDBRow>
                </FundoCinza>
            </EspacoFundoCinza>
        </Container>
    );
};

export default Tabela;
