/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useCallback } from "react";

import FormReact from "react-bootstrap/Form";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import { Link, Redirect } from "react-router-dom";
import { Nav, Navbar, NavDropdown, Row, Col } from "react-bootstrap";
import moment from "moment";
import * as Yup from "yup";
import {
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBCol,
    MDBRow,
} from "mdb-react-ui-kit";
import { FiEdit, FiUsers, FiX } from "react-icons/fi";
import { CSVLink } from "react-csv";
import { ToastContainer, toast } from "react-toastify";
import api from "../../../services/api";
import imgLogo from "../../../assets/Logo.png";

import { useAuth } from "../../../hooks/auth";
import ButtonAzulEscuro from "../../../components/ButtonAzulEscuro";

import { Container, Content } from "./styles";
import ButtonRoxo from "../../../components/ButtonRoxo";
import ButtonAzul from "../../../components/ButtonAzul";
import InputPesquisa from "../../../components/InputPesquisa";
import getValidationErrors from "../../../utils/getValidationErrors";

interface Tabela {
    id: string;
    nome: string;
    largura: string;
    peso: string;
    precoMinimoFabrica: string;
    precoMedioFabrica: string;
    precoMaximoFabrica: string;
    precoMinimoAtacado: string;
    precoMedioAtacado: string;
    precoMaximoAtacado: string;
}

interface AdicionarRegistro {
    nome: string;
    largura: string;
    peso: string;
    precoMinimoFabrica: string;
    precoMedioFabrica: string;
    precoMaximoFabrica: string;
    precoMinimoAtacado: string;
    precoMedioAtacado: string;
    precoMaximoAtacado: string;
}

const TabelaWeTextil: React.FC = () => {
    const { cliente } = useAuth();
    const [tabela, setTabela] = useState<Tabela[]>([]);
    const formRef = useRef<FormHandles>(null);

    const headers = [
        { label: "id", key: "id" },
        { label: "Nome", key: "nome" },
        { label: "Preco Minimo Fabrica", key: "precoMinimoFabrica" },
        { label: "Preco Medio Fabrica", key: "precoMedioFabrica" },
        { label: "Preco Maximo Fabrica", key: "precoMaximoFabrica" },
        { label: "Preco Minimo Atacado", key: "precoMinimoAtacado" },
        { label: "Preco Medio Atacado", key: "precoMedioAtacado" },
        { label: "Preco Maximo Atacado", key: "precoMaximoAtacado" },
    ];

    const [hiddenAdicionar, setHiddenAdicionar] = useState(true);
    const [hiddenExcluir, setHiddenExcluir] = useState(true);
    const [hiddenAtualizar, setHiddenAtualizar] = useState(true);
    const [hiddenInfosAtualizar, setHiddenInfosAtualizar] = useState(true);

    const [infosRegistroSelecionado, setInfosRegistroSelecionado] =
        useState<Tabela>();
    const [registroSelecionado, setRegistroSelecionado] = useState<string>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleRegistroSelecionado = (e: any) => {
        setRegistroSelecionado(e.target.value);
        if (e.target.value) {
            setHiddenExcluir(false);
            setHiddenAtualizar(false);
            setInfosRegistroSelecionado(
                tabela.find(saida => saida.id === e.target.value),
            );
        }
    };

    const excluirRegistro = () => {
        api.delete(`/tabela/${registroSelecionado}`)
            .then(() => {
                toast.success("Registro excluído com sucesso!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
                api.get("/tabela").then(response => setTabela(response.data));
            })
            .catch(() => {
                toast.error("Erro! Tente novamente!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            });
    };

    const handleSubmitAdicionar = useCallback(
        async (data: AdicionarRegistro) => {
            try {
                const {
                    nome,
                    largura,
                    peso,
                    precoMinimoFabrica,
                    precoMedioFabrica,
                    precoMaximoFabrica,
                    precoMinimoAtacado,
                    precoMedioAtacado,
                    precoMaximoAtacado,
                } = data;

                const formData = {
                    nome,
                    largura: !largura ? null : largura,
                    peso: !peso ? null : peso,
                    precoMinimoFabrica: !precoMinimoFabrica
                        ? null
                        : precoMinimoFabrica,
                    precoMedioFabrica: !precoMedioFabrica
                        ? null
                        : precoMedioFabrica,
                    precoMaximoFabrica: !precoMaximoFabrica
                        ? null
                        : precoMaximoFabrica,
                    precoMinimoAtacado: !precoMinimoAtacado
                        ? null
                        : precoMinimoAtacado,
                    precoMedioAtacado: !precoMedioAtacado
                        ? null
                        : precoMedioAtacado,
                    precoMaximoAtacado: !precoMaximoAtacado
                        ? null
                        : precoMaximoAtacado,
                };

                await api
                    .post("/tabela", formData)
                    .then(() => {
                        toast.success("Inserido com sucesso!", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                        });
                        api.get("/tabela").then(response =>
                            setTabela(response.data),
                        );
                        setHiddenAdicionar(true);
                    })
                    .catch(() => {
                        toast.error("Erro! Tente novamente!", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                        });
                    });
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);
                    return;
                }

                toast.error("Erro no servidor! Tente novamente!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            }
        },
        [],
    );

    const handleSubmitAtualizar = useCallback(
        async (data: AdicionarRegistro) => {
            try {
                const {
                    nome,
                    largura,
                    peso,
                    precoMinimoFabrica,
                    precoMedioFabrica,
                    precoMaximoFabrica,
                    precoMinimoAtacado,
                    precoMedioAtacado,
                    precoMaximoAtacado,
                } = data;

                const formData = {
                    nome,
                    largura: !largura ? null : largura,
                    peso: !peso ? null : peso,
                    precoMinimoFabrica: !precoMinimoFabrica
                        ? null
                        : precoMinimoFabrica,
                    precoMedioFabrica: !precoMedioFabrica
                        ? null
                        : precoMedioFabrica,
                    precoMaximoFabrica: !precoMaximoFabrica
                        ? null
                        : precoMaximoFabrica,
                    precoMinimoAtacado: !precoMinimoAtacado
                        ? null
                        : precoMinimoAtacado,
                    precoMedioAtacado: !precoMedioAtacado
                        ? null
                        : precoMedioAtacado,
                    precoMaximoAtacado: !precoMaximoAtacado
                        ? null
                        : precoMaximoAtacado,
                };

                await api
                    .put(`/tabela/${registroSelecionado}`, formData)
                    .then(() => {
                        toast.success("Atualizado com sucesso!", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                        });
                        api.get("/tabela").then(response =>
                            setTabela(response.data),
                        );
                        setHiddenInfosAtualizar(true);
                    })
                    .catch(() => {
                        toast.error("Erro! Tente novamente!", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                        });
                    });
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);
                    return;
                }

                toast.error("Erro no servidor! Tente novamente!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            }
        },
        [registroSelecionado],
    );

    useEffect(() => {
        api.get("/tabela").then(response => setTabela(response.data));
    }, []);

    // Redirecionamentos de páginas privadas
    if (cliente.controleFluxo !== "IS_ADMIN") {
        return <Redirect to="/" />;
    }

    return (
        <Container>
            <Navbar
                collapseOnSelect
                expand="lg"
                style={{
                    background: "#ffffff",
                }}
            >
                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    style={{ border: "none" }}
                />
                <Navbar.Brand style={{ marginLeft: "2%" }}>
                    <Link to="/">
                        <img width="125px" src={imgLogo} alt="WeTextil" />
                    </Link>
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto text-center" />
                    <Nav>
                        <Nav.Link
                            href="/dashboardMapeamentoMercado"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Mapeie Mercado
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardFabricacaoMalha"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Fabrique Malha
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardPesquisa"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Pesquisas
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardTabelaWeTextil"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#846cf9",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                <strong>Tabela WeTêxtil</strong>
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardClientes"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Clientes
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardCategorias"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Categorias
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardAssinaturas"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Assinaturas
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardProdutos"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Produtos/Serviços
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardOutros"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Outros
                            </span>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Content>
                <ToastContainer />
                <MDBRow style={{ marginBottom: 20, marginTop: -20 }} end>
                    <MDBCol md="3" hidden={hiddenExcluir}>
                        <ButtonRoxo
                            style={{ height: 50 }}
                            onClick={excluirRegistro}
                        >
                            Remover
                        </ButtonRoxo>
                    </MDBCol>
                    <MDBCol md="3" hidden={hiddenAtualizar}>
                        <ButtonRoxo
                            style={{ height: 50 }}
                            onClick={() => setHiddenInfosAtualizar(false)}
                        >
                            Atualizar
                        </ButtonRoxo>
                    </MDBCol>
                    <MDBCol md="3">
                        <ButtonAzulEscuro
                            style={{ height: 50 }}
                            onClick={() => setHiddenAdicionar(false)}
                        >
                            Adicionar
                        </ButtonAzulEscuro>
                    </MDBCol>
                    <MDBCol md="3">
                        <CSVLink
                            data={tabela}
                            headers={headers}
                            filename={`TabelaWeTextil-${moment().format(
                                "DD/MM/YYYY HH:mm",
                            )}`}
                            style={{ textDecoration: "none" }}
                        >
                            <ButtonAzulEscuro
                                style={{ height: 50 }}
                                type="submit"
                            >
                                Download
                            </ButtonAzulEscuro>
                        </CSVLink>
                    </MDBCol>
                </MDBRow>

                <Form
                    ref={formRef}
                    onSubmit={handleSubmitAtualizar}
                    hidden={hiddenInfosAtualizar}
                >
                    <MDBRow style={{ marginBottom: 20 }} end>
                        <MDBCol md="4" style={{ marginTop: 17 }}>
                            <h6 style={{ textAlign: "left" }}>Nome</h6>
                            <InputPesquisa
                                icon={FiUsers}
                                placeholder="Nome"
                                defaultValue={infosRegistroSelecionado?.nome}
                                name="nome"
                                type="text"
                            />
                        </MDBCol>
                        <MDBCol md="4" style={{ marginTop: 17 }}>
                            <h6 style={{ textAlign: "left" }}>Largura</h6>
                            <InputPesquisa
                                icon={FiEdit}
                                placeholder="Largura"
                                defaultValue={infosRegistroSelecionado?.largura}
                                name="largura"
                                type="text"
                            />
                        </MDBCol>
                        <MDBCol md="4" style={{ marginTop: 17 }}>
                            <h6 style={{ textAlign: "left" }}>Peso</h6>
                            <InputPesquisa
                                icon={FiEdit}
                                placeholder="Peso"
                                defaultValue={infosRegistroSelecionado?.peso}
                                name="peso"
                                type="text"
                            />
                        </MDBCol>
                        <MDBCol md="4" style={{ marginTop: 17 }}>
                            <h6 style={{ textAlign: "left" }}>
                                Preço Mínimo Fábrica
                            </h6>
                            <InputPesquisa
                                icon={FiEdit}
                                placeholder="precoMinimoFabrica"
                                defaultValue={
                                    infosRegistroSelecionado?.precoMinimoFabrica
                                }
                                name="precoMinimoFabrica"
                                type="text"
                            />
                        </MDBCol>
                        <MDBCol md="4" style={{ marginTop: 17 }}>
                            <h6 style={{ textAlign: "left" }}>
                                Preço Médio Fábrica
                            </h6>
                            <InputPesquisa
                                icon={FiEdit}
                                placeholder="precoMedioFabrica"
                                defaultValue={
                                    infosRegistroSelecionado?.precoMedioFabrica
                                }
                                name="precoMedioFabrica"
                                type="text"
                            />
                        </MDBCol>
                        <MDBCol md="4" style={{ marginTop: 17 }}>
                            <h6 style={{ textAlign: "left" }}>
                                Preço Máximo Fábrica
                            </h6>
                            <InputPesquisa
                                icon={FiEdit}
                                placeholder="precoMaximoFabrica"
                                defaultValue={
                                    infosRegistroSelecionado?.precoMaximoFabrica
                                }
                                name="precoMaximoFabrica"
                                type="text"
                            />
                        </MDBCol>
                        <MDBCol md="4" style={{ marginTop: 17 }}>
                            <h6 style={{ textAlign: "left" }}>
                                Preço Mínimo Atacado
                            </h6>
                            <InputPesquisa
                                icon={FiEdit}
                                placeholder="precoMinimoAtacado"
                                defaultValue={
                                    infosRegistroSelecionado?.precoMinimoAtacado
                                }
                                name="precoMinimoAtacado"
                                type="text"
                            />
                        </MDBCol>
                        <MDBCol md="4" style={{ marginTop: 17 }}>
                            <h6 style={{ textAlign: "left" }}>
                                Preço Médio Atacado
                            </h6>
                            <InputPesquisa
                                icon={FiEdit}
                                placeholder="precoMedioAtacado"
                                defaultValue={
                                    infosRegistroSelecionado?.precoMedioAtacado
                                }
                                name="precoMedioAtacado"
                                type="text"
                            />
                        </MDBCol>
                        <MDBCol md="4" style={{ marginTop: 17 }}>
                            <h6 style={{ textAlign: "left" }}>
                                Preço Máximo Atacado
                            </h6>
                            <InputPesquisa
                                icon={FiEdit}
                                placeholder="precoMaximoAtacado"
                                defaultValue={
                                    infosRegistroSelecionado?.precoMaximoAtacado
                                }
                                name="precoMaximoAtacado"
                                type="text"
                            />
                        </MDBCol>

                        <MDBCol md="3" style={{ marginTop: 15 }}>
                            <ButtonRoxo
                                type="submit"
                                style={{ height: 40, marginTop: 0 }}
                            >
                                Confirmar
                            </ButtonRoxo>
                        </MDBCol>
                        <MDBCol md="1" style={{ marginTop: 15 }}>
                            <ButtonAzulEscuro
                                style={{
                                    height: 40,
                                    marginTop: 0,
                                    background: "white",
                                    color: "black",
                                }}
                                onClick={() => {
                                    setHiddenInfosAtualizar(true);
                                }}
                            >
                                <strong>
                                    <FiX />
                                </strong>
                            </ButtonAzulEscuro>
                        </MDBCol>
                    </MDBRow>
                </Form>

                <Form
                    ref={formRef}
                    onSubmit={handleSubmitAdicionar}
                    hidden={hiddenAdicionar}
                >
                    <MDBRow style={{ marginBottom: 20 }} end>
                        <MDBCol md="4" style={{ marginTop: 17 }}>
                            <h6 style={{ textAlign: "left" }}>Nome</h6>
                            <InputPesquisa
                                icon={FiUsers}
                                placeholder="Nome"
                                name="nome"
                                type="text"
                            />
                        </MDBCol>
                        <MDBCol md="4" style={{ marginTop: 17 }}>
                            <h6 style={{ textAlign: "left" }}>Largura</h6>
                            <InputPesquisa
                                icon={FiEdit}
                                placeholder="Largura"
                                name="largura"
                                type="text"
                            />
                        </MDBCol>
                        <MDBCol md="4" style={{ marginTop: 17 }}>
                            <h6 style={{ textAlign: "left" }}>Peso</h6>
                            <InputPesquisa
                                icon={FiEdit}
                                placeholder="Peso"
                                name="peso"
                                type="text"
                            />
                        </MDBCol>
                        <MDBCol md="4" style={{ marginTop: 17 }}>
                            <h6 style={{ textAlign: "left" }}>
                                Preço Mínimo Fábrica
                            </h6>
                            <InputPesquisa
                                icon={FiEdit}
                                placeholder="precoMinimoFabrica"
                                name="precoMinimoFabrica"
                                type="text"
                            />
                        </MDBCol>
                        <MDBCol md="4" style={{ marginTop: 17 }}>
                            <h6 style={{ textAlign: "left" }}>
                                Preço Médio Fábrica
                            </h6>
                            <InputPesquisa
                                icon={FiEdit}
                                placeholder="precoMedioFabrica"
                                name="precoMedioFabrica"
                                type="text"
                            />
                        </MDBCol>
                        <MDBCol md="4" style={{ marginTop: 17 }}>
                            <h6 style={{ textAlign: "left" }}>
                                Preço Máximo Fábrica
                            </h6>
                            <InputPesquisa
                                icon={FiEdit}
                                placeholder="precoMaximoFabrica"
                                name="precoMaximoFabrica"
                                type="text"
                            />
                        </MDBCol>
                        <MDBCol md="4" style={{ marginTop: 17 }}>
                            <h6 style={{ textAlign: "left" }}>
                                Preço Mínimo Atacado
                            </h6>
                            <InputPesquisa
                                icon={FiEdit}
                                placeholder="precoMinimoAtacado"
                                name="precoMinimoAtacado"
                                type="text"
                            />
                        </MDBCol>
                        <MDBCol md="4" style={{ marginTop: 17 }}>
                            <h6 style={{ textAlign: "left" }}>
                                Preço Médio Atacado
                            </h6>
                            <InputPesquisa
                                icon={FiEdit}
                                placeholder="precoMedioAtacado"
                                name="precoMedioAtacado"
                                type="text"
                            />
                        </MDBCol>
                        <MDBCol md="4" style={{ marginTop: 17 }}>
                            <h6 style={{ textAlign: "left" }}>
                                Preço Máximo Atacado
                            </h6>
                            <InputPesquisa
                                icon={FiEdit}
                                placeholder="precoMaximoAtacado"
                                name="precoMaximoAtacado"
                                type="text"
                            />
                        </MDBCol>

                        <MDBCol md="3" style={{ marginTop: 15 }}>
                            <ButtonRoxo
                                type="submit"
                                style={{ height: 40, marginTop: 0 }}
                            >
                                Confirmar
                            </ButtonRoxo>
                        </MDBCol>
                        <MDBCol md="1" style={{ marginTop: 15 }}>
                            <ButtonAzulEscuro
                                style={{
                                    height: 40,
                                    marginTop: 0,
                                    background: "white",
                                    color: "black",
                                }}
                                onClick={() => {
                                    setHiddenAdicionar(true);
                                }}
                            >
                                <strong>
                                    <FiX />
                                </strong>
                            </ButtonAzulEscuro>
                        </MDBCol>
                    </MDBRow>
                </Form>
                <MDBTable responsive bordered striped>
                    <MDBTableHead className="text-center" dark>
                        <tr>
                            <th
                                scope="col"
                                rowSpan={2}
                                style={{
                                    verticalAlign: "middle",
                                }}
                            >
                                Selecionar
                            </th>
                            <th
                                scope="col"
                                rowSpan={2}
                                style={{
                                    verticalAlign: "middle",
                                }}
                            >
                                PRODUTO
                            </th>

                            {/*
                            <th
                                scope="col"
                                rowSpan={2}
                                style={{
                                    verticalAlign: "middle",
                                }}
                            >
                                LARGURA(m)
                            </th>
                            <th
                                scope="col"
                                rowSpan={2}
                                style={{
                                    verticalAlign: "middle",
                                }}
                            >
                                PESO(g/m2)
                            </th>
                            */}
                            <th
                                scope="col"
                                colSpan={3}
                                // style={{ background: "#00d1e6" }}
                            >
                                FÁBRICA
                            </th>

                            <th
                                scope="col"
                                colSpan={3}
                                // style={{ background: "#846cf9" }}
                            >
                                ATACADO
                            </th>
                        </tr>
                        <tr>
                            <th scope="col">PREÇO MÍNIMO</th>
                            <th scope="col">PREÇO MÉDIO</th>
                            <th scope="col">PREÇO MÁXIMO</th>
                            <th scope="col">PREÇO MÍNIMO</th>
                            <th scope="col">PREÇO MÉDIO</th>
                            <th scope="col">PREÇO MÁXIMO</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {tabela.map(registro => (
                            <tr>
                                <td>
                                    <FormReact.Check
                                        name="selecionado"
                                        type="radio"
                                        value={registro.id}
                                        onClick={handleRegistroSelecionado}
                                    />
                                </td>
                                <td>{registro.nome}</td>
                                {/*
                                <td>{registro.largura}</td>
                                <td>{registro.peso}</td>
                                */}
                                <td>{registro.precoMinimoFabrica}</td>
                                <td>{registro.precoMedioFabrica}</td>
                                <td>{registro.precoMaximoFabrica}</td>
                                <td>{registro.precoMinimoAtacado}</td>
                                <td>{registro.precoMedioAtacado}</td>
                                <td>{registro.precoMaximoAtacado}</td>
                            </tr>
                        ))}
                    </MDBTableBody>
                </MDBTable>
            </Content>
        </Container>
    );
};

export default TabelaWeTextil;
