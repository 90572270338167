// eslint-disable-next-line no-unused-vars
import React, { ButtonHTMLAttributes } from "react";

import { Container } from "./styles";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    loading?: boolean;
};

const ButtonAzulEscuro: React.FC<ButtonProps> = ({
    children,
    loading,
    ...rest
}) => (
    <Container type="button" {...rest}>
        {loading ? "Carregando..." : children}
    </Container>
);

export default ButtonAzulEscuro;
