import styled from "styled-components";

export const Container = styled.div``;

export const Content = styled.main`
    width: 100%;
    text-align: center;
    color: black;
    padding: 2% 2% 2% 2%;
    margin-top: 1%;
    margin-bottom: 2%;
`;
