/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

import { Link, useHistory, useParams, Redirect } from "react-router-dom";
import FormReact from "react-bootstrap/Form";
import moment from "moment";

import { Nav, Navbar, NavDropdown, Row, Col } from "react-bootstrap";

import { CSVLink } from "react-csv";
import { StringifyOptions } from "querystring";
import { MDBCardImage, MDBCol, MDBRow } from "mdb-react-ui-kit";
import ButtonAzulEscuro from "../../../../components/ButtonAzulEscuro";
import imgLogo from "../../../../assets/Logo.png";

import api from "../../../../services/api";

import { useAuth } from "../../../../hooks/auth";

import avatarPadrao from "../../../../assets/avatar.png";

import { AvatarInput, Container, Content, ImagemInput } from "./styles";

interface Categoria {
    id: string;
    nome: string;
    idCategoriaPai: string;
}

interface Cidade {
    nome: string;
    sigla?: string;
    regiao?: string;
}

interface Estado {
    nome: string;
    sigla: string;
    regiao: string;
}

interface Assinatura {
    code: string;
    name: string;
    reference: string;
    status: string;
    senderName: string;
    created_at: string;
}

interface Produto {
    id: string;
    nome: string;
    descricao: string;
    img01_url: string;
    cliente: Cliente;
    categorias: Categoria;
    isProduto: boolean;
    preco: string;
    unidadeMedida: string;
    isVisivel: boolean;
}

interface params {
    idClienteRota: string;
}

interface Cliente {
    id: string;
    controleFluxo: string;
    isPessoaJuridica: string;
    nome: string;
    docIdent: string;
    celular: string;
    email: string;
    celularValidado: boolean;
    news: string;
    plano: string;
    ativo: boolean;
    cupomDesconto: string;
    biografia: string;
    linkInstagram: string;
    linkFacebook: string;
    linkLinkedin: string;
    linkSite: string;
    cidade: Cidade;
    estado: Estado;
    categorias: Categoria[];
    produtos: Produto[];
    assinaturas: Assinatura[];
    avatar_url: string;
    created_at: string;
    updated_at: string;
}

const Detalhes: React.FC = () => {
    const { cliente } = useAuth();
    const { idClienteRota } = useParams<params>();

    const [clienteAtual, setClienteAtual] = useState<Cliente>();
    const history = useHistory();

    useEffect(() => {
        api.get(`/clientes/${idClienteRota}`).then(response =>
            setClienteAtual(response.data),
        );
    });

    // Redirecionamentos de páginas privadas
    if (cliente.controleFluxo !== "IS_ADMIN") {
        return <Redirect to="/" />;
    }

    return (
        <Container>
            <Navbar
                collapseOnSelect
                expand="lg"
                style={{
                    background: "#ffffff",
                }}
            >
                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    style={{ border: "none" }}
                />
                <Navbar.Brand style={{ marginLeft: "2%" }}>
                    <Link to="/">
                        <img width="125px" src={imgLogo} alt="WeTextil" />
                    </Link>
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto text-center" />
                    <Nav>
                        <Nav.Link
                            href="/dashboardMapeamentoMercado"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Mapeie Mercado
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardFabricacaoMalha"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Fabrique Malha
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardPesquisa"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Pesquisas
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardTabelaWeTextil"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Tabela WeTêxtil
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardClientes"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#846cf9",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                <strong>Clientes</strong>
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardCategorias"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Categorias
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardAssinaturas"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Assinaturas
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardProdutos"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Produtos/Serviços
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardOutros"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Outros
                            </span>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <Content>
                <h2>
                    <strong>Dados Cadastrais</strong>
                </h2>
                <MDBRow>
                    <MDBCol
                        md="3"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <AvatarInput>
                            {clienteAtual?.avatar_url && (
                                <img
                                    src={clienteAtual?.avatar_url}
                                    alt="Avatar"
                                />
                            )}
                            {!clienteAtual?.avatar_url && (
                                <img src={avatarPadrao} alt="Avatar" />
                            )}
                        </AvatarInput>
                    </MDBCol>
                    <MDBCol md="4">
                        <h5>
                            <strong>Nome:</strong> {clienteAtual?.nome}
                        </h5>
                        <h5>
                            <strong>Email:</strong> {clienteAtual?.email}
                        </h5>
                        {clienteAtual?.isPessoaJuridica === "true" && (
                            <h5>
                                <strong>Tipo:</strong> Pessoa Jurídica
                            </h5>
                        )}
                        {clienteAtual?.isPessoaJuridica === "false" && (
                            <h5>
                                <strong>Tipo:</strong> Pessoa Física
                            </h5>
                        )}
                        <h5>
                            <strong>Documento:</strong> {clienteAtual?.docIdent}
                        </h5>
                        <h5>
                            <strong>Celular:</strong> {clienteAtual?.celular}
                        </h5>
                        <h5>
                            <strong>Descrição:</strong>{" "}
                            {clienteAtual?.biografia}
                        </h5>
                        <h5>
                            <strong>Instagram:</strong>{" "}
                            {clienteAtual?.linkInstagram}
                        </h5>
                        <h5>
                            <strong>Facebook:</strong>{" "}
                            {clienteAtual?.linkFacebook}
                        </h5>
                        <h5>
                            <strong>Linkedin:</strong>{" "}
                            {clienteAtual?.linkLinkedin}
                        </h5>
                        <h5>
                            <strong>Site:</strong> {clienteAtual?.linkSite}
                        </h5>
                    </MDBCol>
                    <MDBCol md="5">
                        <h5>
                            <strong>ID:</strong> {clienteAtual?.id}
                        </h5>
                        <h5>
                            <strong>Situação:</strong>{" "}
                            {clienteAtual?.controleFluxo}
                        </h5>
                        {clienteAtual?.celularValidado && (
                            <h5>
                                <strong>Fez a validação de celular?</strong> Sim
                            </h5>
                        )}
                        {!clienteAtual?.celularValidado && (
                            <h5>
                                <strong>Fez a validação de celular?</strong> Não
                            </h5>
                        )}
                        {clienteAtual?.news === "true" && (
                            <h5>
                                <strong>Aceita receber notícias?</strong> Sim
                            </h5>
                        )}
                        {clienteAtual?.news === "false" && (
                            <h5>
                                <strong>Aceita receber notícias?</strong> Não
                            </h5>
                        )}
                        <h5>
                            <strong>Cupom:</strong>{" "}
                            {clienteAtual?.cupomDesconto}
                        </h5>
                        <h5>
                            <strong>Plano:</strong> {clienteAtual?.plano}
                        </h5>
                        <h5>
                            <strong>Cliente desde: </strong>
                            {moment(clienteAtual?.created_at).format(
                                "DD/MM/YYYY HH:mm",
                            )}
                        </h5>
                        <h5>
                            <strong>Última atualização: </strong>
                            {moment(clienteAtual?.updated_at).format(
                                "DD/MM/YYYY HH:mm",
                            )}
                        </h5>
                        {clienteAtual?.ativo && (
                            <h5>
                                <strong>Status:</strong> Ativo
                            </h5>
                        )}
                        {!clienteAtual?.ativo && (
                            <h5>
                                <strong>Status:</strong> Inativo
                            </h5>
                        )}
                        <h5>
                            <strong>Cidade:</strong> {clienteAtual?.cidade.nome}{" "}
                            - {clienteAtual?.estado.sigla}
                        </h5>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ marginTop: 20 }}>
                    <MDBCol md="6" className="col-example">
                        {clienteAtual?.categorias[0] && (
                            <h2 style={{ marginTop: 20 }}>
                                <strong>Categorias</strong>
                            </h2>
                        )}

                        <MDBRow style={{ marginTop: 20 }}>
                            {clienteAtual?.categorias?.map(
                                categoria =>
                                    categoria.idCategoriaPai === null && (
                                        <MDBCol md="6" className="col-example">
                                            <h5>
                                                <strong>Nome: </strong>
                                                {categoria.nome}
                                            </h5>
                                            <h5>
                                                <strong>ID: </strong>
                                                {categoria.id}
                                            </h5>
                                        </MDBCol>
                                    ),
                            )}
                        </MDBRow>
                    </MDBCol>
                    <MDBCol md="6" className="col-example">
                        {clienteAtual?.categorias[0] && (
                            <h2 style={{ marginTop: 20 }}>
                                <strong>Sub Categorias</strong>
                            </h2>
                        )}
                        <MDBRow style={{ marginTop: 20 }}>
                            {clienteAtual?.categorias?.map(
                                categoria =>
                                    categoria.idCategoriaPai !== null && (
                                        <MDBCol md="6" className="col-example">
                                            <h5>
                                                <strong>Nome: </strong>
                                                {categoria.nome}
                                            </h5>
                                            <h5>
                                                <strong>ID Categoria: </strong>
                                                {categoria.idCategoriaPai}
                                            </h5>
                                        </MDBCol>
                                    ),
                            )}
                        </MDBRow>
                    </MDBCol>
                </MDBRow>

                {clienteAtual?.produtos[0] && (
                    <h2 style={{ marginTop: 20 }}>
                        <strong>Produtos/Serviços</strong>
                    </h2>
                )}
                {clienteAtual?.produtos?.map(produto => (
                    <MDBRow style={{ marginTop: 20 }}>
                        <MDBCol
                            md="3"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <ImagemInput>
                                <img src={produto.img01_url} alt="Avatar" />
                            </ImagemInput>
                        </MDBCol>
                        <MDBCol md="4">
                            <h5>
                                <strong>Nome: </strong>
                                {produto.nome}
                            </h5>
                            {produto.isProduto && (
                                <h5>
                                    <strong>Tipo: </strong> Produto
                                </h5>
                            )}
                            {!produto.isProduto && (
                                <h5>
                                    <strong>Tipo: </strong> Serviço
                                </h5>
                            )}
                            <h5>
                                <strong>Descriçao: </strong>
                                {produto.descricao}
                            </h5>
                        </MDBCol>
                        <MDBCol md="5">
                            <h5>
                                <strong>ID: </strong>
                                {produto.id}
                            </h5>
                            <h5>
                                <strong>Preço: </strong>
                                {produto.preco}/{produto.unidadeMedida}
                            </h5>
                            {produto.isVisivel && (
                                <h5>
                                    <strong>Preço visível? </strong> Sim
                                </h5>
                            )}
                            {!produto.isVisivel && (
                                <h5>
                                    <strong>Preço visível? </strong> Não
                                </h5>
                            )}
                        </MDBCol>
                    </MDBRow>
                ))}

                {clienteAtual?.assinaturas[0] && (
                    <h2 style={{ marginTop: 20 }}>
                        <strong>Assinaturas</strong>
                    </h2>
                )}
                {clienteAtual?.assinaturas?.map(assinatura => (
                    <MDBRow style={{ marginTop: 20 }}>
                        <MDBCol md="6">
                            <h5>
                                <strong>Código: </strong>
                                {assinatura.code}
                            </h5>
                            <h5>
                                <strong>Plano: </strong>
                                {assinatura.name}
                            </h5>
                            <h5>
                                <strong>Referência: </strong>
                                {assinatura.reference}
                            </h5>
                        </MDBCol>
                        <MDBCol md="6">
                            <h5>
                                <strong>Status: </strong>
                                {assinatura.status}
                            </h5>
                            <h5>
                                <strong>Pagador: </strong>
                                {assinatura.senderName}
                            </h5>
                            <h5>
                                <strong>Data: </strong>
                                {moment(assinatura.created_at).format(
                                    "DD/MM/YYYY HH:mm",
                                )}
                            </h5>
                        </MDBCol>
                    </MDBRow>
                ))}
            </Content>
        </Container>
    );
};
export default Detalhes;
