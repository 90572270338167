/* eslint-disable no-unused-vars */
import React, { useCallback, useRef, useState, useEffect } from "react";
import {
    FiUser,
    FiMapPin,
    FiSearch,
    FiPackage,
    FiFilter,
    FiPhone,
    FiPower,
    FiFacebook,
    FiInstagram,
    FiLinkedin,
} from "react-icons/fi";
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardImage,
    MDBRow,
    MDBCol,
    MDBCardFooter,
    MDBInputGroup,
} from "mdb-react-ui-kit";

import { Link, useHistory, Redirect } from "react-router-dom";
import Button from "@material-ui/core/Button";
import * as Yup from "yup";

import { Form } from "@unform/web";
// eslint-disable-next-line no-unused-vars
import { FormHandles } from "@unform/core";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import api from "../../services/api";

import { useAuth } from "../../hooks/auth";
import "react-toastify/dist/ReactToastify.css";
import {
    Container,
    Content,
    ButtonMenu,
    FundoCinza,
    EspacoFundoCinza,
} from "./styles";
import avatarPadrao from "../../assets/avatar.png";

import InputPesquisa from "../../components/InputPesquisa";

import imgLogo from "../../assets/Logo.png";
import imgLogoBranca from "../../assets/LogoBranca.png";

import getValidationErrors from "../../utils/getValidationErrors";
import InputHome from "../../components/InputHome";
import ButtonIcon from "../../components/ButtonIcon";

interface PesquisaHomeFormData {
    pesquisaPesquisa: string;
}

interface Categoria {
    id: string;
    nome: string;
    idCategoriaPai: string;
}

interface Produto {
    id: string;
    nome: string;
    descricao: string;
}

interface Cidade {
    nome: string;
    sigla?: string;
    regiao?: string;
}

interface Estado {
    nome: string;
    sigla: string;
    regiao: string;
}

interface Cliente {
    controleFluxo: string;
    id: string;
    nome: string;
    email: string;
    celular: string;
    avatar_url: string;
    categorias: Categoria[];
    produtos: Produto[];
    estado: Estado;
    cidade: Cidade;
}

interface Outros {
    id: string;
    chave: string;
    valor: string;
}

const Pesquisa: React.FC = () => {
    const { cliente, signOut } = useAuth();
    const formRef = useRef<FormHandles>(null);
    const history = useHistory();

    const [categorias, setCategorias] = useState<Categoria[]>([]);
    const [subCategorias, setSubCategorias] = useState<Categoria[]>([]);
    const [clientes, setClientes] = useState<Cliente[]>([]);
    const [outros, setOutros] = useState<Outros[]>([]);
    const [menuExpandido, setMenuExpandido] = useState(false);

    const [cidades, setCidades] = useState<Cidade[]>([]);

    useEffect(() => {
        toast.info(
            "Não faça pagamentos antes de verificar a idoneidade do fornecedor.",
            {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            },
        );
    }, []);

    useEffect(() => {
        api.get("/categorias/categorias").then(response =>
            setCategorias(response.data),
        );
        api.get("/controle").then(response => setOutros(response.data));
    }, []);

    useEffect(() => {
        api.get("/clientes/cidades")
            .then(response => setCidades(response.data))
            .catch(response => {
                if (
                    JSON.parse(response.request.responseText).message ===
                        "JWT token is missing!" ||
                    JSON.parse(response.request.responseText).message ===
                        "Invalid JWT token"
                ) {
                    signOut();
                    toast.warn("Sessão expirada! Faça login novamente!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            });
    }, [signOut]);

    const pesquisaHome = localStorage.getItem("@dbwetextil:pesquisa");
    const nomeCategoriaHome = localStorage.getItem("@dbwetextil:nomeCategoria");

    useEffect(() => {
        if (nomeCategoriaHome !== null || nomeCategoriaHome !== undefined) {
            api.get(`/clientes/categoria/${nomeCategoriaHome}`).then(response =>
                setClientes(response.data),
            );
        }
        // localStorage.removeItem("@dbwetextil:nomeCategoria");
    }, [nomeCategoriaHome]);

    useEffect(() => {
        if (pesquisaHome) {
            api.get(`/clientes/pesquisa/${pesquisaHome}`).then(response =>
                setClientes(response.data),
            );
        } else if (!nomeCategoriaHome) {
            api.get(`/clientes/ativos`).then(response =>
                setClientes(response.data),
            );
        }
    }, [nomeCategoriaHome, pesquisaHome]);

    const clickCategoria = () => {
        localStorage.removeItem("@dbwetextil:nomeCategoria");
        api.get(`/clientes/ativos`).then(response =>
            setClientes(response.data),
        );
        setMenuExpandido(false);
    };

    const clickCategoriaMenu = (nomeCategoria: string) => {
        localStorage.setItem("@dbwetextil:nomeCategoria", nomeCategoria);
        api.get(`/clientes/categoria/${nomeCategoria}`).then(response =>
            setClientes(response.data),
        );

        const insertPesquisa = {
            idCliente: cliente.id,
            nomeCliente: cliente.nome,
            pesquisa: nomeCategoria,
        };

        api.post("/pesquisa", insertPesquisa);
        history.push("/pesquisa");
    };

    const clickPesquisaCategoria = (
        nomeCategoria: string,
        idCategoria: string,
    ) => {
        localStorage.removeItem("@dbwetextil:nomeCategoria");
        api.get(`/clientes/categoria/${nomeCategoria}`).then(response =>
            setClientes(response.data),
        );
        api.get(`/categorias/subCategorias/${idCategoria}`).then(response =>
            setSubCategorias(response.data),
        );
        setMenuExpandido(false);

        const insertPesquisa = {
            idCliente: cliente.id,
            nomeCliente: cliente.nome,
            pesquisa: nomeCategoria,
        };

        api.post("/pesquisa", insertPesquisa);
    };

    const clickPesquisaSubCategoria = (nomeCategoria: string) => {
        localStorage.removeItem("@dbwetextil:nomeCategoria");
        api.get(`/clientes/subCategoria/${nomeCategoria}`).then(response =>
            setClientes(response.data),
        );
        setMenuExpandido(false);

        const insertPesquisa = {
            idCliente: cliente.id,
            nomeCliente: cliente.nome,
            pesquisa: nomeCategoria,
        };

        api.post("/pesquisa", insertPesquisa);
    };

    const clickPesquisaCidade = (nomeCidade: string) => {
        localStorage.removeItem("@dbwetextil:nomeCategoria");
        api.get(`/clientes/cidade/${nomeCidade}`).then(response =>
            setClientes(response.data),
        );
        setMenuExpandido(false);
    };

    const clickCliente = (idCliente: string) => {
        history.push(`/loja/${idCliente}`);
    };

    const handleSubmit = useCallback(
        async (data: PesquisaHomeFormData) => {
            try {
                const { pesquisaPesquisa } = data;

                const insertPesquisa = {
                    idCliente: cliente.id,
                    nomeCliente: cliente.nome,
                    pesquisa: pesquisaPesquisa,
                };

                await api.post("/pesquisa", insertPesquisa);

                localStorage.setItem("@dbwetextil:pesquisa", pesquisaPesquisa);

                history.push("/pesquisa");
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);
                    return;
                }

                toast.error("Ocorreu um erro! Tente novamente!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        },
        [cliente.id, cliente.nome, history],
    );

    // Redirecionamentos de páginas privadas
    if (cliente.controleFluxo === "FALTA_PAGAMENTO") {
        return <Redirect to={`/planosPagamento/${cliente.id}`} />;
    }
    if (cliente.controleFluxo === "FALTA_CONFIRMAR_CELULAR") {
        return <Redirect to="/confirmaSMS" />;
    }

    return (
        <Container>
            <ToastContainer />
            <Navbar
                collapseOnSelect
                expand="lg"
                style={{
                    background: "#ffffff",
                }}
            >
                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    style={{ border: "none" }}
                />
                <Navbar.Brand style={{ marginLeft: "2%" }}>
                    <Link to="/">
                        <img width="125px" src={imgLogo} alt="WeTextil" />
                    </Link>
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto text-center" />
                    <Nav>
                        <Nav.Link
                            href="/"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                início
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/nossoMovimento"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                sobre
                            </span>
                        </Nav.Link>

                        <Nav.Link
                            href="/planos"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                planos
                            </span>
                        </Nav.Link>

                        <Nav.Link
                            href="#"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#CAC9C8",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                fabrique sua malha
                            </span>
                        </Nav.Link>

                        <Nav.Link
                            href="#"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#CAC9C8",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                mapeie seu mercado
                            </span>
                        </Nav.Link>

                        <NavDropdown
                            title="categorias"
                            id="basic-nav-dropdown"
                            style={{
                                padding: "0px 20px 0px 20px",
                                textAlign: "center",
                                marginTop: 8,
                                fontWeight: "bold",
                                color: "#2d2d2d",
                            }}
                        >
                            {categorias.map(categoria => (
                                <NavDropdown.Item
                                    onClick={
                                        () => clickCategoriaMenu(categoria.nome)
                                        // eslint-disable-next-line react/jsx-curly-newline
                                    }
                                >
                                    {categoria.nome}
                                </NavDropdown.Item>
                            ))}
                        </NavDropdown>
                        {!cliente && (
                            <Nav.Link href="/cliente">
                                <ButtonMenu>
                                    <button type="button">
                                        <span
                                            style={{
                                                padding: "0px 20px 0px 20px",
                                            }}
                                        >
                                            cadastre-se
                                        </span>
                                    </button>
                                </ButtonMenu>
                            </Nav.Link>
                        )}
                        {!cliente && (
                            <Nav.Link
                                href="/login"
                                style={{
                                    textAlign: "center",
                                    marginTop: 8,
                                    color: "#2d2d2d",
                                }}
                            >
                                <span style={{ padding: "0px 20px 0px 30px" }}>
                                    login
                                </span>
                            </Nav.Link>
                        )}

                        {cliente && cliente?.controleFluxo !== "IS_ADMIN" && (
                            <Nav.Link
                                href="/minhaVitrine"
                                style={{
                                    textAlign: "center",
                                    marginTop: 8,
                                    color: "#2d2d2d",
                                }}
                            >
                                <span style={{ padding: "0px 20px 0px 20px" }}>
                                    minha vitrine
                                </span>
                            </Nav.Link>
                        )}

                        {cliente && cliente?.controleFluxo !== "IS_ADMIN" && (
                            <NavDropdown
                                title="meu perfil"
                                id="basic-nav-dropdown"
                                style={{
                                    padding: "0px 30px 0px 20px",
                                    textAlign: "center",
                                    marginTop: 8,
                                    fontWeight: "bold",
                                    color: "#2d2d2d",
                                }}
                            >
                                <NavDropdown.Item href="/minhaConta">
                                    minha conta
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={signOut}>
                                    sair
                                </NavDropdown.Item>
                            </NavDropdown>
                        )}

                        {cliente && cliente?.controleFluxo === "IS_ADMIN" && (
                            <Nav.Link href="/dashboardClientes">
                                <ButtonMenu>
                                    <button type="button" disabled>
                                        <span
                                            style={{
                                                padding: "0px 20px 0px 10px",
                                            }}
                                        >
                                            Dashboard
                                        </span>
                                    </button>
                                </ButtonMenu>
                            </Nav.Link>
                        )}

                        {cliente && cliente?.controleFluxo === "IS_ADMIN" && (
                            <Nav.Link
                                onClick={signOut}
                                style={{
                                    textAlign: "center",
                                    marginTop: 10,
                                    color: "#2d2d2d",
                                }}
                            >
                                <span style={{ padding: "0px 30px 0px 20px" }}>
                                    sair
                                </span>
                            </Nav.Link>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <Content>
                <MDBRow>
                    <MDBCol md="3" className="col-example">
                        <Form ref={formRef} onSubmit={handleSubmit}>
                            <MDBRow
                                style={{
                                    borderRadius: 10,
                                    width: "100%",
                                    marginLeft: 0,
                                    boxShadow: "0 0 10px #e8e8e8",
                                    marginBottom: 10,
                                }}
                            >
                                <MDBCol size="10" className="col-example">
                                    <InputHome
                                        placeholder="O que está procurando?"
                                        name="pesquisaPesquisa"
                                        type="text"
                                    />
                                </MDBCol>
                                <MDBCol
                                    size="2"
                                    className="col-example text-right"
                                    style={{ background: "transparent" }}
                                >
                                    <button
                                        type="submit"
                                        style={{
                                            background: "transparent",
                                            border: "none",
                                        }}
                                    >
                                        <FiSearch
                                            style={{
                                                width: 20,
                                                height: 20,
                                                color: "#2d2d2d",
                                                marginTop: 12,
                                                cursor: "pointer",
                                            }}
                                        />
                                    </button>
                                </MDBCol>
                            </MDBRow>
                        </Form>

                        <Navbar
                            collapseOnSelect
                            expand="md"
                            style={{
                                background: "#ffffff",
                            }}
                            expanded={menuExpandido}
                        >
                            <Navbar.Toggle
                                aria-controls="navbarScroll"
                                className="justify-content-center"
                                style={{
                                    alignItems: "center",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    border: "none",
                                    width: "100%",
                                }}
                                onClick={() => setMenuExpandido(true)}
                            >
                                <MDBRow>
                                    <MDBCol>
                                        <MDBRow
                                            className="mb-1"
                                            style={{ marginTop: 10 }}
                                        >
                                            <MDBCol
                                                size="12"
                                                className="col-example text-right"
                                            >
                                                <strong>
                                                    <FiFilter
                                                        style={{
                                                            marginLeft: 30,
                                                        }}
                                                    />
                                                </strong>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCol>
                                </MDBRow>
                            </Navbar.Toggle>
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <MDBRow>
                                    <Navbar.Text
                                        style={{
                                            marginLeft: 10,
                                        }}
                                    >
                                        <strong
                                            style={{
                                                fontSize: "large",
                                                cursor: "pointer",
                                            }}
                                            onClick={clickCategoria}
                                        >
                                            Categorias
                                        </strong>
                                    </Navbar.Text>
                                    {categorias.map(categoria => (
                                        <Nav
                                            className="nav-item dropdown"
                                            style={{
                                                background: "#ffffff",
                                                width: "100%",
                                            }}
                                            key={categoria.id}
                                        >
                                            <Nav.Item
                                                as="li"
                                                style={{
                                                    marginLeft: 10,
                                                }}
                                            >
                                                <Navbar.Text
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <strong
                                                        onClick={
                                                            () =>
                                                                clickPesquisaCategoria(
                                                                    categoria.nome,
                                                                    categoria.id,
                                                                )
                                                            // eslint-disable-next-line react/jsx-curly-newline
                                                        }
                                                        style={{
                                                            fontSize: "medium",
                                                            marginRight: 10,
                                                            marginLeft: 10,
                                                        }}
                                                    >
                                                        {categoria.nome}
                                                    </strong>
                                                    {subCategorias.map(
                                                        subCategoria =>
                                                            subCategoria.idCategoriaPai ===
                                                                categoria.id && (
                                                                <Nav.Item
                                                                    as="li"
                                                                    style={{
                                                                        marginLeft: 10,
                                                                    }}
                                                                    key={
                                                                        subCategoria.id
                                                                    }
                                                                >
                                                                    <Navbar.Text
                                                                        style={{
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={
                                                                            () =>
                                                                                clickPesquisaSubCategoria(
                                                                                    subCategoria.nome,
                                                                                )
                                                                            // eslint-disable-next-line react/jsx-curly-newline
                                                                        }
                                                                    >
                                                                        <strong
                                                                            style={{
                                                                                fontSize:
                                                                                    "small",
                                                                                marginRight: 10,
                                                                                marginLeft: 10,
                                                                            }}
                                                                        >
                                                                            {
                                                                                subCategoria.nome
                                                                            }
                                                                        </strong>
                                                                    </Navbar.Text>
                                                                </Nav.Item>
                                                            ),
                                                    )}
                                                </Navbar.Text>
                                            </Nav.Item>
                                        </Nav>
                                    ))}
                                    <Navbar.Text
                                        style={{
                                            marginLeft: 10,
                                        }}
                                    >
                                        <strong
                                            style={{
                                                cursor: "pointer",
                                                fontSize: "large",
                                            }}
                                            onClick={clickCategoria}
                                        >
                                            Cidade
                                        </strong>
                                    </Navbar.Text>
                                    {cidades.map(infos => (
                                        <Nav
                                            className="nav-item dropdown"
                                            style={{
                                                background: "#ffffff",
                                                width: "100%",
                                            }}
                                            key={infos.nome}
                                        >
                                            <Nav.Item
                                                as="li"
                                                style={{
                                                    marginLeft: 10,
                                                }}
                                            >
                                                <Navbar.Text
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={
                                                        () =>
                                                            clickPesquisaCidade(
                                                                infos.nome,
                                                            )
                                                        // eslint-disable-next-line react/jsx-curly-newline
                                                    }
                                                >
                                                    <strong
                                                        style={{
                                                            fontSize: "medium",
                                                            marginRight: 10,
                                                            marginLeft: 10,
                                                        }}
                                                    >
                                                        {infos.nome} -{" "}
                                                        {infos.sigla}
                                                    </strong>
                                                </Navbar.Text>
                                            </Nav.Item>
                                        </Nav>
                                    ))}
                                </MDBRow>
                            </Navbar.Collapse>
                        </Navbar>
                    </MDBCol>
                    <MDBCol md="9" className="col-example">
                        <MDBRow>
                            <MDBCol md="12" className="col-example">
                                <MDBRow className="row-cols-1 row-cols-md-3 g-3">
                                    {clientes.map(clienteMap => (
                                        <MDBCol
                                            style={{
                                                marginTop: 10,
                                                marginBottom: 10,
                                            }}
                                            key={clienteMap.id}
                                        >
                                            <MDBCard
                                                className="h-100 border-white"
                                                style={{
                                                    color: "#000000",
                                                    borderRadius: 10,
                                                    cursor: "pointer",
                                                    boxShadow:
                                                        "0 0 10px #e8e8e8",
                                                }}
                                                onClick={
                                                    () =>
                                                        clickCliente(
                                                            clienteMap.id,
                                                        )
                                                    // eslint-disable-next-line react/jsx-curly-newline
                                                }
                                            >
                                                {clienteMap.avatar_url ===
                                                    null && (
                                                    <MDBCardImage
                                                        src={avatarPadrao}
                                                        alt="..."
                                                        width="180px"
                                                        height="150px"
                                                        position="top"
                                                        style={{
                                                            borderTopLeftRadius: 10,
                                                            borderTopRightRadius: 10,
                                                        }}
                                                    />
                                                )}

                                                {clienteMap.avatar_url !==
                                                    null && (
                                                    <MDBCardImage
                                                        src={
                                                            clienteMap.avatar_url
                                                        }
                                                        alt="..."
                                                        width="180px"
                                                        height="150px"
                                                        position="top"
                                                        style={{
                                                            borderTopLeftRadius: 10,
                                                            borderTopRightRadius: 10,
                                                        }}
                                                    />
                                                )}
                                                <MDBCardBody>
                                                    <MDBCardText>
                                                        {clienteMap.categorias.map(
                                                            categoria => {
                                                                if (
                                                                    categoria.idCategoriaPai ===
                                                                    null
                                                                ) {
                                                                    return (
                                                                        <strong
                                                                            style={{
                                                                                color: "#00d1e6",
                                                                            }}
                                                                            key={
                                                                                categoria.id
                                                                            }
                                                                        >
                                                                            {
                                                                                categoria.nome
                                                                            }
                                                                            <br />
                                                                        </strong>
                                                                    );
                                                                }
                                                                return (
                                                                    <strong
                                                                        key={
                                                                            categoria.id
                                                                        }
                                                                    />
                                                                );
                                                            },
                                                        )}
                                                    </MDBCardText>
                                                    <MDBCardTitle
                                                        style={{
                                                            color: "#9296AD",
                                                        }}
                                                    >
                                                        {clienteMap.nome}
                                                    </MDBCardTitle>
                                                    <MDBCardFooter
                                                        style={{
                                                            backgroundColor:
                                                                "white",
                                                        }}
                                                    >
                                                        <MDBRow className="mb-1">
                                                            <MDBCol
                                                                size="12"
                                                                className="col-example text-left"
                                                            >
                                                                <FiMapPin
                                                                    style={{
                                                                        marginTop:
                                                                            -5,
                                                                        marginRight: 10,
                                                                        marginLeft:
                                                                            -20,
                                                                    }}
                                                                />
                                                                {
                                                                    clienteMap
                                                                        .cidade
                                                                        .nome
                                                                }{" "}
                                                                -{" "}
                                                                {
                                                                    clienteMap
                                                                        .estado
                                                                        .sigla
                                                                }
                                                            </MDBCol>
                                                        </MDBRow>
                                                        <MDBRow className="mb-1">
                                                            <MDBCol
                                                                size="12"
                                                                className="col-example text-left"
                                                            >
                                                                <FiPhone
                                                                    style={{
                                                                        marginTop:
                                                                            -5,
                                                                        marginRight: 10,
                                                                        marginLeft:
                                                                            -20,
                                                                    }}
                                                                />
                                                                WhatsApp
                                                            </MDBCol>
                                                        </MDBRow>
                                                        {clienteMap.produtos
                                                            .length > 0 && (
                                                            <MDBRow className="mb-1">
                                                                <MDBCol
                                                                    size="12"
                                                                    className="col-example text-left"
                                                                >
                                                                    <FiPackage
                                                                        style={{
                                                                            marginTop:
                                                                                -5,
                                                                            marginRight: 10,
                                                                            marginLeft:
                                                                                -20,
                                                                        }}
                                                                    />
                                                                    {
                                                                        clienteMap
                                                                            .produtos
                                                                            .length
                                                                    }{" "}
                                                                    produto(s)
                                                                </MDBCol>
                                                            </MDBRow>
                                                        )}
                                                    </MDBCardFooter>
                                                </MDBCardBody>
                                            </MDBCard>
                                        </MDBCol>
                                    ))}
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </Content>
            <EspacoFundoCinza>
                <FundoCinza>
                    <MDBRow
                        style={{
                            marginLeft: "1%",
                            marginRight: "3%",
                            marginBottom: "10%",
                        }}
                    >
                        <MDBCol
                            md="1"
                            className="text-center align-items-center"
                            style={{
                                marginTop: "2%",
                                marginBottom: "2%",
                                alignItems: "center",
                            }}
                        >
                            <img
                                width="100px"
                                src={imgLogoBranca}
                                alt="WeTextil"
                            />
                        </MDBCol>
                        <MDBCol
                            md="6"
                            style={{ marginTop: "2%", marginBottom: "2%" }}
                        >
                            <MDBRow className="row-cols-1 row-cols-md-1 g-1 text-center">
                                <strong>CATEGORIAS</strong>
                            </MDBRow>
                            <MDBRow className="row-cols-1 row-cols-md-3 g-3 text-center">
                                {categorias.map(categoria => (
                                    <MDBCol
                                        key={categoria.id}
                                        onClick={
                                            () =>
                                                clickCategoriaMenu(
                                                    categoria.nome,
                                                )
                                            // eslint-disable-next-line react/jsx-curly-newline
                                        }
                                        style={{ cursor: "pointer" }}
                                    >
                                        <small>{categoria.nome}⠀</small>
                                    </MDBCol>
                                ))}
                            </MDBRow>
                        </MDBCol>
                        <MDBCol
                            md="3"
                            style={{ marginTop: "2%", marginBottom: "2%" }}
                        >
                            <MDBRow className="row-cols-1 row-cols-md-1 g-1 text-center">
                                <strong>AJUDA</strong>
                            </MDBRow>
                            <MDBRow
                                className="row-cols-1 row-cols-md-1 g-1 text-center"
                                style={{ marginTop: "2%" }}
                            >
                                <small>16 99787-1671</small>
                            </MDBRow>
                            <MDBRow
                                className="row-cols-1 row-cols-md-1 g-1 text-center"
                                style={{ marginTop: "1%" }}
                            >
                                <small>contato@wetextil.com</small>
                            </MDBRow>
                            <MDBRow
                                className="row-cols-1 row-cols-md-1 g-1 text-center"
                                style={{ marginTop: "1%" }}
                            >
                                <small>
                                    <a
                                        href="/termosDeUso"
                                        target="_blank"
                                        style={{ color: "white" }}
                                    >
                                        Política de Privacidade e Termos de Uso
                                    </a>
                                </small>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol
                            md="2"
                            style={{ marginTop: "2%", marginBottom: "2%" }}
                        >
                            <MDBRow className="row-cols-1 row-cols-md-1 g-1 text-center">
                                <strong>REDES SOCIAIS</strong>
                            </MDBRow>
                            <MDBRow
                                style={{
                                    marginTop: "4%",
                                    marginBottom: "2%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Link
                                    to={{
                                        pathname: `${
                                            outros.find(
                                                saida =>
                                                    saida.chave ===
                                                    "linkFacebook",
                                            )?.valor
                                        }`,
                                    }}
                                    target="_blank"
                                >
                                    <ButtonIcon type="submit">
                                        <FiFacebook />
                                    </ButtonIcon>
                                </Link>
                                <Link
                                    to={{
                                        pathname: `${
                                            outros.find(
                                                saida =>
                                                    saida.chave ===
                                                    "linkInstagram",
                                            )?.valor
                                        }`,
                                    }}
                                    target="_blank"
                                >
                                    <ButtonIcon type="submit">
                                        <FiInstagram />
                                    </ButtonIcon>
                                </Link>
                                <Link
                                    to={{
                                        pathname: `${
                                            outros.find(
                                                saida =>
                                                    saida.chave ===
                                                    "linkLinkedin",
                                            )?.valor
                                        }`,
                                    }}
                                    target="_blank"
                                >
                                    <ButtonIcon type="submit">
                                        <FiLinkedin />
                                    </ButtonIcon>
                                </Link>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow
                        style={{
                            marginLeft: "1%",
                            marginRight: "3%",
                        }}
                    >
                        <MDBCol
                            md="12"
                            className="text-center align-items-center"
                            style={{
                                marginTop: "2%",
                                marginBottom: "2%",
                                alignItems: "center",
                            }}
                        >
                            Copyright ©️ 2021 WeTêxtil. All trademarks and logos
                            are the property of their respective owners. All
                            rights reserved.
                        </MDBCol>
                    </MDBRow>
                </FundoCinza>
            </EspacoFundoCinza>
        </Container>
    );
};

export default Pesquisa;
