import styled from "styled-components";

import Tooltip from "../Tooltip";

export const Container = styled.div`
    background: #ffffff;
    border-radius: 10px;
    padding: 16px;
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    border-color: #000000;
    border: 1px solid #666360;
    color: #666360;

    input {
        color: #00d1e6;
        background: transparent;
        flex: 1;
        border: 0;
        &::placeholder {
            color: #666360;
        }
    }
    svg {
        margin-right: 16px;
    }
`;

export const Error = styled(Tooltip)`
    height: 20px;
    margin-left: 16px;
    svg {
        margin: 0;
    }
    span {
        background: #c53030;
        color: #fff;
        &::before {
            border-color: #c53030 transparent;
        }
    }
`;
