/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link, useParams, Redirect, useHistory } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import { Form } from "@unform/web";
// eslint-disable-next-line no-unused-vars
import { FormHandles } from "@unform/core";
import { FaWhatsapp } from "react-icons/fa";
import { FiUser, FiPackage, FiMapPin } from "react-icons/fi";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";

import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import imgLogo from "../../assets/Logo.png";

import api from "../../services/api";
import {
    Container,
    Content,
    Square,
    AvatarInput,
    ButtonWhats,
    ButtonMenu,
} from "./styles";
import avatarPadrao from "../../assets/avatar.png";
import { useAuth } from "../../hooks/auth";

interface Categoria {
    id: string;
    nome: string;
    idCategoriaPai: string;
}

interface Cidade {
    nome: string;
    sigla?: string;
    regiao?: string;
}

interface Estado {
    nome: string;
    sigla: string;
    regiao: string;
}

interface Cliente {
    controleFluxo: string;
    nome: string;
    avatar_url: string;
    cidade: Cidade;
    estado: Estado;
    celular: string;
    email: string;
}

interface Produto {
    id: string;
    nome: string;
    descricao: string;
    img01_url: string;
    cliente: Cliente;
    categorias: Categoria;
}

interface params {
    idProduto: string;
}

const DetalhesProduto: React.FC = () => {
    const { cliente, signOut } = useAuth();
    const { idProduto } = useParams<params>();

    const [produto, setProduto] = useState<Produto>();
    const history = useHistory();

    useEffect(() => {
        api.get(`/produtos/${idProduto}`).then(response =>
            setProduto(response.data),
        );
    });

    const [categorias, setCategorias] = useState<Categoria[]>([]);

    useEffect(() => {
        api.get("/categorias/categorias").then(response =>
            setCategorias(response.data),
        );
    }, []);

    const clickCategoria = (nomeCategoria: string) => {
        const insertPesquisa = {
            idCliente: cliente.id,
            nomeCliente: cliente.nome,
            pesquisa: nomeCategoria,
        };

        api.post("/pesquisa", insertPesquisa);

        localStorage.setItem("@dbwetextil:nomeCategoria", nomeCategoria);
        history.push("/pesquisa");
    };

    const message = `Olá ${produto?.cliente.nome}, estou entrando em contato pois encontrei seu produto "${produto?.nome}" na wetextil.com e fiquei interessado!`;

    // Redirecionamentos de páginas privadas
    if (cliente.controleFluxo === "FALTA_PAGAMENTO") {
        return <Redirect to={`/planosPagamento/${cliente.id}`} />;
    }
    if (cliente.controleFluxo === "FALTA_CONFIRMAR_CELULAR") {
        return <Redirect to="/confirmaSMS" />;
    }

    return (
        <Container>
            <Navbar
                collapseOnSelect
                expand="lg"
                style={{
                    background: "#ffffff",
                }}
            >
                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    style={{ border: "none" }}
                />
                <Navbar.Brand style={{ marginLeft: "2%" }}>
                    <Link to="/">
                        <img width="125px" src={imgLogo} alt="WeTextil" />
                    </Link>
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto text-center" />
                    <Nav>
                        <Nav.Link
                            href="/"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                início
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/nossoMovimento"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                sobre
                            </span>
                        </Nav.Link>

                        <Nav.Link
                            href="/planos"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                planos
                            </span>
                        </Nav.Link>

                        <Nav.Link
                            href="#"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#CAC9C8",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                fabrique sua malha
                            </span>
                        </Nav.Link>

                        <Nav.Link
                            href="#"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#CAC9C8",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                mapeie seu mercado
                            </span>
                        </Nav.Link>

                        <NavDropdown
                            title="categorias"
                            id="basic-nav-dropdown"
                            style={{
                                padding: "0px 20px 0px 20px",
                                textAlign: "center",
                                marginTop: 8,
                                fontWeight: "bold",
                                color: "#2d2d2d",
                            }}
                        >
                            {categorias.map(categoria => (
                                <NavDropdown.Item
                                    onClick={
                                        () => clickCategoria(categoria.nome)
                                        // eslint-disable-next-line react/jsx-curly-newline
                                    }
                                >
                                    {categoria.nome}
                                </NavDropdown.Item>
                            ))}
                        </NavDropdown>
                        {!cliente && (
                            <Nav.Link href="/cliente">
                                <ButtonMenu>
                                    <button type="button">
                                        <span
                                            style={{
                                                padding: "0px 20px 0px 20px",
                                            }}
                                        >
                                            cadastre-se
                                        </span>
                                    </button>
                                </ButtonMenu>
                            </Nav.Link>
                        )}
                        {!cliente && (
                            <Nav.Link
                                href="/login"
                                style={{
                                    textAlign: "center",
                                    marginTop: 8,
                                    color: "#2d2d2d",
                                }}
                            >
                                <span style={{ padding: "0px 20px 0px 30px" }}>
                                    login
                                </span>
                            </Nav.Link>
                        )}

                        {cliente && cliente?.controleFluxo !== "IS_ADMIN" && (
                            <Nav.Link
                                href="/minhaVitrine"
                                style={{
                                    textAlign: "center",
                                    marginTop: 8,
                                    color: "#2d2d2d",
                                }}
                            >
                                <span style={{ padding: "0px 20px 0px 20px" }}>
                                    minha vitrine
                                </span>
                            </Nav.Link>
                        )}

                        {cliente && cliente?.controleFluxo !== "IS_ADMIN" && (
                            <NavDropdown
                                title="meu perfil"
                                id="basic-nav-dropdown"
                                style={{
                                    padding: "0px 30px 0px 20px",
                                    textAlign: "center",
                                    marginTop: 8,
                                    fontWeight: "bold",
                                    color: "#2d2d2d",
                                }}
                            >
                                <NavDropdown.Item href="/minhaConta">
                                    minha conta
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={signOut}>
                                    sair
                                </NavDropdown.Item>
                            </NavDropdown>
                        )}

                        {cliente && cliente?.controleFluxo === "IS_ADMIN" && (
                            <Nav.Link href="/dashboardClientes">
                                <ButtonMenu>
                                    <button type="button" disabled>
                                        <span
                                            style={{
                                                padding: "0px 20px 0px 10px",
                                            }}
                                        >
                                            Dashboard
                                        </span>
                                    </button>
                                </ButtonMenu>
                            </Nav.Link>
                        )}

                        {cliente && cliente?.controleFluxo === "IS_ADMIN" && (
                            <Nav.Link
                                onClick={signOut}
                                style={{
                                    textAlign: "center",
                                    marginTop: 10,
                                    color: "#2d2d2d",
                                }}
                            >
                                <span style={{ padding: "0px 30px 0px 20px" }}>
                                    sair
                                </span>
                            </Nav.Link>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <Content>
                <Square>
                    <MDBRow style={{ color: "#5A5F7A", marginTop: "5%" }}>
                        <MDBCol
                            sm="6"
                            className="col-example align-items-center text-center"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                            }}
                        >
                            <img
                                src={produto?.img01_url}
                                alt="..."
                                width="90%"
                                style={{
                                    borderRadius: 20,
                                }}
                            />
                        </MDBCol>
                        <MDBCol
                            sm="6"
                            className="col-example text-left"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                            }}
                        >
                            <div
                                style={{
                                    marginLeft: 20,
                                    marginTop: 20,
                                    marginRight: 20,
                                }}
                            >
                                <h2>{produto?.nome}</h2>
                                <h6 style={{ marginTop: 20 }}>Vendido por:</h6>
                                <AvatarInput>
                                    {produto?.cliente.avatar_url && (
                                        <img
                                            src={produto?.cliente.avatar_url}
                                            alt="Avatar"
                                        />
                                    )}
                                    {!produto?.cliente.avatar_url && (
                                        <img src={avatarPadrao} alt="Avatar" />
                                    )}
                                    <strong style={{ marginLeft: 15 }}>
                                        {produto?.cliente.nome}
                                    </strong>
                                </AvatarInput>

                                <h5 style={{ marginTop: 20 }}>
                                    Detalhes do produto
                                </h5>
                                <span>{produto?.descricao}</span>
                                <h6 style={{ marginTop: 20 }}>
                                    <FiMapPin
                                        style={{
                                            marginTop: -5,
                                            marginRight: 10,
                                        }}
                                    />
                                    {produto?.cliente.cidade.nome} -{" "}
                                    {produto?.cliente.estado.sigla}
                                </h6>
                                <ButtonWhats
                                    className="text-center"
                                    style={{ marginLeft: -10 }}
                                >
                                    <Link
                                        to={{
                                            pathname: `https://api.whatsapp.com/send?phone=55${produto?.cliente.celular}&text=${message}`,
                                        }}
                                        target="_blank"
                                    >
                                        <button
                                            type="submit"
                                            style={{
                                                backgroundColor: "#32da7f",
                                                color: "#ffffff",
                                            }}
                                        >
                                            <FaWhatsapp /> Whatsapp
                                        </button>
                                    </Link>
                                </ButtonWhats>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </Square>
            </Content>
        </Container>
    );
};
export default DetalhesProduto;
