import React, { useState, useEffect } from "react";

import { FiInstagram } from "react-icons/fi";
import { Link } from "react-router-dom";
import { Container } from "./styles";

import logoImg from "../../assets/Logo.png";
import imgManutencao from "../../assets/manutencao.png";
import ButtonIcon from "../../components/ButtonIcon";
import api from "../../services/api";

interface Outros {
    id: string;
    chave: string;
    valor: string;
}
const Manutencao: React.FC = () => {
    const [outros, setOutros] = useState<Outros[]>([]);

    useEffect(() => {
        api.get("/controle").then(response => setOutros(response.data));
    }, []);
    return (
        <Container>
            <img
                src={logoImg}
                width="200px"
                alt="WeTextil"
                style={{ marginBottom: 80 }}
            />
            <img
                src={imgManutencao}
                width="200px"
                alt="WeTextil"
                style={{ marginBottom: 80 }}
            />
            <h1>Em construção!</h1>
            <br />
            <h5 style={{ padding: "0% 5% 0% 5%", textAlign: "center" }}>
                Nosso site está em construção, mas logo estará tudo funcionando
                por aqui.
            </h5>
            <br />
            <ButtonIcon type="submit">
                <Link
                    to={{
                        pathname: `${
                            outros.find(
                                saida => saida.chave === "linkInstagram",
                            )?.valor
                        }`,
                    }}
                    target="_blank"
                    style={{
                        textDecoration: "none",
                    }}
                >
                    <FiInstagram style={{ marginTop: 8 }} />
                </Link>
            </ButtonIcon>
        </Container>
    );
};

export default Manutencao;
