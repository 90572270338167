import styled from "styled-components";
import { shade } from "polished";

export const Container = styled.div``;

export const Atencao = styled.div`
    width: 100%;
    margin-top: 20;
    margin-bottom: 20;
    border-radius: 10px;
    color: #2d2d2d;
    background-color: white;
    box-shadow: 0 0 10px #e8e8e8;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const ButtonMenu = styled.div`
    button {
        background: #846cf9;
        border-radius: 5px;
        color: #ffffff;
        border: 0;
        height: 38px;
        width: 100%;
        padding: 0 10px;
        font-weight: 500;

        &:hover {
            background: ${shade(0.2, "#846cf9")};
        }
    }
`;

export const ButtonWhats = styled.div`
    button {
        background: #32da7f;
        border-radius: 5px;
        color: #ffffff;
        border: 0;
        height: 40px;
        width: 100%;
        padding: 0 16px;
        font-weight: 500;
        margin-top: 10px;
        transition: background-color 0.2s;

        &:hover {
            background: ${shade(0.2, "#32da7f")};
        }
    }
`;

export const Content = styled.main`
    padding: 1% 4%;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const AvatarInput = styled.div`
    img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }
`;

export const EspacoFundoCinza = styled.main`
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

export const FundoCinza = styled.div`
    width: 100%;
    height: 100%;
    background-color: #2d2d2d;
`;
