import styled, { keyframes } from "styled-components";

import { shade } from "polished";
import signInBackgroundImg from "../../assets/imgTelaLogin.png";

export const Container = styled.div`
    height: 100vh;
    display: flex;
    align-items: stretch;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    // Com isso, essa parte nunca vai ter mais que 700px
    // e a imagem sempre irá ter o tamanho restante disponível.
    max-width: 700px;
`;

export const Background = styled.div`
    flex: 1;
    background: url(${signInBackgroundImg}) no-repeat center;
    background-size: cover;
    height: 100%;
`;

const appearFromLeft = keyframes`
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        transform: translateX(0px);
    }
`;

export const AnimationContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    border-width: 1px;
    //border-color: gray;
    border-style: solid;
    padding: 20px 20px 20px 20px;
    box-shadow: 0 0 5px grey;
    justify-content: center;
    animation: ${appearFromLeft} 2s;
    form {
        width: 310px;
        text-align: center;
        a {
            font-size: small;
            display: block;
            margin-top: 15px;
            text-align: right;
            margin-right: 5px;
            color: #00d1e6;
            text-decoration: none;
            transition: color 0.2s;
            &:hover {
                color: ${shade(0.2, "#00d1e6")};
            }
        }
    }
`;
