/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
    // eslint-disable-next-line no-unused-vars
    ChangeEvent,
    useCallback,
    useRef,
    useState,
    useEffect,
} from "react";
import { Form } from "@unform/web";
// eslint-disable-next-line no-unused-vars
import { FormHandles } from "@unform/core";
import {
    FiMail,
    FiLock,
    FiUser,
    FiCamera,
    FiArrowLeft,
    FiLink,
    FiLinkedin,
    FiFacebook,
    FiInstagram,
    FiType,
    FiPower,
    FiPackage,
    FiArrowDown,
    FiCornerRightDown,
} from "react-icons/fi";
import * as Yup from "yup";
// import Select from "react-select";

import { Nav, Navbar, NavDropdown } from "react-bootstrap";

import { Link, useHistory, Redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import imgLogo from "../../assets/Logo.png";

import api from "../../services/api";
import "react-toastify/dist/ReactToastify.css";

import getValidationErrors from "../../utils/getValidationErrors";

import { Container, Content, AvatarInput, ButtonMenu } from "./styles";
import Input from "../../components/Input";
import Textarea from "../../components/Textarea";
import ButtonAzul from "../../components/ButtonAzul";
import { useAuth } from "../../hooks/auth";

import avatarPadrao from "../../assets/avatarProfile.png";
import ButtonCancelarPlano from "../../components/ButtonCancelarPlano";

interface ProfileFormData {
    nome: string;
    email: string;
    oldSenha: string;
    senha: string;
    senhaConfimacao: string;
    biografia: string;
    linkInstagram: string;
    linkFacebook: string;
    linkLinkedin: string;
    linkSite: string;
}

interface Categoria {
    id: string;
    nome: string;
    idCategoriaPai: string;
}

const MinhaConta: React.FC = () => {
    const formRef = useRef<FormHandles>(null);

    const history = useHistory();

    const [confirmar, setConfirmar] = useState(false);

    const { cliente, updateCliente, signOut } = useAuth();

    const [categorias, setCategorias] = useState<Categoria[]>([]);

    let codeAssinatura = "";
    let statusAssinatura = "";

    if (cliente.assinaturas) {
        codeAssinatura = cliente.assinaturas[0]?.code;
        statusAssinatura = cliente.assinaturas[0]?.status;
    }

    useEffect(() => {
        api.get("/categorias/categorias").then(response =>
            setCategorias(response.data),
        );
    }, []);

    const clickCategoria = (nomeCategoria: string) => {
        const insertPesquisa = {
            idCliente: cliente.id,
            nomeCliente: cliente.nome,
            pesquisa: nomeCategoria,
        };

        api.post("/pesquisa", insertPesquisa);
        localStorage.setItem("@dbwetextil:nomeCategoria", nomeCategoria);
        history.push("/pesquisa");
    };

    const cancelAssinatura = async () => {
        setConfirmar(true);
        /*
        if (confirmar) {
            api.put(`/assinaturas/cancel/${codeAssinatura}`);
            signOut();
            addToast({
                type: "info",
                title: "Plano cancelado!",
                description:
                    "Sentimos muito por isso, mas seu plano foi cancelado a seu pedido.",
            });
        }
        */
    };

    /*
    const [categoriasCliente, setCategoriasCliente] = useState<Categoria[]>([]);

    const [notShow, setNotShow] = useState<boolean>(true);

    useEffect(() => {
        api.get(`/clientes/${cliente.id}`).then(response =>
            setCategoriasCliente(response.data.categorias),
        );
    }, [cliente.id]);

    const [categorias] = useState<Categoria[]>([]);

    const [selectedIdCategoria, setSelectedIdCategoria] = useState([]);

    const [selectedValueCategoria, setSelectedValueCategoria] = useState([]);
    const handleChangeCategoria = (e: any) => {
        setSelectedValueCategoria(e);
        setSelectedIdCategoria(e.id);
        setNotShow(false);
    };

    const [subCategorias, setSubCategorias] = useState<Categoria[]>([]);
    useEffect(() => {
        if (categorias.length < 3) {
            categoriasCliente.map(categoria => {
                if (categoria.idCategoriaPai === null) {
                    return categorias.push(categoria);
                }
                return null;
            });
        }
    }, [categorias, categoriasCliente]);

    useEffect(() => {
        api.get(`/categorias/subCategorias/${selectedIdCategoria}`).then(
            response => setSubCategorias(response.data),
        );
    }, [selectedIdCategoria]);

    const [selectedValueSubCategoria, setSelectedValueSubCategoria] = useState(
        [],
    );
    const handleChangeSubCategoria = (e: any) => {
        setSelectedValueSubCategoria(e.map((x: any) => x));
    };

    const [insertCategoria, setInsertCategoria] = useState([]);

    useEffect(() => {
        setInsertCategoria(
            selectedValueSubCategoria.concat(selectedValueCategoria),
        );
    }, [selectedValueCategoria, selectedValueSubCategoria]);

    */

    const handleSubmit = useCallback(
        async (data: ProfileFormData) => {
            try {
                formRef.current?.setErrors({});

                const schema = Yup.object().shape({
                    nome: Yup.string().required("Nome obrigatório"),
                    email: Yup.string()
                        .required("E-mail obrigatório")
                        .email("Digite um e-mail válido"),
                    oldSenha: Yup.string(),

                    senha: Yup.string().when("oldSenha", {
                        is: true,
                        then: Yup.string().required("Campo obrigatório"),
                        otherwise: Yup.string(),
                    }),

                    senhaConfirmacao: Yup.string()
                        .when("oldSenha", {
                            is: true,
                            then: Yup.string().required("Campo obrigatório"),
                            otherwise: Yup.string(),
                        })
                        .oneOf(
                            [Yup.ref("senha"), null],
                            "As senhas devem ser iguais",
                        ),
                });

                await schema.validate(data, {
                    // Faz com que o Yup não pare no primeiro erro. Por padrão é true.
                    abortEarly: false,
                });

                const {
                    nome,
                    email,
                    oldSenha,
                    senha,
                    senhaConfimacao,
                    biografia,
                    linkInstagram,
                    linkFacebook,
                    linkLinkedin,
                    linkSite,
                } = data;

                const formData = {
                    nome,
                    email,
                    biografia,
                    linkInstagram,
                    linkFacebook,
                    linkLinkedin,
                    linkSite,
                    ...(oldSenha
                        ? {
                              oldSenha,
                              senha,
                              senhaConfimacao,
                          }
                        : {}),
                };

                const response = await api.put("/clientes/me", formData);

                updateCliente(response.data);

                history.push("/minhaVitrine");

                toast.success("Perfil atualizado!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);
                    return;
                }

                toast.error("Erro na atualização! Tente novamente!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        },
        [history, updateCliente],
    );

    const handleAvatarChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            if (event.target.files) {
                const data = new FormData();

                data.append("avatar", event.target.files[0]);

                api.patch("/clientes/avatar", data).then(response => {
                    updateCliente(response.data);
                    toast.success("Foto de perfil atualizada!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
            }
        },
        [updateCliente],
    );

    // Redirecionamentos de páginas privadas
    if (cliente.controleFluxo === "FALTA_PAGAMENTO") {
        return <Redirect to={`/planosPagamento/${cliente.id}`} />;
    }
    if (cliente.controleFluxo === "FALTA_CONFIRMAR_CELULAR") {
        return <Redirect to="/confirmaSMS" />;
    }

    return (
        <Container>
            <ToastContainer />
            <Navbar
                collapseOnSelect
                expand="lg"
                style={{
                    background: "#ffffff",
                }}
            >
                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    style={{ border: "none" }}
                />
                <Navbar.Brand style={{ marginLeft: "2%" }}>
                    <Link to="/">
                        <img width="125px" src={imgLogo} alt="WeTextil" />
                    </Link>
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto text-center" />
                    <Nav>
                        <Nav.Link
                            href="/"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                início
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/nossoMovimento"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                sobre
                            </span>
                        </Nav.Link>

                        <Nav.Link
                            href="/planos"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                planos
                            </span>
                        </Nav.Link>

                        <Nav.Link
                            href="#"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#CAC9C8",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                fabrique sua malha
                            </span>
                        </Nav.Link>

                        <Nav.Link
                            href="#"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#CAC9C8",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                mapeie seu mercado
                            </span>
                        </Nav.Link>

                        <NavDropdown
                            title="categorias"
                            id="basic-nav-dropdown"
                            style={{
                                padding: "0px 20px 0px 20px",
                                textAlign: "center",
                                marginTop: 8,
                                fontWeight: "bold",
                                color: "#2d2d2d",
                            }}
                        >
                            {categorias.map(categoria => (
                                <NavDropdown.Item
                                    onClick={
                                        () => clickCategoria(categoria.nome)
                                        // eslint-disable-next-line react/jsx-curly-newline
                                    }
                                >
                                    {categoria.nome}
                                </NavDropdown.Item>
                            ))}
                        </NavDropdown>
                        {!cliente && (
                            <Nav.Link href="/cliente">
                                <ButtonMenu>
                                    <button type="button">
                                        <span
                                            style={{
                                                padding: "0px 20px 0px 20px",
                                            }}
                                        >
                                            cadastre-se
                                        </span>
                                    </button>
                                </ButtonMenu>
                            </Nav.Link>
                        )}
                        {!cliente && (
                            <Nav.Link
                                href="/login"
                                style={{
                                    textAlign: "center",
                                    marginTop: 8,
                                    color: "#2d2d2d",
                                }}
                            >
                                <span style={{ padding: "0px 20px 0px 30px" }}>
                                    login
                                </span>
                            </Nav.Link>
                        )}

                        {cliente && cliente?.controleFluxo !== "IS_ADMIN" && (
                            <Nav.Link
                                href="/minhaVitrine"
                                style={{
                                    textAlign: "center",
                                    marginTop: 8,
                                    color: "#2d2d2d",
                                }}
                            >
                                <span style={{ padding: "0px 20px 0px 20px" }}>
                                    minha vitrine
                                </span>
                            </Nav.Link>
                        )}

                        {cliente && cliente?.controleFluxo !== "IS_ADMIN" && (
                            <NavDropdown
                                title="meu perfil"
                                id="basic-nav-dropdown"
                                style={{
                                    padding: "0px 30px 0px 20px",
                                    textAlign: "center",
                                    marginTop: 8,
                                    fontWeight: "bold",
                                    color: "#2d2d2d",
                                }}
                            >
                                <NavDropdown.Item href="/minhaConta">
                                    minha conta
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={signOut}>
                                    sair
                                </NavDropdown.Item>
                            </NavDropdown>
                        )}

                        {cliente && cliente?.controleFluxo === "IS_ADMIN" && (
                            <Nav.Link href="/dashboardClientes">
                                <ButtonMenu>
                                    <button type="button" disabled>
                                        <span
                                            style={{
                                                padding: "0px 20px 0px 10px",
                                            }}
                                        >
                                            Dashboard
                                        </span>
                                    </button>
                                </ButtonMenu>
                            </Nav.Link>
                        )}

                        {cliente && cliente?.controleFluxo === "IS_ADMIN" && (
                            <Nav.Link
                                onClick={signOut}
                                style={{
                                    textAlign: "center",
                                    marginTop: 10,
                                    color: "#2d2d2d",
                                }}
                            >
                                <span style={{ padding: "0px 30px 0px 20px" }}>
                                    sair
                                </span>
                            </Nav.Link>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <Content>
                <Form
                    ref={formRef}
                    initialData={{
                        nome: cliente.nome,
                        email: cliente.email,
                        biografia: cliente.biografia,
                        linkInstagram: cliente.linkInstagram,
                        linkFacebook: cliente.linkFacebook,
                        linkLinkedin: cliente.linkLinkedin,
                        linkSite: cliente.linkSite,
                    }}
                    onSubmit={handleSubmit}
                >
                    <AvatarInput>
                        {cliente.avatar_url === null && (
                            <img src={avatarPadrao} alt={cliente.nome} />
                        )}

                        {cliente.avatar_url && (
                            <img src={cliente.avatar_url} alt={cliente.nome} />
                        )}
                        <label htmlFor="avatar">
                            <FiCamera />
                            <input
                                type="file"
                                id="avatar"
                                onChange={handleAvatarChange}
                            />
                        </label>
                    </AvatarInput>

                    <h1>Minha conta</h1>

                    <Input
                        icon={FiUser}
                        name="nome"
                        placeholder="Nome"
                        type="text"
                    />
                    <Input
                        icon={FiMail}
                        name="email"
                        placeholder="E-mail"
                        type="email"
                    />

                    <h2 style={{ marginTop: 20 }}>
                        Turbine seu perfil! <FiCornerRightDown />
                    </h2>

                    <Textarea
                        icon={FiType}
                        name="biografia"
                        placeholder="Descreva com detalhes seu serviço/produto para que outros cientes encontre-o nas pesquisas!"
                        style={{
                            border: "none",
                            resize: "none",
                            width: "100%",
                            height: "120%",
                        }}
                    />

                    <Input
                        icon={FiInstagram}
                        name="linkInstagram"
                        placeholder="Instagram"
                        type="text"
                    />

                    <Input
                        icon={FiFacebook}
                        name="linkFacebook"
                        placeholder="Facebook"
                        type="text"
                    />

                    <Input
                        icon={FiLinkedin}
                        name="linkLinkedin"
                        placeholder="Linkedin"
                        type="text"
                    />

                    <Input
                        icon={FiLink}
                        name="linkSite"
                        placeholder="Site"
                        type="text"
                    />

                    <h2 style={{ marginTop: 20 }}>Alterar senha</h2>

                    <Input
                        icon={FiLock}
                        name="oldSenha"
                        placeholder="Senha atual"
                        type="password"
                    />

                    <Input
                        icon={FiLock}
                        name="senha"
                        placeholder="Nova senha"
                        type="password"
                    />

                    <Input
                        icon={FiLock}
                        name="senhaConfirmacao"
                        placeholder="Confirmar senha"
                        type="password"
                    />

                    <ButtonAzul type="submit">Confirmar mudanças</ButtonAzul>

                    {statusAssinatura === "ACTIVE" && !confirmar && (
                        <ButtonCancelarPlano
                            confirmar={confirmar}
                            onClick={cancelAssinatura}
                            style={{ color: "black" }}
                        >
                            <small>Cancelar plano</small>
                        </ButtonCancelarPlano>
                    )}

                    {confirmar && (
                        <small
                            style={{
                                background: "#ffffff",
                                borderRadius: 5,
                                color: "#5d53a9",
                                border: 0,
                                padding: "20px 16px",
                                height: 50,
                                width: "100%",
                            }}
                        >
                            Para confirmar seu cancelamento, envie um email para
                            cancelamento@wetextil.com
                        </small>
                    )}
                </Form>
            </Content>
        </Container>
    );
};

export default MinhaConta;
