import React, { useEffect, useState } from "react";

import moment from "moment";
import { Link, Redirect } from "react-router-dom";

import { Nav, Navbar } from "react-bootstrap";

import "react-toastify/dist/ReactToastify.css";
import {
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBCol,
    MDBRow,
} from "mdb-react-ui-kit";

import { ToastContainer } from "react-toastify";

import { CSVLink } from "react-csv";
import api from "../../../services/api";
import imgLogo from "../../../assets/Logo.png";

import { useAuth } from "../../../hooks/auth";
import ButtonAzulEscuro from "../../../components/ButtonAzulEscuro";

import { Container, Content } from "./styles";

interface Pesquisa {
    id: string;
    nomeCliente: string;
    idCliente: string;
    pesquisa: string;
    data: string;
}

const Pesquisa: React.FC = () => {
    const { cliente } = useAuth();
    const headers = [
        { label: "Cliente", key: "nomeCliente" },
        { label: "idCliente", key: "idCliente" },
        { label: "Pesquisa", key: "pesquisa" },
        { label: "Data", key: "data" },
    ];

    const [pesquisas, setPesquisas] = useState<Pesquisa[]>([]);

    useEffect(() => {
        api.get("/pesquisa").then(response => setPesquisas(response.data));
    }, []);

    // Redirecionamentos de páginas privadas
    if (cliente.controleFluxo !== "IS_ADMIN") {
        return <Redirect to="/" />;
    }

    return (
        <Container>
            <Navbar
                collapseOnSelect
                expand="lg"
                style={{
                    background: "#ffffff",
                }}
            >
                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    style={{ border: "none" }}
                />
                <Navbar.Brand style={{ marginLeft: "2%" }}>
                    <Link to="/">
                        <img width="125px" src={imgLogo} alt="WeTextil" />
                    </Link>
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto text-center" />
                    <Nav>
                        <Nav.Link
                            href="/dashboardMapeamentoMercado"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Mapeie Mercado
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardFabricacaoMalha"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Fabrique Malha
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardPesquisa"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#846cf9",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                <strong>Pesquisas</strong>
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardTabelaWeTextil"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Tabela WeTêxtil
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardClientes"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Clientes
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardCategorias"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Categorias
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardAssinaturas"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Assinaturas
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardProdutos"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Produtos/Serviços
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardOutros"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Outros
                            </span>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Content>
                <ToastContainer />
                <MDBRow style={{ marginBottom: 20, marginTop: -20 }} end>
                    <MDBCol md="3">
                        <CSVLink
                            data={pesquisas}
                            headers={headers}
                            filename={`TabelaPesquisa-${moment().format(
                                "DD/MM/YYYY HH:mm",
                            )}`}
                            style={{ textDecoration: "none" }}
                        >
                            <ButtonAzulEscuro
                                style={{ height: 50 }}
                                type="submit"
                            >
                                Download
                            </ButtonAzulEscuro>
                        </CSVLink>
                    </MDBCol>
                </MDBRow>

                <MDBTable responsive bordered striped>
                    <MDBTableHead className="text-center" dark>
                        <tr>
                            <th scope="col">Cliente</th>
                            <th scope="col">Pesquisa</th>
                            <th scope="col">Data</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {pesquisas &&
                            pesquisas.map(pesquisa => (
                                <tr>
                                    <td>{pesquisa.nomeCliente}</td>
                                    <td>{pesquisa.pesquisa}</td>
                                    <td>
                                        {moment(pesquisa.data).format(
                                            "DD/MM/YYYY",
                                        )}
                                    </td>
                                </tr>
                            ))}
                    </MDBTableBody>
                </MDBTable>
            </Content>
        </Container>
    );
};

export default Pesquisa;
