import styled, { css } from "styled-components";

import Tooltip from "../Tooltip";

interface ContainerProps {
    isFocused: boolean;
    isFilled: boolean;
    isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
    background: rgba(255, 255, 255, 0.02);
    border-radius: 10px;
    padding: 10px 0px 10px;
    width: 100%;
    display: flex;
    align-items: center;
    color: #2d2d2d;

    ${props =>
        props.isErrored &&
        css`
            border-color: red;
        `}
    ${props =>
        props.isFocused &&
        css`
            border-color: #00d1e6;
            color: #00d1e6;
        `}
    ${props =>
        props.isFilled &&
        css`
            color: #00d1e6;
            border-color: #00d1e6;
        `}
    input {
        color: #2d2d2d;
        background: transparent;
        flex: 1;
        border: 0;
        &::placeholder {
            color: #2d2d2d;
        }
    }
`;

export const Error = styled(Tooltip)`
    height: 15px;
    margin-bottom: 10px;
    margin-right: 5px;
    svg {
        margin: 0;
    }
    span {
        background: #c53030;
        color: #fff;
        &::before {
            border-color: #c53030 transparent;
        }
    }
`;
