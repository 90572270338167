/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
import React, { useCallback, useRef, useState, useEffect } from "react";
// eslint-disable-next-line no-unused-vars
import { FormHandles } from "@unform/core";
import {
    FiInstagram,
    FiFacebook,
    FiSearch,
    FiClock,
    FiUser,
    FiLinkedin,
    FiClipboard,
    FiLock,
    FiXCircle,
} from "react-icons/fi";

import { VscRocket } from "react-icons/vsc";
import { HiOutlineSpeakerphone, HiOutlineDocumentText } from "react-icons/hi";
import { FaWhatsapp } from "react-icons/fa";
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardImage,
    MDBRow,
    MDBCol,
    MDBContainer,
    MDBFooter,
    MDBCardText,
    MDBInputGroup,
} from "mdb-react-ui-kit";

import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";

import { Form } from "@unform/web";

import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import CookieBanner from "react-cookie-banner";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../../hooks/auth";
import "react-toastify/dist/ReactToastify.css";
import {
    Container,
    Content,
    ButtonMenu,
    ContainerSearch,
    TextoContainerSearch,
    ComoFunciona,
    Categorias,
    Consultores,
    TextoCategorias,
    Quadrados,
    FundoAzul,
    FundoRoxo,
    FundoCinza,
    TituloComoFunciona,
    PopupConsultor1,
    PopupConsultor2,
    PopupConsultor3,
    CloseConsultores,
} from "./styles";

import api from "../../services/api";

import InputHome from "../../components/InputHome";

import imgLogo from "../../assets/Logo.png";
import imgLogoBranca from "../../assets/LogoBranca.png";
import imgConteudoExclusivo from "../../assets/ConteudoExclusivo.png";
import imgDinheiro from "../../assets/Dinheiro.png";
import imgInformacaoPriviliegiada from "../../assets/InformacaoPrivilegiada.png";
import imgNetwork from "../../assets/Network.png";
import imgTempo from "../../assets/Tempo.png";
import imgVisibilidade from "../../assets/Visibilidade.png";

import minhaVitrine from "../../assets/minhaVitrine.png";
import ViviAssinante from "../../assets/ViviAssinante.png";
import ViviExclusiva from "../../assets/ViviExclusiva.png";
import TabelaExclusiva from "../../assets/TabelaExclusiva.png";
import TabelaAssinante from "../../assets/TabelaAssinante.png";
import MatchAssinante from "../../assets/MatchAssinante.png";
import MatchExclusivo from "../../assets/MatchExclusivo.png";
import BannerBlog from "../../assets/BannerBlog.png";
import BannerInsta from "../../assets/BannerInsta.png";
import FabriqueMalha from "../../assets/FabriqueMalha.png";
import MapeamentoMercado from "../../assets/MapeamentoMercado.png";
import imgNossoMovimento from "../../assets/imgNossoMovimento.png";
import Consultor01Exclusivo from "../../assets/Consultor01Exclusivo.png";
import Consultor02Exclusivo from "../../assets/Consultor02Exclusivo.png";
import Consultor03Exclusivo from "../../assets/Consultor03Exclusivo.png";

import ButtonRoxo from "../../components/ButtonRoxo";
import getValidationErrors from "../../utils/getValidationErrors";
import ButtonBranco from "../../components/ButtonBranco";
import ButtonCategoria from "../../components/ButtonCategoria";
import ButtonIcon from "../../components/ButtonIcon";
import PopupAviso from "../../components/PopupAviso/index.js";
import ButtonAzulEscuro from "../../components/ButtonAzulEscuro";
import ButtonCinza from "../../components/ButtonCinza";
import PopupConsultor from "../../components/PopupConsultor/index.js";

interface PesquisaHomeFormData {
    pesquisaForm: string;
}

interface Categoria {
    id: string;
    nome: string;
    idCategoriaPai: string;
}

interface Cliente {
    id: string;
    nome: string;
    controleFluxo: string;
    cidade: string;
    estado: string;
    email: string;
    celular: string;
    avatar_url: string;
    categorias: Categoria[];
}

interface Produto {
    id: string;
    nome: string;
    descricao: string;
    img01_url: string;
    categorias: Categoria[];
    cliente: Cliente;
}

interface Outros {
    id: string;
    chave: string;
    valor: string;
}

const Home: React.FC = () => {
    const { cliente, signOut } = useAuth();
    const formRef = useRef<FormHandles>(null);
    const history = useHistory();

    const [buttonPopup, setButtonPopup] = useState(true);
    const [produtos, setProdutos] = useState<Produto[]>([]);
    const [outros, setOutros] = useState<Outros[]>([]);
    const [pesquisa, setPesquisa] = useState("");
    const [qtdClienteCategoria, setQtdClienteCategoria] = useState<Cliente[]>(
        [],
    );
    const [categoriaSelecionada, setCategoriaSelecionada] =
        useState<string>("");
    const [categorias, setCategorias] = useState<Categoria[]>([]);
    const [buttonPopupConsultor1, setButtonPopupConsultor1] = useState(false);
    const [buttonPopupConsultor2, setButtonPopupConsultor2] = useState(false);
    const [buttonPopupConsultor3, setButtonPopupConsultor3] = useState(false);

    useEffect(() => {
        api.get("/controle").then(response => setOutros(response.data));
        api.get("/categorias/categorias").then(response =>
            setCategorias(response.data),
        );
        localStorage.removeItem("@dbwetextil:nomeCategoria");
    }, [outros]);

    const clickCategoria = (nomeCategoria: string) => {
        setCategoriaSelecionada(nomeCategoria);

        if (cliente) {
            const insertPesquisa = {
                idCliente: cliente.id,
                nomeCliente: cliente.nome,
                pesquisa: nomeCategoria,
            };

            api.post("/pesquisa", insertPesquisa);
            setPesquisa("");
            localStorage.setItem("@dbwetextil:nomeCategoria", nomeCategoria);
            history.push("/pesquisa");
        } else {
            const insertPesquisa = {
                pesquisa: nomeCategoria,
            };

            api.post("/pesquisa", insertPesquisa);
            setPesquisa("");
            api.get(`/clientes/categoria/${nomeCategoria}`).then(response =>
                setQtdClienteCategoria(response.data),
            );
        }
    };

    useEffect(() => {
        if (pesquisa.length > 0) {
            setCategoriaSelecionada("");
            api.get(`/clientes/pesquisa/${pesquisa}`).then(response =>
                setProdutos(response.data),
            );
        }
    }, [pesquisa, produtos]);

    const changePesquisa = (e: {
        target: { value: React.SetStateAction<string> };
    }) => {
        setPesquisa(e.target.value);
    };

    const mensagemPesquisaInteligente = `Olá WeTêxtil, vim através do site e gostaria da pesquisa personalizada de ${categoriaSelecionada}!`;

    const handleSubmit = useCallback(
        async (data: PesquisaHomeFormData) => {
            try {
                formRef.current?.setErrors({});
                if (!cliente) {
                    const { pesquisaForm } = data;
                    const insertPesquisa = {
                        pesquisa: pesquisaForm,
                    };

                    await api.post("/pesquisa", insertPesquisa);

                    toast.info(
                        "Faça login ou cadastre-se para ver os resultados!",
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        },
                    );
                } else if (cliente && cliente.celularValidado === false) {
                    const { pesquisaForm } = data;
                    const insertPesquisa = {
                        idCliente: cliente.id,
                        nomeCliente: cliente.nome,
                        pesquisa: pesquisaForm,
                    };

                    await api.post("/pesquisa", insertPesquisa);

                    toast.info("É necessário a verificação do seu celular!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    history.push("/confirmaSMS");
                } else {
                    const { pesquisaForm } = data;

                    if (cliente) {
                        const insertPesquisa = {
                            idCliente: cliente.id,
                            nomeCliente: cliente.nome,
                            pesquisa: pesquisaForm,
                        };

                        await api.post("/pesquisa", insertPesquisa);
                    } else {
                        const insertPesquisa = {
                            pesquisa: pesquisaForm,
                        };

                        await api.post("/pesquisa", insertPesquisa);
                    }

                    setPesquisa(pesquisaForm);

                    localStorage.setItem("@dbwetextil:pesquisa", pesquisaForm);

                    history.push("/pesquisa");
                }
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);
                    return;
                }

                toast.error("Erro! Tente novamente!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        },
        [cliente, history],
    );

    return (
        <Container>
            <ToastContainer />
            <CookieBanner
                styles={{
                    banner: { backgroundColor: "rgba(60, 60, 60, 0.8)" },
                    message: { fontWeight: 400 },
                }}
                message="We use cookies. By continuing to use this website you are giving your consent for us to set cookies."
                buttonMessage="Aceito!"
                // eslint-disable-next-line prettier/prettier
                link={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <a href="/termosDeUso" target="_blank">
                        Saber mais!
                    </a>
                }
            />

            <Navbar
                collapseOnSelect
                expand="lg"
                style={{
                    background: "#ffffff",
                }}
            >
                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    style={{ border: "none" }}
                />
                <Navbar.Brand style={{ marginLeft: "2%" }}>
                    <Link to="/">
                        <img width="125px" src={imgLogo} alt="WeTextil" />
                    </Link>
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto text-center" />
                    <Nav>
                        <Nav.Link
                            href="/"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                início
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/nossoMovimento"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                sobre
                            </span>
                        </Nav.Link>

                        <Nav.Link
                            href="/planos"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                planos
                            </span>
                        </Nav.Link>

                        <Nav.Link
                            href="#"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#CAC9C8",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                fabrique sua malha
                            </span>
                        </Nav.Link>

                        <Nav.Link
                            href="#"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#CAC9C8",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                mapeie seu mercado
                            </span>
                        </Nav.Link>

                        <NavDropdown
                            title="categorias"
                            id="basic-nav-dropdown"
                            style={{
                                padding: "0px 20px 0px 20px",
                                textAlign: "center",
                                marginTop: 8,
                                fontWeight: "bold",
                                color: "#2d2d2d",
                            }}
                        >
                            {categorias.map(categoria => (
                                <NavDropdown.Item
                                    onClick={
                                        () => clickCategoria(categoria.nome)
                                        // eslint-disable-next-line react/jsx-curly-newline
                                    }
                                >
                                    {categoria.nome}
                                </NavDropdown.Item>
                            ))}
                        </NavDropdown>
                        {!cliente && (
                            <Nav.Link href="/cliente">
                                <ButtonMenu>
                                    <button type="button">
                                        <span
                                            style={{
                                                padding: "0px 20px 0px 20px",
                                            }}
                                        >
                                            cadastre-se
                                        </span>
                                    </button>
                                </ButtonMenu>
                            </Nav.Link>
                        )}
                        {!cliente && (
                            <Nav.Link
                                href="/login"
                                style={{
                                    textAlign: "center",
                                    marginTop: 8,
                                    color: "#2d2d2d",
                                }}
                            >
                                <span style={{ padding: "0px 20px 0px 30px" }}>
                                    login
                                </span>
                            </Nav.Link>
                        )}

                        {cliente && cliente?.controleFluxo !== "IS_ADMIN" && (
                            <Nav.Link
                                href="/minhaVitrine"
                                style={{
                                    textAlign: "center",
                                    marginTop: 8,
                                    color: "#2d2d2d",
                                }}
                            >
                                <span style={{ padding: "0px 20px 0px 20px" }}>
                                    minha vitrine
                                </span>
                            </Nav.Link>
                        )}

                        {cliente && cliente?.controleFluxo !== "IS_ADMIN" && (
                            <NavDropdown
                                title="meu perfil"
                                id="basic-nav-dropdown"
                                style={{
                                    padding: "0px 30px 0px 20px",
                                    textAlign: "center",
                                    marginTop: 8,
                                    fontWeight: "bold",
                                    color: "#2d2d2d",
                                }}
                            >
                                <NavDropdown.Item href="/minhaConta">
                                    minha conta
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={signOut}>
                                    sair
                                </NavDropdown.Item>
                            </NavDropdown>
                        )}

                        {cliente && cliente?.controleFluxo === "IS_ADMIN" && (
                            <Nav.Link href="/dashboardClientes">
                                <ButtonMenu>
                                    <button type="button" disabled>
                                        <span
                                            style={{
                                                padding: "0px 20px 0px 10px",
                                            }}
                                        >
                                            Dashboard
                                        </span>
                                    </button>
                                </ButtonMenu>
                            </Nav.Link>
                        )}

                        {cliente && cliente?.controleFluxo === "IS_ADMIN" && (
                            <Nav.Link
                                onClick={signOut}
                                style={{
                                    textAlign: "center",
                                    marginTop: 10,
                                    color: "#2d2d2d",
                                }}
                            >
                                <span style={{ padding: "0px 30px 0px 20px" }}>
                                    sair
                                </span>
                            </Nav.Link>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Content>
                {cliente &&
                    !cliente.biografia &&
                    cliente.controleFluxo !== "IS_ADMIN" && (
                        <PopupAviso
                            trigger={buttonPopup}
                            setTrigger={setButtonPopup}
                        >
                            <h3>Turbine seu perfil!</h3>
                            <h5>
                                Descreva seus serviços e produtos e{" "}
                                <strong>seja mais encontrado</strong> nas
                                buscas!
                            </h5>
                            <MDBRow>
                                <MDBCol size="6">
                                    <ButtonAzulEscuro
                                        style={{ height: 50, width: 130 }}
                                        onClick={() => setButtonPopup(false)}
                                    >
                                        dispensar
                                    </ButtonAzulEscuro>
                                </MDBCol>
                                <MDBCol size="6">
                                    <Link
                                        to="/minhaConta"
                                        style={{ textDecoration: "none" }}
                                    >
                                        <ButtonRoxo
                                            style={{ height: 50, width: 130 }}
                                        >
                                            clique aqui
                                        </ButtonRoxo>
                                    </Link>
                                </MDBCol>
                            </MDBRow>
                        </PopupAviso>
                    )}
                {cliente &&
                    (cliente.controleFluxo === "FALTA_PAGAMENTO" ||
                        cliente.controleFluxo ===
                            "FALTA_CONFIRMAR_CELULAR") && (
                        <div
                            style={{
                                width: "97%",
                                color: "black",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                            }}
                        >
                            <MDBRow>
                                <MDBCol
                                    sm="12"
                                    className="col-example text-center"
                                    style={{
                                        marginTop: 15,
                                        marginBottom: 5,
                                    }}
                                >
                                    {cliente.controleFluxo ===
                                        "FALTA_PAGAMENTO" && (
                                        <h6>
                                            Para concluir seu cadastro,
                                            precisamos ir para a etapa de
                                            pagamento.{" "}
                                            <Link to="/minhaConta">
                                                Ir agora!
                                            </Link>
                                        </h6>
                                    )}
                                    {cliente.controleFluxo ===
                                        "FALTA_CONFIRMAR_CELULAR" && (
                                        <h6>
                                            Para concluir seu cadastro,
                                            precisamos confirmar seu celular.{" "}
                                            <Link to="/minhaConta">
                                                Ir agora!
                                            </Link>
                                        </h6>
                                    )}
                                </MDBCol>
                            </MDBRow>
                        </div>
                    )}

                <ContainerSearch>
                    <TextoContainerSearch>
                        <MDBRow style={{ marginLeft: 0 }}>
                            <h1
                                style={{
                                    fontSize: 60,
                                    fontWeight: "bold",
                                }}
                            >
                                <strong>WeTêxtil</strong>
                            </h1>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol
                                lg="12"
                                sm="12"
                                style={{ marginLeft: 0, marginTop: -5 }}
                            >
                                <h6 style={{ fontSize: 16 }}>
                                    Tudo o que você precisa em um
                                </h6>
                                <h6 style={{ marginTop: -8, fontSize: 16 }}>
                                    só lugar!
                                </h6>
                            </MDBCol>
                        </MDBRow>
                        <Form ref={formRef} onSubmit={handleSubmit}>
                            <MDBRow
                                style={{
                                    background: "rgba(255, 255, 255, 0.8)",
                                    borderRadius: 10,
                                    width: "90%",
                                    marginLeft: 0,
                                    marginTop: 20,
                                }}
                            >
                                <MDBCol size="10" className="col-example">
                                    <InputHome
                                        placeholder="O que está procurando?"
                                        name="pesquisaForm"
                                        onChange={changePesquisa}
                                        type="text"
                                    />
                                </MDBCol>
                                <MDBCol
                                    size="2"
                                    className="col-example text-right"
                                    style={{ background: "transparent" }}
                                >
                                    <button
                                        type="submit"
                                        style={{
                                            background: "transparent",
                                            border: "none",
                                        }}
                                    >
                                        <FiSearch
                                            style={{
                                                width: 20,
                                                height: 20,
                                                color: "#2d2d2d",
                                                marginTop: 12,
                                                cursor: "pointer",
                                            }}
                                        />
                                    </button>
                                </MDBCol>
                            </MDBRow>
                        </Form>
                        {produtos.length > 0 && pesquisa.length > 0 && (
                            <MDBRow>
                                <MDBCol size="12" className="col-example">
                                    {!cliente && (
                                        <strong>
                                            Aproximadamente {produtos.length}{" "}
                                            resultados.
                                            <a
                                                href="/login"
                                                style={{
                                                    color: "#32DA7F",
                                                    textDecoration: "none",
                                                }}
                                            >
                                                {" "}
                                                Faça login ou cadastre-se para
                                                visualizá-los!
                                            </a>
                                        </strong>
                                    )}
                                    {!!cliente && (
                                        <strong>
                                            Aproximadamente {produtos.length}{" "}
                                            resultados.
                                        </strong>
                                    )}
                                </MDBCol>
                            </MDBRow>
                        )}

                        {produtos.length === 0 && pesquisa.length > 0 && (
                            <MDBRow>
                                <MDBCol size="12" className="col-example">
                                    <strong>
                                        Nenhum produto foi encontrado. Amplie
                                        sua busca.
                                        {cliente && (
                                            <MDBRow>
                                                <MDBCol
                                                    size="9"
                                                    className="col-example"
                                                >
                                                    <Link
                                                        to={{
                                                            pathname: `https://api.whatsapp.com/send?phone=55${
                                                                outros.find(
                                                                    saida =>
                                                                        saida.chave ===
                                                                        "telefonePIP",
                                                                )?.valor
                                                            }&text=Olá, sou cliente do site wetextil.com e gostaria de uma PIP!`,
                                                        }}
                                                        target="_blank"
                                                        style={{
                                                            textDecoration:
                                                                "none",
                                                            color: "#ffffff",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                background:
                                                                    "#00d2e7",
                                                                opacity: "0.9",
                                                                padding:
                                                                    "8px 8px 8px 8px",
                                                                marginTop: 20,
                                                                borderRadius: 5,
                                                            }}
                                                        >
                                                            Não encontrou o que
                                                            procura? Acesse
                                                            nossa Pesquisa
                                                            Individual
                                                            Personalizada (PIP)!{" "}
                                                            <FaWhatsapp
                                                                style={{
                                                                    marginLeft: 2,
                                                                    marginTop:
                                                                        -5,
                                                                }}
                                                            />
                                                        </div>
                                                    </Link>
                                                </MDBCol>
                                            </MDBRow>
                                        )}
                                        {!cliente && (
                                            <MDBRow>
                                                <MDBCol
                                                    size="9"
                                                    className="col-example"
                                                >
                                                    <Link
                                                        to={{
                                                            pathname: `https://api.whatsapp.com/send?phone=55${
                                                                outros.find(
                                                                    saida =>
                                                                        saida.chave ===
                                                                        "telefonePIP",
                                                                )?.valor
                                                            }&text=Olá, vim pela wetextil.com e gostaria de um teste da PIP!`,
                                                        }}
                                                        target="_blank"
                                                        style={{
                                                            textDecoration:
                                                                "none",
                                                            color: "#ffffff",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                background:
                                                                    "#00d2e7",
                                                                opacity: "0.9",
                                                                padding:
                                                                    "8px 8px 8px 8px",
                                                                marginTop: 20,
                                                                borderRadius: 5,
                                                            }}
                                                        >
                                                            Faça uma Pesquisa
                                                            Individual
                                                            Personalizada (PIP)
                                                            com o nosso time. O
                                                            teste é gratuito!{" "}
                                                            <FaWhatsapp
                                                                style={{
                                                                    marginLeft: 2,
                                                                    marginTop:
                                                                        -5,
                                                                }}
                                                            />
                                                        </div>
                                                    </Link>
                                                </MDBCol>
                                            </MDBRow>
                                        )}
                                    </strong>
                                </MDBCol>
                            </MDBRow>
                        )}

                        {qtdClienteCategoria !== null && (
                            <MDBRow>
                                <MDBCol size="12" className="col-example">
                                    {qtdClienteCategoria.length === 0 &&
                                        categoriaSelecionada !== "" && (
                                            <strong>
                                                Nenhum anúncio de{" "}
                                                {categoriaSelecionada} foi
                                                encontrado. Amplie sua busca!
                                                {cliente && (
                                                    <MDBRow>
                                                        <MDBCol
                                                            size="9"
                                                            className="col-example"
                                                        >
                                                            <Link
                                                                to={{
                                                                    pathname: `https://api.whatsapp.com/send?phone=55${
                                                                        outros.find(
                                                                            saida =>
                                                                                saida.chave ===
                                                                                "telefonePIP",
                                                                        )?.valor
                                                                    }&text=Olá, sou cliente do site wetextil.com e gostaria de uma PIP!`,
                                                                }}
                                                                target="_blank"
                                                                style={{
                                                                    textDecoration:
                                                                        "none",
                                                                    color: "#ffffff",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        background:
                                                                            "#00d2e7",
                                                                        opacity:
                                                                            "0.9",
                                                                        padding:
                                                                            "8px 8px 8px 8px",
                                                                        marginTop: 20,
                                                                        borderRadius: 5,
                                                                    }}
                                                                >
                                                                    Não
                                                                    encontrou o
                                                                    que procura?
                                                                    Acesse nossa
                                                                    Pesquisa
                                                                    Individual
                                                                    Personalizada
                                                                    (PIP)!{" "}
                                                                    <FaWhatsapp
                                                                        style={{
                                                                            marginLeft: 2,
                                                                            marginTop:
                                                                                -5,
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Link>
                                                        </MDBCol>
                                                    </MDBRow>
                                                )}
                                                {!cliente && (
                                                    <MDBRow>
                                                        <MDBCol
                                                            size="9"
                                                            className="col-example"
                                                        >
                                                            <Link
                                                                to={{
                                                                    pathname: `https://api.whatsapp.com/send?phone=55${
                                                                        outros.find(
                                                                            saida =>
                                                                                saida.chave ===
                                                                                "telefonePIP",
                                                                        )?.valor
                                                                    }&text=Olá, vim pela wetextil.com e gostaria de um teste da PIP!`,
                                                                }}
                                                                target="_blank"
                                                                style={{
                                                                    textDecoration:
                                                                        "none",
                                                                    color: "#ffffff",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        background:
                                                                            "#00d2e7",
                                                                        opacity:
                                                                            "0.9",
                                                                        padding:
                                                                            "8px 8px 8px 8px",
                                                                        marginTop: 20,
                                                                        borderRadius: 5,
                                                                    }}
                                                                >
                                                                    Faça uma
                                                                    Pesquisa
                                                                    Individual
                                                                    Personalizada
                                                                    (PIP) com o
                                                                    nosso time.
                                                                    O teste é
                                                                    gratuito!{" "}
                                                                    <FaWhatsapp
                                                                        style={{
                                                                            marginLeft: 2,
                                                                            marginTop:
                                                                                -5,
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Link>
                                                        </MDBCol>
                                                    </MDBRow>
                                                )}
                                            </strong>
                                        )}

                                    {!cliente &&
                                        categoriaSelecionada !== "" &&
                                        qtdClienteCategoria.length > 0 && (
                                            <strong>
                                                Aproximadamente{" "}
                                                {qtdClienteCategoria.length}{" "}
                                                {categoriaSelecionada}{" "}
                                                encontrados.
                                                <a
                                                    href="/login"
                                                    style={{
                                                        color: "#32DA7F",
                                                        textDecoration: "none",
                                                    }}
                                                >
                                                    {" "}
                                                    Faça login ou cadastre-se
                                                    para visualizá-los!
                                                </a>
                                            </strong>
                                        )}
                                </MDBCol>
                            </MDBRow>
                        )}

                        {produtos.length === 0 &&
                            pesquisa.length === 0 &&
                            qtdClienteCategoria.length === 0 &&
                            !categoriaSelecionada && <strong>⠀</strong>}

                        <h5 style={{ marginTop: 80, fontSize: 16 }}>
                            A WeTêxtil conecta quem quer vender com quem quer
                            comprar.
                            {cliente && cliente?.controleFluxo !== "IS_ADMIN" && (
                                <a
                                    href="/produto"
                                    style={{
                                        textDecoration: "none",
                                        color: "white",
                                    }}
                                >
                                    {" "}
                                    <u>
                                        <strong>Anuncie aqui!</strong>
                                    </u>
                                </a>
                            )}
                            {!cliente && (
                                <a
                                    href="/login"
                                    style={{
                                        textDecoration: "none",
                                        color: "white",
                                    }}
                                >
                                    {" "}
                                    <u>
                                        <strong>Anuncie aqui!</strong>
                                    </u>
                                </a>
                            )}
                            {cliente && cliente?.controleFluxo === "IS_ADMIN" && (
                                <>
                                    {" "}
                                    <u>
                                        <strong>Anuncie aqui!</strong>
                                    </u>
                                </>
                            )}
                        </h5>
                    </TextoContainerSearch>
                </ContainerSearch>

                <TextoCategorias>
                    <h6 style={{ fontSize: 15 }}>
                        <strong>ENCONTRE POR CATEGORIA</strong>
                    </h6>
                </TextoCategorias>
                <Categorias>
                    <MDBRow>
                        <MDBCol
                            sm="12"
                            className="col-example"
                            style={{
                                overflow: "auto",
                            }}
                        >
                            {categorias.map(categoria => (
                                <ButtonCategoria
                                    type="submit"
                                    key={categoria.id}
                                    onClick={
                                        () => clickCategoria(categoria.nome)
                                        // eslint-disable-next-line react/jsx-curly-newline
                                    }
                                    style={{ marginTop: 10 }}
                                >
                                    ⠀{categoria.nome}⠀
                                </ButtonCategoria>
                            ))}
                        </MDBCol>
                    </MDBRow>
                </Categorias>

                {!cliente && (
                    <TituloComoFunciona>
                        <h6>
                            <strong>COMO FUNCIONA A WETÊXTIL?</strong>
                        </h6>
                    </TituloComoFunciona>
                )}

                {!cliente && (
                    <ComoFunciona>
                        <MDBRow
                            style={{ marginTop: "2.5%", marginBottom: "2.5%" }}
                        >
                            <MDBCol sm="3">
                                <MDBCard className="border border-white text-left">
                                    <MDBCardBody>
                                        <MDBCardTitle
                                            style={{ color: "#00d1e6" }}
                                        >
                                            <h6>
                                                <FiLock
                                                    style={{
                                                        marginTop: -5,
                                                        marginRight: 10,
                                                    }}
                                                />
                                                <strong>CADASTRE-SE</strong>
                                            </h6>
                                        </MDBCardTitle>
                                        <MDBCardText>
                                            Faça seu login em nossa plataforma e
                                            tenha acesso à área exclusiva para
                                            assinantes!
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol sm="3">
                                <MDBCard className="border border-white text-left">
                                    <MDBCardBody>
                                        <MDBCardTitle
                                            style={{ color: "#00d1e6" }}
                                        >
                                            <h6>
                                                <HiOutlineDocumentText
                                                    style={{
                                                        marginTop: -5,
                                                        marginRight: 10,
                                                    }}
                                                />
                                                <strong>CATEGORIAS</strong>
                                            </h6>
                                        </MDBCardTitle>
                                        <MDBCardText>
                                            Escolha as categorias e
                                            subcategorias em que você se
                                            encaixa!
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol sm="3">
                                <MDBCard className="border border-white text-left">
                                    <MDBCardBody>
                                        <MDBCardTitle
                                            style={{ color: "#00d1e6" }}
                                        >
                                            <h6>
                                                <HiOutlineSpeakerphone
                                                    style={{
                                                        marginTop: -5,
                                                        marginRight: 10,
                                                    }}
                                                />
                                                <strong>
                                                    TURBINE SEU PERFIL
                                                </strong>
                                            </h6>
                                        </MDBCardTitle>
                                        <MDBCardText>
                                            Descreva os principais
                                            produtos/serviços com que você
                                            trabalha!
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol sm="3">
                                <MDBCard className="border border-white text-left">
                                    <MDBCardBody>
                                        <MDBCardTitle
                                            style={{ color: "#00d1e6" }}
                                        >
                                            <h6>
                                                <VscRocket
                                                    style={{
                                                        marginTop: -5,
                                                        marginRight: 10,
                                                    }}
                                                />
                                                <strong>
                                                    CRESÇA E APAREÇA!
                                                </strong>
                                            </h6>
                                        </MDBCardTitle>
                                        <MDBCardText>
                                            Comece a aparecer nas buscas dentro
                                            da plataforma!
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        </MDBRow>
                    </ComoFunciona>
                )}

                {!cliente && (
                    <Link to="/cliente" style={{ textDecoration: "none" }}>
                        <ButtonRoxo type="submit" style={{ width: 200 }}>
                            comece por aqui
                        </ButtonRoxo>
                    </Link>
                )}

                {!cliente && (
                    <Quadrados>
                        <MDBRow>
                            <MDBCol
                                sm="12"
                                className="col-example p-3"
                                style={{
                                    color: "#00d1e6",
                                    marginBottom: "-2%",
                                }}
                            >
                                <h6>
                                    <strong>DESCUBRA O UNIVERSO TÊXTIL</strong>
                                </h6>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol
                                sm="12"
                                className="col-example p-3"
                                style={{
                                    color: "#2d3135",
                                }}
                            >
                                <h1>
                                    <strong>
                                        Tá, mas o que eu ganho com isso?
                                    </strong>
                                </h1>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow
                            style={{ marginTop: "-1%", marginBottom: "1%" }}
                        >
                            <MDBCol md="6" className="col-example">
                                <MDBRow>
                                    <MDBCol
                                        md="6"
                                        className="col-example"
                                        style={{
                                            marginTop: "1%",
                                            marginBottom: "1%",
                                        }}
                                    >
                                        <img
                                            width="100%"
                                            src={imgTempo}
                                            alt="..."
                                            style={{
                                                borderRadius: 5,
                                                boxShadow: "0 0 10px #c2c0c0",
                                            }}
                                        />
                                    </MDBCol>
                                    <MDBCol
                                        md="6"
                                        className="col-example"
                                        style={{
                                            marginTop: "1%",
                                            marginBottom: "1%",
                                        }}
                                    >
                                        <img
                                            width="100%"
                                            src={imgInformacaoPriviliegiada}
                                            alt="..."
                                            style={{
                                                borderRadius: 5,
                                                boxShadow: "0 0 10px #c2c0c0",
                                            }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                            <MDBCol md="6" className="col-example">
                                <MDBRow>
                                    <MDBCol
                                        md="6"
                                        className="col-example"
                                        style={{
                                            marginTop: "1%",
                                            marginBottom: "1%",
                                        }}
                                    >
                                        <img
                                            width="100%"
                                            src={imgDinheiro}
                                            alt="..."
                                            style={{
                                                borderRadius: 5,
                                                boxShadow: "0 0 10px #c2c0c0",
                                            }}
                                        />
                                    </MDBCol>
                                    <MDBCol
                                        md="6"
                                        className="col-example"
                                        style={{
                                            marginTop: "1%",
                                            marginBottom: "1%",
                                        }}
                                    >
                                        <img
                                            width="100%"
                                            src={imgVisibilidade}
                                            alt="..."
                                            style={{
                                                borderRadius: 5,
                                                boxShadow: "0 0 10px #c2c0c0",
                                            }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow style={{ marginTop: "1%", marginBottom: "1%" }}>
                            <MDBCol md="6" className="col-example">
                                <MDBRow>
                                    <MDBCol
                                        md="6"
                                        className="col-example"
                                        style={{
                                            marginTop: "1%",
                                            marginBottom: "1%",
                                        }}
                                    >
                                        <img
                                            width="100%"
                                            src={imgNetwork}
                                            alt="..."
                                            style={{
                                                borderRadius: 5,
                                                boxShadow: "0 0 10px #c2c0c0",
                                            }}
                                        />
                                    </MDBCol>
                                    <MDBCol
                                        md="6"
                                        className="col-example"
                                        style={{
                                            marginTop: "1%",
                                            marginBottom: "2%",
                                        }}
                                    >
                                        <img
                                            width="100%"
                                            src={imgConteudoExclusivo}
                                            alt="..."
                                            style={{
                                                borderRadius: 5,
                                                boxShadow: "0 0 10px #c2c0c0",
                                            }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                            <MDBCol
                                md="6"
                                className="col-example"
                                style={{
                                    marginTop: "0.4%",
                                    marginBottom: "1%",
                                }}
                            >
                                <Link
                                    to={{
                                        pathname: `https://api.whatsapp.com/send?phone=55${
                                            outros.find(
                                                saida =>
                                                    saida.chave ===
                                                    "telefonePIP",
                                            )?.valor
                                        }&text=Olá, não sou assinante e quero saber mais sobre a pesquisa exclusiva!`,
                                    }}
                                    target="_blank"
                                >
                                    <img
                                        width="100%"
                                        src={ViviExclusiva}
                                        alt="..."
                                        style={{
                                            borderRadius: 5,
                                            boxShadow: "0 0 10px #c2c0c0",
                                        }}
                                    />
                                </Link>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol
                                md="6"
                                className="col-example"
                                style={{
                                    marginTop: "1.5%",
                                    marginBottom: "2%",
                                }}
                            >
                                <img
                                    width="100%"
                                    src={MatchExclusivo}
                                    alt="..."
                                    style={{
                                        borderRadius: 5,
                                        boxShadow: "0 0 10px #c2c0c0",
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md="6" className="col-example">
                                <MDBRow>
                                    <MDBCol
                                        md="12"
                                        className="col-example"
                                        style={{ marginTop: "3%" }}
                                    >
                                        <Link to="/login">
                                            <img
                                                width="100%"
                                                src={TabelaExclusiva}
                                                alt="..."
                                                style={{
                                                    borderRadius: 5,
                                                    boxShadow:
                                                        "0 0 10px #c2c0c0",
                                                }}
                                            />
                                        </Link>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol
                                        md="12"
                                        className="col-example"
                                        style={{ marginTop: "5.5%" }}
                                    >
                                        <Link
                                            to={{
                                                pathname: `${
                                                    outros.find(
                                                        saida =>
                                                            saida.chave ===
                                                            "linkInstagram",
                                                    )?.valor
                                                }`,
                                            }}
                                            target="_blank"
                                        >
                                            <img
                                                width="100%"
                                                src={BannerInsta}
                                                alt="..."
                                                style={{
                                                    borderRadius: 5,
                                                    boxShadow:
                                                        "0 0 10px #c2c0c0",
                                                }}
                                            />
                                        </Link>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                    </Quadrados>
                )}

                {cliente && (
                    <Quadrados>
                        <MDBRow>
                            <MDBCol
                                sm="12"
                                className="col-example p-3"
                                style={{
                                    color: "#00d1e6",
                                    marginBottom: "-2%",
                                }}
                            >
                                <h6>
                                    <strong>DESCUBRA O UNIVERSO TÊXTIL</strong>
                                </h6>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol
                                sm="12"
                                className="col-example p-3"
                                style={{
                                    color: "#2d3135",
                                }}
                            >
                                <h1>
                                    <strong>
                                        Bem vindo(a), {cliente.nome}
                                    </strong>
                                </h1>
                            </MDBCol>
                        </MDBRow>

                        <MDBRow style={{ marginTop: "1%", marginBottom: "1%" }}>
                            <MDBCol
                                md="6"
                                className="col-example"
                                style={{
                                    marginTop: "0.4%",
                                    marginBottom: "1%",
                                }}
                            >
                                <Link to="/minhaVitrine">
                                    <img
                                        width="100%"
                                        src={minhaVitrine}
                                        alt="..."
                                        style={{
                                            borderRadius: 5,
                                            boxShadow: "0 0 10px #c2c0c0",
                                        }}
                                    />
                                </Link>
                            </MDBCol>
                            <MDBCol
                                md="6"
                                className="col-example"
                                style={{
                                    marginTop: "0.4%",
                                    marginBottom: "1%",
                                }}
                            >
                                <Link
                                    to={{
                                        pathname: `https://api.whatsapp.com/send?phone=55${
                                            outros.find(
                                                saida =>
                                                    saida.chave ===
                                                    "telefonePIP",
                                            )?.valor
                                        }&text=Olá, sou assinante e quero fazer uma Pesquisa Individual Personalizada!`,
                                    }}
                                    target="_blank"
                                >
                                    <img
                                        width="100%"
                                        src={ViviAssinante}
                                        alt="..."
                                        style={{
                                            borderRadius: 5,
                                            boxShadow: "0 0 10px #c2c0c0",
                                        }}
                                    />
                                </Link>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol
                                md="6"
                                className="col-example"
                                style={{
                                    marginTop: "1.5%",
                                    marginBottom: "2%",
                                }}
                            >
                                <img
                                    width="100%"
                                    src={MatchAssinante}
                                    alt="..."
                                    style={{
                                        borderRadius: 5,
                                        boxShadow: "0 0 10px #c2c0c0",
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md="6" className="col-example">
                                <MDBRow>
                                    <MDBCol
                                        md="12"
                                        className="col-example"
                                        style={{ marginTop: "3%" }}
                                    >
                                        <Link to="/tabela">
                                            <img
                                                width="100%"
                                                src={TabelaAssinante}
                                                alt="..."
                                                style={{
                                                    borderRadius: 5,
                                                    boxShadow:
                                                        "0 0 10px #c2c0c0",
                                                }}
                                            />
                                        </Link>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol
                                        md="12"
                                        className="col-example"
                                        style={{ marginTop: "5.5%" }}
                                    >
                                        <Link
                                            to={{
                                                pathname: `${
                                                    outros.find(
                                                        saida =>
                                                            saida.chave ===
                                                            "linkInstagram",
                                                    )?.valor
                                                }`,
                                            }}
                                            target="_blank"
                                        >
                                            <img
                                                width="100%"
                                                src={BannerInsta}
                                                alt="..."
                                                style={{
                                                    borderRadius: 5,
                                                    boxShadow:
                                                        "0 0 10px #c2c0c0",
                                                }}
                                            />
                                        </Link>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                    </Quadrados>
                )}

                <FundoAzul>
                    <Consultores
                        style={{ marginTop: "2%", marginBottom: "2%" }}
                    >
                        <MDBRow style={{ marginBottom: "-2%" }}>
                            <MDBCol sm="12" className="col-example p-3">
                                <h6 style={{ fontSize: 15, color: "black" }}>
                                    <strong>ESPECIALISTAS WETÊXTIL</strong>
                                </h6>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol sm="12" className="col-example p-3">
                                <h1 style={{ color: "black" }}>
                                    <strong>Nossos consultores</strong>
                                </h1>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow
                            style={{ marginTop: "-1%", marginBottom: "1%" }}
                        >
                            <MDBCol md="12" className="col-example">
                                <MDBRow center>
                                    <MDBCol
                                        md="4"
                                        className="col-example"
                                        style={{
                                            marginTop: "1%",
                                            marginBottom: "1%",
                                        }}
                                    >
                                        <Link
                                            to="/consultorDois"
                                            style={{ textDecoration: "none" }}
                                        >
                                            <img
                                                width="100%"
                                                src={Consultor02Exclusivo}
                                                alt="..."
                                                style={{
                                                    borderRadius: 5,
                                                    boxShadow:
                                                        "0 0 10px #c2c0c0",
                                                }}
                                            />
                                        </Link>
                                    </MDBCol>

                                    <MDBCol
                                        md="4"
                                        className="col-example"
                                        style={{
                                            marginTop: "1%",
                                            marginBottom: "1%",
                                        }}
                                    >
                                        <Link
                                            to="/consultorTres"
                                            style={{ textDecoration: "none" }}
                                        >
                                            <img
                                                width="100%"
                                                src={Consultor03Exclusivo}
                                                alt="..."
                                                style={{
                                                    borderRadius: 5,
                                                    boxShadow:
                                                        "0 0 10px #c2c0c0",
                                                }}
                                            />
                                        </Link>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                    </Consultores>
                </FundoAzul>

                {!cliente && (
                    <FundoRoxo>
                        <MDBRow
                            style={{
                                marginTop: "5%",
                                marginBottom: "5%",
                                marginLeft: "3%",
                                marginRight: "3%",
                            }}
                        >
                            <MDBCol
                                md="7"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                }}
                            >
                                <img
                                    width="80%"
                                    src={imgNossoMovimento}
                                    alt="WeTextil"
                                />
                            </MDBCol>
                            <MDBCol
                                md="5"
                                style={{
                                    marginTop: 20,
                                    marginBottom: 20,
                                }}
                            >
                                <h6 style={{ marginTop: 40 }}>
                                    <strong>NOSSO MOVIMENTO</strong>
                                </h6>
                                <br />
                                <h2>
                                    <strong>Faça parte da</strong>
                                    <br />
                                    <strong>
                                        maior comunidade de negócios
                                    </strong>
                                    <br />
                                    <strong>têxteis do Brasil!</strong>
                                </h2>
                                <br />
                                <br />
                                <br />
                                {!cliente && (
                                    <Link
                                        to="/cliente"
                                        style={{ textDecoration: "none" }}
                                    >
                                        <ButtonBranco
                                            type="submit"
                                            style={{ width: 200 }}
                                        >
                                            <strong>Seja membro!</strong>
                                        </ButtonBranco>
                                    </Link>
                                )}
                            </MDBCol>
                        </MDBRow>
                    </FundoRoxo>
                )}

                <FundoCinza>
                    <MDBRow
                        style={{
                            marginLeft: "1%",
                            marginRight: "3%",
                            marginBottom: "10%",
                        }}
                    >
                        <MDBCol
                            md="1"
                            className="text-center align-items-center"
                            style={{
                                marginTop: "2%",
                                marginBottom: "2%",
                                alignItems: "center",
                            }}
                        >
                            <img
                                width="100px"
                                src={imgLogoBranca}
                                alt="WeTextil"
                            />
                        </MDBCol>
                        <MDBCol
                            md="6"
                            style={{ marginTop: "2%", marginBottom: "2%" }}
                        >
                            <MDBRow className="row-cols-1 row-cols-md-1 g-1 text-center">
                                <strong>CATEGORIAS</strong>
                            </MDBRow>
                            <MDBRow className="row-cols-1 row-cols-md-3 g-3 text-center">
                                {categorias.map(categoria => (
                                    <MDBCol
                                        key={categoria.id}
                                        onClick={
                                            () => clickCategoria(categoria.nome)
                                            // eslint-disable-next-line react/jsx-curly-newline
                                        }
                                        style={{ cursor: "pointer" }}
                                    >
                                        <small>{categoria.nome}⠀</small>
                                    </MDBCol>
                                ))}
                            </MDBRow>
                        </MDBCol>
                        <MDBCol
                            md="3"
                            style={{ marginTop: "2%", marginBottom: "2%" }}
                        >
                            <MDBRow className="row-cols-1 row-cols-md-1 g-1 text-center">
                                <strong>AJUDA</strong>
                            </MDBRow>
                            <MDBRow
                                className="row-cols-1 row-cols-md-1 g-1 text-center"
                                style={{ marginTop: "2%" }}
                            >
                                <small>
                                    {
                                        outros.find(
                                            saida =>
                                                saida.chave === "telefonePIP",
                                        )?.valor
                                    }
                                </small>
                            </MDBRow>
                            <MDBRow
                                className="row-cols-1 row-cols-md-1 g-1 text-center"
                                style={{ marginTop: "1%" }}
                            >
                                <small>contato@wetextil.com</small>
                            </MDBRow>
                            <MDBRow
                                className="row-cols-1 row-cols-md-1 g-1 text-center"
                                style={{ marginTop: "1%" }}
                            >
                                <small>
                                    <a
                                        href="/termosDeUso"
                                        target="_blank"
                                        style={{ color: "white" }}
                                    >
                                        Política de Privacidade e Termos de Uso
                                    </a>
                                </small>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol
                            md="2"
                            style={{ marginTop: "2%", marginBottom: "2%" }}
                        >
                            <MDBRow className="row-cols-1 row-cols-md-1 g-1 text-center">
                                <strong>REDES SOCIAIS</strong>
                            </MDBRow>
                            <MDBRow
                                style={{
                                    marginTop: "4%",
                                    marginBottom: "2%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Link
                                    to={{
                                        pathname: `${
                                            outros.find(
                                                saida =>
                                                    saida.chave ===
                                                    "linkFacebook",
                                            )?.valor
                                        }`,
                                    }}
                                    target="_blank"
                                >
                                    <ButtonIcon type="submit">
                                        <FiFacebook />
                                    </ButtonIcon>
                                </Link>
                                <Link
                                    to={{
                                        pathname: `${
                                            outros.find(
                                                saida =>
                                                    saida.chave ===
                                                    "linkInstagram",
                                            )?.valor
                                        }`,
                                    }}
                                    target="_blank"
                                >
                                    <ButtonIcon type="submit">
                                        <FiInstagram />
                                    </ButtonIcon>
                                </Link>
                                <Link
                                    to={{
                                        pathname: `${
                                            outros.find(
                                                saida =>
                                                    saida.chave ===
                                                    "linkLinkedin",
                                            )?.valor
                                        }`,
                                    }}
                                    target="_blank"
                                >
                                    <ButtonIcon type="submit">
                                        <FiLinkedin />
                                    </ButtonIcon>
                                </Link>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow
                        style={{
                            marginLeft: "1%",
                            marginRight: "3%",
                        }}
                    >
                        <MDBCol
                            md="12"
                            className="text-center align-items-center"
                            style={{
                                marginTop: "2%",
                                marginBottom: "2%",
                                alignItems: "center",
                            }}
                        >
                            Copyright ©️ 2021 WeTêxtil. All trademarks and logos
                            are the property of their respective owners. All
                            rights reserved.
                        </MDBCol>
                    </MDBRow>
                </FundoCinza>
            </Content>
        </Container>
    );
};

export default Home;
