/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback, useRef } from "react";

import { Link, useHistory, Redirect } from "react-router-dom";
import FormReact from "react-bootstrap/Form";
import { Form } from "@unform/web";
import moment from "moment";
import { Nav, Navbar, NavDropdown, Row, Col } from "react-bootstrap";

import "react-toastify/dist/ReactToastify.css";
import {
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBCol,
    MDBRow,
} from "mdb-react-ui-kit";

import * as Yup from "yup";
import { FormHandles } from "@unform/core";

import { FiArrowDown, FiArrowRight, FiSearch, FiX } from "react-icons/fi";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";

import { CSVLink } from "react-csv";
import { useAuth } from "../../../hooks/auth";
import Input from "../../../components/Input";
import api from "../../../services/api";
import imgLogo from "../../../assets/Logo.png";

import ButtonAzulEscuro from "../../../components/ButtonAzulEscuro";

import { Container, Content } from "./styles";

import getValidationErrors from "../../../utils/getValidationErrors";
import BorderSelect from "../../../components/BorderSelect";
import InputHome from "../../../components/InputHome";
import InputLogin from "../../../components/InputLogin";
import InputPesquisa from "../../../components/InputPesquisa";
import ButtonAzul from "../../../components/ButtonAzul";
import ButtonRoxo from "../../../components/ButtonRoxo";

interface CupomUpdateFormData {
    idCliente: string;
    cupomDesconto: string;
}

interface InativoUpdateFormData {
    idCliente: string;
}

interface FiltroFormData {
    filtro: string;
    busca: string;
}

interface Cliente {
    id: string;
    controleFluxo: string;
    isPessoaJuridica: string;
    nome: string;
    docIdent: string;
    celular: string;
    email: string;
    celularValidado: string;
    news: string;
    plano: string;
    ativo: boolean;
    cupomDesconto: string;
    biografia: string;
    linkInstagram: string;
    linkFacebook: string;
    linkLinkedin: string;
    linkSite: string;
}

interface params {
    idCliente: string;
}

const Clientes: React.FC = () => {
    const { cliente } = useAuth();
    const history = useHistory();
    const formRef = useRef<FormHandles>(null);

    const headers = [
        { label: "id", key: "id" },
        { label: "Nome", key: "nome" },
        { label: "ControleFluxo", key: "controleFluxo" },
        { label: "isPessoaJuridica", key: "isPessoaJuridica" },
        { label: "Documento Identidade", key: "docIdent" },
        { label: "Celular", key: "celular" },
        { label: "Email", key: "email" },
        { label: "Celular Validado", key: "celularValidado" },
        { label: "News", key: "news" },
        { label: "Plano", key: "plano" },
        { label: "Ativo", key: "ativo" },
        { label: "Cupom Desconto", key: "cupomDesconto" },
        { label: "Biografia", key: "biografia" },
        { label: "Link Instagram", key: "linkInstagram" },
        { label: "Link Facebook", key: "linkFacebook" },
        { label: "Link Linkedin", key: "linkLinkedin" },
        { label: "Link Site", key: "linkSite" },
        { label: "Cidade", key: "cidade.nome" },
        { label: "Estado", key: "estado.sigla" },
        { label: "Criado em", key: "created_at" },
        { label: "Atualizado em", key: "updated_at" },
    ];

    const [clientes, setClientes] = useState<Cliente[]>([]);

    useEffect(() => {
        api.get("/clientes").then(response => setClientes(response.data));
    }, []);

    const [hiddenCupom, setHiddenCupom] = useState(true);
    const [hiddenInativo, setHiddenInativo] = useState(true);
    const [hiddenFiltro, setHiddenFiltro] = useState(true);
    const [hiddenDetalhes, setHiddenDetalhes] = useState(true);

    const cupons = [
        { id: "1", nome: "WTETERNO" },
        { id: "2", nome: "WT365DAYSEDZ" },
        { id: "3", nome: "WT180DIASFREE" },
        { id: "4", nome: "WT90DIASFREE" },
        { id: "5", nome: "WT30DIASFREE" },
        { id: "6", nome: "REMOVER CUPOM" },
    ];

    const [selectedNomeCupom, setSelectedNomeCupom] = useState<string>("");
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChangeCupom = (e: any) => {
        setSelectedNomeCupom(e.nome);
    };

    const [selectedIdClienteCupom, setSelectedIdClienteCupom] = useState([]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChangeClienteCupom = (e: any) => {
        setSelectedIdClienteCupom(e.id);
    };

    const filtros = [
        { id: "1", filtro: "Cidade" },
        { id: "2", filtro: "Cliente - Nome ou Email" },
        { id: "3", filtro: "Categoria" },
        { id: "4", filtro: "Sub Categoria" },
        { id: "5", filtro: "Clientes Ativos" },
        { id: "6", filtro: "Clientes Inativos" },
        { id: "7", filtro: "Cupom" },
        { id: "8", filtro: "Plano" },
        { id: "9", filtro: "Receber notícias" },
        { id: "10", filtro: "Pesquisa" },
    ];

    const [selectedNomeFiltro, setSelectedNomeFiltro] = useState<string>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChangeFiltro = (e: any) => {
        setSelectedNomeFiltro(e.filtro);
        if (e.filtro === "Clientes Ativos") {
            api.get(`/clientes/ativos`).then(response =>
                setClientes(response.data),
            );
        }
        if (e.filtro === "Clientes Inativos") {
            api.get(`/clientes/inativos`).then(response =>
                setClientes(response.data),
            );
        }
    };

    const [busca, setBusca] = useState<string>("");
    const changeBusca = (e: {
        target: { value: React.SetStateAction<string> };
    }) => {
        setBusca(e.target.value);
        switch (selectedNomeFiltro) {
            case "Cidade":
                api.get(`/clientes/cidade/${e.target.value}`).then(response =>
                    setClientes(response.data),
                );
                break;
            case "Cliente - Nome ou Email":
                api.get(`/clientes/search/${e.target.value}`).then(response =>
                    setClientes(response.data),
                );
                break;
            case "Categoria":
                api.get(`/clientes/categoria/${e.target.value}`).then(
                    response => setClientes(response.data),
                );
                break;
            case "Sub Categoria":
                api.get(`/clientes/subCategoria/${e.target.value}`).then(
                    response => setClientes(response.data),
                );
                break;
            case "Pesquisa":
                api.get(`/clientes/pesquisa/${e.target.value}`).then(response =>
                    setClientes(response.data),
                );
                break;
            case "Cupom":
                api.get(`/clientes/cupom/${e.target.value}`).then(response =>
                    setClientes(response.data),
                );
                break;

            case "Plano":
                api.get(`/clientes/plano/${e.target.value}`).then(response =>
                    setClientes(response.data),
                );
                break;

            case "Receber notícias":
                if (e.target.value === "Sim" || e.target.value === "sim") {
                    api.get(`/clientes/news/true`).then(response =>
                        setClientes(response.data),
                    );
                } else if (
                    e.target.value === "Não" ||
                    e.target.value === "Nao" ||
                    e.target.value === "não" ||
                    e.target.value === "nao"
                ) {
                    api.get(`/clientes/news/false`).then(response =>
                        setClientes(response.data),
                    );
                }
                break;
            default:
                break;
        }

        if (e.target.value.length === 0) {
            api.get("/clientes").then(response => setClientes(response.data));
        }
    };

    const [selectedIdClienteInativo, setSelectedIdClienteInativo] =
        useState<string>("");
    const [selectedAtivoClienteInativo, setSelectedAtivoClienteInativo] =
        useState<string>("");
    const [
        selectedContraAtivoClienteInativo,
        setSelectedContraAtivoClienteInativo,
    ] = useState<string>("");
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChangeClienteInativo = (e: any) => {
        api.get("/clientes").then(response => setClientes(response.data));
        if (e.ativo === true) {
            setSelectedAtivoClienteInativo("Ativo");
            setSelectedContraAtivoClienteInativo("Inativo");
        } else {
            setSelectedAtivoClienteInativo("Inativo");
            setSelectedContraAtivoClienteInativo("Ativo");
        }
        setSelectedIdClienteInativo(e.id);
    };

    const [clienteSelecionado, setSlienteSelecionado] = useState<string>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClienteSelecionado = (e: any) => {
        setSlienteSelecionado(e.target.value);
        if (e.target.value) {
            setHiddenDetalhes(false);
        }
    };

    const handleSubmitCupom = useCallback(
        async (data: CupomUpdateFormData) => {
            try {
                const { idCliente, cupomDesconto } = data;

                if (selectedNomeCupom === "REMOVER CUPOM") {
                    const formData = {
                        idCliente: selectedIdClienteCupom,
                        cupomDesconto: "",
                    };

                    await api
                        .put("/clientes/cupom", formData)
                        .then(() => {
                            toast.success("Cupom inserido com sucesso!", {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: true,
                                progress: undefined,
                            });
                            setHiddenCupom(true);
                        })
                        .catch(() => {
                            toast.error("Erro! Tente novamente!", {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: true,
                                progress: undefined,
                            });
                            setHiddenCupom(true);
                        });
                } else {
                    const formData = {
                        idCliente: selectedIdClienteCupom,
                        cupomDesconto: selectedNomeCupom,
                    };

                    await api
                        .put("/clientes/cupom", formData)
                        .then(() => {
                            toast.success("Cupom inserido com sucesso!", {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: true,
                                progress: undefined,
                            });
                            setHiddenCupom(true);
                        })
                        .catch(() => {
                            toast.error("Erro! Tente novamente!", {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: true,
                                progress: undefined,
                            });
                            setHiddenCupom(true);
                        });
                }
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);
                    return;
                }

                toast.error("Erro no servidor! Tente novamente!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            }
        },
        [selectedIdClienteCupom, selectedNomeCupom],
    );

    const handleSubmitInativo = useCallback(
        async (data: InativoUpdateFormData) => {
            try {
                formRef.current?.setErrors({});
                const schema = Yup.object().shape({
                    SelectCliente: Yup.string().required("Campo obrigatório"),
                });

                await schema.validate(data, {
                    // Faz com que o Yup não pare no primeiro erro. Por padrão é true.
                    abortEarly: false,
                });

                await api
                    .put(`/clientes/ativo/${selectedIdClienteInativo}`)
                    .then(() => {
                        toast.success("Cliente alterado com sucesso!", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                        });
                        setHiddenInativo(true);
                    })
                    .catch(() => {
                        toast.error("Erro! Tente novamente!", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                        });
                        setHiddenInativo(true);
                    });
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);
                    return;
                }

                toast.error("Erro no servidor! Tente novamente!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            }
        },
        [selectedIdClienteInativo],
    );

    const handleSubmitFiltro = useCallback(async (data: FiltroFormData) => {
        try {
            formRef.current?.setErrors({});
            const schema = Yup.object().shape({
                SelectFiltro: Yup.string().required("Campo obrigatório"),
            });

            await schema.validate(data, {
                // Faz com que o Yup não pare no primeiro erro. Por padrão é true.
                abortEarly: false,
            });

            // const { filtro, busca } = data;
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const errors = getValidationErrors(err);

                formRef.current?.setErrors(errors);
                return;
            }

            toast.error("Erro no servidor! Tente novamente!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        }
    }, []);

    // Redirecionamentos de páginas privadas
    if (cliente.controleFluxo !== "IS_ADMIN") {
        return <Redirect to="/" />;
    }

    return (
        <Container>
            <Navbar
                collapseOnSelect
                expand="lg"
                style={{
                    background: "#ffffff",
                }}
            >
                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    style={{ border: "none" }}
                />
                <Navbar.Brand style={{ marginLeft: "2%" }}>
                    <Link to="/">
                        <img width="125px" src={imgLogo} alt="WeTextil" />
                    </Link>
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto text-center" />
                    <Nav>
                        <Nav.Link
                            href="/dashboardMapeamentoMercado"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Mapeie Mercado
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardFabricacaoMalha"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Fabrique Malha
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardPesquisa"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Pesquisas
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardTabelaWeTextil"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Tabela WeTêxtil
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardClientes"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#846cf9",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                <strong>Clientes</strong>
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardCategorias"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Categorias
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardAssinaturas"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Assinaturas
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardProdutos"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Produtos/Serviços
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardOutros"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Outros
                            </span>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Content>
                <ToastContainer />
                <MDBRow style={{ marginBottom: 20, marginTop: -20 }} end>
                    <MDBCol md="3" hidden={hiddenDetalhes}>
                        <Link
                            to={`/dashboardClientesDetalhes/${clienteSelecionado}`}
                            style={{ textDecoration: "none" }}
                        >
                            <ButtonRoxo style={{ height: 50 }}>
                                Detalhes
                            </ButtonRoxo>
                        </Link>
                    </MDBCol>
                    <MDBCol md="3">
                        <CSVLink
                            data={clientes}
                            headers={headers}
                            filename={`TabelaClientes-${moment().format(
                                "DD/MM/YYYY HH:mm",
                            )}`}
                            style={{ textDecoration: "none" }}
                        >
                            <ButtonAzulEscuro
                                style={{ height: 50 }}
                                type="submit"
                            >
                                Download
                            </ButtonAzulEscuro>
                        </CSVLink>
                    </MDBCol>
                    <MDBCol md="2">
                        <ButtonAzulEscuro
                            style={{ height: 50 }}
                            onClick={() => {
                                setHiddenFiltro(false);
                                setHiddenCupom(true);
                                setHiddenInativo(true);
                            }}
                        >
                            Filtrar
                        </ButtonAzulEscuro>
                    </MDBCol>
                    <MDBCol md="2">
                        <ButtonAzulEscuro
                            style={{ height: 50 }}
                            onClick={() => {
                                setHiddenFiltro(true);
                                setHiddenCupom(false);
                                setHiddenInativo(true);
                            }}
                        >
                            Cupom
                        </ButtonAzulEscuro>
                    </MDBCol>
                    <MDBCol md="2">
                        <ButtonAzulEscuro
                            style={{ height: 50 }}
                            onClick={() => {
                                setHiddenFiltro(true);
                                setHiddenCupom(true);
                                setHiddenInativo(false);
                            }}
                        >
                            Status
                        </ButtonAzulEscuro>
                    </MDBCol>
                </MDBRow>

                <Form ref={formRef} onSubmit={handleSubmitFiltro}>
                    <MDBRow style={{ marginBottom: 20 }} hidden={hiddenFiltro}>
                        <MDBCol md="5" style={{ marginTop: 10 }}>
                            <BorderSelect
                                name="SelectFiltro"
                                value={selectedNomeFiltro}
                                hidden
                            >
                                <Select
                                    className="dropdown"
                                    placeholder="Buscar por..."
                                    onChange={handleChangeFiltro}
                                    options={filtros}
                                    getOptionLabel={option => option.filtro}
                                    getOptionValue={option => option.id}
                                    isSearchable={false}
                                    maxMenuHeight={200}
                                    theme={theme => ({
                                        ...theme,
                                        height: 100,
                                        borderRadius: 10,
                                    })}
                                />
                            </BorderSelect>
                        </MDBCol>
                        <MDBCol md="6" style={{ marginTop: 18 }}>
                            <InputPesquisa
                                icon={FiSearch}
                                placeholder={`Informe ${selectedNomeFiltro}`}
                                name="pesquisaForm"
                                onChange={changeBusca}
                                type="text"
                            />
                        </MDBCol>
                        <MDBCol md="1" style={{ marginTop: 15 }}>
                            <ButtonAzulEscuro
                                style={{
                                    height: 40,
                                    marginTop: 0,
                                    background: "white",
                                    color: "black",
                                }}
                                onClick={() => {
                                    setHiddenFiltro(true);
                                    api.get("/clientes").then(response =>
                                        setClientes(response.data),
                                    );
                                }}
                            >
                                <strong>
                                    <FiX />
                                </strong>
                            </ButtonAzulEscuro>
                        </MDBCol>
                    </MDBRow>
                </Form>

                <Form ref={formRef} onSubmit={handleSubmitCupom}>
                    <MDBRow style={{ marginBottom: 20 }} hidden={hiddenCupom}>
                        <MDBCol md="4" style={{ marginTop: 10 }}>
                            <BorderSelect
                                name="SelectCliente"
                                value={selectedIdClienteCupom}
                                hidden
                            >
                                <Select
                                    className="dropdown"
                                    placeholder="Cliente"
                                    onChange={handleChangeClienteCupom}
                                    options={clientes}
                                    getOptionLabel={
                                        option =>
                                            `${option.nome} - ${option.email}`
                                        // eslint-disable-next-line react/jsx-curly-newline
                                    }
                                    getOptionValue={option => option.id}
                                    isSearchable
                                    maxMenuHeight={200}
                                    theme={theme => ({
                                        ...theme,
                                        height: 100,
                                        borderRadius: 10,
                                    })}
                                />
                            </BorderSelect>
                        </MDBCol>
                        <MDBCol md="4" style={{ marginTop: 10 }}>
                            <BorderSelect
                                name="SelectCupom"
                                value={selectedNomeCupom}
                                hidden
                            >
                                <Select
                                    className="dropdown"
                                    placeholder="Cupom"
                                    onChange={handleChangeCupom}
                                    options={cupons}
                                    getOptionLabel={option => option.nome}
                                    getOptionValue={option => option.id}
                                    isSearchable
                                    maxMenuHeight={200}
                                    theme={theme => ({
                                        ...theme,
                                        height: 100,
                                        borderRadius: 10,
                                    })}
                                />
                            </BorderSelect>
                        </MDBCol>
                        <MDBCol md="3" style={{ marginTop: 15 }}>
                            <ButtonRoxo
                                type="submit"
                                style={{ height: 40, marginTop: 0 }}
                            >
                                Confirmar
                            </ButtonRoxo>
                        </MDBCol>
                        <MDBCol md="1" style={{ marginTop: 15 }}>
                            <ButtonAzulEscuro
                                style={{
                                    height: 40,
                                    marginTop: 0,
                                    background: "white",
                                    color: "black",
                                }}
                                onClick={() => {
                                    setHiddenCupom(true);
                                    api.get("/clientes").then(response =>
                                        setClientes(response.data),
                                    );
                                }}
                            >
                                <strong>
                                    <FiX />
                                </strong>
                            </ButtonAzulEscuro>
                        </MDBCol>
                    </MDBRow>
                </Form>

                <Form ref={formRef} onSubmit={handleSubmitInativo}>
                    <MDBRow style={{ marginBottom: 20 }} hidden={hiddenInativo}>
                        <MDBCol md="5" style={{ marginTop: 10 }}>
                            <BorderSelect
                                name="SelectCliente"
                                value={selectedIdClienteInativo}
                                hidden
                            >
                                <Select
                                    className="dropdown"
                                    placeholder="Cliente"
                                    onChange={handleChangeClienteInativo}
                                    options={clientes}
                                    getOptionLabel={
                                        option =>
                                            `${option.nome} - ${option.email}`
                                        // eslint-disable-next-line react/jsx-curly-newline
                                    }
                                    getOptionValue={option => option.id}
                                    isSearchable
                                    maxMenuHeight={200}
                                    theme={theme => ({
                                        ...theme,
                                        height: 100,
                                        borderRadius: 10,
                                    })}
                                />
                            </BorderSelect>
                        </MDBCol>
                        <MDBCol md="3" style={{ marginTop: 10 }}>
                            <h6 style={{ marginTop: 15 }}>
                                {selectedAtivoClienteInativo}
                                ⠀⠀⠀⠀⠀
                                <FiArrowRight style={{ marginTop: -3 }} />
                                ⠀⠀⠀⠀⠀
                                {selectedContraAtivoClienteInativo}
                            </h6>
                        </MDBCol>
                        <MDBCol md="3" style={{ marginTop: 15 }}>
                            <ButtonRoxo
                                type="submit"
                                style={{ height: 40, marginTop: 0 }}
                            >
                                Confirmar
                            </ButtonRoxo>
                        </MDBCol>
                        <MDBCol md="1" style={{ marginTop: 15 }}>
                            <ButtonAzulEscuro
                                style={{
                                    height: 40,
                                    marginTop: 0,
                                    background: "white",
                                    color: "black",
                                }}
                                onClick={() => {
                                    setHiddenInativo(true);
                                    api.get("/clientes").then(response =>
                                        setClientes(response.data),
                                    );
                                }}
                            >
                                <strong>
                                    <FiX />
                                </strong>
                            </ButtonAzulEscuro>
                        </MDBCol>
                    </MDBRow>
                </Form>

                <MDBTable responsive bordered striped>
                    <MDBTableHead className="text-center" dark>
                        <tr>
                            <th scope="col">Selecionar</th>
                            <th scope="col">Nome</th>
                            <th scope="col">Pessoa</th>
                            <th scope="col">Documento</th>
                            <th scope="col">Celular</th>
                            <th scope="col">Email</th>
                            <th scope="col">News</th>
                            <th scope="col">Plano</th>
                            <th scope="col">Cupom</th>
                            <th scope="col">Descrição</th>
                            <th scope="col">Instagram</th>
                            <th scope="col">Facebook</th>
                            <th scope="col">Linkedin</th>
                            <th scope="col">Site</th>
                            <th scope="col">Status</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {clientes &&
                            clientes.map(clienteAtual => (
                                <tr>
                                    <td>
                                        <FormReact.Check
                                            name="selecionado"
                                            type="radio"
                                            value={clienteAtual.id}
                                            onClick={handleClienteSelecionado}
                                        />
                                    </td>
                                    <td>{clienteAtual.nome}</td>
                                    {clienteAtual.isPessoaJuridica ===
                                        "true" && <td>Jurídica</td>}
                                    {clienteAtual.isPessoaJuridica ===
                                        "false" && <td>Física</td>}
                                    <td>{clienteAtual.docIdent}</td>
                                    <td>{clienteAtual.celular}</td>
                                    <td>{clienteAtual.email}</td>
                                    {clienteAtual.news === "true" && (
                                        <td>Sim</td>
                                    )}
                                    {clienteAtual.news === "false" && (
                                        <td>Não</td>
                                    )}
                                    <td>{clienteAtual.plano}</td>
                                    <td>{clienteAtual.cupomDesconto}</td>
                                    <td>{clienteAtual.biografia}</td>
                                    <td>{clienteAtual.linkInstagram}</td>
                                    <td>{clienteAtual.linkFacebook}</td>
                                    <td>{clienteAtual.linkLinkedin}</td>
                                    <td>{clienteAtual.linkSite}</td>
                                    {clienteAtual.ativo === true && (
                                        <td>Ativo</td>
                                    )}
                                    {clienteAtual.ativo === false && (
                                        <td>Inativo</td>
                                    )}
                                </tr>
                            ))}
                    </MDBTableBody>
                </MDBTable>
            </Content>
        </Container>
    );
};

export default Clientes;
