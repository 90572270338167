import styled, { css } from "styled-components";

import { animated } from "react-spring";

interface ContainerProps {
    type?: "success" | "info" | "error" | "warning";
}

const toastTypeVariations = {
    info: css`
        background: #00d1e6;
        color: white;
        z-index: 1;
    `,

    success: css`
        background: #32da7f;
        color: white;
        z-index: 1;
    `,

    error: css`
        // background: #fddede;
        // color: #c53030;

        background: red;
        color: white;
        z-index: 1;
    `,

    warning: css`
        background: yellow;
        color: black;
        z-index: 1;
    `,
};

export const Container = styled(animated.div)<ContainerProps>`
    width: 320px;
    position: relative;
    padding: 16px 30px 16px 16px;
    border-radius: 10px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
    display: flex;

    & + div {
        margin-top: 8px;
    }
    ${props => toastTypeVariations[props.type || "info"]}
    > svg {
        margin: 4px 12px 0 0;
    }
    div {
        flex: 1;
        p {
            margin-top: 4px;
            font-size: 14px;
            line-height: 20px;
        }
    }
    button {
        position: absolute;
        right: 16px;
        top: 20px;
        border: 0;
        background: transparent;
        color: inherit;
    }
    ${props =>
        !props &&
        css`
            align-items: center;
            svg {
                margin-top: 0;
            }
        `}
`;
