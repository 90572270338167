import styled from "styled-components";

import imgTelaLogin from "../../assets/imgTelaLogin.png";

export const Container = styled.div`
    height: 100vh;
    display: flex;
    align-items: stretch;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2%;
    width: 100%;
    max-width: 1024px;
    color: black;
    text-align: center;

    div {
        width: 100%;
        margin-bottom: 5%;
    }
`;

export const Background = styled.div`
    flex: 1;
    background: url(${imgTelaLogin}) top;
    background-size: cover;
    height: 100%;
`;
