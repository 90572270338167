/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function celular(e: React.FormEvent<HTMLInputElement>) {
    e.currentTarget.maxLength = 15;
    let { value } = e.currentTarget;
    if (!value.match(/^((\d{2})) (\d{1}) (\d{4})-(\d{4})$/)) {
        value = value.replace(/\D/g, ""); // Remove tudo o que não é dígito
        value = value.replace(/^(\d{2})(\d)/g, "($1) $2"); // Coloca parênteses em volta dos dois primeiros dígitos
        value = value.replace(/(\d)(\d{4})$/, "$1-$2"); // Coloca hífen entre o quarto e o quinto dígitos
        e.currentTarget.value = value;
    }
    return e;
}

export function cpf(e: React.FormEvent<HTMLInputElement>) {
    e.currentTarget.maxLength = 14;
    let { value } = e.currentTarget;
    if (!value.match(/^(\d{3}).(\d{3}).(\d{3})-(\d{2})$/)) {
        value = value.replace(/\D/g, "");
        value = value.replace(/(\d{3})(\d)/, "$1.$2");
        value = value.replace(/(\d{3})(\d)/, "$1.$2");
        value = value.replace(/(\d{3})(\d{2})$/, "$1-$2");
        e.currentTarget.value = value;
    }
    return e;
}

export function cnpj(e: React.FormEvent<HTMLInputElement>) {
    e.currentTarget.maxLength = 18;
    let { value } = e.currentTarget;
    // eslint-disable-next-line no-useless-escape
    if (!value.match(/^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/)) {
        value = value.replace(/\D/g, "");
        value = value.replace(/(\d{2})(\d)/, "$1.$2");
        value = value.replace(/(\d{3})(\d)/, "$1.$2");
        value = value.replace(/(\d{3})(\d)/, "$1/$2");
        value = value.replace(/(\d{3})(\d{4})/, "$1/$2");
        value = value.replace(/(\d{4})(\d{2})/, "$1-$2");
        e.currentTarget.value = value;
    }
    return e;
}
