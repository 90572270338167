import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Form } from "@unform/web";
// eslint-disable-next-line no-unused-vars
import { FormHandles } from "@unform/core";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";

import * as Yup from "yup";

import { ToastContainer, toast } from "react-toastify";
import api from "../../services/api";

import logoImg from "../../assets/Logo.png";
import Input from "../../components/Input";

import ButtonAzul from "../../components/ButtonAzul";
import { Container, Content, Background } from "./styles";
import { useAuth } from "../../hooks/auth";
import "react-toastify/dist/ReactToastify.css";
import "../../components/ProgressBar/styles.css";

interface InterfaceFormData {
    codigoEmail: string;
    idCliente: string;
}

interface Cliente {
    idCliente: string;
    controleFluxo: string;
    email: string;
}

const ConfirmaEmail: React.FC = () => {
    const { cliente, signOut } = useAuth();

    let idCliente = localStorage.getItem("@dbwetextil:idCadastroCliente");

    if (!idCliente) {
        idCliente = cliente.id;
    }

    const [clienteStorage, setClienteStorage] = useState<Cliente>(
        {} as Cliente,
    );

    useEffect(() => {
        api.get(`/clientes/${idCliente}`).then(response =>
            setClienteStorage(response.data),
        );
    }, [idCliente]);

    const formRef = useRef<FormHandles>(null);
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [EmailEnviado, setEmailEnviado] = useState<number>(0);
    const [EmailValidado, setEmailValidado] = useState<boolean>(false);

    const enviarEmail = async () => {
        setLoading(true);
        await api.post(`/validacoesEmail/${idCliente}`);
        setEmailEnviado(200);
    };

    const handleSubmit = useCallback(
        async (data: InterfaceFormData) => {
            try {
                setLoading(true);
                formRef.current?.setErrors({});

                const schema = Yup.object().shape({
                    codigoEmail: Yup.string().required().length(6),
                });

                await schema.validate(data, {
                    // Faz com que o Yup não pare no primeiro erro. Por padrão é true.
                    abortEarly: false,
                });

                const { codigoEmail } = data;

                const validarCodigo = { idCliente, codigoEmail };

                const resposta = await api.post(
                    `/validacoesEmail`,
                    validarCodigo,
                );

                setEmailValidado(resposta.data.isValidado);

                history.push("/");
                signOut();

                toast.success("Sucesso! Faça login para usar o site!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } catch (err) {
                toast.error("Código inválido! Tente novamente!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } finally {
                setLoading(false);
            }
        },
        [history, idCliente, signOut],
    );

    return (
        <Container>
            <Background />
            <Content>
                <ToastContainer />
                <MDBRow>
                    <MDBCol size="md-3" className="col-example text-center">
                        <Link to="/">
                            <img
                                src={logoImg}
                                width="150px"
                                alt="WeTextil"
                                style={{ cursor: "pointer" }}
                            />
                        </Link>
                    </MDBCol>
                    <MDBCol size="md-9" className="col-example">
                        <ol
                            className="progressBar"
                            style={{ marginLeft: 10, marginRight: 10 }}
                        >
                            <li
                                className="isActive"
                                style={{ color: "#b6b9c9" }}
                            >
                                <span>Cadastro</span>
                            </li>
                            <li
                                className="isActive"
                                style={{ color: "#b6b9c9" }}
                            >
                                <span>Plano</span>
                            </li>
                            <li
                                className="isActive"
                                style={{ color: "#b6b9c9" }}
                            >
                                <span>Pagamento</span>
                            </li>
                            <li className="isActive">
                                <span>Confirmação</span>
                            </li>
                        </ol>
                    </MDBCol>
                </MDBRow>

                <Form
                    ref={formRef}
                    onSubmit={handleSubmit}
                    style={{ marginTop: 10 }}
                >
                    {EmailEnviado === 0 && (
                        <>
                            <span>
                                Um código de verificação será enviado para o
                                e-mail: {clienteStorage.email}
                            </span>

                            <ButtonAzul
                                style={{ marginBottom: 10 }}
                                onClick={enviarEmail}
                                loading={loading}
                            >
                                Enviar código
                            </ButtonAzul>
                        </>
                    )}

                    {EmailEnviado === 200 && (
                        <>
                            <span>
                                Email enviado para {clienteStorage.email}
                            </span>
                            <Input
                                name="codigoEmail"
                                placeholder="Insira o código aqui"
                                type="text"
                            />
                        </>
                    )}

                    {EmailEnviado === 200 && (
                        <strong
                            onClick={enviarEmail}
                            style={{ cursor: "pointer" }}
                        >
                            Não recebeu? Reenviar código
                        </strong>
                    )}

                    {EmailEnviado === 200 && (
                        <ButtonAzul type="submit">Confirmar código</ButtonAzul>
                    )}

                    {EmailValidado === true && (
                        <span>Email validado com sucesso!</span>
                    )}
                </Form>
                <br />
            </Content>
        </Container>
    );
};
export default ConfirmaEmail;
