import React, {
    // eslint-disable-next-line no-unused-vars
    TextareaHTMLAttributes,
    useEffect,
    useRef,
} from "react";

// eslint-disable-next-line no-unused-vars
import { IconBaseProps } from "react-icons";

import { useField } from "@unform/core";

import { Container } from "./styles";

// Faz com que todos os atributos padrões de um input sejam também do componente.
interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    name: string;
    containerStyle?: object;
    icon?: React.ComponentType<IconBaseProps>;
    hidden?: boolean;
}

const TextareaDashboard: React.FC<TextareaProps> = ({
    name,
    containerStyle = {},
    icon: Icon,
    hidden,
    ...rest
}) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const { fieldName, defaultValue, registerField } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: textareaRef.current,
            path: "value",
        });
    }, [fieldName, registerField]);

    return (
        <Container style={containerStyle}>
            {Icon && <Icon size={20} />}
            <textarea
                name={name}
                defaultValue={defaultValue}
                ref={textareaRef}
                hidden={hidden}
                style={{ height: 100 }}
                {...rest}
            />
        </Container>
    );
};

export default TextareaDashboard;
