/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
/* eslint-disable default-case */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-lonely-if */
/* eslint-disable no-unused-vars */
import React, { useCallback, useRef, useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import parse from "html-react-parser";
import { Form } from "@unform/web";
// eslint-disable-next-line no-unused-vars
import { FormHandles } from "@unform/core";
import { FiLock, FiSearch } from "react-icons/fi";
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBRow,
    MDBCol,
    MDBCardFooter,
    MDBContainer,
    MDBInputGroup,
    MDBCardSubTitle,
} from "mdb-react-ui-kit";

import Button from "@material-ui/core/Button";
import FormReact from "react-bootstrap/Form";
import api from "../../services/api";

import logoImg from "../../assets/Logo.png";
import loadingImg from "../../assets/loading.svg";

import ButtonAzul from "../../components/ButtonAzul";
import ButtonCinza from "../../components/ButtonCinza";
import ButtonAzulEscuro from "../../components/ButtonAzulEscuro";
import InputPesquisa from "../../components/InputPesquisa";
import {
    Container,
    Content,
    Background,
    PlanoPagamento,
    ConteudoPlanoPagamento,
    Planos,
    Geral,
    Valores,
    Adicionais,
    Box,
    Infos,
} from "./styles";

import "../../components/ProgressBar/styles.css";
import InputHome from "../../components/InputHome";
import InputLogin from "../../components/InputLogin";

import Popup from "../../components/Popup/index.js";
import ButtonRoxo from "../../components/ButtonRoxo";
import ButtonBranco from "../../components/ButtonBranco";

interface CupomFormData {
    cupomDesconto: string | null;
    idCliente: string;
}

interface params {
    idCliente: string;
}

interface Outros {
    id: string;
    chave: string;
    valor: string;
}

const PlanosPagamento: React.FC = () => {
    const formRef = useRef<FormHandles>(null);
    const { idCliente } = useParams<params>();
    const history = useHistory();

    const [cupom, setCupom] = useState("");
    const [outros, setOutros] = useState<Outros[]>([]);
    const [loading, setLoading] = useState(false);
    const [planoGratis, setPlanoGratis] = useState<boolean>(false);

    const [buttonPopup, setButtonPopup] = useState(false);
    const [hiddenButtonAplicar, setHiddenButtonAplicar] = useState(true);

    const changeCupom = (e: {
        target: { value: React.SetStateAction<string> };
    }) => {
        setCupom(e.target.value);
        setPlanoGratis(false);
        setHiddenButtonAplicar(false);
        if (e.target.value.length === 0) {
            setHiddenButtonAplicar(true);
        }
    };

    const [planoSelecionado, setPlanoSelecionado] = useState<string | null>("");
    const escolhePlano = (e: any) => {
        setPlanoSelecionado(e.target.value);
    };

    useEffect(() => {
        api.get("/controle").then(response => {
            setOutros(response.data);
        });
    }, []);

    let linkMensalConexao = outros.find(
        (saida: { chave: string }) => saida.chave === "linkPlanoMensalConexao",
    )?.valor;
    let linkAnualConexao = outros.find(
        (saida: { chave: string }) => saida.chave === "linkPlanoAnualConexao",
    )?.valor;
    let linkAnualMentoria = outros.find(
        (saida: { chave: string }) => saida.chave === "linkPlanoAnualMentoria",
    )?.valor;

    const redirect = async () => {
        if (cupom === "WTTESTE" || cupom === "WT365DAYSEDZ") {
            history.push("/login");
        } else {
            if (cupom === "PLANOTESTE" && planoGratis === true) {
                window.location.href = "http://pag.ae/7XBDgip11";
            }
        }

        if (
            planoSelecionado !== "Plano Conexao Anual Promocional" &&
            planoSelecionado !== "Plano Conexao Mensal"
        ) {
            toast.warn("Selecione um plano ou insira um cupom válido!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            setButtonPopup(true);
            setLoading(true);

            setTimeout(() => {
                if (planoGratis === false) {
                    const formDataPlano = {
                        idCliente,
                        plano: planoSelecionado,
                    };

                    const formData = {
                        idCliente,
                        cupomDesconto: null,
                    };

                    api.put("/clientes/plano", formDataPlano);
                    api.put("/clientes/cupom", formData);

                    switch (planoSelecionado) {
                        case "Plano Conexao Anual Promocional":
                            window.location.href = linkAnualConexao || "Error";
                            break;
                        case "Plano Conexao Mensal":
                            window.location.href = linkMensalConexao || "Error";
                            break;
                    }
                }

                /*
                // Plano Mentoria Anual
                if (planoGratis === false) {
                    window.location.href = linkAnualMentoria || "Error";
                }
                */
            }, 7500);
        }
    };

    const handleSubmit = useCallback(
        async (data: CupomFormData) => {
            try {
                formRef.current?.setErrors({});

                const { cupomDesconto } = data;
                /*
                if (
                    cupomDesconto === "WTTESTE" ||
                    cupomDesconto === "WTETERNO"
                ) {
                    const formData = {
                        cupomDesconto,
                        idCliente,
                    };

                    await api.put("/clientes/cupom", formData);
                    setPlanoGratis(true);

                    const formDataPlano = {
                        idCliente,
                        plano: null,
                    };

                    await api.put("/clientes/plano", formDataPlano);

                    toast.success(
                        "Cupom vitalício inserido com sucesso! Faça login!",
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        },
                    );
                } else if (cupomDesconto === "WT90DIASFREE") {
                    const formData = {
                        cupomDesconto,
                        idCliente,
                    };

                    await api.put("/clientes/cupom", formData);
                    setPlanoGratis(true);

                    const formDataPlano = {
                        idCliente,
                        plano: null,
                    };

                    await api.put("/clientes/plano", formDataPlano);

                    toast.success(
                        "Cupom 3 meses grátis inserido com sucesso! Faça login!",
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        },
                    );
                } else if (cupomDesconto === "WT30DIASFREE") {
                    const formData = {
                        cupomDesconto,
                        idCliente,
                    };

                    await api.put("/clientes/cupom", formData);
                    setPlanoGratis(true);

                    const formDataPlano = {
                        idCliente,
                        plano: null,
                    };

                    await api.put("/clientes/plano", formDataPlano);

                    toast.success(
                        "Cupom 1 mês grátis inserido com sucesso! Faça login!",
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        },
                    );
                } else if (cupomDesconto === "WT180DIASFREE") {
                    const formData = {
                        cupomDesconto,
                        idCliente,
                    };

                    await api.put("/clientes/cupom", formData);
                    setPlanoGratis(true);

                    const formDataPlano = {
                        idCliente,
                        plano: null,
                    };

                    await api.put("/clientes/plano", formDataPlano);

                    toast.success(
                        "Cupom 6 meses grátis inserido com sucesso! Faça login!",
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        },
                    );
                }
                */

                if (cupomDesconto === "WTTESTE") {
                    const formData = {
                        cupomDesconto,
                        idCliente,
                    };

                    await api.put("/clientes/cupom", formData);
                    setPlanoGratis(true);

                    const formDataPlano = {
                        idCliente,
                        plano: null,
                    };

                    await api.put("/clientes/plano", formDataPlano);

                    toast.success(
                        "Cupom vitalício inserido com sucesso! Faça login!",
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        },
                    );

                    setHiddenButtonAplicar(true);
                } else if (cupomDesconto === "WT365DAYSEDZ") {
                    const formData = {
                        cupomDesconto,
                        idCliente,
                    };

                    await api.put("/clientes/cupom", formData);
                    setPlanoGratis(true);

                    const formDataPlano = {
                        idCliente,
                        plano: null,
                    };

                    await api.put("/clientes/plano", formDataPlano);

                    toast.success(
                        "Cupom 12 meses de acesso com Eduzz inserido com sucesso! Faça login!",
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        },
                    );

                    setHiddenButtonAplicar(true);
                } else if (cupomDesconto === "PLANOTESTE") {
                    const formData = {
                        cupomDesconto,
                        idCliente,
                    };

                    await api.put("/clientes/cupom", formData);
                    setPlanoGratis(true);

                    const formDataPlano = {
                        idCliente,
                        plano: null,
                    };

                    await api.put("/clientes/plano", formDataPlano);

                    toast.success("Cupom Plano Teste inserido com sucesso!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    setHiddenButtonAplicar(true);

                    toast.success("Avance para efetuar o pagamento!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    setPlanoGratis(false);

                    toast.error("Cupom não encontrado!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } catch (err) {
                toast.error("Erro no cadastro! Tente novamente!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        },
        [idCliente],
    );

    return (
        <Container>
            <Background />
            <Content>
                <ToastContainer />
                <Geral>
                    <MDBRow center>
                        <MDBCol md="6" style={{ marginTop: 10 }}>
                            <Box>
                                <h6>
                                    <strong>PLANO CONEXÃO</strong>
                                </h6>
                                <Valores style={{ color: "#00d1e6" }}>
                                    <Infos style={{ color: "#00d1e6" }}>
                                        <strong>
                                            {parse(
                                                outros.find(
                                                    (saida: {
                                                        chave: string;
                                                    }) =>
                                                        saida.chave ===
                                                        "textoPlanoConexao",
                                                )?.valor || "Loading...",
                                            )}
                                        </strong>
                                    </Infos>
                                    <MDBRow center>
                                        <MDBCol size="1">
                                            <FormReact.Check
                                                inline
                                                name="plano"
                                                type="radio"
                                                id="planoConexaoMensal"
                                                value="Plano Conexao Mensal"
                                                onClick={escolhePlano}
                                            />
                                        </MDBCol>
                                        <MDBCol size="5">
                                            <h6
                                                style={{
                                                    textDecoration:
                                                        "line-through",
                                                }}
                                            >
                                                de: R$ 29,90
                                            </h6>
                                        </MDBCol>
                                    </MDBRow>

                                    <h5
                                        style={{
                                            marginTop: -5,
                                            marginBottom: -8,
                                        }}
                                    >
                                        <strong>
                                            R${" "}
                                            <strong
                                                style={{
                                                    fontSize: 35,
                                                }}
                                            >
                                                {
                                                    outros.find(
                                                        saida =>
                                                            saida.chave ===
                                                            "valorPlanoMensalConexao",
                                                    )?.valor
                                                }
                                            </strong>
                                            /mês
                                        </strong>
                                    </h5>
                                    <small>por 3 meses</small>
                                    <small>
                                        <br />
                                        R$ 29,90/mês depois de 3 meses
                                    </small>
                                </Valores>
                            </Box>
                        </MDBCol>
                        <MDBCol md="6" style={{ marginTop: 10 }}>
                            <Box>
                                <h6>
                                    <strong>PLANO MENTORIA</strong>
                                </h6>
                                <Valores
                                    style={{
                                        backgroundColor: "#846cf9",
                                        color: "white",
                                    }}
                                >
                                    <Infos
                                        style={{
                                            color: "white",
                                        }}
                                    >
                                        <strong>
                                            {parse(
                                                outros.find(
                                                    (saida: {
                                                        chave: string;
                                                    }) =>
                                                        saida.chave ===
                                                        "textoPlanoMentoria",
                                                )?.valor || "Loading...",
                                            )}
                                        </strong>
                                    </Infos>
                                    <h5
                                        style={{
                                            marginTop: -10,
                                            marginBottom: -5,
                                        }}
                                    >
                                        <strong>
                                            R${" "}
                                            <strong
                                                style={{
                                                    fontSize: 35,
                                                }}
                                            >
                                                {
                                                    outros.find(
                                                        saida =>
                                                            saida.chave ===
                                                            "valorPlanoAnualMentoriaAtual",
                                                    )?.valor
                                                }
                                            </strong>
                                            /ano
                                        </strong>
                                    </h5>
                                    <small>
                                        Equivale a{" "}
                                        <strong>
                                            R$
                                            {
                                                outros.find(
                                                    saida =>
                                                        saida.chave ===
                                                        "valorPlanoAnualMentoriaAtualEquivalencia",
                                                )?.valor
                                            }
                                            /mês
                                        </strong>
                                    </small>
                                </Valores>
                            </Box>
                        </MDBCol>
                    </MDBRow>

                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <MDBRow
                            style={{
                                marginTop: "1%",
                                background: "rgba(255, 255, 255, 0.8)",
                                borderRadius: 5,
                                width: "100%",
                                marginLeft: 0,
                                boxShadow: "0 0 2px grey",
                            }}
                        >
                            <MDBCol size="9" className="col-example">
                                <InputHome
                                    placeholder="Tem um cupom? Insira aqui"
                                    name="cupomDesconto"
                                    onChange={changeCupom}
                                    type="text"
                                />
                            </MDBCol>
                            <MDBCol
                                hidden={hiddenButtonAplicar}
                                size="3"
                                className="col-example text-center"
                                style={{
                                    background: "#06d1e7",
                                    border: "none",
                                    borderWidth: 0,
                                    color: "#064657",
                                    height: 44,
                                    width: "100%",
                                    fontWeight: 500,
                                    cursor: "pointer",
                                    borderTopRightRadius: 5,
                                    borderBottomRightRadius: 5,
                                }}
                            >
                                <button
                                    type="submit"
                                    style={{
                                        background: "transparent",
                                        border: "none",
                                        width: "100%",
                                    }}
                                >
                                    <h6
                                        style={{
                                            color: "#2d2d2d",
                                            marginTop: 12.5,
                                        }}
                                    >
                                        <strong> aplicar </strong>
                                    </h6>
                                </button>
                            </MDBCol>
                        </MDBRow>
                    </Form>

                    <MDBContainer>
                        <MDBRow
                            end
                            style={{
                                width: "105%",
                                marginBottom: "2%",
                            }}
                        >
                            <MDBCol lg="3" className="col-example">
                                <ButtonAzulEscuro
                                    onClick={redirect}
                                    style={{
                                        height: "90%",
                                        borderRadius: 5,
                                    }}
                                >
                                    {loading === false && (
                                        <strong>Avançar</strong>
                                    )}

                                    {loading === true && (
                                        <strong>Carregando...</strong>
                                    )}
                                </ButtonAzulEscuro>
                                <Popup
                                    trigger={buttonPopup}
                                    setTrigger={setButtonPopup}
                                >
                                    <h4>ATENÇÃO</h4>
                                    <p>
                                        Quando finalizar a compra no PagSeguro,
                                        aguarde 60 segundos ou clique em
                                        &ldquo;Voltar para a loja&ldquo; para
                                        confirmarmos seu pagamento!
                                    </p>
                                    <img
                                        width="50px"
                                        src={loadingImg}
                                        alt="WeTextil"
                                        style={{
                                            display: "block",
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                        }}
                                    />
                                </Popup>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </Geral>
            </Content>
        </Container>
    );
};
export default PlanosPagamento;
