import styled from "styled-components";

import { shade } from "polished";

export const Container = styled.div`
    > header {
        height: 144px;
        background: linear-gradient(90deg, #235dff 0%, #42a5f5 100%);
        display: flex;
        align-items: center;

        div {
            width: 100%;
            max-width: 1120px;
            margin: 0 auto;

            svg {
                color: #ffffff;
                width: 24px;
                height: 24px;
            }
        }
    }
`;

export const ButtonMenu = styled.div`
    button {
        background: #846cf9;
        border-radius: 5px;
        color: #ffffff;
        border: 0;
        height: 38px;
        width: 100%;
        padding: 0 10px;
        font-weight: 500;

        &:hover {
            background: ${shade(0.2, "#846cf9")};
        }
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    form {
        width: 340px;
        margin-bottom: 50px;

        text-align: center;

        display: flex;
        flex-direction: column;

        h1 {
            margin-bottom: 24px;
            font-size: 20px;
            text-align: left;
            color: #00d1e6;
        }

        h2 {
            margin-bottom: 24px;
            font-size: 20px;
            text-align: left;
            color: #00d1e6;
        }

        h4 {
            margin-top: 10px;
            font-size: 15px;
            text-align: left;
            color: #00d1e6;
            margin-bottom: -10px;
        }

        div {
            color: #000000;
        }

        a {
            display: block;

            margin-top: 24px;

            color: #00d1e6;
            text-decoration: none;

            transition: color 0.2s;

            &:hover {
                color: ${shade(0.2, "#00d1e6")};
            }
        }
    }
`;

export const AvatarInput = styled.div`
    margin-bottom: 32px;

    align-self: center;
    position: relative;

    img {
        width: 186px;
        height: 186px;

        border-radius: 50%;
    }

    label {
        position: absolute;

        width: 48px;
        height: 48px;

        background: #00d1e6;

        border-radius: 50%;

        right: 0;
        bottom: 0;

        border: 0;

        cursor: pointer;

        transition: background-color 0.2s;

        display: flex;
        align-items: center;
        justify-content: center;

        input {
            display: none;
        }

        svg {
            width: 20px;
            height: 20px;
            color: #312e38;
        }

        &:hover {
            background: ${shade(0.2, "#00d1e6")};
        }
    }
`;
