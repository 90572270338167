import styled, { keyframes } from "styled-components";

import { shade } from "polished";
import cadastroClienteImg from "../../assets/cadastroProduto.png";

export const Container = styled.div`
    height: 100vh;
    display: flex;
    align-items: stretch;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 50px;
    max-width: 800px;
`;

export const Background = styled.div`
    //Ocupa todo o espaço menos os 700px
    flex: 1;
    background: url(${cadastroClienteImg}) top;
    background-size: cover;
    height: 100%;
`;

const appearFromLeft = keyframes`
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        transform: translateX(0px);
    }
`;

export const AnimationContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: ${appearFromLeft} 2s;

    form {
        margin: 80px 0;
        width: 318px;
        text-align: center;

        h3 {
            margin-bottom: 24px;
            color: #000000;
        }

        a {
            display: block;
            margin-top: 24px;
            color: #f4ede8;
            text-decoration: none;
            transition: color 0.2s;
            &:hover {
                color: ${shade(0.2, "#f4ede8")};
            }
        }

        div {
            color: #000000;
        }

        input {
            color: #000000;
        }

        h6 {
            font-size: small;
            display: block;
            margin-top: 15px;
            text-align: left;
            margin-right: 5px;
            color: #000000;
            text-decoration: none;
            transition: color 0.2s;
            &:hover {
                color: ${shade(0.2, "#000000")};
            }
        }
    }

    > a {
        display: flex;
        align-items: center;
        margin-top: -30px;
        color: #00d1e6;
        text-decoration: none;
        transition: color 0.2s;

        svg {
            margin-right: 16px;
        }

        &:hover {
            color: ${shade(0.2, "#00d1e6")};
        }
    }
`;

export const ImagemInput = styled.div`
    align-self: center;
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    label {
        margin-left: 10px;
        width: 240px;
        height: 50px;

        background: #00d1e6;

        border-radius: 20px;

        cursor: pointer;

        transition: background-color 0.2s;

        display: flex;
        align-items: center;
        justify-content: center;

        input {
            //display: none;
        }

        svg {
            width: 20px;
            height: 20px;
            color: #312e38;
        }

        &:hover {
            background: ${shade(0.2, "#00d1e6")};
        }
    }
`;
