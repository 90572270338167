import React, {
    // eslint-disable-next-line no-unused-vars
    TextareaHTMLAttributes,
    useEffect,
    useRef,
    useState,
    useCallback,
} from "react";

// eslint-disable-next-line no-unused-vars
import { IconBaseProps } from "react-icons";
import { FiAlertCircle } from "react-icons/fi";

import { useField } from "@unform/core";

import { Container, Error } from "./styles";

// Faz com que todos os atributos padrões de um input sejam também do componente.
interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    name: string;
    containerStyle?: object;
    icon?: React.ComponentType<IconBaseProps>;
    hidden?: boolean;
}

const Textarea: React.FC<TextareaProps> = ({
    name,
    containerStyle = {},
    icon: Icon,
    hidden,
    ...rest
}) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const [isFocused, setIsFocused] = useState(false);

    const [isFilled, setIsFilled] = useState(false);

    const { fieldName, defaultValue, error, registerField } = useField(name);

    const handleInputFocus = useCallback(() => {
        setIsFocused(true);
    }, []);

    const handleInputBlur = useCallback(() => {
        setIsFocused(false);
        setIsFilled(!!textareaRef.current?.value);
    }, []);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: textareaRef.current,
            path: "value",
        });
    }, [fieldName, registerField]);

    return (
        <Container
            style={containerStyle}
            isErrored={!!error}
            isFilled={isFilled}
            isFocused={isFocused}
        >
            {Icon && <Icon size={20} />}
            <textarea
                name={name}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                defaultValue={defaultValue}
                ref={textareaRef}
                hidden={hidden}
                {...rest}
            />

            {error && (
                <Error title={error}>
                    <FiAlertCircle color="#c53030" size={20} />
                </Error>
            )}
        </Container>
    );
};

export default Textarea;
