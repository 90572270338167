import React from "react";

import { useTransition } from "react-spring";

import Toast from "./Toast";

import { Container } from "./styles";
// eslint-disable-next-line no-unused-vars
import { ToastMessages } from "../../hooks/toast";

interface ToastContainerProps {
    messages: ToastMessages[];
}

const ToastContainer: React.FC<ToastContainerProps> = ({ messages }) => {
    const messagesWithTransitions = useTransition(
        messages,
        message => message.id,
        {
            from: { right: "-120%", opacity: 0 },

            enter: { right: "0%", opacity: 1 },

            leave: { right: "-120%", opacity: 0 },
        },
    );

    return (
        <Container>
            {messagesWithTransitions.map(({ item, key, props }) => (
                <Toast key={key} style={props} message={item} />
            ))}
        </Container>
    );
};

export default ToastContainer;
