/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useRef, useEffect, useState } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { FiEdit3 } from "react-icons/fi";
import { Form } from "@unform/web";
// eslint-disable-next-line no-unused-vars
import { FormHandles } from "@unform/core";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";

import * as Yup from "yup";

import { ToastContainer, toast } from "react-toastify";
import api from "../../services/api";

import Input from "../../components/Input";

import ButtonAzul from "../../components/ButtonAzul";
import { Container, Content } from "./styles";
import "react-toastify/dist/ReactToastify.css";
import "../../components/ProgressBar/styles.css";
import { useAuth } from "../../hooks/auth";
import Inputmask from "../../components/Inputmask";

interface InterfaceFormData {
    codigoSMS: string;
    newCelular: string;
    idCliente: string;
}

interface Cliente {
    controleFluxo: string;
    idCliente: string;
    celular: string;
}

const ConfirmaSMS: React.FC = () => {
    const { cliente, updateCliente, signOut } = useAuth();

    let idCliente = localStorage.getItem("@dbwetextil:idCadastroCliente");

    if (!idCliente) {
        idCliente = cliente?.id;
    }

    const idAssinaturaAtualAux = window.location.search;
    const idAssinaturaAtualNew = new URLSearchParams(idAssinaturaAtualAux);
    const code = idAssinaturaAtualNew.get("code");
    const formData = {
        idCliente,
        code,
    };

    const configTipo = {
        headers: {
            "Content-Type": "application/json",
            Accept: "application/vnd.pagseguro.com.br.v3+json;charset=ISO-8859-1",
        },
    };

    api.post("/assinaturas", formData, configTipo);

    /*
    const updateClienteAtual = {
        nome: cliente?.nome,
        email: cliente?.email,
        avatar_url: cliente?.avatar_url,
        biografia: cliente?.biografia,
        linkInstagram: cliente?.linkInstagram,
        linkFacebook: cliente?.linkFacebook,
        linkLinkedin: cliente?.linkLinkedin,
        linkSite: cliente?.linkSite,
        cupomDesconto: cliente?.cupomDesconto,
        celularValidado: cliente?.celularValidado,
        celular: cliente?.celular,
        categorias: cliente?.categorias,
        cidade: cliente?.cidade,
        estado: cliente?.estado,
        controleFluxo: "FALTA_CONFIRMAR_CELULAR",
    };

    api.put("/clientes/me", updateClienteAtual).then(volta =>
        updateCliente(volta.data),
    );
    */

    const formRef = useRef<FormHandles>(null);
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [clienteStorage, setClienteStorage] = useState<Cliente>(
        {} as Cliente,
    );
    const [SMSEnviado, setSMSEnviado] = useState<number>(0);
    const [SMSValidado, setSMSValidado] = useState<boolean>(false);

    useEffect(() => {
        api.get(`/clientes/${idCliente}`).then(response =>
            setClienteStorage(response.data),
        );
    }, [idCliente]);

    const [novoCelular, setNovoCelular] = useState<string>(
        cliente?.celular || "",
    );

    const [editarCelular, setEditarCelular] = useState<boolean>(false);

    const editCelular = (e: any) => {
        setShow(true);
        setEditarCelular(true);
        setNovoCelular(e.target.value);

        if (e.target.value) {
            const celularSemMask = e.target.value
                .replace(/\)/g, "")
                .replace(/\(/g, "")
                .replace(/-/g, "")
                .replace(/ /g, "");

            if (celularSemMask.length === 11) {
                setShow(false);
            }
        }
    };

    const enviarSMS = async () => {
        toast.info("SMS enviado! Verifique seu celular!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setLoading(true);

        let data;

        if (editarCelular === true) {
            const celularSemMask = novoCelular
                .replace(/\)/g, "")
                .replace(/\(/g, "")
                .replace(/-/g, "")
                .replace(/ /g, "");

            data = { celular: celularSemMask };

            const update = {
                idCliente,
                celular: celularSemMask,
            };

            const response = await api.put("/clientes/celular", update);

            updateCliente(response.data);
        } else {
            const clienteAtual = await api.get(`/clientes/${idCliente}`);
            data = { celular: clienteAtual.data.celular };
        }

        api.post(`/validacoesCelular/${idCliente}`, data);
        setSMSEnviado(200);
    };

    const handleSubmit = useCallback(
        async (data: InterfaceFormData) => {
            try {
                formRef.current?.setErrors({});

                const schema = Yup.object().shape({
                    codigoSMS: Yup.string().required().length(6),
                });

                await schema.validate(data, {
                    // Faz com que o Yup não pare no primeiro erro. Por padrão é true.
                    abortEarly: false,
                });

                const { codigoSMS } = data;

                const validarCodigo = { idCliente, codigoSMS };

                const resposta = await api.post(
                    `/validacoesCelular`,
                    validarCodigo,
                );

                setSMSValidado(resposta.data.isValidado);

                if (cliente) {
                    const update = {
                        idCliente,
                        celularValidado: true,
                    };

                    const response = await api.put("/clientes/celular", update);

                    updateCliente(response.data);
                }

                toast.success("Celular validado com sucesso!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                history.push("/minhaConta");
            } catch (err) {
                toast.error("Código inválido! Tente novamente!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        },
        [idCliente, cliente, history, updateCliente],
    );

    if (cliente && cliente?.celularValidado) {
        signOut();
        return <Redirect to="/" />;
    }

    return (
        <Container>
            <Content>
                <ToastContainer />
                <MDBRow style={{ width: "80%", verticalAlign: "middle" }}>
                    <MDBCol size="md-12" className="col-example">
                        <ol
                            className="progressBar"
                            style={{ marginLeft: 10, marginRight: 10 }}
                        >
                            <li
                                className="isActive"
                                style={{ color: "#b6b9c9" }}
                            >
                                <span>Cadastro</span>
                            </li>
                            <li
                                className="isActive"
                                style={{ color: "#b6b9c9" }}
                            >
                                <span>Plano</span>
                            </li>
                            <li
                                className="isActive"
                                style={{ color: "#b6b9c9" }}
                            >
                                <span>Pagamento</span>
                            </li>
                            <li className="isActive">
                                <span>Confirmação</span>
                            </li>
                        </ol>
                    </MDBCol>
                </MDBRow>

                <Form
                    ref={formRef}
                    onSubmit={handleSubmit}
                    style={{ marginTop: "10%" }}
                >
                    {SMSEnviado === 0 && (
                        <>
                            {!editarCelular && (
                                <span>
                                    Um código de verificação será enviado para:{" "}
                                    {clienteStorage.celular}{" "}
                                    <FiEdit3
                                        onClick={editCelular}
                                        style={{ cursor: "pointer" }}
                                    />
                                </span>
                            )}

                            {editarCelular && (
                                <>
                                    <span>
                                        Informe seu número para que possamos
                                        enviar o código de verificação:{" "}
                                    </span>
                                    <Inputmask
                                        name="newCelular"
                                        mask="celular"
                                        placeholder="Celular - (xx) 9xxxx-xxxx"
                                        type="text"
                                        onChange={editCelular}
                                        style={{ textAlign: "center" }}
                                    />
                                </>
                            )}

                            <ButtonAzul
                                style={{ marginBottom: 10 }}
                                onClick={enviarSMS}
                                loading={loading}
                                hidden={show}
                            >
                                Enviar código
                            </ButtonAzul>
                        </>
                    )}

                    {SMSEnviado === 200 && (
                        <div style={{ marginBottom: 20 }}>
                            {novoCelular && editarCelular === true && (
                                <span>SMS enviado para {novoCelular}</span>
                            )}

                            {editarCelular === false && (
                                <span>
                                    SMS enviado para {clienteStorage.celular}
                                </span>
                            )}
                            <Input
                                name="codigoSMS"
                                placeholder="Insira o código aqui"
                                type="text"
                                style={{
                                    textAlign: "center",
                                }}
                            />
                        </div>
                    )}

                    {SMSEnviado === 200 && (
                        <ButtonAzul type="submit">Confirmar código</ButtonAzul>
                    )}

                    {SMSEnviado === 200 && !SMSValidado && (
                        <div style={{ marginTop: 20 }}>
                            <small
                                onClick={enviarSMS}
                                style={{ cursor: "pointer" }}
                            >
                                {novoCelular && editarCelular === true && (
                                    <>
                                        Não recebeu? Clique aqui para reenviar o
                                        código para {novoCelular}
                                    </>
                                )}

                                {editarCelular === false && (
                                    <>
                                        Não recebeu? Reenviar código para{" "}
                                        {clienteStorage.celular}
                                    </>
                                )}
                            </small>
                        </div>
                    )}
                </Form>
                <br />
            </Content>
        </Container>
    );
};
export default ConfirmaSMS;
