/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback, useRef } from "react";

import { Link, useHistory, Redirect } from "react-router-dom";
import FormReact from "react-bootstrap/Form";
import { Form } from "@unform/web";
import moment from "moment";
import { Nav, Navbar, NavDropdown, Row, Col } from "react-bootstrap";

import "react-toastify/dist/ReactToastify.css";
import {
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBCol,
    MDBRow,
} from "mdb-react-ui-kit";

import * as Yup from "yup";
import { FormHandles } from "@unform/core";

import {
    FiLock,
    FiArrowDown,
    FiArrowRight,
    FiEdit,
    FiEye,
    FiMail,
    FiUsers,
    FiX,
} from "react-icons/fi";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";

import { CSVLink } from "react-csv";
import { useAuth } from "../../../hooks/auth";
import Input from "../../../components/Input";
import api from "../../../services/api";
import imgLogo from "../../../assets/Logo.png";

import ButtonAzulEscuro from "../../../components/ButtonAzulEscuro";

import { Container, Content } from "./styles";

import getValidationErrors from "../../../utils/getValidationErrors";
import BorderSelect from "../../../components/BorderSelect";
import InputHome from "../../../components/InputHome";
import InputLogin from "../../../components/InputLogin";
import InputPesquisa from "../../../components/InputPesquisa";
import ButtonAzul from "../../../components/ButtonAzul";
import ButtonRoxo from "../../../components/ButtonRoxo";
import Textarea from "../../../components/Textarea";
import TextareaDashboard from "../../../components/TextareaDashboard";

interface UpdateFormData {
    idControle: string;
    valor: string;
}

interface Outros {
    id: string;
    chave: string;
    valor: string;
}

interface Admin {
    id: string;
    nome: string;
    email: string;
}

interface AdicionarAdmin {
    senha: string;
    nome: string;
    email: string;
}

const Outros: React.FC = () => {
    const { cliente } = useAuth();
    const history = useHistory();
    const formRef = useRef<FormHandles>(null);

    const [outros, setOutros] = useState<Outros[]>([]);
    const [admin, setAdmin] = useState<Admin[]>([]);

    const [hiddenLinks, setHiddenLinks] = useState(true);
    const [hiddenValoresPlanos, setHiddenValoresPlanos] = useState(true);
    const [hiddenTextos, setHiddenTextos] = useState(true);
    const [hiddenMesTabela, setHiddenMesTabela] = useState(true);
    const [hiddenTelefonePIP, setHiddenTelefonePIP] = useState(true);
    const [hiddenExcluir, setHiddenExcluir] = useState(true);
    const [hiddenAdicionar, setHiddenAdicionar] = useState(true);

    const opcoes = [
        { id: "1", opcao: "Links" },
        { id: "2", opcao: "Valores de Planos" },
        { id: "3", opcao: "Mês da Tabela WeTêxtil" },
        { id: "4", opcao: "Telefone da PIP" },
    ];

    useEffect(() => {
        api.get("/controle").then(response => setOutros(response.data));
        api.get("/admin").then(response => setAdmin(response.data));
    }, []);

    const [selectedChave, setSelectedChave] = useState<string>();
    const [selectedValores, setSelectedValores] = useState<string>();
    const [selectedOpcao, setSelectedOpcao] = useState<string>("");
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChange = (e: any) => {
        setSelectedOpcao(e.id);
        setSelectedValores(e.valor);
    };

    const [selectedIDLink, setSelectedIDLink] = useState<string>("");
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChangeChaves = (e: any) => {
        setSelectedIDLink(e.id);
        setSelectedValores(e.valor);
    };

    const [adminSelecionado, setAdminSelecionado] = useState<string>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleAdminSelecionado = (e: any) => {
        setAdminSelecionado(e.target.value);
        if (e.target.value) {
            setHiddenExcluir(false);
        }
    };

    const excluirAdmin = () => {
        api.delete(`/admin/${adminSelecionado}`)
            .then(() => {
                toast.success("Administrador excluído com sucesso!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
                api.get("/controle").then(response => setOutros(response.data));
                api.get("/admin").then(response => setAdmin(response.data));
            })
            .catch(() => {
                toast.error("Erro! Tente novamente!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            });
    };

    const handleSubmitAdicionar = useCallback(async (data: AdicionarAdmin) => {
        try {
            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
                nome: Yup.string().required("Campo obrigatório"),
                email: Yup.string()
                    .required("Campo obrigatório")
                    .email("Digite um e-mail válido"),
                senha: Yup.string().min(8, "Mínimo de 8 dígitos"),
            });

            const { nome, email, senha } = data;

            const formData = {
                nome,
                email,
                senha,
            };

            await api
                .post(`/admin`, formData)
                .then(() => {
                    toast.success("Atualizado com sucesso!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                    });
                    api.get("/admin").then(response => setAdmin(response.data));
                    setHiddenTelefonePIP(true);
                    setHiddenValoresPlanos(true);
                    setHiddenMesTabela(true);
                    setHiddenLinks(true);
                    setHiddenAdicionar(true);
                    setHiddenTextos(true);
                })
                .catch(() => {
                    toast.error("Erro! Tente novamente!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                    });
                    setHiddenTelefonePIP(true);
                    setHiddenValoresPlanos(true);
                    setHiddenMesTabela(true);
                    setHiddenLinks(true);
                    setHiddenAdicionar(true);
                    setHiddenTextos(true);
                });
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const errors = getValidationErrors(err);

                formRef.current?.setErrors(errors);
                return;
            }

            toast.error("Erro no servidor! Tente novamente!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            setHiddenTelefonePIP(true);
            setHiddenValoresPlanos(true);
            setHiddenMesTabela(true);
            setHiddenLinks(true);
            setHiddenAdicionar(true);
            setHiddenTextos(true);
        }
    }, []);

    const handleSubmit = useCallback(async (data: UpdateFormData) => {
        try {
            formRef.current?.setErrors({});

            const { idControle, valor } = data;

            const formData = {
                idControle,
                valor,
            };

            await api
                .put(`/controle/${idControle}`, formData)
                .then(() => {
                    toast.success("Atualizado com sucesso!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                    });
                    api.get("/controle").then(response =>
                        setOutros(response.data),
                    );
                    setHiddenTelefonePIP(true);
                    setHiddenValoresPlanos(true);
                    setHiddenMesTabela(true);
                    setHiddenLinks(true);
                    setHiddenAdicionar(true);
                    setHiddenTextos(true);
                })
                .catch(() => {
                    toast.error("Erro! Tente novamente!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                    });
                    api.get("/controle").then(response =>
                        setOutros(response.data),
                    );
                    setHiddenTelefonePIP(true);
                    setHiddenValoresPlanos(true);
                    setHiddenMesTabela(true);
                    setHiddenLinks(true);
                    setHiddenAdicionar(true);
                    setHiddenTextos(true);
                });
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const errors = getValidationErrors(err);

                formRef.current?.setErrors(errors);
                return;
            }

            toast.error("Erro no servidor! Tente novamente!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            setHiddenTelefonePIP(true);
            setHiddenValoresPlanos(true);
            setHiddenMesTabela(true);
            setHiddenLinks(true);
            setHiddenAdicionar(true);
            setHiddenTextos(true);
        }
    }, []);

    // Redirecionamentos de páginas privadas
    if (cliente.controleFluxo !== "IS_ADMIN") {
        return <Redirect to="/" />;
    }

    return (
        <Container>
            <Navbar
                collapseOnSelect
                expand="lg"
                style={{
                    background: "#ffffff",
                }}
            >
                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    style={{ border: "none" }}
                />
                <Navbar.Brand style={{ marginLeft: "2%" }}>
                    <Link to="/">
                        <img width="125px" src={imgLogo} alt="WeTextil" />
                    </Link>
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto text-center" />
                    <Nav>
                        <Nav.Link
                            href="/dashboardMapeamentoMercado"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Mapeie Mercado
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardFabricacaoMalha"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Fabrique Malha
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardPesquisa"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Pesquisas
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardTabelaWeTextil"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Tabela WeTêxtil
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardClientes"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Clientes
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardCategorias"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Categorias
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardAssinaturas"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Assinaturas
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardProdutos"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Produtos/Serviços
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardOutros"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#846cf9",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                <strong>Outros</strong>
                            </span>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Content>
                <ToastContainer />

                <MDBRow style={{ marginBottom: 20, marginTop: -20 }} end>
                    <MDBCol md="2" hidden={hiddenExcluir}>
                        <ButtonRoxo
                            style={{ height: 50 }}
                            onClick={excluirAdmin}
                        >
                            Excluir admin
                        </ButtonRoxo>
                    </MDBCol>
                    <MDBCol md="2">
                        <ButtonAzulEscuro
                            style={{ height: 50 }}
                            onClick={() => {
                                setHiddenAdicionar(false);
                                setHiddenLinks(true);
                                setHiddenTelefonePIP(true);
                                setHiddenValoresPlanos(true);
                                setHiddenMesTabela(true);
                                setHiddenTextos(true);
                            }}
                        >
                            Adicionar admin
                        </ButtonAzulEscuro>
                    </MDBCol>
                    <MDBCol md="2">
                        <ButtonAzulEscuro
                            style={{ height: 50 }}
                            onClick={() => {
                                setHiddenValoresPlanos(false);
                                setHiddenTelefonePIP(true);
                                setHiddenMesTabela(true);
                                setHiddenLinks(true);
                                setHiddenAdicionar(true);
                                setHiddenTextos(true);
                            }}
                        >
                            Valores Planos
                        </ButtonAzulEscuro>
                    </MDBCol>
                    <MDBCol md="1">
                        <ButtonAzulEscuro
                            style={{ height: 50 }}
                            onClick={() => {
                                setHiddenTextos(false);
                                setHiddenValoresPlanos(true);
                                setHiddenTelefonePIP(true);
                                setHiddenMesTabela(true);
                                setHiddenLinks(true);
                                setHiddenAdicionar(true);
                            }}
                        >
                            Textos
                        </ButtonAzulEscuro>
                    </MDBCol>
                    <MDBCol md="1">
                        <ButtonAzulEscuro
                            style={{ height: 50 }}
                            onClick={() => {
                                setHiddenLinks(false);
                                setHiddenTelefonePIP(true);
                                setHiddenValoresPlanos(true);
                                setHiddenMesTabela(true);
                                setHiddenAdicionar(true);
                                setHiddenTextos(true);
                            }}
                        >
                            Links
                        </ButtonAzulEscuro>
                    </MDBCol>
                    <MDBCol md="1">
                        <ButtonAzulEscuro
                            style={{ height: 50 }}
                            onClick={() => {
                                setHiddenMesTabela(false);
                                setHiddenTelefonePIP(true);
                                setHiddenValoresPlanos(true);
                                setHiddenLinks(true);
                                setHiddenAdicionar(true);
                                setHiddenTextos(true);
                            }}
                        >
                            Tabela
                        </ButtonAzulEscuro>
                    </MDBCol>
                    <MDBCol md="1">
                        <ButtonAzulEscuro
                            style={{ height: 50 }}
                            onClick={() => {
                                setHiddenTelefonePIP(false);
                                setHiddenValoresPlanos(true);
                                setHiddenMesTabela(true);
                                setHiddenLinks(true);
                                setHiddenAdicionar(true);
                                setHiddenTextos(true);
                            }}
                        >
                            PIP
                        </ButtonAzulEscuro>
                    </MDBCol>
                </MDBRow>

                <Form
                    ref={formRef}
                    onSubmit={handleSubmit}
                    hidden={hiddenLinks}
                >
                    <MDBRow style={{ marginBottom: 20 }}>
                        <MDBCol md="3" style={{ marginTop: 10 }}>
                            <BorderSelect
                                name="idControle"
                                value={selectedIDLink}
                                hidden
                            >
                                <Select
                                    className="dropdown"
                                    placeholder="Selecionar"
                                    onChange={handleChangeChaves}
                                    options={outros.filter(saida =>
                                        saida.chave.includes("link"),
                                    )}
                                    getOptionLabel={option => option.chave}
                                    getOptionValue={option => option.id}
                                    isSearchable={false}
                                    maxMenuHeight={200}
                                    theme={theme => ({
                                        ...theme,
                                        borderRadius: 10,
                                    })}
                                />
                            </BorderSelect>
                        </MDBCol>
                        <MDBCol md="5" style={{ marginTop: 17 }}>
                            <InputPesquisa
                                icon={FiEdit}
                                placeholder={selectedValores}
                                defaultValue={selectedValores}
                                name="valor"
                                type="text"
                            />
                        </MDBCol>

                        <MDBCol md="3" style={{ marginTop: 15 }}>
                            <ButtonRoxo
                                type="submit"
                                style={{ height: 40, marginTop: 0 }}
                            >
                                Confirmar
                            </ButtonRoxo>
                        </MDBCol>
                        <MDBCol md="1" style={{ marginTop: 15 }}>
                            <ButtonAzulEscuro
                                style={{
                                    height: 40,
                                    marginTop: 0,
                                    background: "white",
                                    color: "black",
                                }}
                                onClick={() => {
                                    setHiddenLinks(true);
                                }}
                            >
                                <strong>
                                    <FiX />
                                </strong>
                            </ButtonAzulEscuro>
                        </MDBCol>
                    </MDBRow>
                </Form>

                <Form
                    ref={formRef}
                    onSubmit={handleSubmitAdicionar}
                    hidden={hiddenAdicionar}
                >
                    <MDBRow style={{ marginBottom: 20 }}>
                        <MDBCol md="3" style={{ marginTop: 17 }}>
                            <InputPesquisa
                                icon={FiUsers}
                                placeholder="Nome"
                                name="nome"
                                type="text"
                            />
                        </MDBCol>
                        <MDBCol md="3" style={{ marginTop: 17 }}>
                            <InputPesquisa
                                icon={FiMail}
                                placeholder="Email"
                                name="email"
                                type="text"
                            />
                        </MDBCol>
                        <MDBCol md="3" style={{ marginTop: 17 }}>
                            <InputPesquisa
                                icon={FiLock}
                                placeholder="Senha"
                                name="senha"
                                type="text"
                            />
                        </MDBCol>
                        <MDBCol md="2" style={{ marginTop: 15 }}>
                            <ButtonRoxo
                                type="submit"
                                style={{ height: 40, marginTop: 0 }}
                            >
                                Confirmar
                            </ButtonRoxo>
                        </MDBCol>
                        <MDBCol md="1" style={{ marginTop: 15 }}>
                            <ButtonAzulEscuro
                                style={{
                                    height: 40,
                                    marginTop: 0,
                                    background: "white",
                                    color: "black",
                                }}
                                onClick={() => {
                                    setHiddenAdicionar(true);
                                }}
                            >
                                <strong>
                                    <FiX />
                                </strong>
                            </ButtonAzulEscuro>
                        </MDBCol>
                    </MDBRow>
                </Form>

                <Form
                    ref={formRef}
                    onSubmit={handleSubmit}
                    hidden={hiddenValoresPlanos}
                >
                    <MDBRow style={{ marginBottom: 20 }}>
                        <MDBCol md="4" style={{ marginTop: 10 }}>
                            <BorderSelect
                                name="idControle"
                                value={selectedIDLink}
                                hidden
                            >
                                <Select
                                    className="dropdown"
                                    placeholder="Selecionar"
                                    onChange={handleChangeChaves}
                                    options={outros.filter(saida =>
                                        saida.chave.includes("valor"),
                                    )}
                                    getOptionLabel={option => option.chave}
                                    getOptionValue={option => option.id}
                                    isSearchable={false}
                                    maxMenuHeight={200}
                                    theme={theme => ({
                                        ...theme,
                                        borderRadius: 10,
                                    })}
                                />
                            </BorderSelect>
                        </MDBCol>
                        <MDBCol md="5" style={{ marginTop: 17 }}>
                            <InputPesquisa
                                icon={FiEdit}
                                placeholder={selectedValores}
                                defaultValue={selectedValores}
                                name="valor"
                                type="text"
                            />
                        </MDBCol>

                        <MDBCol md="2" style={{ marginTop: 15 }}>
                            <ButtonRoxo
                                type="submit"
                                style={{ height: 40, marginTop: 0 }}
                            >
                                Confirmar
                            </ButtonRoxo>
                        </MDBCol>
                        <MDBCol md="1" style={{ marginTop: 15 }}>
                            <ButtonAzulEscuro
                                style={{
                                    height: 40,
                                    marginTop: 0,
                                    background: "white",
                                    color: "black",
                                }}
                                onClick={() => {
                                    setHiddenValoresPlanos(true);
                                }}
                            >
                                <strong>
                                    <FiX />
                                </strong>
                            </ButtonAzulEscuro>
                        </MDBCol>
                    </MDBRow>
                </Form>

                <Form
                    ref={formRef}
                    onSubmit={handleSubmit}
                    hidden={hiddenTextos}
                >
                    <MDBRow style={{ marginBottom: 20 }}>
                        <MDBCol md="4" style={{ marginTop: 10 }}>
                            <BorderSelect
                                name="idControle"
                                value={selectedIDLink}
                                hidden
                            >
                                <Select
                                    className="dropdown"
                                    placeholder="Selecionar"
                                    onChange={handleChangeChaves}
                                    options={outros.filter(saida =>
                                        saida.chave.includes("texto"),
                                    )}
                                    getOptionLabel={option => option.chave}
                                    getOptionValue={option => option.id}
                                    isSearchable={false}
                                    maxMenuHeight={200}
                                    theme={theme => ({
                                        ...theme,
                                        borderRadius: 10,
                                    })}
                                />
                            </BorderSelect>
                        </MDBCol>
                        <MDBCol md="5" style={{ marginTop: 17 }}>
                            <TextareaDashboard
                                icon={FiEdit}
                                placeholder={selectedValores}
                                defaultValue={selectedValores}
                                style={{
                                    border: "none",
                                    resize: "none",
                                    width: "100%",
                                    height: "100%",
                                }}
                                name="valor"
                            />
                        </MDBCol>

                        <MDBCol md="2" style={{ marginTop: 15 }}>
                            <ButtonRoxo
                                type="submit"
                                style={{ height: 40, marginTop: 0 }}
                            >
                                Confirmar
                            </ButtonRoxo>
                        </MDBCol>
                        <MDBCol md="1" style={{ marginTop: 15 }}>
                            <ButtonAzulEscuro
                                style={{
                                    height: 40,
                                    marginTop: 0,
                                    background: "white",
                                    color: "black",
                                }}
                                onClick={() => {
                                    setHiddenTextos(true);
                                }}
                            >
                                <strong>
                                    <FiX />
                                </strong>
                            </ButtonAzulEscuro>
                        </MDBCol>
                    </MDBRow>
                </Form>

                <Form
                    ref={formRef}
                    onSubmit={handleSubmit}
                    hidden={hiddenMesTabela}
                >
                    <MDBRow style={{ marginBottom: 20 }}>
                        <MDBCol md="3" style={{ marginTop: 10 }}>
                            <BorderSelect
                                name="idControle"
                                value={selectedIDLink}
                                hidden
                            >
                                <Select
                                    className="dropdown"
                                    placeholder="Selecionar"
                                    onChange={handleChangeChaves}
                                    options={outros.filter(saida =>
                                        saida.chave.includes("Tabela"),
                                    )}
                                    getOptionLabel={option => option.chave}
                                    getOptionValue={option => option.id}
                                    isSearchable={false}
                                    maxMenuHeight={200}
                                    theme={theme => ({
                                        ...theme,
                                        borderRadius: 10,
                                    })}
                                />
                            </BorderSelect>
                        </MDBCol>
                        <MDBCol md="3" style={{ marginTop: 17 }}>
                            <InputPesquisa
                                icon={FiEye}
                                placeholder={selectedValores}
                                defaultValue={selectedValores}
                                name="view"
                                type="text"
                                disabled
                            />
                        </MDBCol>
                        <MDBCol md="3" style={{ marginTop: 17 }}>
                            <InputPesquisa
                                icon={FiEdit}
                                placeholder={selectedValores}
                                defaultValue={selectedValores}
                                onChange={handleChangeChaves}
                                name="valor"
                                type="text"
                            />
                        </MDBCol>

                        <MDBCol md="2" style={{ marginTop: 15 }}>
                            <ButtonRoxo
                                type="submit"
                                style={{ height: 40, marginTop: 0 }}
                            >
                                Confirmar
                            </ButtonRoxo>
                        </MDBCol>
                        <MDBCol md="1" style={{ marginTop: 15 }}>
                            <ButtonAzulEscuro
                                style={{
                                    height: 40,
                                    marginTop: 0,
                                    background: "white",
                                    color: "black",
                                }}
                                onClick={() => {
                                    setHiddenMesTabela(true);
                                }}
                            >
                                <strong>
                                    <FiX />
                                </strong>
                            </ButtonAzulEscuro>
                        </MDBCol>
                    </MDBRow>
                </Form>

                <Form
                    ref={formRef}
                    onSubmit={handleSubmit}
                    hidden={hiddenTelefonePIP}
                >
                    <MDBRow style={{ marginBottom: 20 }}>
                        <MDBCol md="3" style={{ marginTop: 10 }}>
                            <BorderSelect
                                name="idControle"
                                value={selectedIDLink}
                                hidden
                            >
                                <Select
                                    className="dropdown"
                                    placeholder="Selecionar"
                                    onChange={handleChangeChaves}
                                    options={outros.filter(saida =>
                                        saida.chave.includes("telefonePIP"),
                                    )}
                                    getOptionLabel={option => option.chave}
                                    getOptionValue={option => option.id}
                                    isSearchable={false}
                                    maxMenuHeight={200}
                                    theme={theme => ({
                                        ...theme,
                                        borderRadius: 10,
                                    })}
                                />
                            </BorderSelect>
                        </MDBCol>
                        <MDBCol md="3" style={{ marginTop: 17 }}>
                            <InputPesquisa
                                icon={FiEye}
                                placeholder={selectedValores}
                                defaultValue={selectedValores}
                                name="view"
                                type="text"
                                disabled
                            />
                        </MDBCol>
                        <MDBCol md="3" style={{ marginTop: 17 }}>
                            <InputPesquisa
                                icon={FiEdit}
                                placeholder={selectedValores}
                                defaultValue={selectedValores}
                                onChange={handleChangeChaves}
                                name="valor"
                                type="text"
                            />
                        </MDBCol>

                        <MDBCol md="2" style={{ marginTop: 15 }}>
                            <ButtonRoxo
                                type="submit"
                                style={{ height: 40, marginTop: 0 }}
                            >
                                Confirmar
                            </ButtonRoxo>
                        </MDBCol>
                        <MDBCol md="1" style={{ marginTop: 15 }}>
                            <ButtonAzulEscuro
                                style={{
                                    height: 40,
                                    marginTop: 0,
                                    background: "white",
                                    color: "black",
                                }}
                                onClick={() => {
                                    setHiddenTelefonePIP(true);
                                }}
                            >
                                <strong>
                                    <FiX />
                                </strong>
                            </ButtonAzulEscuro>
                        </MDBCol>
                    </MDBRow>
                </Form>

                <MDBTable responsive bordered striped>
                    <MDBTableHead className="text-center" dark>
                        <tr>
                            <th scope="col">Selecionar</th>
                            <th scope="col">Nome</th>
                            <th scope="col">Email</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {admin.map(atual => (
                            <tr>
                                <td>
                                    <FormReact.Check
                                        name="selecionado"
                                        type="radio"
                                        value={atual.id}
                                        onClick={handleAdminSelecionado}
                                    />
                                </td>
                                <td>{atual.nome}</td>
                                <td>{atual.email}</td>
                            </tr>
                        ))}
                    </MDBTableBody>
                </MDBTable>
            </Content>
        </Container>
    );
};

export default Outros;
