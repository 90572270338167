import React, { useState, useEffect } from "react";
import { FiInstagram, FiFacebook, FiLinkedin } from "react-icons/fi";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";

import { Link, useHistory } from "react-router-dom";

import { Nav, Navbar, NavDropdown } from "react-bootstrap";

import { useAuth } from "../../hooks/auth";
import {
    Container,
    Content,
    ButtonMenu,
    FundoCinza,
    EspacoFundoCinza,
} from "./styles";

import imgLogo from "../../assets/Logo.png";
import api from "../../services/api";
import ButtonIcon from "../../components/ButtonIcon";

import imgLogoBranca from "../../assets/LogoBranca.png";

interface Categoria {
    id: string;
    nome: string;
    idCategoriaPai: string;
}

interface Outros {
    id: string;
    chave: string;
    valor: string;
}

const TermosDeUso: React.FC = () => {
    const { cliente, signOut } = useAuth();
    const history = useHistory();

    const [categorias, setCategorias] = useState<Categoria[]>([]);
    const [outros, setOutros] = useState<Outros[]>([]);

    useEffect(() => {
        api.get("/categorias/categorias").then(response =>
            setCategorias(response.data),
        );
        api.get("/controle").then(response => setOutros(response.data));
    }, []);

    const clickCategoria = (nomeCategoria: string) => {
        if (cliente) {
            const insertPesquisa = {
                idCliente: cliente.id,
                nomeCliente: cliente.nome,
                pesquisa: nomeCategoria,
            };

            api.post("/pesquisa", insertPesquisa);
        } else {
            const insertPesquisa = {
                pesquisa: nomeCategoria,
            };

            api.post("/pesquisa", insertPesquisa);
        }
        localStorage.setItem("@dbwetextil:nomeCategoria", nomeCategoria);
        history.push("/pesquisa");
    };

    return (
        <Container>
            <Navbar
                collapseOnSelect
                expand="lg"
                style={{
                    background: "#ffffff",
                    zIndex: 1,
                }}
            >
                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    style={{ border: "none" }}
                />
                <Navbar.Brand style={{ marginLeft: "2%" }}>
                    <Link to="/">
                        <img width="125px" src={imgLogo} alt="WeTextil" />
                    </Link>
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto text-center" />
                    <Nav>
                        <Nav.Link
                            href="/"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                início
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/nossoMovimento"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                sobre
                            </span>
                        </Nav.Link>

                        <Nav.Link
                            href="/planos"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                planos
                            </span>
                        </Nav.Link>

                        <Nav.Link
                            href="#"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#CAC9C8",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                fabrique sua malha
                            </span>
                        </Nav.Link>

                        <Nav.Link
                            href="#"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#CAC9C8",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                mapeie seu mercado
                            </span>
                        </Nav.Link>

                        <NavDropdown
                            title="categorias"
                            id="basic-nav-dropdown"
                            style={{
                                padding: "0px 20px 0px 20px",
                                textAlign: "center",
                                marginTop: 8,
                                fontWeight: "bold",
                                color: "#2d2d2d",
                            }}
                        >
                            {categorias.map(categoria => (
                                <NavDropdown.Item
                                    onClick={
                                        () => clickCategoria(categoria.nome)
                                        // eslint-disable-next-line react/jsx-curly-newline
                                    }
                                >
                                    {categoria.nome}
                                </NavDropdown.Item>
                            ))}
                        </NavDropdown>
                        {!cliente && (
                            <Nav.Link href="/cliente">
                                <ButtonMenu>
                                    <button type="button">
                                        <span
                                            style={{
                                                padding: "0px 20px 0px 20px",
                                            }}
                                        >
                                            cadastre-se
                                        </span>
                                    </button>
                                </ButtonMenu>
                            </Nav.Link>
                        )}
                        {!cliente && (
                            <Nav.Link
                                href="/login"
                                style={{
                                    textAlign: "center",
                                    marginTop: 8,
                                    color: "#2d2d2d",
                                }}
                            >
                                <span style={{ padding: "0px 20px 0px 30px" }}>
                                    login
                                </span>
                            </Nav.Link>
                        )}

                        {cliente && cliente?.controleFluxo !== "IS_ADMIN" && (
                            <Nav.Link
                                href="/minhaVitrine"
                                style={{
                                    textAlign: "center",
                                    marginTop: 8,
                                    color: "#2d2d2d",
                                }}
                            >
                                <span style={{ padding: "0px 20px 0px 20px" }}>
                                    minha vitrine
                                </span>
                            </Nav.Link>
                        )}

                        {cliente && cliente?.controleFluxo !== "IS_ADMIN" && (
                            <NavDropdown
                                title="meu perfil"
                                id="basic-nav-dropdown"
                                style={{
                                    padding: "0px 30px 0px 20px",
                                    textAlign: "center",
                                    marginTop: 8,
                                    fontWeight: "bold",
                                    color: "#2d2d2d",
                                }}
                            >
                                <NavDropdown.Item href="/minhaConta">
                                    minha conta
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={signOut}>
                                    sair
                                </NavDropdown.Item>
                            </NavDropdown>
                        )}

                        {cliente && cliente?.controleFluxo === "IS_ADMIN" && (
                            <Nav.Link href="/dashboardClientes">
                                <ButtonMenu>
                                    <button type="button" disabled>
                                        <span
                                            style={{
                                                padding: "0px 20px 0px 10px",
                                            }}
                                        >
                                            Dashboard
                                        </span>
                                    </button>
                                </ButtonMenu>
                            </Nav.Link>
                        )}

                        {cliente && cliente?.controleFluxo === "IS_ADMIN" && (
                            <Nav.Link
                                onClick={signOut}
                                style={{
                                    textAlign: "center",
                                    marginTop: 10,
                                    color: "#2d2d2d",
                                }}
                            >
                                <span style={{ padding: "0px 30px 0px 20px" }}>
                                    sair
                                </span>
                            </Nav.Link>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <Content>
                <MDBCol
                    md="12"
                    className="col-example text-center"
                    style={{
                        color: "#846cf9",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        backgroundColor: "#ffffff",
                    }}
                >
                    <span style={{ fontSize: 40, fontWeight: "bold" }}>
                        Termos de Uso WeTêxtil
                    </span>

                    <span style={{ fontSize: 25, fontWeight: "bold" }}>
                        WeTêxtil Conexão de Negócios Ltda
                    </span>
                </MDBCol>

                <MDBRow
                    className="row-cols-1 row-cols-md-2 g-2"
                    style={{ marginTop: "4%", marginBottom: "1%" }}
                >
                    <MDBCol
                        md="12"
                        className="col-example text-justify"
                        style={{ color: "#000000" }}
                    >
                        <p>
                            Este documento estabelece os termos e condições
                            aplicáveis a esta plataforma digital. Leia os termos
                            cuidadosamente antes de aceitá-los e iniciar a
                            utilização do site.
                        </p>
                        <p>
                            Este documento se aplicará aos Usuários Assinantes
                            que pretendem obter um Perfil neste site para
                            divulgação de seus Produtos ou Serviços relacionados
                            à cadeia de produção Têxtil, bem como se comunicar
                            com os demais usuários ou interessados.
                        </p>
                        <p>
                            Na eventual discordância sobre os termos de uso
                            recomenda-se que o site não seja utilizado.
                        </p>
                        <p>
                            Este documento foi redigido de forma simples e
                            acessível, justamente para que você possa ler e
                            entender a forma como utilizamos os seus dados para
                            lhe oferecer uma experiência segura e confortável no
                            uso dos serviços que oferecemos a você.
                        </p>
                        <p
                            style={{
                                color: "#846cf9",
                                fontWeight: "bold",
                            }}
                        >
                            Regras de uso e de negócio
                        </p>
                        <p>
                            A WeTêxtil tem como finalidade, o networking entre
                            os assinantes, com o objetivo de demonstrarem seus
                            serviços e produtos.
                        </p>
                        <p>
                            Uma vez assinante da Plataforma{" "}
                            <strong>WeTêxtil Conexão de Negócios Ltda</strong>,
                            será disponibilizado um Perfil para cada Usuário,
                            que escolherá as categorias e subcategorias nas
                            quais se encaixa, por exemplo: Confeccionista,
                            Prestador de Serviços, Atacadista, Lojistas,
                            Consultor, Fabricante de Tecidos, Máquinas,
                            Atacadista, Representante Comercial.
                        </p>
                        <p>
                            O interessado deverá realizar seu cadastro prévio
                            fornecendo todas as informações solicitadas para
                            criação de seu Perfil, que poderá passar por análise
                            de moderador.
                        </p>
                        <p>
                            A <strong>WeTêxtil Conexão de Negócios Ltda</strong>
                            , fornecerá apenas espaço para divulgação dos
                            produtos ou serviços oferecidos pelos Perfis que
                            realizarem os cadastros, portanto, a plataforma não
                            oferece qualquer garantia quanto a veracidade e
                            confiabilidade das ofertas de produtos ou serviços
                            divulgados nesta plataforma e não se
                            responsabilizará de forma alguma por desacordos
                            comerciais ou eventuais cobranças de serviços.
                        </p>
                        <p>
                            O uso deste site também não resulta em qualquer
                            garantia de contratação e fechamento de venda.
                        </p>
                        <p>
                            Eventuais links, hyperlinks ou botões,
                            redirecionando o acesso para websites de terceiros,
                            não implicará em nenhuma associação entre a{" "}
                            <strong>WeTêxtil Conexão de Negócios Ltda</strong> e
                            os responsáveis pelos sites fora deste domínio.
                        </p>
                        <p>
                            Os Usuários serão exclusivamente responsáveis pela
                            divulgação de eventuais links que redirecionam para
                            conteúdo ou sites externos, devendo conferir as
                            políticas de privacidade e os termos de uso
                            aplicáveis. A{" "}
                            <strong>WeTêxtil Conexão de Negócios Ltda</strong>{" "}
                            não assume qualquer responsabilidade por eventuais
                            políticas de terceiros.
                        </p>
                        <p>
                            A <strong>WeTêxtil Conexão de Negócios Ltda</strong>
                            , armazenará informações da experiência do usuário
                            dentro da plataforma e se reserva ao direito de
                            moderação de conteúdo, podendo a seu critério
                            remover ou recusar-se a exibir conteúdo que
                            eventualmente venha considerar impróprio ou
                            desconexo com os objetivos deste site.
                        </p>
                        <p>
                            O Usuário Assinante se declara ciente de que seus
                            dados existentes no Perfil se tornarão públicos e
                            não confidenciais, estando igualmente ciente de que
                            essa informação pública de Perfil será coletada e
                            utilizada por terceiros para se comunicarem com o
                            Usuário Assinante, já que disponibilizamos o
                            telefone cadastrado para contato direto entre as
                            partes, sem qualquer tipo de responsabilidade
                            conferida à{" "}
                            <strong>WeTêxtil Conexão de Negócios Ltda</strong>
                        </p>
                        <p>
                            Poderemos, eventualmente, divulgar dados e anúncios,
                            caso necessário para: proteger a integridade do
                            Site; proteger direitos da plataforma; cumprir ordem
                            judicial; responder requisições de autoridades ou
                            atender a reclamações de violações de direitos de
                            terceiros.
                        </p>
                        <p
                            style={{
                                color: "#846cf9",
                                fontWeight: "bold",
                            }}
                        >
                            Período Gratuito
                        </p>
                        <p>
                            A utilização do site poderá ser gratuita por alguns
                            meses de acordo com promoções promovidas pela{" "}
                            <strong>WeTêxtil Conexão de Negócios Ltda</strong>,
                            podendo este plano ser suspenso a qualquer momento.
                        </p>
                        <p>
                            A utilização da plataforma oferece, eventualmente,
                            de acordo com ações de marketing, um período
                            gratuito que será adquirido através de cupons de
                            descontos liberados pela WeTêxtil Conexão de
                            Negócios Ltda.
                        </p>
                        <p>
                            Ultrapassado o período, cessará a utilização
                            gratuita, e a cobrança será realizada através de
                            gateway de pagamento Pagseguro.
                        </p>
                        <p>
                            A <strong>WeTêxtil Conexão de Negócios Ltda</strong>
                            , se reserva ao direito de manter a promoção de
                            período gratuito enquanto se fizer necessário,
                            podendo este benefício ser suprimido a qualquer
                            momento a seu exclusivo critério, com a devida
                            atualização dos termos de uso.
                        </p>
                        <p>
                            Caso a{" "}
                            <strong>WeTêxtil Conexão de Negócios Ltda</strong>,
                            deseje suprimir o período gratuito, os usuários que
                            eventualmente já se encontravam no gozo do período
                            de gratuidade não serão prejudicados e terão o
                            benefício até que se finalizem os prazos dos cupons
                            de promoção de gratuidade.
                        </p>
                        <p>
                            Os assinantes que receberam cupom vitalício de
                            acesso gratuito ao plano conexão, terão, enquanto
                            existir a plataforma WeTêxtil, acesso a esse plano.
                        </p>
                        <p
                            style={{
                                color: "#846cf9",
                                fontWeight: "bold",
                            }}
                        >
                            Assinatura Anual Plano Conexão e Plano Consultoria e
                            da Rescisão
                        </p>
                        <p>
                            A utilização da plataforma será feita através de
                            assinatura de recorrência anual. Os valores estão
                            disponíveis na aba planos do site.
                        </p>
                        <p>
                            Os pagamentos das mensalidades serão feitos através
                            de Gateway de Pagamento - PagSeguro.
                        </p>
                        <p>
                            Como a assinatura é anual, os usuários poderão
                            revogar a renovação a qualquer momento através do
                            site na aba meu perfil.
                        </p>
                        <p>
                            <strong>Dois planos são disponibilizados</strong> e
                            o assinante tem direito a:
                        </p>
                        <p
                            style={{
                                color: "#846cf9",
                                fontWeight: "bold",
                            }}
                        >
                            No Plano Conexão -
                        </p>
                        <p>
                            • Cadastre seu perfil e aparecer nas buscas dentro
                            do site.
                        </p>
                        <p>• Cadastrar fotos de mercadorias.</p>
                        <p>
                            • Ter acesso a PIP, Pesquisa Individual
                            Personalizada, onde uma pessoa do time da WeTêxtil,
                            auxilia, através do whatsApp, ao assinante a
                            encontrar as demandas de fornecedores. A WeTêxtil
                            apenas indica o nome da empresa, sem ter qualquer
                            responsabilidade na efetivação de eventual transação
                            comercial entre as partes.
                        </p>
                        <p>
                            • Tem acesso a uma tabela de média de preços, de
                            alguns produtos mais buscados dentro da plataforma.
                            Os preços são meramente informativos e a pesquisa é
                            feita através de cotação de preços em fábricas de
                            tecidos.
                        </p>
                        <p
                            style={{
                                color: "#846cf9",
                                fontWeight: "bold",
                            }}
                        >
                            No Plano Mentoria -
                        </p>
                        <p>
                            • Cadastre seu perfil e aparecer nas buscas dentro
                            do site.
                        </p>
                        <p>• Cadastrar fotos de mercadorias.</p>
                        <p>
                            • Ter acesso a PIP, Pesquisa Individual
                            Personalizada, onde 1 pessoa do time da WeTêxtil,
                            auxilia, através do whatsApp, ao assinante a
                            encontrar as demandas de fornecedores. A WeTêxtil
                            apenas indica o nome da empresa, sem ter qualquer
                            responsabilidade na efetivação de eventual transação
                            comercial entre as partes.
                        </p>
                        <p>
                            • Tem acesso a uma tabela de média de preços de
                            alguns produtos mais buscados dentro da plataforma.
                            Os preços são meramente informativos e a pesquisa é
                            feita através de cotação de preços em fábricas de
                            tecidos.
                        </p>
                        <p>
                            • Acesso a cursos e palestras que estão hospedados
                            na plataforma Eduzz.
                        </p>
                        <p
                            style={{
                                color: "#846cf9",
                                fontWeight: "bold",
                            }}
                        >
                            Login e senha
                        </p>
                        <p>
                            O login e senha para acesso ao Perfil é de inteira
                            responsabilidade do Usuário Assinante e deverão ser
                            mantidos em sigilo, recomendando-se que o Usuário
                            troque a senha periodicamente.
                        </p>
                        <p>
                            Ao selecionar o aceite na página de cadastro do
                            Perfil de Usuários, a identidade informada para fins
                            cadastrais será presumida por verdadeira e a{" "}
                            <strong>WeTêxtil Conexão de Negócios Ltda</strong>,
                            não se responsabilizará por informações inverídicas.
                        </p>
                        <p>
                            A <strong>WeTêxtil Conexão de Negócios Ltda</strong>
                            , não se responsabilizará por eventual utilização
                            indevida da conta dos Usuários.
                        </p>
                        <p
                            style={{
                                color: "#846cf9",
                                fontWeight: "bold",
                            }}
                        >
                            O Usuário se compromete:
                        </p>
                        <p>
                            Não violar as regras deste termo ou legislações
                            aplicáveis;
                        </p>
                        <p>
                            Não utilizar a plataforma com fins discriminatórios;
                        </p>
                        <p>
                            Não violar direitos de propriedade intelectual,
                            marcas e patentes, direitos autorais;{" "}
                        </p>
                        <p>Não divulgar segredos comerciais;</p>
                        <p>Não violar direitos de privacidade;</p>
                        <p>
                            Não publicar materiais ilegais; ofensivos, enganosos
                            ou obscenos;
                        </p>
                        <p>
                            Não atentar contra as medidas de segurança do site;
                        </p>
                        <p>Usar o site para finalidades legítimas;</p>
                        <p>
                            Manter seu cadastro completo e com informações
                            atualizadas;
                        </p>
                        <p>
                            Assumir as responsabilidades cíveis e penais
                            decorrentes da utilização indevida do site;
                        </p>
                        <p>
                            Isentar a{" "}
                            <strong>WeTêxtil Conexão de Negócios Ltda</strong>{" "}
                            seus representantes de quaisquer danos resultantes
                            do uso deste site.
                        </p>
                        <p
                            style={{
                                color: "#846cf9",
                                fontWeight: "bold",
                            }}
                        >
                            Dos Termos Aplicáveis à Cada Perfil de
                            Usuário/Assinante:
                        </p>
                        <p>
                            Na Plataforma o usuário tem como objetivo ser um
                            canal para divulgação/promoção de produtos, serviços
                            e contato com os fornecedores, possibilitando
                            através do telefone informado no cadastro clicar e
                            ir direto ao whatsapp informado. Possibilitando a
                            realização de contatos entre profissionais e
                            empresas com interesse comum.
                        </p>
                        <p>
                            O usuário poderá cadastrar produtos para divulgação
                            com foto.Esses produtos serão encontrados por
                            potenciais parceiros através da pesquisa existente
                            dentro do site.
                        </p>
                        <p>
                            Todas as informações serão fornecidas e cadastradas
                            pelo Usuário Assinante.
                        </p>
                        <p>
                            O usuário poderá cadastrar quantos produtos achar
                            necessário, sendo disponibilizada uma imagem por
                            produto.
                        </p>
                        <p>
                            Objetivo ser um canal para pesquisa de fornecedores,
                            serviços e contato.
                        </p>
                        <p>
                            Os dados cadastrais serão configurados dentro do
                            perfil e podem ser alterados a qualquer momento,
                            este perfil será disponibilizado para consulta para
                            todos os membros cadastrados na plataforma.
                        </p>
                        <p>
                            O assinante poderá descrever sobre áreas de atuação,
                            descrever os produtos e serviços.
                        </p>
                        <p>
                            Estes termos de uso poderão ser modificados sempre
                            que houver necessidade de atualização.
                        </p>
                        <p>Última Atualização: 15/08/2021</p>
                        <p>
                            Mais informações a respeito do Site ou destes Termos
                            de Uso poderão ser obtidas através dos seguintes
                            endereços:
                        </p>
                        <p>
                            <strong>WeTêxtil Conexão de Negócios Ltda</strong>
                        </p>
                        <p>
                            Al. do Universo, 1466. Ville de montagne. Nova Lima
                            - MG
                        </p>
                        <p>contato@wetextil.com</p>
                    </MDBCol>
                </MDBRow>
                <MDBCol
                    md="12"
                    className="col-example text-center"
                    style={{
                        color: "#846cf9",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        backgroundColor: "#ffffff",
                    }}
                >
                    <span style={{ fontSize: 40, fontWeight: "bold" }}>
                        Política de Privacidade
                    </span>
                    <span style={{ fontSize: 25, fontWeight: "bold" }}>
                        WeTêxtil Conexão de Negócios Ltda
                    </span>
                </MDBCol>
                <MDBRow
                    className="row-cols-1 row-cols-md-2 g-2"
                    style={{ marginTop: "4%", marginBottom: "4%" }}
                >
                    <MDBCol
                        md="12"
                        className="col-example text-justify"
                        style={{ color: "#000000" }}
                    >
                        <p
                            style={{
                                color: "#846cf9",
                                fontWeight: "bold",
                            }}
                        >
                            1. Informações gerais
                        </p>
                        <p>
                            A presente Política de Privacidade contém
                            informações a respeito do modo como tratamos, total
                            ou parcialmente, de forma automatizada ou não, os
                            dados pessoais dos usuários que acessam nosso site.
                            Seu objetivo é esclarecer os interessados acerca dos
                            tipos de dados que são coletados e dos motivos da
                            coleta.
                        </p>
                        <p>
                            Esta Política de Privacidade poderá ser atualizada
                            em decorrência de eventual atualização normativa,
                            razão pela qual sugere-se que o usuário consulte
                            periodicamente esta seção.
                        </p>
                        <p
                            style={{
                                color: "#846cf9",
                                fontWeight: "bold",
                            }}
                        >
                            2. Tipos de dados coletados
                        </p>
                        <p>
                            Dados de identificação do usuário para realização de
                            cadastro.
                        </p>
                        <p>
                            A utilização, pelo usuário, de determinadas
                            funcionalidades do site dependerá de cadastro, sendo
                            que, nestes casos, os seguintes dados do usuário
                            serão coletados e armazenados:
                        </p>
                        <p>- nome</p>
                        <p>- endereço de e-mail </p>
                        <p>- cidade e estado </p>
                        <p>- detalhes de redes sociais </p>
                        <p>- número de telefone</p>
                        <p>- número de CPF </p>
                        <p>- número de CNPJ</p>
                        <p>
                            - Categoria de empresa ou prestador de serviço a que
                            se enquadra. Ex.: atacadista, confeccionista,
                            consultor, lojista, etc..
                        </p>
                        <p>
                            O Usuário contratante de Plano na Plataforma se
                            declara ciente de que seus dados existentes no
                            Perfil se tornarão públicos e não confidenciais,
                            estando igualmente ciente de que essa informação
                            pública de Perfil poderá ser coletada e utilizada
                            por terceiros para se comunicarem com o Assinante,
                            sem qualquer tipo de responsabilidade conferida à{" "}
                            <strong>WeTêxtil Conexão de Negócios Ltda</strong>
                        </p>
                        <p>
                            As senhas de cadastro para acesso à plataforma são
                            criptografadas. Assim como as senhas de SMS para
                            confirmação de celular.
                        </p>
                        <p
                            style={{
                                color: "#846cf9",
                                fontWeight: "bold",
                            }}
                        >
                            3. Dados informados no formulário de contato
                        </p>
                        <p>
                            Os dados informados pelo usuário que utilizar o
                            formulário de contato disponibilizado no site,
                            incluindo o teor da mensagem enviada, serão
                            coletados e armazenados para possibilitar a resposta
                            da solicitação.
                        </p>
                        <p
                            style={{
                                color: "#846cf9",
                                fontWeight: "bold",
                            }}
                        >
                            4. Dados relacionados à execução de contratos
                            firmados com o usuário
                        </p>
                        <p>
                            Para a execução de contrato de prestação de serviços
                            firmado entre o site e o usuário, poderão ser
                            coletados e armazenados outros dados relacionados ou
                            necessários à sua execução, incluindo o teor de
                            eventuais comunicações tidas com o usuário.
                        </p>
                        <p
                            style={{
                                color: "#846cf9",
                                fontWeight: "bold",
                            }}
                        >
                            5. Newsletter
                        </p>
                        <p>
                            O endereço de e-mail cadastrado pelo usuário que
                            optar por se inscrever em nossa Newsletter será
                            coletado e armazenado até que o usuário solicite seu
                            descadastro.
                        </p>
                        <p
                            style={{
                                color: "#846cf9",
                                fontWeight: "bold",
                            }}
                        >
                            6. Dados para Cobrança
                        </p>
                        <p>
                            O site faz a cobrança através do PagSeguro e assim
                            não coleta nem armazena nenhum dado referente a
                            pagamento, portanto o PagSeguro é responsável por
                            essa parte.
                        </p>
                        <p
                            style={{
                                color: "#846cf9",
                                fontWeight: "bold",
                            }}
                        >
                            7. Coleta de dados não previstos expressamente
                        </p>
                        <p>
                            Eventualmente, outros tipos de dados não previstos
                            expressamente nesta Política de Privacidade poderão
                            ser coletados, desde que sejam fornecidos com a
                            concordância do usuário, ou, ainda, que a coleta
                            seja permitida ou imposta por lei.
                        </p>
                        <p
                            style={{
                                color: "#846cf9",
                                fontWeight: "bold",
                            }}
                        >
                            8. Finalidades do tratamento dos dados pessoais
                        </p>
                        <p>
                            Os dados pessoais do usuário coletados pelo site têm
                            por finalidade facilitar, agilizar e cumprir os
                            compromissos estabelecidos com o usuário e fazer
                            cumprir as solicitações realizadas por meio do
                            preenchimento de formulários.
                        </p>
                        <p>
                            Os dados pessoais poderão ser utilizados também com
                            uma finalidade comercial, para personalizar o
                            conteúdo oferecido ao usuário, bem como para dar
                            subsídio ao site para a melhora da qualidade e
                            funcionamento de seus serviços.
                        </p>
                        <p>
                            O site recolhe os dados do usuário para que seja
                            realizada a definição de perfis (profiling), ou
                            seja, tratamento automatizado de dados pessoais que
                            consiste em utilizar estes dados para avaliar certos
                            aspectos pessoais do usuário, principalmente para
                            analisar ou prever características relacionadas,
                            preferências pessoais, interesses, localização ou
                            deslocamento.
                        </p>
                        <p>
                            Os dados cadastrais do usuário serão utilizados para
                            permitir o acesso a determinados conteúdos do site,
                            exclusivos para usuários cadastrados.
                        </p>
                        <p>
                            O tratamento de dados pessoais para outras
                            finalidades não previstas nesta Política de
                            Privacidade ocorrerá mediante atualização desta
                            Política, sendo que, em qualquer caso, os direitos e
                            obrigações aqui previstos permanecerão aplicáveis.
                        </p>
                        <p
                            style={{
                                color: "#846cf9",
                                fontWeight: "bold",
                            }}
                        >
                            9. Prazo de conservação dos dados pessoais
                        </p>
                        <p>
                            Os dados pessoais do usuário serão conservados por
                            um período não superior ao exigido para cumprir os
                            objetivos em razão dos quais eles são processados.
                        </p>
                        <p>
                            Os períodos de conservação dos dados são definidos
                            de acordo com o seguinte critério:
                        </p>
                        <p>
                            - Pelo tempo necessário para a manutenção dos
                            serviços.
                        </p>
                        <p
                            style={{
                                color: "#846cf9",
                                fontWeight: "bold",
                            }}
                        >
                            10. Leads da WeTêxtil Conexão de Negócios Ltda
                        </p>
                        <p>
                            Para oferecer conteúdos e materiais do seu
                            interesse, poderá ser necessário realizar o
                            cruzamento de dados coletados, para direcionar as
                            ações de marketing.
                        </p>
                        <p>
                            Algumas possibilidades de uso dos seus dados poderão
                            ocorrer.
                        </p>
                        <p>
                            Nesse sentido, recomendamos que leia os exemplos
                            abaixo:
                        </p>
                        <p>
                            Seu e-mail é utilizado para a operação de envio do
                            material ou informação por você requisitada no
                            preenchimento do formulário. Também pode ser usado
                            para envio de Newsletters, sempre relacionadas a
                            temas de marketing, comunicação de lançamento de
                            novos materiais gratuitos ou de novos produtos da{" "}
                            <strong>WeTêxtil Conexão de Negócios Ltda</strong>{" "}
                            No entanto, o usuário pode cancelar a assinatura a
                            qualquer momento;
                        </p>
                        <p>
                            <strong>Email: contato@wetextil.com</strong>
                        </p>
                        <p>
                            Enviar a você mensagens a respeito de suporte ou
                            serviço, como alertas, notificações e atualizações;
                        </p>
                        <p>
                            Nos comunicar com você sobre produtos, serviços,
                            promoções, notícias, atualizações, eventos e outros
                            assuntos que você possa ter interesse; e
                        </p>
                        <p>
                            Realizar publicidade direcionada conforme seus
                            gostos, interesses e outras informações coletadas.
                        </p>
                        <p
                            style={{
                                color: "#846cf9",
                                fontWeight: "bold",
                            }}
                        >
                            12. Dados de navegação (cookies)
                        </p>
                        <p>
                            Cookies são pequenos arquivos de texto enviados pelo
                            site ao computador do usuário e que nele ficam
                            armazenados, com informações relacionadas à
                            navegação do site.
                        </p>
                        <p>
                            Por meio dos cookies, pequenas quantidades de
                            informação são armazenadas pelo navegador do usuário
                            para que nosso servidor possa lê-las posteriormente.
                            Podem ser armazenados, por exemplo, dados sobre o
                            dispositivo utilizado pelo usuário, bem como seu
                            local e horário de acesso ao site.
                        </p>
                        <p>
                            Os cookies não permitem que qualquer arquivo ou
                            informação sejam extraídos do disco rígido do
                            usuário, não sendo possível, ainda, que, por meio
                            deles, se tenha acesso a informações pessoais que
                            não tenham partido do usuário ou da forma como
                            utiliza os recursos do site.
                        </p>
                        <p>
                            É importante ressaltar que nem todo cookie contém
                            informações que permitam a identificação do usuário,
                            sendo que determinados tipos de cookies podem ser
                            empregados simplesmente para que os sites sejam
                            carregados corretamente ou para que suas
                            funcionalidades funcionem do modo esperado.
                        </p>
                        <p>
                            As informações coletadas por meio destes cookies são
                            utilizadas para melhorar e personalizar a
                            experiência do usuário, sendo que alguns cookies
                            podem, por exemplo, ser utilizados para lembrar as
                            preferências e escolhas do usuário, bem como para o
                            oferecimento de conteúdo personalizado.
                        </p>
                        <p>
                            Estes dados de navegação poderão, ainda, ser
                            compartilhados com eventuais parceiros do site,
                            buscando o aprimoramento dos produtos e serviços
                            ofertados ao usuário.
                        </p>

                        <p
                            style={{
                                color: "#846cf9",
                                fontWeight: "bold",
                            }}
                        >
                            13. Cookies de redes sociais
                        </p>
                        <p>
                            O site utiliza plugins de redes sociais, que
                            permitem acessá-las a partir do site. Assim, ao
                            fazê-lo, os cookies utilizados por elas poderão ser
                            armazenados no navegador do usuário.
                        </p>
                        <p>
                            Cada rede social possui sua própria política de
                            privacidade e de proteção de dados pessoais, sendo
                            as pessoas físicas ou jurídicas que as mantêm
                            responsáveis pelos dados coletados e pelas práticas
                            de privacidade adotadas.
                        </p>
                        <p
                            style={{
                                color: "#846cf9",
                                fontWeight: "bold",
                            }}
                        >
                            14. Gestão dos cookies e configurações do navegador
                        </p>
                        <p>
                            O usuário poderá se opor ao registro de cookies pelo
                            site, bastando que desative esta opção no seu
                            próprio navegador ou aparelho.
                        </p>
                        <p>
                            A desativação dos cookies, no entanto, pode afetar a
                            disponibilidade de algumas ferramentas e
                            funcionalidades do site, comprometendo seu correto e
                            esperado funcionamento. Outra consequência possível
                            é remoção das preferências do usuário que
                            eventualmente tiverem sido salvas, prejudicando sua
                            experiência.
                        </p>
                        <p
                            style={{
                                color: "#846cf9",
                                fontWeight: "bold",
                            }}
                        >
                            15. Das alterações
                        </p>
                        <p>
                            O editor se reserva o direito de modificar, a
                            qualquer momento e sem qualquer aviso prévio, o
                            site, as presentes normas, especialmente para
                            adaptá-las às evoluções que o site sofrer, seja pela
                            disponibilização de novas funcionalidades, seja pela
                            supressão ou modificação daquelas já existentes.
                        </p>
                        <p>
                            Dessa forma, convida-se o usuário a consultar
                            periodicamente esta página para verificar as
                            atualizações.
                        </p>
                        <p>
                            Ao utilizar o serviço após eventuais modificações, o
                            usuário demonstra sua concordância com as novas
                            normas. Caso discorde de alguma das modificações,
                            deverá apresentar a sua ressalva pelo endereço
                            físico ou eletrônico indicado ao final desta
                            Política, se assim o desejar.
                        </p>
                        <p
                            style={{
                                color: "#846cf9",
                                fontWeight: "bold",
                            }}
                        >
                            16. Do Direito aplicável e do foro
                        </p>
                        <p>
                            Para a solução das controvérsias decorrentes do
                            presente instrumento, será aplicado integralmente o
                            Direito brasileiro.
                        </p>
                        <p>
                            Os eventuais litígios deverão ser apresentados no
                            foro da comarca de Belo Horizonte/MG.
                        </p>
                        <p>
                            Dúvidas e solicitações acerca da presente Política
                            podem ser dirimidas pelo e-mail:
                            contato@wetextil.com, ou por correio enviado ao
                            seguinte endereço:
                        </p>
                        <p>
                            <strong>WeTêxtil Conexão de Negócios Ltda</strong>
                        </p>
                        <p>
                            Al. do Universo, 1466. Ville de montagne. Nova Lima
                            - MG
                        </p>
                        <p>
                            A presente versão desta Política de Privacidade foi
                            atualizada pela última vez em: 14/10/2021.
                        </p>
                    </MDBCol>
                </MDBRow>
            </Content>

            <EspacoFundoCinza>
                <FundoCinza>
                    <MDBRow
                        style={{
                            marginLeft: "1%",
                            marginRight: "3%",
                            marginBottom: "10%",
                        }}
                    >
                        <MDBCol
                            md="1"
                            className="text-center align-items-center"
                            style={{
                                marginTop: "2%",
                                marginBottom: "2%",
                                alignItems: "center",
                            }}
                        >
                            <img
                                width="100px"
                                src={imgLogoBranca}
                                alt="WeTextil"
                            />
                        </MDBCol>
                        <MDBCol
                            md="6"
                            style={{ marginTop: "2%", marginBottom: "2%" }}
                        >
                            <MDBRow className="row-cols-1 row-cols-md-1 g-1 text-center">
                                <strong>CATEGORIAS</strong>
                            </MDBRow>
                            <MDBRow className="row-cols-1 row-cols-md-3 g-3 text-center">
                                {categorias.map(categoria => (
                                    <MDBCol
                                        key={categoria.id}
                                        onClick={
                                            () => clickCategoria(categoria.nome)
                                            // eslint-disable-next-line react/jsx-curly-newline
                                        }
                                        style={{ cursor: "pointer" }}
                                    >
                                        <small>{categoria.nome}⠀</small>
                                    </MDBCol>
                                ))}
                            </MDBRow>
                        </MDBCol>
                        <MDBCol
                            md="3"
                            style={{ marginTop: "2%", marginBottom: "2%" }}
                        >
                            <MDBRow className="row-cols-1 row-cols-md-1 g-1 text-center">
                                <strong>AJUDA</strong>
                            </MDBRow>
                            <MDBRow
                                className="row-cols-1 row-cols-md-1 g-1 text-center"
                                style={{ marginTop: "2%" }}
                            >
                                <small>16 99787-1671</small>
                            </MDBRow>
                            <MDBRow
                                className="row-cols-1 row-cols-md-1 g-1 text-center"
                                style={{ marginTop: "1%" }}
                            >
                                <small>contato@wetextil.com</small>
                            </MDBRow>
                            <MDBRow
                                className="row-cols-1 row-cols-md-1 g-1 text-center"
                                style={{ marginTop: "1%" }}
                            >
                                <small>
                                    <a
                                        href="/termosDeUso"
                                        target="_blank"
                                        style={{ color: "white" }}
                                    >
                                        Política de Privacidade e Termos de Uso
                                    </a>
                                </small>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol
                            md="2"
                            style={{ marginTop: "2%", marginBottom: "2%" }}
                        >
                            <MDBRow className="row-cols-1 row-cols-md-1 g-1 text-center">
                                <strong>REDES SOCIAIS</strong>
                            </MDBRow>
                            <MDBRow
                                style={{
                                    marginTop: "4%",
                                    marginBottom: "2%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Link
                                    to={{
                                        pathname: `${
                                            outros.find(
                                                saida =>
                                                    saida.chave ===
                                                    "linkFacebook",
                                            )?.valor
                                        }`,
                                    }}
                                    target="_blank"
                                >
                                    <ButtonIcon type="submit">
                                        <FiFacebook />
                                    </ButtonIcon>
                                </Link>
                                <Link
                                    to={{
                                        pathname: `${
                                            outros.find(
                                                saida =>
                                                    saida.chave ===
                                                    "linkInstagram",
                                            )?.valor
                                        }`,
                                    }}
                                    target="_blank"
                                >
                                    <ButtonIcon type="submit">
                                        <FiInstagram />
                                    </ButtonIcon>
                                </Link>
                                <Link
                                    to={{
                                        pathname: `${
                                            outros.find(
                                                saida =>
                                                    saida.chave ===
                                                    "linkLinkedin",
                                            )?.valor
                                        }`,
                                    }}
                                    target="_blank"
                                >
                                    <ButtonIcon type="submit">
                                        <FiLinkedin />
                                    </ButtonIcon>
                                </Link>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow
                        style={{
                            marginLeft: "1%",
                            marginRight: "3%",
                        }}
                    >
                        <MDBCol
                            md="12"
                            className="text-center align-items-center"
                            style={{
                                marginTop: "2%",
                                marginBottom: "2%",
                                alignItems: "center",
                            }}
                        >
                            Copyright ©️ 2021 WeTêxtil. All trademarks and logos
                            are the property of their respective owners. All
                            rights reserved.
                        </MDBCol>
                    </MDBRow>
                </FundoCinza>
            </EspacoFundoCinza>
        </Container>
    );
};

export default TermosDeUso;
