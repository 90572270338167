import styled from "styled-components";

export const Container = styled.div`
    position: absolute;
    right: 0px;
    top: 60px;
    padding: 10px;
    overflow: hidden;
    z-index: 1;
`;
