import React, { useCallback, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Form } from "@unform/web";
// eslint-disable-next-line no-unused-vars
import { FormHandles } from "@unform/core";
import { FiLogIn, FiMail } from "react-icons/fi";

import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import getValidationErrors from "../../utils/getValidationErrors";

import api from "../../services/api";

import logoImg from "../../assets/Logo.png";
import Input from "../../components/Input";

import ButtonAzul from "../../components/ButtonAzul";
import { Container, Content, Background, AnimationContainer } from "./styles";
import "react-toastify/dist/ReactToastify.css";
import "../../components/ProgressBar/styles.css";

interface ForgotSenhaFormData {
    email: string;
}

const ForgotSenha: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const formRef = useRef<FormHandles>(null);
    const handleSubmit = useCallback(async (data: ForgotSenhaFormData) => {
        try {
            setLoading(true);
            formRef.current?.setErrors({});
            const schema = Yup.object().shape({
                email: Yup.string()
                    .required("E-mail obrigatório")
                    .email("Digite um e-mail válido"),
            });
            await schema.validate(data, {
                // Faz com que o Yup não pare no primeiro erro. Por padrão é true.
                abortEarly: false,
            });

            await api.post("/clientes/senha/forgot", {
                email: data.email,
            });

            toast.success(
                "Email de recuperação de senha enviado! Verifique seu email!",
                {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },
            );
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const errors = getValidationErrors(err);

                formRef.current?.setErrors(errors);

                return;
            }

            toast.error("Erro na recuperação de senha! Tente novamente!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } finally {
            setLoading(false);
        }
    }, []);

    return (
        <Container>
            <Content>
                <ToastContainer />
                <AnimationContainer>
                    <img
                        src={logoImg}
                        width="200px"
                        alt="WeTextil"
                        style={{ marginBottom: 10 }}
                    />

                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <h5> Recuperar senha </h5>

                        <Input
                            icon={FiMail}
                            name="email"
                            placeholder="E-mail"
                            type="email"
                        />
                        <ButtonAzul loading={loading} type="submit">
                            Recuperar
                        </ButtonAzul>
                    </Form>
                    <Link to="/login">
                        <FiLogIn />
                        Voltar ao login
                    </Link>
                </AnimationContainer>
            </Content>
            <Background />
        </Container>
    );
};
export default ForgotSenha;
