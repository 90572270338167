/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useRef, useState, useEffect } from "react";
import { Form } from "@unform/web";
// eslint-disable-next-line no-unused-vars
import { FormHandles } from "@unform/core";
import { FiType, FiDollarSign, FiPackage, FiTool } from "react-icons/fi";
import * as Yup from "yup";

import { useHistory, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";

import Select from "react-select";
import FormReact from "react-bootstrap/Form";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { ToastContainer, toast } from "react-toastify";
import api from "../../services/api";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../hooks/auth";

import getValidationErrors from "../../utils/getValidationErrors";

import {
    Background,
    Container,
    Content,
    AnimationContainer,
    ImagemInput,
} from "./styles";

import Input from "../../components/Input";
import BorderSelect from "../../components/BorderSelect";
import ButtonAzul from "../../components/ButtonAzul";
import imgProduto from "../../assets/imgProduto.png";
import TextareaDashboard from "../../components/TextareaDashboard";

interface CadastroProdutoFormData {
    nome: string;
    descricao: string;
    idCliente: string;
    preco: string;
    unidadeMedida: string;
    vetorAuxProduto: Categoria[];
}

interface Categoria {
    id: string;
    nome: string;
    idCategoriaPai: string;
}

interface Unidadedemedidas {
    id: number;
    nome: string;
}

const CadastroProduto: React.FC = () => {
    const formRef = useRef<FormHandles>(null);

    const { cliente } = useAuth();
    const history = useHistory();

    const [categoriasCliente, setCategoriasCliente] = useState<Categoria[]>([]);

    const [notShow, setNotShow] = useState<boolean>(true);

    useEffect(() => {
        api.get(`/clientes/${cliente.id}`).then(response =>
            setCategoriasCliente(response.data.categorias),
        );
    }, [cliente.id]);

    const [categorias] = useState<Categoria[]>([]);

    const [unidadeDeMedidas] = useState<Unidadedemedidas[]>([
        { id: 1, nome: "kg" },
        { id: 2, nome: "metro" },
        { id: 3, nome: "unidade" },
        { id: 4, nome: "outro" },
    ]);

    const [selectedIdCategoria, setSelectedIdCategoria] = useState([]);

    const [selectedValueCategoria, setSelectedValueCategoria] = useState([]);
    const handleChangeCategoria = (e: any) => {
        setSelectedValueCategoria(e);
        setSelectedIdCategoria(e.id);
        setNotShow(false);
    };

    const [selectedValueUnidadeDeMedida, setSelectedValueUnidadeDeMedida] =
        useState([]);
    const handleChangeUnidadeDeMedidas = (e: any) => {
        setSelectedValueUnidadeDeMedida(e.nome);
    };

    const [subCategorias, setSubCategorias] = useState<Categoria[]>([]);
    useEffect(() => {
        if (cliente.controleFluxo !== "IS_ADMIN") {
            categoriasCliente.map(categoria => {
                if (categoria.idCategoriaPai === null) {
                    return categorias.push(categoria);
                }
                return null;
            });
        }
    }, [categorias, categoriasCliente, cliente.controleFluxo]);

    useEffect(() => {
        api.get(`/categorias/subCategorias/${selectedIdCategoria}`).then(
            response => setSubCategorias(response.data),
        );
    }, [selectedIdCategoria]);

    const [selectedValueSubCategoria, setSelectedValueSubCategoria] = useState(
        [],
    );
    const handleChangeSubCategoria = (e: any) => {
        setSelectedValueSubCategoria(e.map((x: any) => x));
    };

    const [insertCategoria, setInsertCategoria] = useState([]);

    useEffect(() => {
        setInsertCategoria(
            selectedValueSubCategoria.concat(selectedValueCategoria),
        );
    }, [selectedValueCategoria, selectedValueSubCategoria]);

    const [file, setFile] = useState("");

    const [isProduto, setIsProduto] = useState<string>("");
    const tipo = (e: any) => {
        setIsProduto(e.target.value);
    };

    const [isVisivel, setIsVisivel] = useState<string>("");
    const visibilidade = (e: any) => {
        setIsVisivel(e.target.value);
    };

    const saveFile = (e: any) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = useCallback(
        async (data: CadastroProdutoFormData) => {
            try {
                if (file) {
                    formRef.current?.setErrors({});
                    if (isProduto === "true") {
                        const schema = Yup.object().shape({
                            isTipo: Yup.string().required("Campo obrigatório"),
                            isVisibilidade:
                                Yup.string().required("Campo obrigatório"),
                            nome: Yup.string().required("Nome obrigatório"),
                            preco: Yup.string()
                                .required("Preço obrigatório")
                                .typeError("Use . para casas decimais"),
                            SelectUnidadeDeMedida:
                                Yup.string().required("Campo obrigatório"),
                            SelectCategoria:
                                Yup.string().required("Campo obrigatório"),
                        });

                        const { nome, descricao, preco } = data;

                        const ehProduto = true;

                        let ehVisivel;

                        if (isVisivel === "true") {
                            ehVisivel = true;
                        } else {
                            ehVisivel = false;
                        }

                        const precoFormatado = preco.replace(/,/g, ".");

                        const formData = {
                            isProduto: ehProduto,
                            nome,
                            preco: parseFloat(precoFormatado),
                            unidadeMedida: selectedValueUnidadeDeMedida,
                            descricao,
                            isVisivel: ehVisivel,
                            idCliente: cliente.id,
                            vetorAuxProduto: insertCategoria,
                        };

                        await schema.validate(data, {
                            // Faz com que o Yup não pare no primeiro erro. Por padrão é true.
                            abortEarly: false,
                        });

                        const retorno = await api.post("/produtos", formData);

                        const configTipo = {
                            headers: {
                                "content-type": "multipart/form-data",
                            },
                        };
                        const formDataProduto = new FormData();
                        formDataProduto.append("img01", file);
                        formDataProduto.append("idProduto", retorno.data.id);

                        api.patch(
                            "/produtos/img01",
                            formDataProduto,
                            configTipo,
                        );

                        history.push("/minhaVitrine");
                    }

                    if (isProduto === "false") {
                        const schema = Yup.object().shape({
                            isTipo: Yup.string().required("Campo obrigatório"),
                            nome: Yup.string().required("Nome obrigatório"),
                            SelectCategoria:
                                Yup.string().required("Campo obrigatório"),
                        });

                        const { nome, descricao } = data;

                        const ehProduto = false;

                        const formData = {
                            isProduto: ehProduto,
                            nome,
                            descricao,
                            idCliente: cliente.id,
                            vetorAuxProduto: insertCategoria,
                        };

                        await schema.validate(data, {
                            // Faz com que o Yup não pare no primeiro erro. Por padrão é true.
                            abortEarly: false,
                        });

                        const retorno = await api.post("/produtos", formData);

                        const configTipo = {
                            headers: {
                                "content-type": "multipart/form-data",
                            },
                        };
                        const formDataProduto = new FormData();
                        formDataProduto.append("img01", file);
                        formDataProduto.append("idProduto", retorno.data.id);

                        api.patch(
                            "/produtos/img01",
                            formDataProduto,
                            configTipo,
                        );

                        history.push("/minhaVitrine");
                    }
                } else if (!file) {
                    toast.warn(
                        "É necessário incluir uma imagem que represente o produto/serviço!",
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        },
                    );
                }
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);
                    return;
                }

                toast.error("Erro no cadastro! Tente novamente!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        },
        [
            file,
            isProduto,
            isVisivel,
            selectedValueUnidadeDeMedida,
            cliente.id,
            insertCategoria,
            history,
        ],
    );

    // Redirecionamentos de páginas privadas
    if (cliente.controleFluxo === "FALTA_PAGAMENTO") {
        return <Redirect to={`/planosPagamento/${cliente.id}`} />;
    }
    if (cliente.controleFluxo === "FALTA_CONFIRMAR_CELULAR") {
        return <Redirect to="/confirmaSMS" />;
    }

    return (
        <Container>
            <Content>
                <ToastContainer />
                <AnimationContainer>
                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <h4>Cadastre seu produto/serviço</h4>
                        <BorderSelect name="isTipo" value={isProduto} hidden>
                            <FormReact.Check
                                inline
                                label="Produto"
                                name="isProduto"
                                type="radio"
                                id="true"
                                value="true"
                                onClick={tipo}
                            />
                            <FormReact.Check
                                inline
                                label="Serviço"
                                name="isProduto"
                                type="radio"
                                id="false"
                                value="false"
                                onClick={tipo}
                            />
                        </BorderSelect>

                        {isProduto === "true" && (
                            <Input
                                icon={FiPackage}
                                name="nome"
                                placeholder="Produto"
                                type="text"
                            />
                        )}

                        {isProduto === "false" && (
                            <Input
                                icon={FiTool}
                                name="nome"
                                placeholder="Serviço"
                                type="text"
                            />
                        )}

                        <TextareaDashboard
                            icon={FiType}
                            name="descricao"
                            placeholder="Descrição"
                            style={{
                                border: "none",
                                resize: "none",
                                width: "100%",
                                height: "120%",
                            }}
                        />

                        {isProduto === "true" && (
                            <MDBRow>
                                <MDBCol size="md">
                                    <Input
                                        icon={FiDollarSign}
                                        name="preco"
                                        placeholder="Preço"
                                        type="text"
                                        style={{ width: "100%" }}
                                    />
                                </MDBCol>
                                <MDBCol size="md" style={{ marginTop: 5 }}>
                                    <BorderSelect
                                        name="SelectUnidadeDeMedida"
                                        value={selectedValueUnidadeDeMedida}
                                        hidden
                                    >
                                        <Select
                                            className="dropdown"
                                            placeholder="Unidade de Medida"
                                            onChange={
                                                handleChangeUnidadeDeMedidas
                                            }
                                            options={unidadeDeMedidas}
                                            getOptionLabel={
                                                option => option.nome
                                                // eslint-disable-next-line react/jsx-curly-newline
                                            }
                                            isSearchable={false}
                                            maxMenuHeight={170}
                                            theme={theme => ({
                                                ...theme,
                                                height: 100,
                                                borderRadius: 5,
                                            })}
                                        />
                                    </BorderSelect>
                                </MDBCol>
                                <MDBCol
                                    size="md"
                                    className="col-example"
                                    style={{
                                        verticalAlign: "middle",
                                        marginTop: 2,
                                        textAlign: "left",
                                    }}
                                >
                                    <span> Deixar preço vísivel? </span>
                                    <BorderSelect
                                        name="isVisibilidade"
                                        value={isVisivel}
                                        hidden
                                    >
                                        <FormReact.Check
                                            inline
                                            label="Sim"
                                            name="isVisivel"
                                            type="radio"
                                            id="true"
                                            value="true"
                                            onClick={visibilidade}
                                            style={{
                                                zIndex: 0,
                                            }}
                                        />
                                        <FormReact.Check
                                            inline
                                            label="Não"
                                            name="isVisivel"
                                            type="radio"
                                            id="false"
                                            value="false"
                                            onClick={visibilidade}
                                            style={{
                                                zIndex: 0,
                                            }}
                                        />
                                    </BorderSelect>
                                </MDBCol>
                            </MDBRow>
                        )}

                        <ImagemInput style={{ marginTop: 10 }}>
                            {isProduto === "true" && (
                                <>Insira uma imagem do seu produto:</>
                            )}

                            {isProduto === "false" && (
                                <>Insira uma imagem do seu serviço:</>
                            )}

                            <input
                                type="file"
                                name="img01"
                                onChange={saveFile}
                                style={{ marginLeft: 10 }}
                            />
                        </ImagemInput>

                        <br />
                        <MDBRow style={{ marginTop: 10 }}>
                            <MDBCol size="md" className="col-example">
                                <img
                                    src={
                                        file
                                            ? URL.createObjectURL(file)
                                            : imgProduto
                                    }
                                    alt="..."
                                    width="200px"
                                    height="100px"
                                    style={{
                                        borderRadius: 20,
                                        marginBottom: 10,
                                    }}
                                />
                            </MDBCol>
                            <MDBCol size="md" className="col-example">
                                <BorderSelect
                                    name="SelectCategoria"
                                    value={selectedValueCategoria}
                                    hidden
                                >
                                    <Select
                                        className="dropdown"
                                        placeholder="Categoria"
                                        onChange={handleChangeCategoria}
                                        options={categorias}
                                        getOptionLabel={option => option.nome}
                                        getOptionValue={option => option.id}
                                        isSearchable={false}
                                        maxMenuHeight={100}
                                        theme={theme => ({
                                            ...theme,
                                            height: 100,
                                            borderRadius: 10,
                                        })}
                                    />
                                </BorderSelect>

                                <div style={{ marginTop: 10 }} />

                                <Select
                                    className="dropdown"
                                    placeholder="Sub Categoria"
                                    onChange={handleChangeSubCategoria}
                                    options={subCategorias}
                                    getOptionLabel={option => option.nome}
                                    getOptionValue={option => option.id}
                                    isMulti
                                    isSearchable={false}
                                    maxMenuHeight={70}
                                    theme={theme => ({
                                        ...theme,
                                        height: 100,
                                        borderRadius: 10,
                                    })}
                                    isDisabled={notShow}
                                />
                            </MDBCol>
                        </MDBRow>

                        <ButtonAzul type="submit"> Cadastrar </ButtonAzul>
                        <br />
                    </Form>
                </AnimationContainer>
            </Content>
            <Background />
        </Container>
    );
};

export default CadastroProduto;
