import React from "react";

import "./style.css";

import ButtonAzulEscuro from "../ButtonAzulEscuro";

//<ButtonAzulEscuro className="close-btn" onClick={()=>props.setTrigger(false)} style={{width:"30%"}}>Você está sendo redirecionado</ButtonAzulEscuro>

function PopupAviso(props) {
    return props.trigger ? (
        <div className="popupAviso">
            <div className="popupAviso-innerAviso">{props.children}</div>
        </div>
    ) : null;
}

export default PopupAviso;
