/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import { FiTrash2, FiPower, FiUser, FiEdit } from "react-icons/fi";

import { Link, useHistory, Redirect } from "react-router-dom";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardImage,
    MDBRow,
    MDBCol,
    MDBCardFooter,
    MDBContainer,
} from "mdb-react-ui-kit";

// eslint-disable-next-line no-unused-vars
import { Form } from "@unform/web";
import { ToastContainer, toast } from "react-toastify";
import imgLogo from "../../assets/Logo.png";

import {
    Container,
    Schedule,
    Content,
    SemProduto,
    ContentSemProduto,
    ButtonMenu,
} from "./styles";

import ButtonAzul from "../../components/ButtonAzul";

import { useAuth } from "../../hooks/auth";

import "react-toastify/dist/ReactToastify.css";

import cadProduto from "../../assets/anunciar.png";
import "bootstrap/dist/css/bootstrap.min.css";
import api from "../../services/api";

interface Categoria {
    id: string;
    nome: string;
    idCategoriaPai: string;
}

interface Produto {
    id: string;
    nome: string;
    descricao: string;
    img01_url: string;
    categorias: Categoria[];
}

const MinhaVitrine: React.FC = () => {
    const { signOut, cliente } = useAuth();

    const [produtos, setProdutos] = useState<Produto[]>([]);
    const history = useHistory();

    useEffect(() => {
        api.get(`/produtos/cliente/${cliente.id}`)
            .then(response => setProdutos(response.data))
            .catch(response => {
                if (
                    JSON.parse(response.request.responseText).message ===
                        "JWT token is missing!" ||
                    JSON.parse(response.request.responseText).message ===
                        "Invalid JWT token"
                ) {
                    signOut();
                    toast.warn("Sessão expirada! Faça login novamente!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            });
    }, [cliente.id, produtos, signOut]);

    const [categorias, setCategorias] = useState<Categoria[]>([]);

    useEffect(() => {
        api.get("/categorias/categorias").then(response =>
            setCategorias(response.data),
        );
    }, []);

    const clickCategoria = (nomeCategoria: string) => {
        const insertPesquisa = {
            idCliente: cliente.id,
            nomeCliente: cliente.nome,
            pesquisa: nomeCategoria,
        };

        api.post("/pesquisa", insertPesquisa);
        localStorage.setItem("@dbwetextil:nomeCategoria", nomeCategoria);
        history.push("/pesquisa");
    };

    const deleteProduto = (id: string) => {
        api.delete(`/produtos/${id}`);
        // setProdutos(produtos.filter(produto => produto.id !== id));
        api.get(`/produtos/cliente/${cliente.id}`).then(response =>
            setProdutos(response.data),
        );
    };

    const updateProduto = (id: string) => {
        history.push(`/updateProduto/${id}`);
    };

    // Redirecionamentos de páginas privadas
    if (cliente.controleFluxo === "FALTA_PAGAMENTO") {
        return <Redirect to={`/planosPagamento/${cliente.id}`} />;
    }
    if (cliente.controleFluxo === "FALTA_CONFIRMAR_CELULAR") {
        return <Redirect to="/confirmaSMS" />;
    }

    return (
        <Container>
            <ToastContainer />
            <Navbar
                collapseOnSelect
                expand="lg"
                style={{
                    background: "#ffffff",
                }}
            >
                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    style={{ border: "none" }}
                />
                <Navbar.Brand style={{ marginLeft: "2%" }}>
                    <Link to="/">
                        <img width="125px" src={imgLogo} alt="WeTextil" />
                    </Link>
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto text-center" />
                    <Nav>
                        <Nav.Link
                            href="/"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                início
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/nossoMovimento"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                sobre
                            </span>
                        </Nav.Link>

                        <Nav.Link
                            href="/planos"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                planos
                            </span>
                        </Nav.Link>

                        <Nav.Link
                            href="#"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#CAC9C8",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                fabrique sua malha
                            </span>
                        </Nav.Link>

                        <Nav.Link
                            href="#"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#CAC9C8",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                mapeie seu mercado
                            </span>
                        </Nav.Link>

                        <NavDropdown
                            title="categorias"
                            id="basic-nav-dropdown"
                            style={{
                                padding: "0px 20px 0px 20px",
                                textAlign: "center",
                                marginTop: 8,
                                fontWeight: "bold",
                                color: "#2d2d2d",
                            }}
                        >
                            {categorias.map(categoria => (
                                <NavDropdown.Item
                                    onClick={
                                        () => clickCategoria(categoria.nome)
                                        // eslint-disable-next-line react/jsx-curly-newline
                                    }
                                >
                                    {categoria.nome}
                                </NavDropdown.Item>
                            ))}
                        </NavDropdown>
                        {!cliente && (
                            <Nav.Link href="/cliente">
                                <ButtonMenu>
                                    <button type="button">
                                        <span
                                            style={{
                                                padding: "0px 20px 0px 20px",
                                            }}
                                        >
                                            cadastre-se
                                        </span>
                                    </button>
                                </ButtonMenu>
                            </Nav.Link>
                        )}
                        {!cliente && (
                            <Nav.Link
                                href="/login"
                                style={{
                                    textAlign: "center",
                                    marginTop: 8,
                                    color: "#2d2d2d",
                                }}
                            >
                                <span style={{ padding: "0px 20px 0px 30px" }}>
                                    login
                                </span>
                            </Nav.Link>
                        )}

                        {cliente && cliente?.controleFluxo !== "IS_ADMIN" && (
                            <Nav.Link
                                href="/minhaVitrine"
                                style={{
                                    textAlign: "center",
                                    marginTop: 8,
                                    color: "#2d2d2d",
                                }}
                            >
                                <span style={{ padding: "0px 20px 0px 20px" }}>
                                    minha vitrine
                                </span>
                            </Nav.Link>
                        )}

                        {cliente && cliente?.controleFluxo !== "IS_ADMIN" && (
                            <NavDropdown
                                title="meu perfil"
                                id="basic-nav-dropdown"
                                style={{
                                    padding: "0px 30px 0px 20px",
                                    textAlign: "center",
                                    marginTop: 8,
                                    fontWeight: "bold",
                                    color: "#2d2d2d",
                                }}
                            >
                                <NavDropdown.Item href="/minhaConta">
                                    minha conta
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={signOut}>
                                    sair
                                </NavDropdown.Item>
                            </NavDropdown>
                        )}

                        {cliente && cliente?.controleFluxo === "IS_ADMIN" && (
                            <Nav.Link href="/dashboardClientes">
                                <ButtonMenu>
                                    <button type="button" disabled>
                                        <span
                                            style={{
                                                padding: "0px 20px 0px 10px",
                                            }}
                                        >
                                            Dashboard
                                        </span>
                                    </button>
                                </ButtonMenu>
                            </Nav.Link>
                        )}

                        {cliente && cliente?.controleFluxo === "IS_ADMIN" && (
                            <Nav.Link
                                onClick={signOut}
                                style={{
                                    textAlign: "center",
                                    marginTop: 10,
                                    color: "#2d2d2d",
                                }}
                            >
                                <span style={{ padding: "0px 30px 0px 20px" }}>
                                    sair
                                </span>
                            </Nav.Link>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <Schedule>
                {produtos.length === 0 && (
                    <ContentSemProduto>
                        <SemProduto>
                            <img
                                src={cadProduto}
                                width="200px"
                                alt="WeTextil"
                            />
                            <h4>{cliente.nome}, seja bem-vindo(a)!</h4>
                            <h6>
                                É hora de começar a aparecer e aumentar suas
                                chances de fazer bons negócios!
                            </h6>
                            <Link
                                to="/produto"
                                style={{ textDecoration: "none" }}
                            >
                                <ButtonAzul>
                                    Cadastrar produto/serviço
                                </ButtonAzul>
                            </Link>
                        </SemProduto>
                    </ContentSemProduto>
                )}
            </Schedule>

            <Content>
                {produtos.length > 0 && (
                    <MDBRow className="flex-row-reverse">
                        <MDBCol lg="4">
                            <Link
                                to="/produto"
                                style={{ textDecoration: "none" }}
                            >
                                <ButtonAzul>
                                    Cadastrar produto/serviço
                                </ButtonAzul>
                            </Link>
                        </MDBCol>
                    </MDBRow>
                )}

                <MDBRow className="row-cols-1 row-cols-md-3 g-3">
                    {produtos.map(produto => (
                        <MDBCol style={{ marginTop: 30 }} key={produto.id}>
                            <MDBCard
                                className="h-100 border border-white"
                                style={{
                                    color: "#000000",
                                    borderRadius: 10,
                                    boxShadow: "0 0 10px #e8e8e8",
                                }}
                            >
                                <MDBCardImage
                                    src={produto.img01_url}
                                    alt="..."
                                    width="400px"
                                    height="200px"
                                    position="top"
                                    style={{
                                        borderTopLeftRadius: 10,
                                        borderTopRightRadius: 10,
                                    }}
                                />
                                <MDBCardBody>
                                    <MDBCardText>
                                        {produto.categorias.map(categoria => (
                                            <strong
                                                style={{
                                                    color: "#32da7f",
                                                }}
                                                key={categoria.id}
                                            >
                                                {categoria.idCategoriaPai ===
                                                    null && (
                                                    <strong>
                                                        {categoria.nome}
                                                    </strong>
                                                )}
                                            </strong>
                                        ))}
                                    </MDBCardText>
                                    <MDBCardTitle
                                        style={{
                                            color: "#9296AD",
                                        }}
                                    >
                                        {produto.nome}
                                    </MDBCardTitle>
                                    <MDBCardText>
                                        {produto.descricao}
                                    </MDBCardText>
                                </MDBCardBody>
                                <MDBCardFooter
                                    style={{
                                        backgroundColor: "#ffffff",
                                    }}
                                >
                                    <MDBContainer
                                        className="text-center"
                                        style={{ color: "#000000" }}
                                    >
                                        <MDBRow>
                                            <MDBCol size="6">
                                                <MDBRow>
                                                    <MDBCol
                                                        size="md"
                                                        className="col-example"
                                                    >
                                                        <FiEdit
                                                            style={{
                                                                marginTop: -3,
                                                                marginRight: 10,
                                                            }}
                                                        />
                                                        <span
                                                            role="button"
                                                            tabIndex={0}
                                                            title="Editar"
                                                            onClick={
                                                                () =>
                                                                    updateProduto(
                                                                        produto.id,
                                                                        // eslint-disable-next-line prettier/prettier
                                                                    )
                                                                // eslint-disable-next-line react/jsx-curly-newline
                                                            }
                                                        >
                                                            Editar
                                                        </span>
                                                    </MDBCol>
                                                </MDBRow>
                                            </MDBCol>
                                            <MDBCol size="6">
                                                <MDBRow>
                                                    <MDBCol
                                                        size="md"
                                                        className="col-example"
                                                    >
                                                        <FiTrash2
                                                            style={{
                                                                marginTop: -3,
                                                                marginRight: 10,
                                                            }}
                                                        />
                                                        <span
                                                            role="button"
                                                            tabIndex={0}
                                                            title="Excluir"
                                                            onClick={
                                                                () =>
                                                                    deleteProduto(
                                                                        produto.id,
                                                                        // eslint-disable-next-line prettier/prettier
                                                                    )
                                                                // eslint-disable-next-line react/jsx-curly-newline
                                                            }
                                                        >
                                                            Excluir
                                                        </span>
                                                    </MDBCol>
                                                </MDBRow>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBContainer>
                                </MDBCardFooter>
                            </MDBCard>
                        </MDBCol>
                    ))}
                </MDBRow>
            </Content>
        </Container>
    );
};

export default MinhaVitrine;
