import styled, { css } from "styled-components";

import Tooltip from "../Tooltip";

interface ContainerProps {
    isFocused: boolean;
    isFilled: boolean;
    isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
    background: #ffffff;
    border-radius: 10px;
    margin-top: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    border-color: transparent;
    border: 2px solid transparent;
    color: #666360;

    ${props =>
        props.isErrored &&
        css`
            border-color: red;
            z-index: 1;
        `}
    ${props =>
        props.isFocused &&
        css`
            border-color: transparent;
            color: transparent;
            z-index: 1;
        `}
    ${props =>
        props.isFilled &&
        css`
            color: transparent;
            border-color: transparent;
            z-index: 1;
        `}

    input {
        color: #00d1e6;
        background: red;
        flex: 1;
        border: 0;
        z-index: 1;
        &::placeholder {
            color: #666360;
            z-index: 1;
        }
    }

    .dropdown {
        width: 100%;
        max-width: 100%;
    }
`;

export const Error = styled(Tooltip)`
    height: 20px;
    margin: -8px 8px 0px;
    max-width: 20px;
    z-index: 1;
    svg {
        margin: 0;
        z-index: 1;
    }
    span {
        background: #c53030;
        color: #fff;
        &::before {
            border-color: #c53030 transparent;
            z-index: 1;
        }
        z-index: 1;
    }
`;
