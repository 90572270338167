import styled, { keyframes } from "styled-components";

import { shade } from "polished";

import cadastroClienteImg from "../../assets/imgTelaPagamento.png";

export const Container = styled.div`
    height: 100vh;
    display: flex;
    align-items: stretch;
    margin-right: 3%;
`;

export const Geral = styled.div`
    width: 95%;
    align-items: center;
    margin-top: 2%;
    margin-bottom: 1%;
    margin-left: 5%;
    margin-right: 5%;

    p {
        text-align: right;
        padding: 1.5% 8% 1% 0%;
    }
`;

export const DadosCadastrais = styled.div`
    width: 92%;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    box-shadow: 0 0 1px grey;
    margin-top: 1%;
`;

export const ConteudoDadosCadastrais = styled.div`
    margin-top: -2%;
    margin-bottom: -1%;
    margin-left: 10%;
    margin-right: 10%;
`;

export const Content = styled.div`
    width: 100%;
    max-width: 800px;
    color: black;
    justify-content: center;
    width: 100%;
`;

export const Background = styled.div`
    //Ocupa todo o espaço menos os 700px
    flex: 1;
    background: url(${cadastroClienteImg}) top;
    background-size: cover;
    height: 100%;
`;

const appearFromLeft = keyframes`
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        transform: translateX(0px);
    }
`;

export const AnimationContainer = styled.div`
    animation: ${appearFromLeft} 2s;

    form {
        margin: 20px 0;
        width: 95%;
        text-align: center;

        h2 {
            margin-bottom: 24px;
            color: #000000;
        }

        h5 {
            font-size: medium;
            margin-bottom: 30px;
            text-align: center;
            margin-right: 5px;
            color: #000000;
            text-decoration: none;
            transition: color 0.2s;
            &:hover {
                color: ${shade(0.2, "#000000")};
            }
        }

        h6 {
            display: block;
            text-align: left;
            margin-right: 5px;
            color: #000000;
            text-decoration: none;
            transition: color 0.2s;
            &:hover {
                color: ${shade(0.2, "#000000")};
            }
        }

        div {
            color: #000000;
        }

        input {
            color: #000000;
        }
    }
`;
