import styled, { keyframes } from "styled-components";

import { shade } from "polished";
import signInBackgroundImg from "../../assets/login.png";

export const Container = styled.div`
    height: 100vh;
    display: flex;
    align-items: stretch;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    // Com isso, essa parte nunca vai ter mais que 700px
    // e a imagem sempre irá ter o tamanho restante disponível.
    max-width: 700px;
`;

export const Background = styled.div`
    flex: 1;
    background: url(${signInBackgroundImg}) top;
    background-size: cover;
    height: 100%;
`;

const appearFromLeft = keyframes`
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        transform: translateX(0px);
    }
`;

export const AnimationContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: ${appearFromLeft} 2s;

    h5 {
        color: #000000;
    }
    form {
        margin-top: 5px;
        width: 340px;
        text-align: center;
        a {
            font-size: small;
            display: block;
            margin-top: 15px;
            text-align: right;
            margin-right: 5px;
            color: #00d1e6;
            text-decoration: none;
            transition: color 0.2s;
            &:hover {
                color: ${shade(0.2, "#00d1e6")};
            }
        }

        h6 {
            color: #000000;
        }
    }
    > a {
        display: flex;
        align-items: center;
        margin-top: 5px;
        color: #00d1e6;
        text-decoration: none;
        transition: color 0.2s;
        svg {
            margin-right: 16px;
        }
        &:hover {
            color: ${shade(0.2, "#00d1e6")};
        }
    }
`;
