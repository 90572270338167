/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useCallback } from "react";

import { Link, useHistory, Redirect } from "react-router-dom";
import { Form } from "@unform/web";
import FormReact from "react-bootstrap/Form";
import moment from "moment";
import { FormHandles } from "@unform/core";
import "react-toastify/dist/ReactToastify.css";
import {
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBCol,
    MDBRow,
} from "mdb-react-ui-kit";
import { FiCalendar, FiSearch, FiX } from "react-icons/fi";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";

import { Nav, Navbar, NavDropdown, Row, Col } from "react-bootstrap";

import { CSVLink } from "react-csv";
import imgLogo from "../../../assets/Logo.png";

import api from "../../../services/api";

import { useAuth } from "../../../hooks/auth";
import ButtonAzulEscuro from "../../../components/ButtonAzulEscuro";

import { Container, Content } from "./styles";
import ButtonRoxo from "../../../components/ButtonRoxo";
import InputPesquisa from "../../../components/InputPesquisa";
import BorderSelect from "../../../components/BorderSelect";

interface Cliente {
    nome: string;
}

interface Assinatura {
    id: string;
    name: string;
    code: string;
    status: string;
    senderName: string;
    senderEmail: string;
    created_at: string;
    updated_at: string;
    cliente: Cliente;
}

interface FiltroData {
    dataInicio: string;
    dataFim: string;
}

const Assinaturas: React.FC = () => {
    const { cliente } = useAuth();
    const history = useHistory();
    const formRef = useRef<FormHandles>(null);

    const [assinaturas, setAssinaturas] = useState<Assinatura[]>([]);
    const [assinaturasFixa, setAssinaturasFixa] = useState<Assinatura[]>([]);
    const [hiddenFiltroData, setHiddenFiltroData] = useState(true);
    const [hiddenFiltroStatus, setHiddenFiltroStatus] = useState(true);

    const status = [
        { id: "3", filtro: "ACTIVE" },
        { id: "7", filtro: "CANCELLED_BY_RECEIVER" },
        { id: "8", filtro: "CANCELLED_BY_SENDER" },
        { id: "1", filtro: "INITIATED" },
        { id: "2", filtro: "PENDING" },
        { id: "4", filtro: "PAYMENT_METHOD_CHANGE" },
        { id: "5", filtro: "SUSPENDED" },
        { id: "6", filtro: "CANCELLED" },
        { id: "9", filtro: "EXPIRED" },
    ];

    const [selectedNomeFiltro, setSelectedNomeFiltro] = useState<string>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChangeFiltro = (e: any) => {
        setSelectedNomeFiltro(e.filtro);

        setAssinaturas(
            assinaturasFixa.filter(saida =>
                saida.status.includes(`${e.filtro}`),
            ),
        );

        /*
        if (e.filtro === "Clientes Ativos") {
            api.get(`/clientes/ativos`).then(response =>
                setClientes(response.data),
            );
        }
        if (e.filtro === "Clientes Inativos") {
            api.get(`/clientes/inativos`).then(response =>
                setClientes(response.data),
            );
        }
        */
    };

    const handleSubmitFiltro = useCallback(async (data: FiltroData) => {
        try {
            const { dataInicio, dataFim } = data;

            const formData = {
                dataInicio,
                dataFim,
            };

            await api.post("/assinaturas/data", formData).then(response => {
                setAssinaturas(response.data);
            });
        } catch (err) {
            toast.error("Erro no servidor! Tente novamente!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        }
    }, []);

    useEffect(() => {
        api.get("/assinaturas").then(response => {
            setAssinaturas(response.data);
            setAssinaturasFixa(response.data);
        });
    }, []);

    // Redirecionamentos de páginas privadas
    if (cliente.controleFluxo !== "IS_ADMIN") {
        return <Redirect to="/" />;
    }

    return (
        <Container>
            <Navbar
                collapseOnSelect
                expand="lg"
                style={{
                    background: "#ffffff",
                }}
            >
                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    style={{ border: "none" }}
                />
                <Navbar.Brand style={{ marginLeft: "2%" }}>
                    <Link to="/">
                        <img width="125px" src={imgLogo} alt="WeTextil" />
                    </Link>
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto text-center" />
                    <Nav>
                        <Nav.Link
                            href="/dashboardMapeamentoMercado"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Mapeie Mercado
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardFabricacaoMalha"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Fabrique Malha
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardPesquisa"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Pesquisas
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardTabelaWeTextil"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Tabela WeTêxtil
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardClientes"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Clientes
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardCategorias"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Categorias
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardAssinaturas"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#846cf9",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                <strong>Assinaturas</strong>
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardProdutos"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Produtos/Serviços
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardOutros"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Outros
                            </span>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Content>
                <ToastContainer />

                <MDBRow style={{ marginBottom: 20, marginTop: -20 }} end>
                    <MDBCol md="2">
                        <ButtonAzulEscuro
                            style={{ height: 50 }}
                            onClick={() => {
                                setHiddenFiltroData(false);
                                setHiddenFiltroStatus(true);
                            }}
                        >
                            Filtro Data
                        </ButtonAzulEscuro>
                    </MDBCol>
                    <MDBCol md="2">
                        <ButtonAzulEscuro
                            style={{ height: 50 }}
                            onClick={() => {
                                setHiddenFiltroStatus(false);
                                setHiddenFiltroData(true);
                            }}
                        >
                            Filtro Status
                        </ButtonAzulEscuro>
                    </MDBCol>
                </MDBRow>

                <Form ref={formRef} onSubmit={handleSubmitFiltro}>
                    <MDBRow
                        style={{ marginBottom: 20 }}
                        hidden={hiddenFiltroData}
                    >
                        <MDBCol md="1" style={{ marginTop: 25 }}>
                            <h6>Data Início:</h6>
                        </MDBCol>
                        <MDBCol md="3" style={{ marginTop: 18 }}>
                            <InputPesquisa
                                icon={FiCalendar}
                                placeholder="dd/mm/aa"
                                name="dataInicio"
                                type="text"
                            />
                        </MDBCol>
                        <MDBCol md="1" style={{ marginTop: 25 }}>
                            <h6>Data Fim:</h6>
                        </MDBCol>
                        <MDBCol md="3" style={{ marginTop: 18 }}>
                            <InputPesquisa
                                icon={FiCalendar}
                                placeholder="dd/mm/aa"
                                name="dataFim"
                                type="text"
                            />
                        </MDBCol>
                        <MDBCol md="3" style={{ marginTop: 15 }}>
                            <ButtonRoxo
                                type="submit"
                                style={{ height: 40, marginTop: 0 }}
                            >
                                Confirmar
                            </ButtonRoxo>
                        </MDBCol>
                        <MDBCol md="1" style={{ marginTop: 15 }}>
                            <ButtonAzulEscuro
                                style={{
                                    height: 40,
                                    marginTop: 0,
                                    background: "white",
                                    color: "black",
                                }}
                                onClick={() => {
                                    setHiddenFiltroData(true);
                                    api.get("/assinaturas").then(response =>
                                        setAssinaturas(response.data),
                                    );
                                }}
                            >
                                <strong>
                                    <FiX />
                                </strong>
                            </ButtonAzulEscuro>
                        </MDBCol>
                    </MDBRow>
                </Form>

                <MDBRow
                    style={{ marginBottom: 20 }}
                    hidden={hiddenFiltroStatus}
                >
                    <MDBCol md="5" style={{ marginTop: 10 }}>
                        <Select
                            className="dropdown"
                            placeholder="Selecione o status"
                            onChange={handleChangeFiltro}
                            options={status}
                            getOptionLabel={option => option.filtro}
                            getOptionValue={option => option.id}
                            isSearchable={false}
                            maxMenuHeight={200}
                            theme={theme => ({
                                ...theme,
                                height: 100,
                                borderRadius: 10,
                            })}
                        />
                    </MDBCol>
                    <MDBCol md="1" style={{ marginTop: 10 }}>
                        <ButtonAzulEscuro
                            style={{
                                height: 40,
                                marginTop: 0,
                                background: "white",
                                color: "black",
                            }}
                            onClick={() => {
                                setHiddenFiltroStatus(true);
                                api.get("/assinaturas").then(response =>
                                    setAssinaturas(response.data),
                                );
                            }}
                        >
                            <strong>
                                <FiX />
                            </strong>
                        </ButtonAzulEscuro>
                    </MDBCol>
                </MDBRow>

                <MDBTable responsive bordered striped>
                    <MDBTableHead className="text-center" dark>
                        <tr>
                            <th>Plano</th>
                            <th>Codigo</th>
                            <th>Status</th>
                            <th>Cliente</th>
                            <th>Pagador</th>
                            <th>Email</th>
                            <th>Adesão</th>
                            <th>Atualização</th>
                        </tr>{" "}
                    </MDBTableHead>
                    <MDBTableBody>
                        {assinaturas.map(assinatura => (
                            <tr>
                                <td>{assinatura.name}</td>
                                <td>{assinatura.code}</td>
                                <td>{assinatura.status}</td>
                                <td>{assinatura.cliente.nome}</td>
                                <td>{assinatura.senderName}</td>
                                <td>{assinatura.senderEmail}</td>
                                <td>
                                    {moment(assinatura.created_at).format(
                                        "DD/MM/YYYY",
                                    )}
                                </td>
                                <td>
                                    {moment(assinatura.updated_at).format(
                                        "DD/MM/YYYY",
                                    )}
                                </td>
                            </tr>
                        ))}{" "}
                    </MDBTableBody>
                </MDBTable>
            </Content>
        </Container>
    );
};

export default Assinaturas;
