import styled from "styled-components";

import { shade } from "polished";

export const Container = styled.button`
    width: 40px;
    height: 40px;
    background: #ffffff;
    border-radius: 50px;
    border: 0;
    color: #2d2d2d;
    font-size: 16;
    margin-inline: 10px;
    margin-top: 5;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
        background: ${shade(0.2, "#ffffff")};
    }
`;
