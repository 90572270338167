import styled from "styled-components";
import { shade } from "polished";
import CapaHome from "../../assets/CapaHome.png";
import PopupConsultorUm from "../../assets/PopupConsultor1.png";
import PopupConsultorDois from "../../assets/PopupConsultor2.png";
import PopupConsultorTres from "../../assets/PopupConsultor3.png";

export const Container = styled.div``;

export const ContainerSearch = styled.div`
    border-left: 10%;
    border-right: 10%;
    width: 100%;
    background: url(${CapaHome}) no-repeat center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const PopupConsultor1 = styled.div`
    color: black;
    position: absolute;
    width: 95%;
    top: 30px;
    left: 20px;
    right: 10px;
    height: 90%;
    padding-right: 60px;
    background: url(${PopupConsultorUm}) no-repeat left;
    background-size: cover;
`;

export const PopupConsultor2 = styled.div`
    color: black;
    position: absolute;
    width: 95%;
    top: 30px;
    left: 20px;
    right: 10px;
    height: 90%;
    padding-right: 60px;
    background: url(${PopupConsultorDois}) no-repeat left;
    background-size: cover;
`;

export const PopupConsultor3 = styled.div`
    color: black;
    position: absolute;
    width: 95%;
    top: 30px;
    left: 20px;
    right: 10px;
    height: 90%;
    padding-right: 60px;
    background: url(${PopupConsultorTres}) no-repeat left;
    background-size: cover;
`;

export const CloseConsultores = styled.div`
    color: gray;
    position: absolute;
    top: 5px;
    right: 10px;
    padding-right: 20px;
    padding-left: 20px;
`;

export const TextoContainerSearch = styled.div`
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 7.5%;
    margin-bottom: 5%;

    h1,
    h4,
    h5,
    h6 {
        color: white;
    }
`;

export const Categorias = styled.div`
    width: 90%;
    margin-bottom: 20px;
    border-radius: 10px;
    border-width: 0.5px;
    margin-top: 15px;
    color: #2d2d2d;
    text-align: center;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const TextoCategorias = styled.div`
    width: 85%;
    height: 100%;
    margin-top: 50px;
    color: black;
    text-align: left;
    background-color: white;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const TituloComoFunciona = styled.div`
    width: 85%;
    height: 100%;
    margin-top: 30px;
    color: black;
    text-align: left;
    background-color: white;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const ComoFunciona = styled.div`
    width: 85%;
    height: 100%;
    margin-bottom: 20px;
    border-radius: 10px;
    margin-top: 20px;
    color: #2d2d2d;
    text-align: center;
    background-color: white;
    box-shadow: 0 0 10px #e8e8e8;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Quadrados = styled.div`
    width: 85%;
    height: 100%;
    border-radius: 10px;
    margin-top: 20px;
    color: #2d2d2d;
    text-align: left;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 50px;
`;

export const FundoAzul = styled.div`
    width: 100%;
    height: 100%;
    background-color: #00d1e6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Consultores = styled.div`
    width: 85%;
`;

export const FundoRoxo = styled.div`
    width: 100%;
    height: 100%;
    background-color: #846cf9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const FundoCinza = styled.div`
    width: 100%;
    height: 100%;
    background-color: #2d2d2d;
`;

export const ButtonMenu = styled.div`
    button {
        background: #846cf9;
        border-radius: 5px;
        color: #ffffff;
        border: 0;
        height: 38px;
        width: 100%;
        padding: 0 10px;
        font-weight: 500;

        &:hover {
            background: ${shade(0.2, "#846cf9")};
        }
    }
`;

export const ButtonCategoria = styled.div`
    span {
        background: #dee8ff;
        opacity: 0.8;
        border-radius: 20px;
        color: #839bff;
        border: 1;
        border-color: #839bff;
        height: 5vh;
        width: 28%;
        font-weight: 500;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }
`;

export const Content = styled.main`
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;
