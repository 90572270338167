/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback, useRef } from "react";

import { Link, useHistory, Redirect } from "react-router-dom";
import FormReact from "react-bootstrap/Form";
import { Form } from "@unform/web";
import moment from "moment";
import { Nav, Navbar, NavDropdown, Row, Col } from "react-bootstrap";

import "react-toastify/dist/ReactToastify.css";
import {
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBCol,
    MDBRow,
} from "mdb-react-ui-kit";

import * as Yup from "yup";
import { FormHandles } from "@unform/core";

import { FiArrowDown, FiArrowRight, FiSearch, FiX } from "react-icons/fi";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";

import { CSVLink } from "react-csv";
import { useAuth } from "../../../hooks/auth";
import Input from "../../../components/Input";
import api from "../../../services/api";
import imgLogo from "../../../assets/Logo.png";

import ButtonAzulEscuro from "../../../components/ButtonAzulEscuro";

import { Container, Content } from "./styles";

import getValidationErrors from "../../../utils/getValidationErrors";
import BorderSelect from "../../../components/BorderSelect";
import InputHome from "../../../components/InputHome";
import InputLogin from "../../../components/InputLogin";
import InputPesquisa from "../../../components/InputPesquisa";
import ButtonAzul from "../../../components/ButtonAzul";
import ButtonRoxo from "../../../components/ButtonRoxo";

interface FiltroFormData {
    filtro: string;
    busca: string;
}

interface StatusFormData {
    status: string;
}

interface MapeamentoMercado {
    id: string;
    nome: string;
    documento: string;
    email: string;
    telefone: string;
    descricao: string;
    status: string;
}

interface params {
    idCliente: string;
}

const MapeieMercado: React.FC = () => {
    const { cliente } = useAuth();
    const history = useHistory();
    const formRef = useRef<FormHandles>(null);

    const [mapeamentoMercado, setMapeamentoMercado] = useState<
        MapeamentoMercado[]
    >([]);

    useEffect(() => {
        api.get("/mapeamento").then(response =>
            setMapeamentoMercado(response.data),
        );
    }, []);

    const [hiddenFiltro, setHiddenFiltro] = useState(true);

    const filtros = [
        { id: "1", filtro: "Aguardando" },
        { id: "2", filtro: "Em andamento" },
        { id: "3", filtro: "Finalizado" },
    ];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const changeStatus = async (e: any) => {
        const formData = {
            status: e.target.value,
        };

        await api
            .put(`/mapeamento/${e.target.id}`, formData)
            .then(() => {
                toast.success(`Status alterado com sucesso!`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch(retorno => {
                toast.error(
                    `Erro ao alterar o status! ${
                        JSON.parse(retorno.request.responseText).message
                    }`,
                    {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                    },
                );
            });
    };

    const [selectedNomeFiltro, setSelectedNomeFiltro] = useState<string>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChangeFiltro = (e: any) => {
        setSelectedNomeFiltro(e.filtro);
        if (e.filtro === "Aguardando") {
            api.get(`/mapeamento`).then(response =>
                setMapeamentoMercado(
                    response.data.filter(
                        (saida: { status: string | string[] }) =>
                            saida.status.includes("aguardando"),
                    ),
                ),
            );
        }
        if (e.filtro === "Em andamento") {
            api.get(`/mapeamento`).then(response =>
                setMapeamentoMercado(
                    response.data.filter(
                        (saida: { status: string | string[] }) =>
                            saida.status.includes("em andamento"),
                    ),
                ),
            );
        }
        if (e.filtro === "Finalizado") {
            api.get(`/mapeamento`).then(response =>
                setMapeamentoMercado(
                    response.data.filter(
                        (saida: { status: string | string[] }) =>
                            saida.status.includes("finalizado"),
                    ),
                ),
            );
        }
    };

    const handleSubmitFiltro = useCallback(async (data: FiltroFormData) => {
        try {
            formRef.current?.setErrors({});
            const schema = Yup.object().shape({
                SelectFiltro: Yup.string().required("Campo obrigatório"),
            });

            await schema.validate(data, {
                // Faz com que o Yup não pare no primeiro erro. Por padrão é true.
                abortEarly: false,
            });

            // const { filtro, busca } = data;
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const errors = getValidationErrors(err);

                formRef.current?.setErrors(errors);
                return;
            }

            toast.error("Erro no servidor! Tente novamente!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        }
    }, []);

    // Redirecionamentos de páginas privadas
    if (cliente.controleFluxo !== "IS_ADMIN") {
        return <Redirect to="/" />;
    }

    return (
        <Container>
            <Navbar
                collapseOnSelect
                expand="lg"
                style={{
                    background: "#ffffff",
                }}
            >
                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    style={{ border: "none" }}
                />
                <Navbar.Brand style={{ marginLeft: "2%" }}>
                    <Link to="/">
                        <img width="125px" src={imgLogo} alt="WeTextil" />
                    </Link>
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto text-center" />
                    <Nav>
                        <Nav.Link
                            href="/dashboardMapeamentoMercado"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#846cf9",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                <strong>Mapeie Mercado</strong>
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardFabricacaoMalha"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Fabrique Malha
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardPesquisa"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Pesquisas
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardTabelaWeTextil"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Tabela WeTêxtil
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardClientes"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Clientes
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardCategorias"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Categorias
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardAssinaturas"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Assinaturas
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardProdutos"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Produtos/Serviços
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardOutros"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Outros
                            </span>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Content>
                <ToastContainer />
                <MDBRow style={{ marginBottom: 20, marginTop: -20 }} end>
                    <MDBCol md="2">
                        <ButtonAzulEscuro
                            style={{ height: 50 }}
                            onClick={() => {
                                setHiddenFiltro(false);
                            }}
                        >
                            Filtrar
                        </ButtonAzulEscuro>
                    </MDBCol>
                </MDBRow>

                <Form ref={formRef} onSubmit={handleSubmitFiltro}>
                    <MDBRow
                        style={{ marginBottom: 20 }}
                        hidden={hiddenFiltro}
                        end
                    >
                        <MDBCol md="4" style={{ marginTop: 10 }}>
                            <BorderSelect
                                name="SelectFiltro"
                                value={selectedNomeFiltro}
                                hidden
                            >
                                <Select
                                    className="dropdown"
                                    placeholder="Buscar por..."
                                    onChange={handleChangeFiltro}
                                    options={filtros}
                                    getOptionLabel={option => option.filtro}
                                    getOptionValue={option => option.id}
                                    isSearchable={false}
                                    maxMenuHeight={200}
                                    theme={theme => ({
                                        ...theme,
                                        height: 100,
                                        borderRadius: 10,
                                    })}
                                />
                            </BorderSelect>
                        </MDBCol>
                        <MDBCol md="1" style={{ marginTop: 15 }}>
                            <ButtonAzulEscuro
                                style={{
                                    height: 40,
                                    marginTop: 0,
                                    background: "white",
                                    color: "black",
                                }}
                                onClick={() => {
                                    setHiddenFiltro(true);
                                    api.get("/mapeamento").then(response =>
                                        setMapeamentoMercado(response.data),
                                    );
                                }}
                            >
                                <strong>
                                    <FiX />
                                </strong>
                            </ButtonAzulEscuro>
                        </MDBCol>
                    </MDBRow>
                </Form>

                <MDBTable responsive bordered striped>
                    <MDBTableHead className="text-center" dark>
                        <tr>
                            <th scope="col">Nome</th>
                            <th scope="col">Documento</th>
                            <th scope="col">Email</th>
                            <th scope="col">Telefone</th>
                            <th scope="col">Descrição</th>
                            <th scope="col">Status</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {mapeamentoMercado &&
                            mapeamentoMercado.map(mapeamento => (
                                <tr>
                                    <td>{mapeamento.nome}</td>
                                    <td>{mapeamento.documento}</td>
                                    <td>{mapeamento.email}</td>
                                    <td>{mapeamento.telefone}</td>
                                    <td>{mapeamento.descricao}</td>
                                    <td>
                                        <select
                                            name="status"
                                            id={mapeamento.id}
                                            onChange={changeStatus}
                                        >
                                            <option
                                                value="aguardando"
                                                selected={
                                                    mapeamento.status ===
                                                    "aguardando"
                                                }
                                            >
                                                Aguardando
                                            </option>
                                            <option
                                                value="em andamento"
                                                selected={
                                                    mapeamento.status ===
                                                    "em andamento"
                                                }
                                            >
                                                Em andamento
                                            </option>
                                            <option
                                                value="finalizado"
                                                selected={
                                                    mapeamento.status ===
                                                    "finalizado"
                                                }
                                            >
                                                Finalizado
                                            </option>
                                        </select>
                                    </td>
                                </tr>
                            ))}
                    </MDBTableBody>
                </MDBTable>
            </Content>
        </Container>
    );
};

export default MapeieMercado;
