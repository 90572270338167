/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
    FiUser,
    FiInstagram,
    FiFacebook,
    FiPackage,
    FiPower,
    FiLinkedin,
} from "react-icons/fi";
import { MDBRow, MDBCol, MDBContainer, MDBFooter } from "mdb-react-ui-kit";

import { Link, useHistory } from "react-router-dom";

import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import api from "../../services/api";

import { useAuth } from "../../hooks/auth";
import {
    Container,
    Content,
    ButtonMenu,
    FundoCinza,
    EspacoFundoCinza,
} from "./styles";

import imgLogo from "../../assets/Logo.png";
import imgNossoMovimento01 from "../../assets/nossoMovimento01.png";
import imgNossoMovimento02 from "../../assets/nossoMovimento02.png";
import imgLogoBranca from "../../assets/LogoBranca.png";
import ButtonIcon from "../../components/ButtonIcon";

interface Categoria {
    id: string;
    nome: string;
    idCategoriaPai: string;
}

interface Outros {
    id: string;
    chave: string;
    valor: string;
}
const NossoMovimento: React.FC = () => {
    const { cliente, signOut } = useAuth();
    const history = useHistory();
    const [outros, setOutros] = useState<Outros[]>([]);

    const [categorias, setCategorias] = useState<Categoria[]>([]);

    useEffect(() => {
        api.get("/categorias/categorias").then(response =>
            setCategorias(response.data),
        );
        api.get("/controle").then(response => setOutros(response.data));
    }, []);

    const clickCategoria = (nomeCategoria: string) => {
        if (cliente) {
            const insertPesquisa = {
                idCliente: cliente.id,
                nomeCliente: cliente.nome,
                pesquisa: nomeCategoria,
            };

            api.post("/pesquisa", insertPesquisa);
        } else {
            const insertPesquisa = {
                pesquisa: nomeCategoria,
            };

            api.post("/pesquisa", insertPesquisa);
        }
        localStorage.setItem("@dbwetextil:nomeCategoria", nomeCategoria);
        history.push("/pesquisa");
    };

    return (
        <Container>
            <Navbar
                collapseOnSelect
                expand="lg"
                style={{
                    background: "#ffffff",
                }}
            >
                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    style={{ border: "none" }}
                />
                <Navbar.Brand style={{ marginLeft: "2%" }}>
                    <Link to="/">
                        <img width="125px" src={imgLogo} alt="WeTextil" />
                    </Link>
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto text-center" />
                    <Nav>
                        <Nav.Link
                            href="/"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                início
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/nossoMovimento"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                sobre
                            </span>
                        </Nav.Link>

                        <Nav.Link
                            href="/planos"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                planos
                            </span>
                        </Nav.Link>

                        <Nav.Link
                            href="#"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#CAC9C8",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                fabrique sua malha
                            </span>
                        </Nav.Link>

                        <Nav.Link
                            href="#"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#CAC9C8",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                mapeie seu mercado
                            </span>
                        </Nav.Link>

                        <NavDropdown
                            title="categorias"
                            id="basic-nav-dropdown"
                            style={{
                                padding: "0px 20px 0px 20px",
                                textAlign: "center",
                                marginTop: 8,
                                fontWeight: "bold",
                                color: "#2d2d2d",
                            }}
                        >
                            {categorias.map(categoria => (
                                <NavDropdown.Item
                                    onClick={
                                        () => clickCategoria(categoria.nome)
                                        // eslint-disable-next-line react/jsx-curly-newline
                                    }
                                >
                                    {categoria.nome}
                                </NavDropdown.Item>
                            ))}
                        </NavDropdown>
                        {!cliente && (
                            <Nav.Link href="/cliente">
                                <ButtonMenu>
                                    <button type="button">
                                        <span
                                            style={{
                                                padding: "0px 20px 0px 20px",
                                            }}
                                        >
                                            cadastre-se
                                        </span>
                                    </button>
                                </ButtonMenu>
                            </Nav.Link>
                        )}
                        {!cliente && (
                            <Nav.Link
                                href="/login"
                                style={{
                                    textAlign: "center",
                                    marginTop: 8,
                                    color: "#2d2d2d",
                                }}
                            >
                                <span style={{ padding: "0px 20px 0px 30px" }}>
                                    login
                                </span>
                            </Nav.Link>
                        )}

                        {cliente && cliente?.controleFluxo !== "IS_ADMIN" && (
                            <Nav.Link
                                href="/minhaVitrine"
                                style={{
                                    textAlign: "center",
                                    marginTop: 8,
                                    color: "#2d2d2d",
                                }}
                            >
                                <span style={{ padding: "0px 20px 0px 20px" }}>
                                    minha vitrine
                                </span>
                            </Nav.Link>
                        )}

                        {cliente && cliente?.controleFluxo !== "IS_ADMIN" && (
                            <NavDropdown
                                title="meu perfil"
                                id="basic-nav-dropdown"
                                style={{
                                    padding: "0px 30px 0px 20px",
                                    textAlign: "center",
                                    marginTop: 8,
                                    fontWeight: "bold",
                                    color: "#2d2d2d",
                                }}
                            >
                                <NavDropdown.Item href="/minhaConta">
                                    minha conta
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={signOut}>
                                    sair
                                </NavDropdown.Item>
                            </NavDropdown>
                        )}

                        {cliente && cliente?.controleFluxo === "IS_ADMIN" && (
                            <Nav.Link href="/dashboardClientes">
                                <ButtonMenu>
                                    <button type="button" disabled>
                                        <span
                                            style={{
                                                padding: "0px 20px 0px 10px",
                                            }}
                                        >
                                            Dashboard
                                        </span>
                                    </button>
                                </ButtonMenu>
                            </Nav.Link>
                        )}

                        {cliente && cliente?.controleFluxo === "IS_ADMIN" && (
                            <Nav.Link
                                onClick={signOut}
                                style={{
                                    textAlign: "center",
                                    marginTop: 10,
                                    color: "#2d2d2d",
                                }}
                            >
                                <span style={{ padding: "0px 30px 0px 20px" }}>
                                    sair
                                </span>
                            </Nav.Link>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <Content>
                <MDBRow>
                    <MDBCol
                        md="6"
                        className="col-example"
                        style={{
                            color: "#00d1e6",
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            backgroundColor: "#ffffff",
                        }}
                    >
                        <strong
                            className="text-left"
                            style={{ color: "#000000" }}
                        >
                            Seja bem-vindo à WeTêxtil
                        </strong>
                        <span style={{ fontSize: 40 }}>
                            Somos a <strong>maior comunidade têxtil</strong> do
                            Brasil
                        </span>
                    </MDBCol>

                    <MDBCol
                        md="6"
                        className="col-example"
                        style={{
                            color: "#5A5F7A",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            justifyContent: "center",
                            backgroundColor: "#ffffff",
                        }}
                    >
                        <img
                            width="60%"
                            src={imgNossoMovimento01}
                            alt="WeTextil"
                        />
                    </MDBCol>
                </MDBRow>

                <MDBRow
                    className="row-cols-1 row-cols-md-2 g-2"
                    style={{ marginTop: "8%", marginBottom: "4%" }}
                >
                    <MDBCol
                        md="4"
                        className="col-example text-left"
                        style={{ color: "#5A5F7A" }}
                    >
                        <h1>Conheça o propósito da WeTêxtil</h1>
                    </MDBCol>
                    <MDBCol
                        md="8"
                        className="col-example text-justify"
                        style={{ color: "#000000" }}
                    >
                        <strong>NOSSO MOVIMENTO</strong>
                        <br />
                        <br />
                        <p>
                            Tudo começou com uma <strong>inquietação</strong>.
                            Uma <strong>agitação interna</strong> que serviu
                            como um lembrete de que o segmento têxtil não fazia
                            “apenas” parte das nossas vidas: ele é e tem sido,
                            há décadas, a fonte de sustento de nossas famílias.
                        </p>
                        <br />
                        <p>
                            Dessa <strong>sensação</strong>, surgiu o{" "}
                            <strong>impulso necessário</strong> para criarmos
                            algo novo, que pudesse{" "}
                            <strong>
                                ligar todas as pontas da cadeia têxtil{" "}
                            </strong>
                            e que, finalmente, <strong>introduzisse</strong>{" "}
                            esse <strong>segmento</strong> no{" "}
                            <strong>mercado digital</strong>.
                        </p>
                        <br />
                        <p>
                            Do impulso, surgiu a <strong>mobilização</strong>.
                            Nos movemos na direção daqueles que, assim como nós,
                            precisavam continuar empreendendo de forma
                            competitiva.
                        </p>
                        <br />
                        <p>
                            Desse <strong>encontro de ideias</strong> e de{" "}
                            <strong>propósitos</strong> surgiu a{" "}
                            <strong>WETÊXTIL TECIDOS</strong>.
                        </p>
                        <br />
                        <p>
                            <strong>Nosso movimento</strong> vai além do que se
                            espera de uma plataforma digital.
                        </p>
                        <br />
                        <p>
                            <strong>Nosso movimento</strong> é para que a
                            WETÊXTIL TECIDOS seja
                            <strong>um PORTAL</strong> onde cada membro da
                            comunidade <strong>consiga</strong>:
                        </p>
                        <br />
                        <p>
                            • Encontrar fornecedores de forma ágil
                            <br />
                            • Encontrar novos clientes
                            <br />
                            • Divulgar sua empresa
                            <br />• Expandir seus negócios
                            <br />
                            • Aumentar seu mix de produtos
                            <br />• Adquirir conhecimento
                            <br />
                            • Divulgar e contratar serviços
                            <br />• Tornar sua marca conhecida por meio de
                            estratégias de marketing digital
                            <br />• Ampliar sua rede de contatos.
                        </p>
                        <br />
                        <p>
                            Tudo isso em um{" "}
                            <strong>
                                AMBIENTE EXCLUSIVO, formado apenas por
                                EMPREENDEDORES DO MERCADO TÊXTIL, com o objetivo
                                único
                            </strong>{" "}
                            de <strong>GERAR DE MATCHS DE NEGÓCIOS!</strong>
                        </p>
                    </MDBCol>
                </MDBRow>

                <div
                    className="bg-light"
                    style={{
                        backgroundSize: "cover",
                        width: "100%",
                        height: "70vh",
                        backgroundImage: `url(${imgNossoMovimento02})`,
                        borderRadius: "10px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}
                >
                    <MDBRow>
                        <MDBCol
                            sm="5"
                            className="col-example"
                            style={{
                                marginLeft: 15,
                                marginRight: 15,
                            }}
                        >
                            <h2>
                                Cadastre-se em nossa comunidade e conecte-se com
                                o movimento.
                            </h2>
                        </MDBCol>
                    </MDBRow>
                    {!cliente && (
                        <MDBRow>
                            <MDBCol
                                sm="3"
                                className="col-example"
                                style={{
                                    marginLeft: 15,
                                    marginRight: 15,
                                }}
                            >
                                <ButtonMenu
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <Link to="/cliente">
                                        <button type="button">
                                            Faça agora seu cadastro!
                                        </button>
                                    </Link>
                                </ButtonMenu>
                            </MDBCol>
                        </MDBRow>
                    )}
                </div>
            </Content>
            <EspacoFundoCinza>
                <FundoCinza>
                    <MDBRow
                        style={{
                            marginLeft: "1%",
                            marginRight: "3%",
                            marginBottom: "10%",
                        }}
                    >
                        <MDBCol
                            md="1"
                            className="text-center align-items-center"
                            style={{
                                marginTop: "2%",
                                marginBottom: "2%",
                                alignItems: "center",
                            }}
                        >
                            <img
                                width="100px"
                                src={imgLogoBranca}
                                alt="WeTextil"
                            />
                        </MDBCol>
                        <MDBCol
                            md="6"
                            style={{ marginTop: "2%", marginBottom: "2%" }}
                        >
                            <MDBRow className="row-cols-1 row-cols-md-1 g-1 text-center">
                                <strong>CATEGORIAS</strong>
                            </MDBRow>
                            <MDBRow className="row-cols-1 row-cols-md-3 g-3 text-center">
                                {categorias.map(categoria => (
                                    <MDBCol
                                        key={categoria.id}
                                        onClick={
                                            () => clickCategoria(categoria.nome)
                                            // eslint-disable-next-line react/jsx-curly-newline
                                        }
                                        style={{ cursor: "pointer" }}
                                    >
                                        <small>{categoria.nome}⠀</small>
                                    </MDBCol>
                                ))}
                            </MDBRow>
                        </MDBCol>
                        <MDBCol
                            md="3"
                            style={{ marginTop: "2%", marginBottom: "2%" }}
                        >
                            <MDBRow className="row-cols-1 row-cols-md-1 g-1 text-center">
                                <strong>AJUDA</strong>
                            </MDBRow>
                            <MDBRow
                                className="row-cols-1 row-cols-md-1 g-1 text-center"
                                style={{ marginTop: "2%" }}
                            >
                                <small>16 99787-1671</small>
                            </MDBRow>
                            <MDBRow
                                className="row-cols-1 row-cols-md-1 g-1 text-center"
                                style={{ marginTop: "1%" }}
                            >
                                <small>contato@wetextil.com</small>
                            </MDBRow>
                            <MDBRow
                                className="row-cols-1 row-cols-md-1 g-1 text-center"
                                style={{ marginTop: "1%" }}
                            >
                                <small>
                                    <a
                                        href="/termosDeUso"
                                        target="_blank"
                                        style={{ color: "white" }}
                                    >
                                        Política de Privacidade e Termos de Uso
                                    </a>
                                </small>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol
                            md="2"
                            style={{ marginTop: "2%", marginBottom: "2%" }}
                        >
                            <MDBRow className="row-cols-1 row-cols-md-1 g-1 text-center">
                                <strong>REDES SOCIAIS</strong>
                            </MDBRow>
                            <MDBRow
                                style={{
                                    marginTop: "4%",
                                    marginBottom: "2%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Link
                                    to={{
                                        pathname: `${
                                            outros.find(
                                                saida =>
                                                    saida.chave ===
                                                    "linkFacebook",
                                            )?.valor
                                        }`,
                                    }}
                                    target="_blank"
                                >
                                    <ButtonIcon type="submit">
                                        <FiFacebook />
                                    </ButtonIcon>
                                </Link>
                                <Link
                                    to={{
                                        pathname: `${
                                            outros.find(
                                                saida =>
                                                    saida.chave ===
                                                    "linkInstagram",
                                            )?.valor
                                        }`,
                                    }}
                                    target="_blank"
                                >
                                    <ButtonIcon type="submit">
                                        <FiInstagram />
                                    </ButtonIcon>
                                </Link>
                                <Link
                                    to={{
                                        pathname: `${
                                            outros.find(
                                                saida =>
                                                    saida.chave ===
                                                    "linkLinkedin",
                                            )?.valor
                                        }`,
                                    }}
                                    target="_blank"
                                >
                                    <ButtonIcon type="submit">
                                        <FiLinkedin />
                                    </ButtonIcon>
                                </Link>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow
                        style={{
                            marginLeft: "1%",
                            marginRight: "3%",
                        }}
                    >
                        <MDBCol
                            md="12"
                            className="text-center align-items-center"
                            style={{
                                marginTop: "2%",
                                marginBottom: "2%",
                                alignItems: "center",
                            }}
                        >
                            Copyright ©️ 2021 WeTêxtil. All trademarks and logos
                            are the property of their respective owners. All
                            rights reserved.
                        </MDBCol>
                    </MDBRow>
                </FundoCinza>
            </EspacoFundoCinza>
        </Container>
    );
};

export default NossoMovimento;
