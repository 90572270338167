import styled from "styled-components";

import { shade } from "polished";

export const Container = styled.div``;

export const Content = styled.main`
    padding: 1% 2% 0% 2%;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Schedule = styled.div`
    flex: 1;
    align-items: center;
`;

export const ButtonMenu = styled.div`
    button {
        background: #846cf9;
        border-radius: 5px;
        color: #ffffff;
        border: 0;
        height: 38px;
        width: 100%;
        padding: 0 10px;
        font-weight: 500;

        &:hover {
            background: ${shade(0.2, "#846cf9")};
        }
    }
`;

export const ContentSemProduto = styled.main`
    padding: 1% 4%;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    height: 85vh;

    display: flex;
    align-items: stretch;
`;

export const SemProduto = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    h4 {
        margin-top: 30px;
        margin-bottom: 20px;
        color: #000000;
    }
    h6 {
        margin-bottom: 20px;
        color: #000000;
    }
`;
