import styled from "styled-components";

export const Container = styled.div`
    height: 100vh;
    display: flex;
    align-items: stretch;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 2% 0 2%;
    width: 100%;
    color: black;
    text-align: center;
    margin-top: 5%;
`;
