import styled from "styled-components";

export const Container = styled.div``;

export const Content = styled.main`
    width: 100%;
    // text-align: center;
    color: black;
    padding: 2% 2% 2% 2%;
    margin-top: 1%;
    margin-bottom: 2%;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
`;

export const AvatarInput = styled.div`
    img {
        width: 200px;
        height: 200px;
        border-radius: 50%;
    }
`;

export const ImagemInput = styled.div`
    img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }
`;
