/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useRef, useState, useEffect } from "react";
import { Form } from "@unform/web";
// eslint-disable-next-line no-unused-vars
import { FormHandles } from "@unform/core";
// import axios from "axios";
import {
    FiPhoneCall,
    FiKey,
    FiMail,
    FiLock,
    FiUser,
    FiPlusCircle,
    FiPlus,
    FiType,
} from "react-icons/fi";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import * as Yup from "yup";
import "../../components/ProgressBar/styles.css";

import { Link, useHistory } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";

import FormReact from "react-bootstrap/Form";

import Select from "react-select";

import { cnpj, cpf } from "cpf-cnpj-validator";
import { ToastContainer, toast } from "react-toastify";
import api from "../../services/api";

import logoImg from "../../assets/Logo.png";

import "react-toastify/dist/ReactToastify.css";

import getValidationErrors from "../../utils/getValidationErrors";
import {
    Container,
    Content,
    AnimationContainer,
    DadosCadastrais,
    ConteudoDadosCadastrais,
    Geral,
    Background,
} from "./styles";
import Input from "../../components/Input";
import Inputmask from "../../components/Inputmask";
import BorderSelect from "../../components/BorderSelect";
import ButtonAzul from "../../components/ButtonAzul";
import ButtonCinza from "../../components/ButtonCinza";
import Textarea from "../../components/Textarea";
import ButtonAzulEscuro from "../../components/ButtonAzulEscuro";

interface CadastroClienteFormData {
    isPessoaJuridica: string;
    nome: string;
    docIdent: string;
    celular: string;
    email: string;
    senha: string;
    confirmaSenha: string;
    cidade: string;
    estado: string;
    biografia: string;
    vetorAuxCliente: Categoria[];
}

interface Categoria {
    id: string;
    nome: string;
    idCategoriaPai: string;
}

interface Estado {
    nome: string;
    sigla: string;
}

interface Cidade {
    id: string;
    nome: string;
}

// Declarando Nobr que evita quebrar linha
declare global {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace JSX {
        interface IntrinsicElements {
            nobr: React.DetailedHTMLProps<
                React.HTMLAttributes<HTMLElement>,
                HTMLElement
            >;
        }
    }
}

const CadastroCliente: React.FC = () => {
    const formRef = useRef<FormHandles>(null);
    const history = useHistory();

    const [notShowFirstSub, setNotShowFirstSub] = useState<boolean>(true);
    const [notShowSecondSub, setNotShowSecondSub] = useState<boolean>(true);
    const [notShowCidade, setNotShowCidade] = useState<boolean>(true);

    const [estados, setEstados] = useState<Estado[]>([]);
    useEffect(() => {
        api.get("/estados").then(response => setEstados(response.data));
    }, []);

    const [selectEstado, setSelectEstado] = useState([]);
    const [idSelectEstado, setIdSelectEstado] = useState<string>("");
    const handleChangeEstado = (e: any) => {
        setIdSelectEstado(e.id);
        setSelectEstado(e.sigla);
        setNotShowCidade(false);
    };

    const [cidades, setCidades] = useState<Cidade[]>([]);
    useEffect(() => {
        api.get(`/cidades/${selectEstado}`).then(response =>
            setCidades(response.data),
        );
    }, [selectEstado]);

    const [selectCidade, setSelectCidade] = useState([]);
    const [idSelectCidade, setIdSelectCidade] = useState<string>("");
    const handleChangeCidade = (e: any) => {
        setIdSelectCidade(e.id);
        setSelectCidade(e.nome);
    };

    const [categorias, setCategorias] = useState<Categoria[]>([]);

    useEffect(() => {
        api.get("/categorias/categorias").then(response =>
            setCategorias(response.data),
        );
    }, []);

    const [selectedIdCategoria, setSelectedIdCategoria] = useState([]);

    const [selectedValueCategoria, setSelectedValueCategoria] = useState([]);
    const handleChangeCategoria = (e: any) => {
        setSelectedValueCategoria(e);
        setSelectedIdCategoria(e.id);
        setNotShowFirstSub(false);
    };

    const [subCategorias, setSubCategorias] = useState<Categoria[]>([]);
    useEffect(() => {
        api.get(`/categorias/subCategorias/${selectedIdCategoria}`).then(
            response => setSubCategorias(response.data),
        );
    }, [selectedIdCategoria]);

    const [selectedValueSubCategoria, setSelectedValueSubCategoria] = useState(
        [],
    );
    const handleChangeSubCategoria = (e: any) => {
        setSelectedValueSubCategoria(e.map((x: any) => x));
    };

    const [categoriasSecundaria, setCategoriasSecundaria] = useState<
        Categoria[]
    >([]);

    useEffect(() => {
        api.get("/categorias/categorias").then(response =>
            setCategoriasSecundaria(response.data),
        );
    }, []);

    const [selectedIdCategoriaSecundaria, setSelectedIdCategoriaSecundaria] =
        useState([]);

    const [
        selectedValueCategoriaSecundaria,
        setSelectedValueCategoriaSecundaria,
    ] = useState([]);
    const handleChangeCategoriaSecundaria = (e: any) => {
        setSelectedValueCategoriaSecundaria(e);
        setSelectedIdCategoriaSecundaria(e.id);
        setNotShowSecondSub(false);
    };

    const [subCategoriasSecundaria, setSubCategoriasSecundaria] = useState<
        Categoria[]
    >([]);

    useEffect(() => {
        api.get(
            `/categorias/subCategorias/${selectedIdCategoriaSecundaria}`,
        ).then(response => setSubCategoriasSecundaria(response.data));
    }, [selectedIdCategoriaSecundaria]);

    const [
        selectedValueSubCategoriaSecundaria,
        setSelectedValueSubCategoriaSecundaria,
    ] = useState([]);
    const handleChangeSubCategoriaSecundaria = (e: any) => {
        setSelectedValueSubCategoriaSecundaria(e.map((x: any) => x));
    };

    const [isPessoaJuridica, setSelectedValuePessoa] = useState<string>("");
    const handleClickPessoa = (e: any) => {
        setSelectedValuePessoa(e.target.value);
    };

    const [termosDeUso, setTermosDeUso] = useState<string>("");
    const handleTermosDeUso = (e: any) => {
        setTermosDeUso(e.target.value);
    };

    const [news, setNews] = useState<string>("false");
    const handleNews = (e: any) => {
        if (news === "true") {
            setNews("false");
        } else {
            setNews(e.target.value);
        }
    };

    const [buttonInsertCategoria, setButtonInsertCategoria] =
        useState<string>();
    const handleClickButtonInsertCategoria = () => {
        setButtonInsertCategoria("Clicado");
    };

    const [insertCategoria, setInsertCategoria] = useState([]);

    useEffect(() => {
        setInsertCategoria(
            selectedValueSubCategoria.concat(
                selectedValueCategoria,
                selectedValueCategoriaSecundaria,
                selectedValueSubCategoriaSecundaria,
            ),
        );
    }, [
        selectedValueCategoria,
        selectedValueSubCategoria,
        selectedValueCategoriaSecundaria,
        selectedValueSubCategoriaSecundaria,
    ]);
    const handleSubmit = useCallback(
        async (data: CadastroClienteFormData) => {
            try {
                formRef.current?.setErrors({});
                const schema = Yup.object().shape({
                    isTipoPessoa: Yup.string().required("Campo obrigatório"),
                    nome: Yup.string().required("Campo obrigatório"),
                    biografia: Yup.string().required("Campo obrigatório"),
                    docIdent: Yup.string().test(
                        "docIdent",
                        "CPF ou CNPJ invalido",
                        value => {
                            if (value) {
                                if (isPessoaJuridica === "true") {
                                    if (cnpj.isValid(value)) {
                                        return true;
                                    }
                                    return false;
                                }
                                if (cpf.isValid(value)) {
                                    return true;
                                }
                                return false;
                            }
                            return false;
                        },
                    ),
                    celular: Yup.string()
                        .required("Campo obrigatório")
                        .length(15, "Campo obrigatório"),
                    email: Yup.string()
                        .required("Campo obrigatório")
                        .email("Digite um e-mail válido"),
                    senha: Yup.string().min(8, "Mínimo de 8 dígitos"),
                    confirmaSenha: Yup.string().oneOf(
                        [Yup.ref("senha"), null],
                        "As senhas devem ser iguais",
                    ),
                    SelectEstado: Yup.string().required("Campo obrigatório"),
                    SelectCidade: Yup.string().required("Campo obrigatório"),
                    SelectCategoria: Yup.string().required("Campo obrigatório"),
                    termosDeUso: Yup.string().required("Campo obrigatório"),
                    news: Yup.string(),
                });

                await schema.validate(data, {
                    // Faz com que o Yup não pare no primeiro erro. Por padrão é true.
                    abortEarly: false,
                });

                const { nome, celular, docIdent, biografia, email, senha } =
                    data;

                const celularSemMask = celular
                    .replace(/\)/g, "")
                    .replace(/\(/g, "")
                    .replace(/-/g, "")
                    .replace(/ /g, "");

                const formData = {
                    isPessoaJuridica,
                    news,
                    nome,
                    docIdent,
                    biografia,
                    celular: celularSemMask,
                    email,
                    senha,
                    idCidade: idSelectCidade,
                    idEstado: idSelectEstado,
                    vetorAuxCliente: insertCategoria,
                };

                await api
                    .post("/clientes", formData)
                    .then(retorno => {
                        localStorage.setItem(
                            "@dbwetextil:idCadastroCliente",
                            retorno.data.id,
                        );

                        history.push(`/planosPagamento/${retorno.data.id}`);
                    })
                    .catch(retorno => {
                        toast.error(
                            `Erro no cadastro! ${
                                JSON.parse(retorno.request.responseText).message
                            }`,
                            {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: true,
                                progress: undefined,
                            },
                        );
                    });
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);
                    return;
                }

                toast.error("Erro no servidor! Tente novamente!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            }
        },
        [
            isPessoaJuridica,
            news,
            idSelectCidade,
            idSelectEstado,
            insertCategoria,
            history,
        ],
    );

    return (
        <Container>
            <Background />
            <Content>
                <ToastContainer />
                <AnimationContainer>
                    <Geral>
                        <h3>
                            <strong>Preencha os seus dados</strong>
                        </h3>
                        <DadosCadastrais>
                            <ConteudoDadosCadastrais>
                                <Form ref={formRef} onSubmit={handleSubmit}>
                                    <MDBRow>
                                        <MDBCol md="8">
                                            <BorderSelect
                                                name="isTipoPessoa"
                                                value={isPessoaJuridica}
                                                hidden
                                            >
                                                <h6
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    <FormReact.Check
                                                        inline
                                                        label="Pessoa Física"
                                                        name="isPessoaJuridica"
                                                        type="radio"
                                                        id="false"
                                                        value="false"
                                                        onClick={
                                                            handleClickPessoa
                                                        }
                                                    />
                                                    <FormReact.Check
                                                        inline
                                                        label="Pessoa Jurídica"
                                                        name="isPessoaJuridica"
                                                        type="radio"
                                                        id="true"
                                                        value="true"
                                                        onClick={
                                                            handleClickPessoa
                                                        }
                                                    />
                                                </h6>
                                            </BorderSelect>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <Input
                                                name="nome"
                                                placeholder="Nome"
                                                type="text"
                                            />
                                        </MDBCol>
                                        <MDBCol md="6">
                                            {isPessoaJuridica === "true" && (
                                                <Inputmask
                                                    name="docIdent"
                                                    mask="cnpj"
                                                    placeholder="CNPJ - xx.xxx.xxx/xxxx-xx"
                                                    type="text"
                                                />
                                            )}

                                            {isPessoaJuridica === "false" && (
                                                <Inputmask
                                                    name="docIdent"
                                                    mask="cpf"
                                                    placeholder="CPF - xxx.xxx.xxx-xx"
                                                    type="text"
                                                />
                                            )}
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <Inputmask
                                                name="celular"
                                                mask="celular"
                                                placeholder="Celular - (xx) 9xxxx-xxxx"
                                                type="text"
                                            />
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <Input
                                                name="email"
                                                placeholder="Email"
                                                type="email"
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <Input
                                                name="senha"
                                                placeholder="Senha"
                                                type="password"
                                            />
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <Input
                                                name="confirmaSenha"
                                                placeholder="Confirme a senha"
                                                type="password"
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <BorderSelect
                                                name="SelectEstado"
                                                value={selectEstado}
                                                hidden
                                            >
                                                <Select
                                                    className="dropdown"
                                                    placeholder="Estado"
                                                    onChange={
                                                        handleChangeEstado
                                                    }
                                                    options={estados}
                                                    getOptionLabel={option =>
                                                        option.nome
                                                    }
                                                    getOptionValue={option =>
                                                        option.sigla
                                                    }
                                                    maxMenuHeight={160}
                                                    theme={theme => ({
                                                        ...theme,
                                                        height: 100,
                                                        borderRadius: 10,
                                                    })}
                                                />
                                            </BorderSelect>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <BorderSelect
                                                name="SelectCidade"
                                                value={selectCidade}
                                                hidden
                                                style={{ display: "none" }}
                                            >
                                                <Select
                                                    className="dropdown"
                                                    placeholder="Cidade"
                                                    onChange={
                                                        handleChangeCidade
                                                    }
                                                    options={cidades}
                                                    getOptionLabel={option =>
                                                        option.nome
                                                    }
                                                    getOptionValue={option =>
                                                        option.id
                                                    }
                                                    maxMenuHeight={160}
                                                    theme={theme => ({
                                                        ...theme,
                                                        height: 100,
                                                        borderRadius: 10,
                                                    })}
                                                    isDisabled={notShowCidade}
                                                />
                                            </BorderSelect>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <BorderSelect
                                                name="SelectCategoria"
                                                value={selectedValueCategoria}
                                                hidden
                                            >
                                                <Select
                                                    className="dropdown"
                                                    placeholder="Categoria"
                                                    onChange={
                                                        handleChangeCategoria
                                                    }
                                                    options={categorias}
                                                    getOptionLabel={option =>
                                                        option.nome
                                                    }
                                                    getOptionValue={option =>
                                                        option.id
                                                    }
                                                    isSearchable={false}
                                                    maxMenuHeight={160}
                                                    theme={theme => ({
                                                        ...theme,
                                                        height: 100,
                                                        borderRadius: 10,
                                                    })}
                                                />
                                            </BorderSelect>
                                        </MDBCol>
                                        <MDBCol
                                            md="6"
                                            style={{
                                                marginTop: 6,
                                            }}
                                        >
                                            <Select
                                                className="dropdown"
                                                placeholder="Sub Categoria"
                                                onChange={
                                                    handleChangeSubCategoria
                                                }
                                                options={subCategorias}
                                                getOptionLabel={option =>
                                                    option.nome
                                                }
                                                getOptionValue={option =>
                                                    option.id
                                                }
                                                isMulti
                                                isSearchable={false}
                                                maxMenuHeight={160}
                                                theme={theme => ({
                                                    ...theme,
                                                    height: 100,
                                                    borderRadius: 10,
                                                })}
                                                isDisabled={notShowFirstSub}
                                            />
                                        </MDBCol>
                                    </MDBRow>

                                    {buttonInsertCategoria !== "Clicado" && (
                                        <MDBRow>
                                            <MDBCol md="12">
                                                <h6
                                                    onClick={
                                                        handleClickButtonInsertCategoria
                                                    }
                                                    style={{
                                                        cursor: "pointer",
                                                        textAlign: "left",
                                                        color: "#2d2d2d",
                                                        marginTop: 10,
                                                    }}
                                                >
                                                    <FiPlus
                                                        style={{
                                                            marginTop: -2,
                                                            marginRight: 5,
                                                        }}
                                                    />
                                                    adicionar categoria
                                                </h6>
                                            </MDBCol>
                                        </MDBRow>
                                    )}

                                    {buttonInsertCategoria === "Clicado" && (
                                        <MDBRow>
                                            <MDBCol
                                                md="6"
                                                style={{
                                                    marginTop: 6,
                                                }}
                                            >
                                                <Select
                                                    className="dropdown"
                                                    placeholder="Categoria"
                                                    onChange={
                                                        handleChangeCategoriaSecundaria
                                                    }
                                                    options={
                                                        categoriasSecundaria
                                                    }
                                                    getOptionLabel={option =>
                                                        option.nome
                                                    }
                                                    getOptionValue={option =>
                                                        option.id
                                                    }
                                                    isSearchable={false}
                                                    maxMenuHeight={160}
                                                    theme={theme => ({
                                                        ...theme,
                                                        height: 100,
                                                        borderRadius: 10,
                                                    })}
                                                />
                                            </MDBCol>
                                            <MDBCol
                                                md="6"
                                                style={{
                                                    marginTop: 6,
                                                }}
                                            >
                                                <Select
                                                    className="dropdown"
                                                    placeholder="Sub Categoria"
                                                    onChange={
                                                        handleChangeSubCategoriaSecundaria
                                                    }
                                                    options={
                                                        subCategoriasSecundaria
                                                    }
                                                    getOptionLabel={option =>
                                                        option.nome
                                                    }
                                                    getOptionValue={option =>
                                                        option.id
                                                    }
                                                    isMulti
                                                    isSearchable={false}
                                                    maxMenuHeight={160}
                                                    theme={theme => ({
                                                        ...theme,
                                                        height: 100,
                                                        borderRadius: 10,
                                                    })}
                                                    isDisabled={
                                                        notShowSecondSub
                                                    }
                                                />
                                            </MDBCol>
                                        </MDBRow>
                                    )}

                                    <MDBRow>
                                        <MDBCol
                                            md="12"
                                            style={{
                                                marginTop: 6,
                                            }}
                                        >
                                            <h6>
                                                Escreva aqui os produtos e
                                                serviços com que trabalha.
                                            </h6>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="12">
                                            <Textarea
                                                name="biografia"
                                                placeholder="Ex.: Sarja; Tricoline; Jeans; Malha; Lençol; Biquini; Camisas Masculinas; Calças; Treinamento de vendas; Modelagem; etc."
                                                style={{
                                                    border: "none",
                                                    resize: "none",
                                                    width: "100%",
                                                    height: "100%",
                                                }}
                                            />
                                        </MDBCol>
                                    </MDBRow>

                                    <MDBRow
                                        style={{
                                            marginTop: "1%",
                                            marginBottom: "1%",
                                        }}
                                    >
                                        <MDBCol md="9" className="col-example">
                                            <MDBRow>
                                                <MDBCol
                                                    md="12"
                                                    className="col-example"
                                                >
                                                    <BorderSelect
                                                        name="termosDeUso"
                                                        value={termosDeUso}
                                                        hidden
                                                    >
                                                        <FormReact.Check
                                                            type="checkbox"
                                                            id="termosDeUso"
                                                        >
                                                            <FormReact.Check.Input
                                                                type="checkbox"
                                                                value="selected"
                                                                onClick={
                                                                    handleTermosDeUso
                                                                }
                                                                style={{
                                                                    zIndex: 0,
                                                                }}
                                                            />
                                                            <FormReact.Check.Label>
                                                                <small>
                                                                    Li e aceito
                                                                    a{" "}
                                                                    <a
                                                                        href="/termosDeUso"
                                                                        target="_blank"
                                                                    >
                                                                        Política
                                                                        de
                                                                        Privacidade
                                                                        e Termos
                                                                        de Uso
                                                                    </a>
                                                                </small>
                                                            </FormReact.Check.Label>
                                                        </FormReact.Check>
                                                    </BorderSelect>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol
                                                    md="12"
                                                    className="col-example"
                                                >
                                                    <BorderSelect
                                                        name="news"
                                                        value={news}
                                                        hidden
                                                    >
                                                        <small>
                                                            <FormReact.Check
                                                                inline
                                                                name="news"
                                                                label="Quero receber informações da WeTêxtil"
                                                                type="checkbox"
                                                                value="true"
                                                                onClick={
                                                                    handleNews
                                                                }
                                                                style={{
                                                                    zIndex: 0,
                                                                }}
                                                            />
                                                        </small>
                                                    </BorderSelect>
                                                </MDBCol>
                                            </MDBRow>
                                        </MDBCol>
                                        <MDBCol md="3" className="col-example">
                                            <ButtonAzulEscuro type="submit">
                                                <strong>Avançar</strong>
                                            </ButtonAzulEscuro>
                                        </MDBCol>
                                    </MDBRow>
                                </Form>
                            </ConteudoDadosCadastrais>
                        </DadosCadastrais>
                        <p>
                            Já é cadastrado?{" "}
                            <a
                                href="/login"
                                style={{
                                    textDecoration: "none",
                                    color: "#00d1e6",
                                }}
                            >
                                {" "}
                                Faça login!
                            </a>
                        </p>
                    </Geral>
                </AnimationContainer>
            </Content>
        </Container>
    );
};

export default CadastroCliente;
