/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
    FiUser,
    FiInstagram,
    FiFacebook,
    FiPackage,
    FiPower,
    FiLinkedin,
} from "react-icons/fi";
import { MDBRow, MDBCol, MDBContainer, MDBFooter } from "mdb-react-ui-kit";
import parse from "html-react-parser";

import { Link, useHistory } from "react-router-dom";

import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import api from "../../services/api";

import { useAuth } from "../../hooks/auth";
import {
    Container,
    Content,
    ButtonMenu,
    Box,
    Adicionais,
    Valores,
    Infos,
} from "./styles";

import imgLogo from "../../assets/Logo.png";
import imgNossoMovimento01 from "../../assets/nossoMovimento01.png";
import imgNossoMovimento02 from "../../assets/nossoMovimento02.png";
import imgLogoBranca from "../../assets/LogoBranca.png";
import ButtonIcon from "../../components/ButtonIcon";
import laptopConexao from "../../assets/laptopConexao.png";
import laptopMentoria from "../../assets/laptopMentoria.png";
import ButtonRoxo from "../../components/ButtonRoxo";
import ButtonAzulEscuro from "../../components/ButtonAzulEscuro";
import ButtonBranco from "../../components/ButtonBranco";

interface Categoria {
    id: string;
    nome: string;
    idCategoriaPai: string;
}

interface Outros {
    id: string;
    chave: string;
    valor: string;
}
const Planos: React.FC = () => {
    const { cliente, signOut } = useAuth();
    const history = useHistory();
    const [outros, setOutros] = useState<Outros[]>([]);

    const [categorias, setCategorias] = useState<Categoria[]>([]);

    useEffect(() => {
        api.get("/categorias/categorias").then(response =>
            setCategorias(response.data),
        );
        api.get("/controle").then(response => setOutros(response.data));
    }, []);

    const clickCategoria = (nomeCategoria: string) => {
        if (cliente) {
            const insertPesquisa = {
                idCliente: cliente.id,
                nomeCliente: cliente.nome,
                pesquisa: nomeCategoria,
            };

            api.post("/pesquisa", insertPesquisa);
        } else {
            const insertPesquisa = {
                pesquisa: nomeCategoria,
            };

            api.post("/pesquisa", insertPesquisa);
        }
        localStorage.setItem("@dbwetextil:nomeCategoria", nomeCategoria);
        history.push("/pesquisa");
    };

    return (
        <Container>
            <Navbar
                collapseOnSelect
                expand="lg"
                style={{
                    background: "#ffffff",
                }}
            >
                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    style={{ border: "none" }}
                />
                <Navbar.Brand style={{ marginLeft: "2%" }}>
                    <Link to="/">
                        <img width="125px" src={imgLogo} alt="WeTextil" />
                    </Link>
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto text-center" />
                    <Nav>
                        <Nav.Link
                            href="/"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                início
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/nossoMovimento"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                sobre
                            </span>
                        </Nav.Link>

                        <Nav.Link
                            href="/planos"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                planos
                            </span>
                        </Nav.Link>

                        <Nav.Link
                            href="#"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#CAC9C8",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                fabrique sua malha
                            </span>
                        </Nav.Link>

                        <Nav.Link
                            href="#"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#CAC9C8",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                mapeie seu mercado
                            </span>
                        </Nav.Link>

                        <NavDropdown
                            title="categorias"
                            id="basic-nav-dropdown"
                            style={{
                                padding: "0px 20px 0px 20px",
                                textAlign: "center",
                                marginTop: 8,
                                fontWeight: "bold",
                                color: "#2d2d2d",
                            }}
                        >
                            {categorias.map(categoria => (
                                <NavDropdown.Item
                                    onClick={
                                        () => clickCategoria(categoria.nome)
                                        // eslint-disable-next-line react/jsx-curly-newline
                                    }
                                >
                                    {categoria.nome}
                                </NavDropdown.Item>
                            ))}
                        </NavDropdown>
                        {!cliente && (
                            <Nav.Link href="/cliente">
                                <ButtonMenu>
                                    <button type="button">
                                        <span
                                            style={{
                                                padding: "0px 20px 0px 20px",
                                            }}
                                        >
                                            cadastre-se
                                        </span>
                                    </button>
                                </ButtonMenu>
                            </Nav.Link>
                        )}
                        {!cliente && (
                            <Nav.Link
                                href="/login"
                                style={{
                                    textAlign: "center",
                                    marginTop: 8,
                                    color: "#2d2d2d",
                                }}
                            >
                                <span style={{ padding: "0px 20px 0px 30px" }}>
                                    login
                                </span>
                            </Nav.Link>
                        )}

                        {cliente && cliente?.controleFluxo !== "IS_ADMIN" && (
                            <Nav.Link
                                href="/minhaVitrine"
                                style={{
                                    textAlign: "center",
                                    marginTop: 8,
                                    color: "#2d2d2d",
                                }}
                            >
                                <span style={{ padding: "0px 20px 0px 20px" }}>
                                    minha vitrine
                                </span>
                            </Nav.Link>
                        )}

                        {cliente && cliente?.controleFluxo !== "IS_ADMIN" && (
                            <NavDropdown
                                title="meu perfil"
                                id="basic-nav-dropdown"
                                style={{
                                    padding: "0px 30px 0px 20px",
                                    textAlign: "center",
                                    marginTop: 8,
                                    fontWeight: "bold",
                                    color: "#2d2d2d",
                                }}
                            >
                                <NavDropdown.Item href="/minhaConta">
                                    minha conta
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={signOut}>
                                    sair
                                </NavDropdown.Item>
                            </NavDropdown>
                        )}

                        {cliente && cliente?.controleFluxo === "IS_ADMIN" && (
                            <Nav.Link href="/dashboardClientes">
                                <ButtonMenu>
                                    <button type="button" disabled>
                                        <span
                                            style={{
                                                padding: "0px 20px 0px 10px",
                                            }}
                                        >
                                            Dashboard
                                        </span>
                                    </button>
                                </ButtonMenu>
                            </Nav.Link>
                        )}

                        {cliente && cliente?.controleFluxo === "IS_ADMIN" && (
                            <Nav.Link
                                onClick={signOut}
                                style={{
                                    textAlign: "center",
                                    marginTop: 10,
                                    color: "#2d2d2d",
                                }}
                            >
                                <span style={{ padding: "0px 30px 0px 20px" }}>
                                    sair
                                </span>
                            </Nav.Link>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <Content>
                <MDBRow center>
                    <MDBCol md="4" style={{ marginTop: 20 }}>
                        <Box>
                            <h5>
                                <strong>PLANO CONEXÃO</strong>
                            </h5>
                            <Valores style={{ color: "#00d1e6" }}>
                                <img
                                    src={laptopConexao}
                                    width="100%"
                                    alt="WeTextil"
                                />
                                <h5
                                    style={{
                                        textDecoration: "line-through",
                                        marginTop: 15,
                                    }}
                                >
                                    de: R$ 29,90
                                </h5>
                                <h4
                                    style={{
                                        marginTop: -5,
                                        marginBottom: -8,
                                    }}
                                >
                                    <strong>
                                        R${" "}
                                        <strong
                                            style={{
                                                fontSize: 45,
                                            }}
                                        >
                                            {
                                                outros.find(
                                                    saida =>
                                                        saida.chave ===
                                                        "valorPlanoMensalConexao",
                                                )?.valor
                                            }
                                        </strong>
                                        /mês
                                    </strong>
                                </h4>
                                <small>por 3 meses</small>
                                <Infos style={{ color: "#00d1e6" }}>
                                    <strong>
                                        {parse(
                                            outros.find(
                                                (saida: { chave: string }) =>
                                                    saida.chave ===
                                                    "textoPlanoConexao",
                                            )?.valor || "Loading...",
                                        )}
                                    </strong>
                                </Infos>
                                <Link
                                    to="/cliente"
                                    style={{ textDecoration: "none" }}
                                >
                                    <ButtonRoxo
                                        style={{ height: 50, marginBottom: 10 }}
                                    >
                                        <strong>ASSINE AGORA</strong>
                                    </ButtonRoxo>
                                </Link>
                                <small>Cancele quando quiser</small>
                                <small>
                                    <br />
                                    R$ 29,90/mês depois de 3 meses
                                </small>
                            </Valores>
                        </Box>
                    </MDBCol>
                    <MDBCol md="4" style={{ marginTop: 20 }}>
                        <Box>
                            <h5>
                                <strong>PLANO MENTORIA</strong>
                            </h5>
                            <Valores
                                style={{
                                    backgroundColor: "#846cf9",
                                    color: "white",
                                }}
                            >
                                <img
                                    src={laptopMentoria}
                                    width="100%"
                                    alt="WeTextil"
                                />
                                <h4
                                    style={{
                                        marginTop: 15,
                                    }}
                                >
                                    <strong>
                                        R${" "}
                                        <strong
                                            style={{
                                                fontSize: 45,
                                            }}
                                        >
                                            {
                                                outros.find(
                                                    saida =>
                                                        saida.chave ===
                                                        "valorPlanoAnualMentoriaAtual",
                                                )?.valor
                                            }
                                        </strong>
                                        /ano
                                    </strong>
                                </h4>
                                <Infos
                                    style={{
                                        color: "white",
                                    }}
                                >
                                    <strong>
                                        {parse(
                                            outros.find(
                                                (saida: { chave: string }) =>
                                                    saida.chave ===
                                                    "textoPlanoMentoria",
                                            )?.valor || "Loading...",
                                        )}
                                    </strong>
                                </Infos>
                                <Link
                                    to="/cliente"
                                    style={{ textDecoration: "none" }}
                                >
                                    <ButtonBranco
                                        style={{ height: 50, marginBottom: 10 }}
                                    >
                                        <strong>ASSINE AGORA</strong>
                                    </ButtonBranco>
                                </Link>
                                <small>
                                    Equivale a{" "}
                                    <strong>
                                        R$
                                        {
                                            outros.find(
                                                saida =>
                                                    saida.chave ===
                                                    "valorPlanoAnualMentoriaAtualEquivalencia",
                                            )?.valor
                                        }
                                        /mês
                                    </strong>
                                </small>
                            </Valores>
                        </Box>
                    </MDBCol>
                </MDBRow>
            </Content>
        </Container>
    );
};

export default Planos;
