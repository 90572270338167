import styled from "styled-components";

export const Container = styled.button`
    background: #ffffff;
    border-radius: 5px;
    color: #5d53a9;
    border: 0;
    padding: 20px 16px;
    height: 50px;
    width: 100%;
    font-weight: 500;
`;
