import styled from "styled-components";

import { shade } from "polished";

export const Container = styled.div``;

export const ButtonMenu = styled.div`
    button {
        background: #846cf9;
        border-radius: 5px;
        color: #ffffff;
        border: 0;
        height: 38px;
        width: 100%;
        padding: 0 10px;
        font-weight: 500;

        &:hover {
            background: ${shade(0.2, "#846cf9")};
        }
    }
`;

export const Content = styled.main`
    padding: 1% 4%;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const EspacoFundoCinza = styled.main`
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

export const FundoCinza = styled.div`
    width: 100%;
    height: 100%;
    background-color: #2d2d2d;
`;
