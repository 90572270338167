import React, {
    useCallback,
    useRef,
    useState,
    useEffect,
    // eslint-disable-next-line no-unused-vars
    ChangeEvent,
} from "react";
import { Form } from "@unform/web";
// eslint-disable-next-line no-unused-vars
import { FormHandles } from "@unform/core";
import { FiUser, FiArrowLeft, FiType } from "react-icons/fi";
import * as Yup from "yup";

import { Link, useHistory, Redirect, useParams } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";

import { ToastContainer, toast } from "react-toastify";
import api from "../../services/api";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../hooks/auth";

import getValidationErrors from "../../utils/getValidationErrors";

import {
    Background,
    Container,
    Content,
    AnimationContainer,
    ImagemInput,
} from "./styles";

import Input from "../../components/Input";
import Textarea from "../../components/Textarea";
import ButtonAzul from "../../components/ButtonAzul";

interface UpdateProdutoFormData {
    nome: string;
    descricao: string;
}

interface Produto {
    id: string;
    nome: string;
    descricao: string;
    img01: string;
    img01_url: string;
}

interface params {
    id: string;
}

const UpdateProduto: React.FC = () => {
    const formRef = useRef<FormHandles>(null);

    const { cliente } = useAuth();
    const { id } = useParams<params>();

    const history = useHistory();

    const [produto, setProduto] = useState<Produto>();

    useEffect(() => {
        api.get(`/produtos/${id}`).then(response => setProduto(response.data));
    }, [id]);

    const handleImagemChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            if (event.target.files) {
                const data = new FormData();

                data.append("img01", event.target.files[0]);
                data.append("idProduto", id);

                api.patch("/produtos/img01", data).then(() => {
                    toast.success("Imagem atualizada!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
            }
        },
        [id],
    );

    const handleSubmit = useCallback(
        async (data: UpdateProdutoFormData) => {
            try {
                formRef.current?.setErrors({});

                const { nome, descricao } = data;

                const FormData = { nome, descricao };

                await api.put(`/produtos/${id}`, FormData);

                history.push("/minhaVitrine");

                toast.success("Produto/Serviço atualizado!", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);
                    return;
                }

                toast.error("Erro na atualização! Tente novamente!", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        },
        [history, id],
    );

    // Redirecionamentos de páginas privadas
    if (cliente.controleFluxo === "FALTA_PAGAMENTO") {
        return <Redirect to={`/planosPagamento/${cliente.id}`} />;
    }
    if (cliente.controleFluxo === "FALTA_CONFIRMAR_CELULAR") {
        return <Redirect to="/confirmaSMS" />;
    }

    return (
        <Container>
            <Content>
                <ToastContainer />
                <AnimationContainer>
                    <Form
                        ref={formRef}
                        initialData={{
                            nome: produto?.nome,
                            descricao: produto?.descricao,
                            img01: produto?.img01,
                        }}
                        onSubmit={handleSubmit}
                    >
                        <h3>Edite seu produto</h3>

                        <Input
                            icon={FiUser}
                            name="nome"
                            placeholder="Nome"
                            type="text"
                        />

                        <Textarea
                            icon={FiType}
                            name="descricao"
                            placeholder="Descrição"
                            style={{
                                border: "none",
                                resize: "none",
                                width: "100%",
                                height: "120%",
                            }}
                        />

                        <h6>Alterar imagem (opcional) :</h6>

                        <ImagemInput style={{ marginTop: 10 }}>
                            <input
                                type="file"
                                name="img01"
                                onChange={handleImagemChange}
                                style={{ marginLeft: 10 }}
                            />
                        </ImagemInput>

                        <ButtonAzul type="submit">
                            {" "}
                            Confirmar alterações{" "}
                        </ButtonAzul>
                    </Form>

                    <Link to="/minhaVitrine">
                        <FiArrowLeft />
                        Voltar para Minha Vitrine
                    </Link>
                </AnimationContainer>
            </Content>
            <Background />
        </Container>
    );
};

export default UpdateProduto;
