import styled from "styled-components";

export const Container = styled.div``;

export const Content = styled.main`
    width: 100%;
    text-align: center;
    color: black;
    padding: 2% 2% 2% 2%;
    margin-top: 1%;
    margin-bottom: 2%;

    td {
        vertical-align: middle;
        white-space: nowrap;
        height: 30px;
        padding-left: 30px;
        padding-right: 30px;
        border: 2px solid white;
        border-collapse: collapse;
    }

    th {
        height: 50px;
        border: 2px solid white;
        border-collapse: collapse;
        padding-left: 30px;
        padding-right: 30px;
        vertical-align: middle;
        white-space: nowrap;
    }

    tr {
        font-size: medium;
    }
`;
