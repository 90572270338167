/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useCallback } from "react";

import FormReact from "react-bootstrap/Form";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import { Link, Redirect } from "react-router-dom";
import { Nav, Navbar, NavDropdown, Row, Col } from "react-bootstrap";
import moment from "moment";
import * as Yup from "yup";
import {
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBCol,
    MDBRow,
} from "mdb-react-ui-kit";
import { FiEdit, FiUsers, FiX } from "react-icons/fi";
import { CSVLink } from "react-csv";
import { ToastContainer, toast } from "react-toastify";
import api from "../../../services/api";
import imgLogo from "../../../assets/Logo.png";
import { useAuth } from "../../../hooks/auth";

import ButtonAzulEscuro from "../../../components/ButtonAzulEscuro";

import { Container, Content } from "./styles";
import ButtonRoxo from "../../../components/ButtonRoxo";
import ButtonAzul from "../../../components/ButtonAzul";
import InputPesquisa from "../../../components/InputPesquisa";
import getValidationErrors from "../../../utils/getValidationErrors";

interface Cliente {
    nome: string;
}

interface Categoria {
    nome: string;
    idCategoriaPai: string;
}

interface Produto {
    id: string;
    nome: string;
    isProduto: boolean;
    descricao: string;
    idCliente: string;
    preco: number;
    unidadeMedida: string;
    isVisivel: boolean;
    cliente: Cliente;
    categorias: Categoria[];
    img01: string;
    img01_url: string;
}

const Produtos: React.FC = () => {
    const { cliente } = useAuth();
    const [produtos, setProdutos] = useState<Produto[]>([]);

    const headers = [
        { label: "id", key: "id" },
        { label: "LinkImagem", key: "img01_url" },
        { label: "isProduto", key: "isProduto" },
        { label: "Nome", key: "nome" },
        { label: "Descricao", key: "descricao" },
        { label: "Preco", key: "preco" },
        { label: "Unidade Medida", key: "unidadeMedida" },
        { label: "isVisivel", key: "isVisivel" },
        { label: "idCliente", key: "idCliente" },
        { label: "Cliente", key: "cliente.nome" },
    ];

    const [hiddenExcluir, setHiddenExcluir] = useState(true);

    const [produtoSelecionado, setProdutoSelecionado] = useState<string>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleProdutoSelecionado = (e: any) => {
        setProdutoSelecionado(e.target.value);
        if (e.target.value) {
            setHiddenExcluir(false);
        }
    };

    const excluirProduto = () => {
        api.delete(`/produtos/${produtoSelecionado}`)
            .then(() => {
                toast.success("Produto/Serviço excluído com sucesso!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
                api.get("/produtos").then(response =>
                    setProdutos(response.data),
                );
            })
            .catch(() => {
                toast.error("Erro! Tente novamente!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            });
    };

    useEffect(() => {
        api.get("/produtos").then(response => setProdutos(response.data));
    }, []);

    // Redirecionamentos de páginas privadas
    if (cliente.controleFluxo !== "IS_ADMIN") {
        return <Redirect to="/" />;
    }

    return (
        <Container>
            <Navbar
                collapseOnSelect
                expand="lg"
                style={{
                    background: "#ffffff",
                }}
            >
                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    style={{ border: "none" }}
                />
                <Navbar.Brand style={{ marginLeft: "2%" }}>
                    <Link to="/">
                        <img width="125px" src={imgLogo} alt="WeTextil" />
                    </Link>
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto text-center" />
                    <Nav>
                        <Nav.Link
                            href="/dashboardMapeamentoMercado"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Mapeie Mercado
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardFabricacaoMalha"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Fabrique Malha
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardPesquisa"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Pesquisas
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardTabelaWeTextil"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Tabela WeTêxtil
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardClientes"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Clientes
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardCategorias"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Categorias
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardAssinaturas"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Assinaturas
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardProdutos"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#846cf9",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                <strong>Produtos/Serviços</strong>
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardOutros"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Outros
                            </span>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Content>
                <ToastContainer />
                <MDBRow style={{ marginBottom: 20, marginTop: -20 }} end>
                    <MDBCol md="3" hidden={hiddenExcluir}>
                        <ButtonRoxo
                            style={{ height: 50 }}
                            onClick={excluirProduto}
                        >
                            Remover
                        </ButtonRoxo>
                    </MDBCol>
                    <MDBCol md="3">
                        <CSVLink
                            data={produtos}
                            headers={headers}
                            filename={`TabelaProdutos-${moment().format(
                                "DD/MM/YYYY HH:mm",
                            )}`}
                            style={{ textDecoration: "none" }}
                        >
                            <ButtonAzulEscuro
                                style={{ height: 50 }}
                                type="submit"
                            >
                                Download
                            </ButtonAzulEscuro>
                        </CSVLink>
                    </MDBCol>
                </MDBRow>

                <MDBTable responsive bordered striped>
                    <MDBTableHead className="text-center" dark>
                        <tr>
                            <th scope="col">Selecionar</th>
                            <th scope="col">Imagem</th>
                            <th scope="col">Tipo</th>
                            <th scope="col">Nome</th>
                            <th scope="col">Descrição</th>
                            <th scope="col">Preço</th>
                            <th scope="col">Unidade Medida</th>
                            <th scope="col">Visível</th>
                            <th scope="col">Cliente</th>
                            <th scope="col">Categorias</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {produtos.map(produto => (
                            <tr>
                                <td>
                                    <FormReact.Check
                                        name="selecionado"
                                        type="radio"
                                        value={produto.id}
                                        onClick={handleProdutoSelecionado}
                                    />
                                </td>
                                <td>
                                    <img
                                        src={produto.img01_url}
                                        width="180px"
                                        height="150px"
                                        alt="WeTextil"
                                        style={{ cursor: "pointer" }}
                                    />
                                </td>
                                {produto.isProduto && <td>Produto</td>}
                                {!produto.isProduto && <td>Serviço</td>}
                                <td>{produto.nome}</td>
                                <td>{produto.descricao}</td>
                                <td>{produto.preco}</td>
                                <td>{produto.unidadeMedida}</td>
                                {produto.isVisivel && <td>Sim</td>}
                                {!produto.isVisivel && <td>Não</td>}
                                <td>{produto.cliente.nome}</td>
                                <td>
                                    {produto.categorias.map(categoria => (
                                        <h6>{categoria.nome}</h6>
                                    ))}
                                </td>
                            </tr>
                        ))}
                    </MDBTableBody>
                </MDBTable>
            </Content>
        </Container>
    );
};

export default Produtos;
