import React from "react";
import { Switch } from "react-router-dom";

import Manutencao from "../pages/Manutencao";
import Route from "./Route";

import Home from "../pages/Home";
import Pesquisa from "../pages/Pesquisa";
import Tabela from "../pages/Tabela";
import Login from "../pages/Login";
import ForgotSenha from "../pages/ForgotSenha";
import ResetSenha from "../pages/ResetSenha";
import CadastroCliente from "../pages/CadastroCliente";
import MinhaConta from "../pages/MinhaConta";
import PlanosPagamento from "../pages/PlanosPagamento";
import ConfirmaSMS from "../pages/ConfirmaSMS";
import ConfirmaEmail from "../pages/ConfirmaEmail";
import Produto from "../pages/CadastroProduto";
import DetalhesProduto from "../pages/DetalhesProduto";
import UpdateProduto from "../pages/UpdateProduto";
import Loja from "../pages/Loja";
import MinhaVitrine from "../pages/MinhaVitrine";
import NossoMovimento from "../pages/NossoMovimento";
import TermosDeUso from "../pages/TermosDeUso";
import Planos from "../pages/Planos";
import FabriqueMalha from "../pages/FabriqueMalha";
import MapeamentoMercado from "../pages/MapeamentoMercado";

import DashboardCategorias from "../pages/Dashboard/Categorias";
import DashboardClientes from "../pages/Dashboard/Clientes";
import DashboardTabelaWeTextil from "../pages/Dashboard/TabelaWeTextil";
import DashboardPesquisa from "../pages/Dashboard/Pesquisa";
import DashboardAssinaturas from "../pages/Dashboard/Assinaturas";
import DashboardProdutos from "../pages/Dashboard/Produtos";
import DashboardOutros from "../pages/Dashboard/Outros";
import DashboardMapeieMercado from "../pages/Dashboard/MapeieMercado";
import DashboardFabriqueMalha from "../pages/Dashboard/FabriqueMalha";

import ConsultorUm from "../pages/Consultores/Primeiro";
import ConsultorDois from "../pages/Consultores/Segundo";
import ConsultorTres from "../pages/Consultores/Terceiro";

import Detalhes from "../pages/Dashboard/Clientes/Detalhes";

const Routes: React.FC = () => {
    return (
        <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/login" exact component={Login} />
            <Route path="/planos" exact component={Planos} />
            <Route path="/manutencao" exact component={Manutencao} />
            <Route path="/forgot" exact component={ForgotSenha} />
            <Route path="/reset-senha" component={ResetSenha} />
            <Route path="/cliente" component={CadastroCliente} />
            <Route path="/consultorUm" component={ConsultorUm} />
            <Route path="/consultorDois" component={ConsultorDois} />
            <Route path="/consultorTres" component={ConsultorTres} />
            <Route
                path="/planosPagamento/:idCliente"
                component={PlanosPagamento}
            />
            <Route path="/confirmaSMS" component={ConfirmaSMS} />
            <Route path="/confirmaEmail" component={ConfirmaEmail} />
            <Route path="/nossoMovimento" component={NossoMovimento} />
            <Route path="/termosDeUso" component={TermosDeUso} />
            <Route path="/fabriqueMalha" component={FabriqueMalha} />
            <Route path="/mapeamentoMercado" component={MapeamentoMercado} />
            <Route path="/minhaVitrine" component={MinhaVitrine} isPrivate />
            <Route path="/produto" component={Produto} isPrivate />
            <Route
                path="/updateProduto/:id"
                component={UpdateProduto}
                isPrivate
            />
            <Route path="/minhaConta" component={MinhaConta} isPrivate />
            <Route path="/pesquisa" exact component={Pesquisa} isPrivate />
            <Route path="/loja/:idCliente" component={Loja} isPrivate />
            <Route
                path="/detalhesProduto/:idProduto"
                component={DetalhesProduto}
                isPrivate
            />
            <Route path="/tabela" component={Tabela} isPrivate />
            <Route
                path="/dashboardClientes"
                component={DashboardClientes}
                isPrivate
            />
            <Route
                path="/dashboardClientesDetalhes/:idClienteRota"
                component={Detalhes}
                isPrivate
            />
            <Route
                path="/dashboardCategorias"
                component={DashboardCategorias}
                isPrivate
            />
            <Route
                path="/dashboardTabelaWeTextil"
                component={DashboardTabelaWeTextil}
                isPrivate
            />
            <Route
                path="/dashboardPesquisa"
                component={DashboardPesquisa}
                isPrivate
            />
            <Route
                path="/dashboardAssinaturas"
                component={DashboardAssinaturas}
                isPrivate
            />
            <Route
                path="/dashboardProdutos"
                component={DashboardProdutos}
                isPrivate
            />
            <Route
                path="/dashboardOutros"
                component={DashboardOutros}
                isPrivate
            />

            <Route
                path="/dashboardMapeamentoMercado"
                component={DashboardMapeieMercado}
                isPrivate
            />
            <Route
                path="/dashboardFabricacaoMalha"
                component={DashboardFabriqueMalha}
                isPrivate
            />
        </Switch>
    );
};

export default Routes;
