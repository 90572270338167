import React, { createContext, useCallback, useState, useContext } from "react";

import api from "../services/api";

interface Assinatura {
    id: string;
    code: string;
    status: string;
}

interface Categoria {
    id: string;
    nome: string;
    idCategoriaPai: string;
}

interface Estado {
    nome: string;
    sigla: string;
}

interface Cidade {
    id: string;
    nome: string;
}

interface Cliente {
    id: string;
    nome: string;
    email: string;
    controleFluxo: string;
    avatar_url?: string;
    biografia?: string;
    linkInstagram?: string;
    linkFacebook?: string;
    linkLinkedin?: string;
    linkSite?: string;
    cupomDesconto?: string;
    celularValidado?: boolean;
    celular?: string;
    emailValidado?: boolean;
    categorias?: Categoria[];
    cidade?: Cidade;
    estado?: Estado;
    assinaturas?: Assinatura[];
}

interface SignInCredentials {
    email: string;
    senha: string;
}

interface AuthContextData {
    cliente: Cliente;
    signIn(credentials: SignInCredentials): Promise<void>;
    signOut(): void;
    updateCliente(cliente: Cliente): void;
}

interface AuthState {
    token: string;
    cliente: Cliente;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
    const [data, setData] = useState<AuthState>(() => {
        const token = localStorage.getItem("@dbwetextil:token");
        const cliente = localStorage.getItem("@dbwetextil:cliente");

        if (token && cliente) {
            api.defaults.headers.authorization = `Bearer ${token}`;
            return {
                token,
                cliente: JSON.parse(cliente),
            };
        }

        return {} as AuthState;
    });

    const signIn = useCallback(async ({ email, senha }) => {
        const response = await api
            .post("session", {
                email,
                senha,
            })
            .catch(retorno => {
                throw new Error(retorno.request.responseText);
            });

        if (response.data.admin) {
            const { token, admin } = response.data;

            const cliente = admin;

            localStorage.setItem("@dbwetextil:token", token);
            localStorage.setItem(
                "@dbwetextil:cliente",
                JSON.stringify(cliente),
            );

            api.defaults.headers.authorization = `Bearer ${token}`;

            setData({ token, cliente });
        }

        if (response.data.cliente) {
            const { token, cliente } = response.data;

            localStorage.setItem("@dbwetextil:token", token);
            localStorage.setItem(
                "@dbwetextil:cliente",
                JSON.stringify(cliente),
            );

            api.defaults.headers.authorization = `Bearer ${token}`;

            setData({ token, cliente });
        }
    }, []);

    const signOut = useCallback(() => {
        localStorage.removeItem("@dbwetextil:token");
        localStorage.removeItem("@dbwetextil:cliente");
        localStorage.removeItem("@dbwetextil:idCadastroCliente");
        localStorage.removeItem("@dbwetextil:idProdutoUpdate");
        localStorage.removeItem("@dbwetextil:idProduto");
        localStorage.removeItem("@dbwetextil:nomeCategoria");
        localStorage.removeItem("@dbwetextil:idClientePesquisa");
        localStorage.removeItem("@dbwetextil:pesquisa");

        setData({} as AuthState);
    }, []);

    const updateCliente = useCallback(
        (cliente: Cliente) => {
            localStorage.setItem(
                "@dbwetextil:cliente",
                JSON.stringify(cliente),
            );
            setData({
                token: data.token,
                cliente,
            });
        },
        [data.token],
    );

    return (
        <AuthContext.Provider
            value={{
                cliente: data.cliente,
                signIn,
                signOut,
                updateCliente,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

function useAuth(): AuthContextData {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error("useAuth must be used within a AuthProvider");
    }

    return context;
}

export { AuthProvider, useAuth };
