import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import parse from "html-react-parser";

import { useAuth } from "../../../hooks/auth";

import { Background, Container, Content, AnimationContainer } from "./styles";

import ButtonRoxo from "../../../components/ButtonRoxo";

import api from "../../../services/api";

interface Outros {
    id: string;
    chave: string;
    valor: string;
}

const Segundo: React.FC = () => {
    const { cliente } = useAuth();
    const [outros, setOutros] = useState<Outros[]>([]);

    useEffect(() => {
        api.get("/controle").then(response => setOutros(response.data));
    }, [outros]);

    return (
        <Container>
            <Content>
                <ToastContainer />
                <AnimationContainer>
                    <MDBRow>
                        <MDBCol sm="10" md="10" lg="10">
                            <h3>ESPECIALISTAS WETÊXTIL</h3>
                        </MDBCol>
                    </MDBRow>

                    {parse(
                        outros.find(
                            (saida: { chave: string }) =>
                                saida.chave === "textoConsultor2",
                        )?.valor || "Loading...",
                    )}

                    {!cliente && (
                        <>
                            <MDBRow
                                center
                                style={{
                                    paddingLeft: 10,
                                }}
                            >
                                <MDBCol size="200px">
                                    <Link
                                        to="/cliente"
                                        style={{
                                            textDecoration: "none",
                                        }}
                                    >
                                        <ButtonRoxo style={{ width: 200 }}>
                                            Cadastre-se
                                        </ButtonRoxo>
                                    </Link>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow
                                style={{
                                    marginTop: 10,
                                    paddingLeft: 10,
                                }}
                                center
                            >
                                <MDBCol size="200px">
                                    Já tem acesso?{" "}
                                    <Link
                                        to="/login"
                                        style={{
                                            color: "black",
                                        }}
                                    >
                                        <u>Fazer login</u>
                                    </Link>
                                </MDBCol>
                            </MDBRow>
                        </>
                    )}

                    {cliente && (
                        <MDBRow
                            center
                            style={{
                                paddingLeft: 10,
                            }}
                        >
                            <MDBCol size="200px">
                                <Link
                                    to={{
                                        pathname: `${
                                            outros.find(
                                                saida =>
                                                    saida.chave === "linkEduzz",
                                            )?.valor
                                        }`,
                                    }}
                                    target="_blank"
                                    style={{ textDecoration: "none" }}
                                >
                                    <ButtonRoxo style={{ width: 200 }}>
                                        Acessar curso
                                    </ButtonRoxo>
                                </Link>
                            </MDBCol>
                        </MDBRow>
                    )}
                </AnimationContainer>
            </Content>
            <Background />
        </Container>
    );
};

export default Segundo;
