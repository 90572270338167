import styled from "styled-components";

import { shade } from "polished";
import fundoPlanos from "../../assets/fundoPlanos.png";

export const Container = styled.div`
    height: 100vh;
    /*background: url(${fundoPlanos});*/
`;

export const ButtonMenu = styled.div`
    button {
        background: #846cf9;
        border-radius: 5px;
        color: #ffffff;
        border: 0;
        height: 38px;
        width: 100%;
        padding: 0 10px;
        font-weight: 500;

        &:hover {
            background: ${shade(0.2, "#846cf9")};
        }
    }
`;

export const Content = styled.main`
    padding: 2% 4% 4% 2%;
    width: 100%;
`;

export const Box = styled.div`
    width: 100%;
    color: white;
    text-align: center;
    background-color: #ff8c00;
    border-radius: 30px;
    padding: 12px 0px 0px 0px;
`;

export const Valores = styled.div`
    width: 100%;
    height: 100%;
    color: #2d2d2d;
    margin-top: 10px;
    padding: 20px 20px 20px 20px;
    text-align: center;
    background-color: white;
    border-radius: 30px;
    box-shadow: 0 0 3px grey;
    justify-content: center;
`;

export const Infos = styled.div`
    color: #2d2d2d;
    margin-top: 10px;
    text-align: left;
    font-size: 16px;
    justify-content: center;
`;

export const Adicionais = styled.div`
    width: 100%;
    height: 120px;
    color: #2d2d2d;
    text-align: left;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
