import React, { useCallback, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Form } from "@unform/web";
// eslint-disable-next-line no-unused-vars
import { FormHandles } from "@unform/core";
import { FiLock } from "react-icons/fi";

import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import getValidationErrors from "../../utils/getValidationErrors";

import api from "../../services/api";

import logoImg from "../../assets/Logo.png";
import Input from "../../components/Input";

import ButtonAzul from "../../components/ButtonAzul";
import { Container, Content, Background, AnimationContainer } from "./styles";
import "react-toastify/dist/ReactToastify.css";
import "../../components/ProgressBar/styles.css";

interface ResetSenhaFormData {
    senha: string;
    confirmacaoSenha: string;
}

const ResetSenha: React.FC = () => {
    const formRef = useRef<FormHandles>(null);
    const location = useLocation();
    const history = useHistory();

    const handleSubmit = useCallback(
        async (data: ResetSenhaFormData) => {
            try {
                formRef.current?.setErrors({});

                const schema = Yup.object().shape({
                    senha: Yup.string().required("Senha obrigatória"),
                    confirmacaoSenha: Yup.string().oneOf(
                        [Yup.ref("senha"), null],
                        "As senhas devem ser iguais",
                    ),
                });

                await schema.validate(data, {
                    // Faz com que o Yup não pare no primeiro erro. Por padrão é true.
                    abortEarly: false,
                });

                const token = location.search.replace("?token=", "");

                if (!token) {
                    throw new Error("");
                }

                const { senha, confirmacaoSenha } = data;

                await api.post("/clientes/senha/reset", {
                    senha,
                    confirmacaoSenha,
                    token,
                });

                history.push("/");
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);

                    return;
                }

                toast.error("Erro ao resetar senha! Tente novamente!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        },
        [history, location.search],
    );

    return (
        <Container>
            <Content>
                <ToastContainer />
                <AnimationContainer>
                    <img
                        src={logoImg}
                        width="200px"
                        alt="WeTextil"
                        style={{ marginBottom: 10 }}
                    />

                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <h5> Alterar senha </h5>

                        <Input
                            icon={FiLock}
                            name="senha"
                            placeholder="Nova senha"
                            type="password"
                        />

                        <Input
                            icon={FiLock}
                            name="confirmacaoSenha"
                            placeholder="Confirmação da senha"
                            type="password"
                        />

                        <ButtonAzul type="submit">Alterar senha</ButtonAzul>
                    </Form>
                </AnimationContainer>
            </Content>
            <Background />
        </Container>
    );
};
export default ResetSenha;
