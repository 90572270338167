import styled from "styled-components";

import cadastroClienteImg from "../../assets/imgTelaPagamentoRecorte.png";

export const Container = styled.div`
    height: 100vh;
    display: flex;
    align-items: stretch;
    margin-right: 3%;
`;

export const Geral = styled.div`
    width: 95%;
    height: 100%;
    align-items: center;
    margin-top: 3%;
    margin-bottom: 2%;
    margin-left: 5%;
    margin-right: 5%;
`;

export const PlanoPagamento = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    box-shadow: 0 0 3px grey;
`;

export const ConteudoPlanoPagamento = styled.div`
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: 5%;
    margin-right: 5%;
`;

export const Planos = styled.div`
    padding: 0% 4% 2% 4%;
    width: 100%;
    height: 100%;
    margin-top: 2%;
    margin-bottom: 2%;
    color: #2d2d2d;
    text-align: center;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 3px grey;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Box = styled.div`
    width: 100%;
    color: white;
    text-align: center;
    background-color: #ff8c00;
    border-radius: 30px;
    padding: 8px 0px 0px 0px;
`;

export const Valores = styled.div`
    width: 100%;
    height: 100%;
    color: #2d2d2d;
    margin-top: 5px;
    padding: 20px 20px 20px 20px;
    text-align: center;
    background-color: white;
    border-radius: 30px;
    box-shadow: 0 0 3px grey;
    justify-content: center;
`;

export const Infos = styled.div`
    color: #2d2d2d;
    margin-top: -10px;
    text-align: left;
    font-size: 14px;
    justify-content: center;
`;

export const Adicionais = styled.div`
    width: 100%;
    height: 120px;
    color: #2d2d2d;
    text-align: left;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 950px;
    color: black;
    justify-content: center;
    width: 100%;
`;

export const Background = styled.div`
    //Ocupa todo o espaço menos os 700px
    flex: 1;
    background: url(${cadastroClienteImg}) top;
    background-size: cover;
    height: 100%;
`;
