/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
    FiUser,
    FiMail,
    FiMapPin,
    FiPackage,
    FiLink,
    FiLinkedin,
    FiFacebook,
    FiInstagram,
} from "react-icons/fi";

import { FaWhatsapp } from "react-icons/fa";
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardImage,
    MDBRow,
    MDBCol,
    MDBCardSubTitle,
} from "mdb-react-ui-kit";

import { Link, useHistory, Redirect, useParams } from "react-router-dom";

// eslint-disable-next-line no-unused-vars
import { Form } from "@unform/web";
// eslint-disable-next-line no-unused-vars
import { FormHandles } from "@unform/core";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import api from "../../services/api";
import ButtonIcon from "../../components/ButtonIcon";

import { useAuth } from "../../hooks/auth";
import {
    Container,
    Content,
    ButtonMenu,
    ButtonWhats,
    AvatarInput,
    Atencao,
    EspacoFundoCinza,
    FundoCinza,
} from "./styles";
import avatarPadrao from "../../assets/avatar.png";

import imgLogo from "../../assets/Logo.png";
import icone01 from "../../assets/icone01.png";
import icone02 from "../../assets/icone02.png";
import icone03 from "../../assets/icone03.png";
import imgLogoBranca from "../../assets/LogoBranca.png";

interface Categoria {
    id: string;
    nome: string;
    idCategoriaPai: string;
}

interface Cidade {
    nome: string;
    sigla?: string;
    regiao?: string;
}

interface Estado {
    nome: string;
    sigla: string;
    regiao: string;
}

interface Cliente {
    controleFluxo: string;
    id: string;
    nome: string;
    biografia: string;
    avatar_url: string;
    celular: string;
    email: string;
    linkInstagram: string;
    linkFacebook: string;
    linkLinkedin: string;
    linkSite: string;
    estado: Estado;
    cidade: Cidade;
    categorias: Categoria[];
}

interface Produto {
    id: string;
    nome: string;
    descricao: string;
    img01_url: string;
    categorias: Categoria[];
}

interface params {
    idCliente: string;
}

interface Outros {
    id: string;
    chave: string;
    valor: string;
}

const Loja: React.FC = () => {
    const { cliente, signOut } = useAuth();
    const history = useHistory();
    const { idCliente } = useParams<params>();

    const [produtos, setProdutos] = useState<Produto[]>([]);
    const [outros, setOutros] = useState<Outros[]>([]);
    const [clienteLoja, setClienteLoja] = useState<Cliente>();

    useEffect(() => {
        api.get(`/clientes/${idCliente}`).then(response =>
            setClienteLoja(response.data),
        );
        api.get("/controle").then(response => setOutros(response.data));
    }, [idCliente]);

    useEffect(() => {
        api.get(`/produtos/cliente/${idCliente}`).then(response =>
            setProdutos(response.data),
        );
    }, [idCliente]);

    const clickProduto = (idProduto: string) => {
        history.push(`/detalhesProduto/${idProduto}`);
    };

    const [categorias, setCategorias] = useState<Categoria[]>([]);

    useEffect(() => {
        api.get("/categorias/categorias").then(response =>
            setCategorias(response.data),
        );
    }, []);

    const clickCategoria = (nomeCategoria: string) => {
        const insertPesquisa = {
            idCliente: cliente.id,
            nomeCliente: cliente.nome,
            pesquisa: nomeCategoria,
        };

        api.post("/pesquisa", insertPesquisa);
        localStorage.setItem("@dbwetextil:nomeCategoria", nomeCategoria);
        history.push("/pesquisa");
    };

    const message = `Olá ${clienteLoja?.nome}, estou entrando em contato pois te encontrei no wetextil.com`;

    // Redirecionamentos de páginas privadas
    if (cliente.controleFluxo === "FALTA_PAGAMENTO") {
        return <Redirect to={`/planosPagamento/${cliente.id}`} />;
    }
    if (cliente.controleFluxo === "FALTA_CONFIRMAR_CELULAR") {
        return <Redirect to="/confirmaSMS" />;
    }

    return (
        <Container>
            <Navbar
                collapseOnSelect
                expand="lg"
                style={{
                    background: "#ffffff",
                }}
            >
                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    style={{ border: "none" }}
                />
                <Navbar.Brand style={{ marginLeft: "2%" }}>
                    <Link to="/">
                        <img width="125px" src={imgLogo} alt="WeTextil" />
                    </Link>
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto text-center" />
                    <Nav>
                        <Nav.Link
                            href="/"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                início
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/nossoMovimento"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                sobre
                            </span>
                        </Nav.Link>

                        <Nav.Link
                            href="/planos"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                planos
                            </span>
                        </Nav.Link>

                        <Nav.Link
                            href="#"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#CAC9C8",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                fabrique sua malha
                            </span>
                        </Nav.Link>

                        <Nav.Link
                            href="#"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#CAC9C8",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                mapeie seu mercado
                            </span>
                        </Nav.Link>

                        <NavDropdown
                            title="categorias"
                            id="basic-nav-dropdown"
                            style={{
                                padding: "0px 20px 0px 20px",
                                textAlign: "center",
                                marginTop: 8,
                                fontWeight: "bold",
                                color: "#2d2d2d",
                            }}
                        >
                            {categorias.map(categoria => (
                                <NavDropdown.Item
                                    onClick={
                                        () => clickCategoria(categoria.nome)
                                        // eslint-disable-next-line react/jsx-curly-newline
                                    }
                                >
                                    {categoria.nome}
                                </NavDropdown.Item>
                            ))}
                        </NavDropdown>
                        {!cliente && (
                            <Nav.Link href="/cliente">
                                <ButtonMenu>
                                    <button type="button">
                                        <span
                                            style={{
                                                padding: "0px 20px 0px 20px",
                                            }}
                                        >
                                            cadastre-se
                                        </span>
                                    </button>
                                </ButtonMenu>
                            </Nav.Link>
                        )}
                        {!cliente && (
                            <Nav.Link
                                href="/login"
                                style={{
                                    textAlign: "center",
                                    marginTop: 8,
                                    color: "#2d2d2d",
                                }}
                            >
                                <span style={{ padding: "0px 20px 0px 30px" }}>
                                    login
                                </span>
                            </Nav.Link>
                        )}

                        {cliente && cliente?.controleFluxo !== "IS_ADMIN" && (
                            <Nav.Link
                                href="/minhaVitrine"
                                style={{
                                    textAlign: "center",
                                    marginTop: 8,
                                    color: "#2d2d2d",
                                }}
                            >
                                <span style={{ padding: "0px 20px 0px 20px" }}>
                                    minha vitrine
                                </span>
                            </Nav.Link>
                        )}

                        {cliente && cliente?.controleFluxo !== "IS_ADMIN" && (
                            <NavDropdown
                                title="meu perfil"
                                id="basic-nav-dropdown"
                                style={{
                                    padding: "0px 30px 0px 20px",
                                    textAlign: "center",
                                    marginTop: 8,
                                    fontWeight: "bold",
                                    color: "#2d2d2d",
                                }}
                            >
                                <NavDropdown.Item href="/minhaConta">
                                    minha conta
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={signOut}>
                                    sair
                                </NavDropdown.Item>
                            </NavDropdown>
                        )}

                        {cliente && cliente?.controleFluxo === "IS_ADMIN" && (
                            <Nav.Link href="/dashboardClientes">
                                <ButtonMenu>
                                    <button type="button" disabled>
                                        <span
                                            style={{
                                                padding: "0px 20px 0px 10px",
                                            }}
                                        >
                                            Dashboard
                                        </span>
                                    </button>
                                </ButtonMenu>
                            </Nav.Link>
                        )}

                        {cliente && cliente?.controleFluxo === "IS_ADMIN" && (
                            <Nav.Link
                                onClick={signOut}
                                style={{
                                    textAlign: "center",
                                    marginTop: 10,
                                    color: "#2d2d2d",
                                }}
                            >
                                <span style={{ padding: "0px 30px 0px 20px" }}>
                                    sair
                                </span>
                            </Nav.Link>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <Content>
                <MDBRow>
                    <MDBCol
                        md="3"
                        className="col-example"
                        style={{
                            marginTop: 20,
                        }}
                    >
                        <Atencao>
                            <MDBCard
                                className="border border-white"
                                style={{ color: "black" }}
                            >
                                <MDBCardBody>
                                    <MDBRow className="mb-2">
                                        <MDBCol
                                            size="3"
                                            className="col-example text-left"
                                            style={{ marginBottom: 10 }}
                                        >
                                            <AvatarInput>
                                                {clienteLoja?.avatar_url && (
                                                    <img
                                                        src={
                                                            clienteLoja?.avatar_url
                                                        }
                                                        alt="Avatar"
                                                    />
                                                )}
                                                {!clienteLoja?.avatar_url && (
                                                    <img
                                                        src={avatarPadrao}
                                                        alt="Avatar"
                                                    />
                                                )}
                                            </AvatarInput>
                                        </MDBCol>
                                        <MDBCol
                                            size="9"
                                            className="col-example text-center"
                                            style={{
                                                width: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <h6>{clienteLoja?.nome}</h6>
                                        </MDBCol>
                                    </MDBRow>

                                    {clienteLoja?.categorias.map(categoria => {
                                        if (categoria.idCategoriaPai === null) {
                                            return (
                                                <strong
                                                    style={{
                                                        color: "#00d1e6",
                                                    }}
                                                    key={categoria.id}
                                                >
                                                    {categoria.nome}
                                                    <br />
                                                </strong>
                                            );
                                        }
                                        return <strong key={categoria.id} />;
                                    })}

                                    {clienteLoja?.biografia && (
                                        <>
                                            <MDBCardText
                                                style={{ marginTop: 10 }}
                                            >
                                                {clienteLoja.biografia}
                                            </MDBCardText>
                                        </>
                                    )}

                                    <MDBCardSubTitle style={{ marginTop: 15 }}>
                                        <FiMail
                                            style={{
                                                marginTop: -5,
                                                marginRight: 10,
                                            }}
                                        />
                                        {clienteLoja?.email}
                                    </MDBCardSubTitle>

                                    <MDBCardSubTitle style={{ marginTop: 10 }}>
                                        <FiMapPin
                                            style={{
                                                marginRight: 10,
                                                marginTop: -5,
                                            }}
                                        />
                                        {clienteLoja?.cidade.nome} -{" "}
                                        {clienteLoja?.estado.sigla}
                                    </MDBCardSubTitle>
                                    {clienteLoja?.linkInstagram && (
                                        <>
                                            <MDBCardSubTitle
                                                style={{ marginTop: 10 }}
                                            >
                                                <FiInstagram
                                                    style={{
                                                        marginRight: 10,
                                                        marginTop: -5,
                                                    }}
                                                />
                                                {clienteLoja?.linkInstagram}
                                            </MDBCardSubTitle>
                                        </>
                                    )}
                                    {clienteLoja?.linkFacebook && (
                                        <>
                                            <MDBCardSubTitle
                                                style={{ marginTop: 10 }}
                                            >
                                                <FiFacebook
                                                    style={{
                                                        marginRight: 10,
                                                        marginTop: -5,
                                                    }}
                                                />
                                                {clienteLoja?.linkFacebook}
                                            </MDBCardSubTitle>
                                        </>
                                    )}
                                    {clienteLoja?.linkLinkedin && (
                                        <>
                                            <MDBCardSubTitle
                                                style={{ marginTop: 10 }}
                                            >
                                                <FiLinkedin
                                                    style={{
                                                        marginRight: 10,
                                                        marginTop: -5,
                                                    }}
                                                />
                                                {clienteLoja?.linkLinkedin}
                                            </MDBCardSubTitle>
                                        </>
                                    )}
                                    {clienteLoja?.linkSite && (
                                        <>
                                            <MDBCardSubTitle
                                                style={{ marginTop: 10 }}
                                            >
                                                <FiLink
                                                    style={{
                                                        marginRight: 10,
                                                        marginTop: -5,
                                                    }}
                                                />
                                                {clienteLoja?.linkSite}
                                            </MDBCardSubTitle>
                                        </>
                                    )}
                                </MDBCardBody>
                            </MDBCard>
                        </Atencao>
                        <ButtonWhats>
                            <Link
                                to={{
                                    pathname: `https://api.whatsapp.com/send?phone=55${clienteLoja?.celular}&text=${message}`,
                                }}
                                target="_blank"
                            >
                                <button
                                    type="submit"
                                    style={{
                                        backgroundColor: "#32da7f",
                                        color: "#ffffff",
                                    }}
                                >
                                    <FaWhatsapp /> Whatsapp
                                </button>
                            </Link>
                        </ButtonWhats>
                        <Atencao style={{ marginTop: 15, marginBottom: 30 }}>
                            <MDBRow>
                                <MDBCol
                                    size="12"
                                    className=" text-center"
                                    style={{
                                        color: "#00d1e6",
                                        marginTop: 10,
                                    }}
                                >
                                    <h6>
                                        <strong>Atenção</strong>
                                    </h6>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow
                                style={{ marginLeft: 15, marginBottom: 10 }}
                            >
                                <MDBCol
                                    size="02"
                                    className=" text-center"
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <img
                                        width="25px"
                                        src={icone01}
                                        alt="WeTextil"
                                    />
                                </MDBCol>
                                <MDBCol
                                    size="10"
                                    className=" text-left"
                                    style={{ fontSize: 13 }}
                                >
                                    1. A WeTêxtil não se responsabiliza pela
                                    negociação entre as partes.
                                </MDBCol>
                            </MDBRow>
                            <MDBRow
                                style={{ marginLeft: 15, marginBottom: 10 }}
                            >
                                <MDBCol
                                    size="02"
                                    className=" text-center"
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                    }}
                                >
                                    <img
                                        width="25px"
                                        src={icone02}
                                        alt="WeTextil"
                                    />
                                </MDBCol>
                                <MDBCol
                                    size="10"
                                    className=" text-left"
                                    style={{ fontSize: 13 }}
                                >
                                    2 . Fique atento aos excessos de facilidades
                                    e preços muito baixo do mercado
                                </MDBCol>
                            </MDBRow>
                            <MDBRow style={{ marginLeft: 15, marginBottom: 5 }}>
                                <MDBCol
                                    size="02"
                                    className=" text-center"
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                    }}
                                >
                                    <img
                                        width="25px"
                                        src={icone03}
                                        alt="WeTextil"
                                    />
                                </MDBCol>
                                <MDBCol
                                    size="10"
                                    className=" text-left"
                                    style={{ fontSize: 13 }}
                                >
                                    3. Verifique diretamente com o fornecedor as
                                    garantias do negócio
                                </MDBCol>
                            </MDBRow>
                        </Atencao>
                    </MDBCol>
                    <MDBCol md="9" className="col-example">
                        <MDBRow>
                            <MDBCol md="12" className="col-example">
                                <MDBRow className="row-cols-1 row-cols-md-3 g-3">
                                    <MDBCol
                                        md="12"
                                        style={{
                                            marginTop: 5,
                                            marginBottom: 5,
                                        }}
                                    >
                                        {produtos.length === 0 && (
                                            <strong
                                                style={{
                                                    color: "#000000",
                                                }}
                                            />
                                        )}
                                    </MDBCol>
                                    {produtos.map(produtoMap => (
                                        <MDBCol
                                            key={produtoMap.id}
                                            style={{
                                                marginTop: 10,
                                                marginBottom: 10,
                                            }}
                                        >
                                            <MDBCard
                                                className="h-100 border border-white"
                                                style={{
                                                    color: "#000000",
                                                    borderRadius: 10,
                                                    cursor: "pointer",

                                                    boxShadow:
                                                        "0 0 10px #e8e8e8",
                                                }}
                                                onClick={
                                                    () =>
                                                        clickProduto(
                                                            produtoMap.id,
                                                        )
                                                    // eslint-disable-next-line react/jsx-curly-newline
                                                }
                                            >
                                                {produtoMap.img01_url ===
                                                    null && (
                                                    <MDBCardImage
                                                        src={avatarPadrao}
                                                        alt="..."
                                                        width="180px"
                                                        height="150px"
                                                        position="top"
                                                        style={{
                                                            borderTopLeftRadius: 10,
                                                            borderTopRightRadius: 10,
                                                        }}
                                                    />
                                                )}

                                                {produtoMap.img01_url !==
                                                    null && (
                                                    <MDBCardImage
                                                        src={
                                                            produtoMap.img01_url
                                                        }
                                                        alt="..."
                                                        width="180px"
                                                        height="150px"
                                                        position="top"
                                                        style={{
                                                            borderTopLeftRadius: 10,
                                                            borderTopRightRadius: 10,
                                                        }}
                                                    />
                                                )}
                                                <MDBCardBody>
                                                    <MDBCardText>
                                                        {produtoMap.categorias.map(
                                                            categoriaMap => {
                                                                if (
                                                                    categoriaMap.idCategoriaPai ===
                                                                    null
                                                                ) {
                                                                    return (
                                                                        <strong
                                                                            style={{
                                                                                color: "#00d1e6",
                                                                            }}
                                                                            key={
                                                                                categoriaMap.id
                                                                            }
                                                                        >
                                                                            {
                                                                                categoriaMap.nome
                                                                            }
                                                                            <br />
                                                                        </strong>
                                                                    );
                                                                }
                                                                return (
                                                                    <span
                                                                        key={
                                                                            categoriaMap.id
                                                                        }
                                                                    />
                                                                );
                                                            },
                                                        )}
                                                    </MDBCardText>
                                                    <MDBCardTitle
                                                        style={{
                                                            color: "#9296AD",
                                                        }}
                                                    >
                                                        {produtoMap.nome}
                                                    </MDBCardTitle>
                                                    <MDBCardText>
                                                        {produtoMap.descricao}
                                                    </MDBCardText>
                                                </MDBCardBody>
                                            </MDBCard>
                                        </MDBCol>
                                    ))}
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </Content>
            <EspacoFundoCinza>
                <FundoCinza>
                    <MDBRow
                        style={{
                            marginLeft: "1%",
                            marginRight: "3%",
                            marginBottom: "10%",
                        }}
                    >
                        <MDBCol
                            md="1"
                            className="text-center align-items-center"
                            style={{
                                marginTop: "2%",
                                marginBottom: "2%",
                                alignItems: "center",
                            }}
                        >
                            <img
                                width="100px"
                                src={imgLogoBranca}
                                alt="WeTextil"
                            />
                        </MDBCol>
                        <MDBCol
                            md="6"
                            style={{ marginTop: "2%", marginBottom: "2%" }}
                        >
                            <MDBRow className="row-cols-1 row-cols-md-1 g-1 text-center">
                                <strong>CATEGORIAS</strong>
                            </MDBRow>
                            <MDBRow className="row-cols-1 row-cols-md-3 g-3 text-center">
                                {categorias.map(categoria => (
                                    <MDBCol
                                        key={categoria.id}
                                        onClick={
                                            () => clickCategoria(categoria.nome)
                                            // eslint-disable-next-line react/jsx-curly-newline
                                        }
                                        style={{ cursor: "pointer" }}
                                    >
                                        <small>{categoria.nome}⠀</small>
                                    </MDBCol>
                                ))}
                            </MDBRow>
                        </MDBCol>
                        <MDBCol
                            md="3"
                            style={{ marginTop: "2%", marginBottom: "2%" }}
                        >
                            <MDBRow className="row-cols-1 row-cols-md-1 g-1 text-center">
                                <strong>AJUDA</strong>
                            </MDBRow>
                            <MDBRow
                                className="row-cols-1 row-cols-md-1 g-1 text-center"
                                style={{ marginTop: "2%" }}
                            >
                                <small>16 99787-1671</small>
                            </MDBRow>
                            <MDBRow
                                className="row-cols-1 row-cols-md-1 g-1 text-center"
                                style={{ marginTop: "1%" }}
                            >
                                <small>contato@wetextil.com</small>
                            </MDBRow>
                            <MDBRow
                                className="row-cols-1 row-cols-md-1 g-1 text-center"
                                style={{ marginTop: "1%" }}
                            >
                                <small>
                                    <a
                                        href="/termosDeUso"
                                        target="_blank"
                                        style={{ color: "white" }}
                                    >
                                        Política de Privacidade e Termos de Uso
                                    </a>
                                </small>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol
                            md="2"
                            style={{ marginTop: "2%", marginBottom: "2%" }}
                        >
                            <MDBRow className="row-cols-1 row-cols-md-1 g-1 text-center">
                                <strong>REDES SOCIAIS</strong>
                            </MDBRow>
                            <MDBRow
                                style={{
                                    marginTop: "4%",
                                    marginBottom: "2%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Link
                                    to={{
                                        pathname: `${
                                            outros.find(
                                                saida =>
                                                    saida.chave ===
                                                    "linkFacebook",
                                            )?.valor
                                        }`,
                                    }}
                                    target="_blank"
                                >
                                    <ButtonIcon type="submit">
                                        <FiFacebook />
                                    </ButtonIcon>
                                </Link>
                                <Link
                                    to={{
                                        pathname: `${
                                            outros.find(
                                                saida =>
                                                    saida.chave ===
                                                    "linkInstagram",
                                            )?.valor
                                        }`,
                                    }}
                                    target="_blank"
                                >
                                    <ButtonIcon type="submit">
                                        <FiInstagram />
                                    </ButtonIcon>
                                </Link>
                                <Link
                                    to={{
                                        pathname: `${
                                            outros.find(
                                                saida =>
                                                    saida.chave ===
                                                    "linkLinkedin",
                                            )?.valor
                                        }`,
                                    }}
                                    target="_blank"
                                >
                                    <ButtonIcon type="submit">
                                        <FiLinkedin />
                                    </ButtonIcon>
                                </Link>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow
                        style={{
                            marginLeft: "1%",
                            marginRight: "3%",
                        }}
                    >
                        <MDBCol
                            md="12"
                            className="text-center align-items-center"
                            style={{
                                marginTop: "2%",
                                marginBottom: "2%",
                                alignItems: "center",
                            }}
                        >
                            Copyright ©️ 2021 WeTêxtil. All trademarks and logos
                            are the property of their respective owners. All
                            rights reserved.
                        </MDBCol>
                    </MDBRow>
                </FundoCinza>
            </EspacoFundoCinza>
        </Container>
    );
};

export default Loja;
