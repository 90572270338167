// eslint-disable-next-line no-unused-vars
import React, { ButtonHTMLAttributes } from "react";

import { Container } from "./styles";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    confirmar?: boolean;
};

const ButtonCancelarPlano: React.FC<ButtonProps> = ({
    children,
    confirmar,
    ...rest
}) => (
    <Container type="button" {...rest}>
        {children}
    </Container>
);

export default ButtonCancelarPlano;
