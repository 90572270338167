import styled, { keyframes } from "styled-components";

import PopupConsultorDois from "../../../assets/PopupConsultor2.png";

export const Container = styled.div`
    height: 100vh;
    display: flex;
    align-items: stretch;
`;

export const Content = styled.div`
    width: 100%;
    max-width: 750px;
`;

export const Background = styled.div`
    flex: 1;
    background: url(${PopupConsultorDois}) no-repeat center;
    background-size: cover;
    height: 100%;
`;

const appearFromLeft = keyframes`
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        transform: translateX(0px);
    }
`;

export const AnimationContainer = styled.div`
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2%;
    padding-bottom: 2%;
    color: black;
    animation: ${appearFromLeft} 2s;
`;
