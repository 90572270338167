/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useCallback } from "react";

import { Link, useHistory, Redirect } from "react-router-dom";
import FormReact from "react-bootstrap/Form";
import { Form } from "@unform/web";

import { Nav, Navbar, NavDropdown, Row, Col } from "react-bootstrap";
import {
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBCol,
    MDBRow,
} from "mdb-react-ui-kit";

import { ToastContainer, toast } from "react-toastify";
import { FormHandles } from "@unform/core";
import { FiUser, FiX } from "react-icons/fi";
import Select from "react-select";
import ButtonAzulEscuro from "../../../components/ButtonAzulEscuro";

import imgLogo from "../../../assets/Logo.png";
import api from "../../../services/api";

import { useAuth } from "../../../hooks/auth";

import { Container, Content } from "./styles";
import ButtonRoxo from "../../../components/ButtonRoxo";
import InputPesquisa from "../../../components/InputPesquisa";

interface Categoria {
    id: string;
    nome: string;
}

interface SubCategoria {
    id: string;
    nome: string;
    idCategoriaPai: string;
}

interface AdicionarCategoria {
    nome: string;
    idCategoriaPai: string;
}

interface EditarCategoria {
    nome: string;
}

const Categorias: React.FC = () => {
    const { cliente } = useAuth();
    const history = useHistory();
    const formRef = useRef<FormHandles>(null);
    const [categorias, setCategorias] = useState<Categoria[]>([]);
    const [subcategorias, setSubCategorias] = useState<SubCategoria[]>([]);
    const [subcategoriasSelecionada, setSubCategoriasSelecionada] = useState<
        SubCategoria[]
    >([]);

    const [hiddenAdicionar, setHiddenAdicionar] = useState(true);
    const [hiddenEditar, setHiddenEditar] = useState(true);
    const [hiddenInfosEditar, setHiddenInfosEditar] = useState(true);

    const [tipo, setTipo] = useState<string>("");
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClickTipo = (e: any) => {
        setTipo(e.target.value);
    };

    const [categoriaSelecionada, setCategoriaSelecionada] = useState<string>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleCategoriaSelecionada = (e: any) => {
        setCategoriaSelecionada(e.target.value);
        if (e.target.value) {
            setHiddenEditar(false);
        }
    };

    const [selectedIdCategoria, setSelectedIdCategoria] = useState<string>("");
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChangeId = (e: any) => {
        setSelectedIdCategoria(e.id);
    };

    const [editarIdCategoria, setEditarIdCategoria] = useState<string>("");
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChangeIdEditar = (e: any) => {
        setEditarIdCategoria(e.id);
        api.get(`/categorias/subCategorias/${e.id}`).then(response =>
            setSubCategoriasSelecionada(response.data),
        );
    };

    const [editarIdSubCategoria, setEditarIdSubCategoria] =
        useState<string>("");
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChangeIdEditarSub = (e: any) => {
        setEditarIdSubCategoria(e.id);
    };

    const handleSubmitAdicionar = useCallback(
        async (data: AdicionarCategoria) => {
            try {
                let formData;

                if (tipo === "categoria") {
                    const { nome } = data;

                    formData = {
                        nome,
                    };
                } else if (tipo === "subcategoria") {
                    const { nome } = data;

                    formData = {
                        nome,
                        idCategoriaPai: selectedIdCategoria,
                    };
                }

                await api
                    .post(`/categorias`, formData)
                    .then(() => {
                        toast.success("Inserido com sucesso!", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                        });
                        api.get("/categorias/subCategorias").then(response =>
                            setSubCategorias(response.data),
                        );
                        api.get("/categorias/categorias").then(response =>
                            setCategorias(response.data),
                        );
                        setHiddenAdicionar(true);
                    })
                    .catch(() => {
                        toast.error("Erro! Tente novamente!", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                        });
                        setHiddenEditar(true);
                    });
            } catch (err) {
                toast.error("Erro no servidor! Tente novamente!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
                setHiddenAdicionar(true);
            }
        },
        [tipo, selectedIdCategoria],
    );

    const handleSubmitEditar = useCallback(
        async (data: EditarCategoria) => {
            try {
                const { nome } = data;
                const formData = {
                    nome,
                };

                if (tipo === "categoria") {
                    await api
                        .put(`/categorias/${editarIdCategoria}`, formData)
                        .then(() => {
                            toast.success("Atualizado com sucesso!", {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: true,
                                progress: undefined,
                            });
                            api.get("/categorias/subCategorias").then(
                                response => setSubCategorias(response.data),
                            );
                            api.get("/categorias/categorias").then(response =>
                                setCategorias(response.data),
                            );
                            setHiddenInfosEditar(true);
                        })
                        .catch(() => {
                            toast.error("Erro! Tente novamente!", {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: true,
                                progress: undefined,
                            });
                            api.get("/categorias/subCategorias").then(
                                response => setSubCategorias(response.data),
                            );
                            api.get("/categorias/categorias").then(response =>
                                setCategorias(response.data),
                            );
                            setHiddenInfosEditar(true);
                        });
                } else if (tipo === "subcategoria") {
                    await api
                        .put(`/categorias/${editarIdSubCategoria}`, formData)
                        .then(() => {
                            toast.success("Atualizado com sucesso!", {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: true,
                                progress: undefined,
                            });
                            api.get("/categorias/subCategorias").then(
                                response => setSubCategorias(response.data),
                            );
                            api.get("/categorias/categorias").then(response =>
                                setCategorias(response.data),
                            );
                            setHiddenInfosEditar(true);
                        })
                        .catch(() => {
                            toast.error("Erro! Tente novamente!", {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: true,
                                progress: undefined,
                            });
                            api.get("/categorias/subCategorias").then(
                                response => setSubCategorias(response.data),
                            );
                            api.get("/categorias/categorias").then(response =>
                                setCategorias(response.data),
                            );
                            setHiddenInfosEditar(true);
                        });
                }
            } catch (err) {
                toast.error("Erro no servidor! Tente novamente!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
                setHiddenAdicionar(true);
            }
        },
        [tipo, editarIdCategoria, editarIdSubCategoria],
    );

    useEffect(() => {
        api.get("/categorias/categorias").then(response =>
            setCategorias(response.data),
        );
        api.get("/categorias/subCategorias").then(response =>
            setSubCategorias(response.data),
        );
    }, []);

    // Redirecionamentos de páginas privadas
    if (cliente.controleFluxo !== "IS_ADMIN") {
        return <Redirect to="/" />;
    }

    return (
        <Container>
            <Navbar
                collapseOnSelect
                expand="lg"
                style={{
                    background: "#ffffff",
                }}
            >
                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    style={{ border: "none" }}
                />
                <Navbar.Brand style={{ marginLeft: "2%" }}>
                    <Link to="/">
                        <img width="125px" src={imgLogo} alt="WeTextil" />
                    </Link>
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto text-center" />
                    <Nav>
                        <Nav.Link
                            href="/dashboardMapeamentoMercado"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Mapeie Mercado
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardFabricacaoMalha"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Fabrique Malha
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardPesquisa"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Pesquisas
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardTabelaWeTextil"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Tabela WeTêxtil
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardClientes"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Clientes
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardCategorias"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#846cf9",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                <strong>Categorias</strong>
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardAssinaturas"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Assinaturas
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardProdutos"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Produtos/Serviços
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboardOutros"
                            style={{
                                textAlign: "center",
                                marginTop: 8,
                                color: "#2d2d2d",
                            }}
                        >
                            <span style={{ padding: "0px 20px 0px 20px" }}>
                                Outros
                            </span>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Content>
                <ToastContainer />
                <MDBRow style={{ marginBottom: 20, marginTop: -20 }} end>
                    <MDBCol md="3">
                        <ButtonAzulEscuro
                            style={{ height: 50 }}
                            onClick={() => {
                                setHiddenInfosEditar(false);
                                setHiddenAdicionar(true);
                            }}
                        >
                            Editar
                        </ButtonAzulEscuro>
                    </MDBCol>
                    <MDBCol md="3">
                        <ButtonAzulEscuro
                            style={{ height: 50 }}
                            onClick={() => {
                                setHiddenAdicionar(false);
                                setHiddenInfosEditar(true);
                            }}
                        >
                            Adicionar
                        </ButtonAzulEscuro>
                    </MDBCol>
                </MDBRow>

                <Form
                    ref={formRef}
                    onSubmit={handleSubmitAdicionar}
                    hidden={hiddenAdicionar}
                >
                    <MDBRow style={{ marginBottom: 20 }}>
                        <MDBCol md="3" style={{ marginTop: 17 }}>
                            <h6
                                style={{
                                    textAlign: "center",
                                    marginTop: 10,
                                }}
                            >
                                <FormReact.Check
                                    inline
                                    label="Categoria"
                                    name="tipo"
                                    type="radio"
                                    id="categoria"
                                    value="categoria"
                                    onClick={handleClickTipo}
                                />
                                <FormReact.Check
                                    inline
                                    label="SubCategoria"
                                    name="tipo"
                                    type="radio"
                                    id="subcategoria"
                                    value="subcategoria"
                                    onClick={handleClickTipo}
                                />
                            </h6>
                        </MDBCol>
                        <MDBCol md="3" style={{ marginTop: 17 }}>
                            <InputPesquisa
                                icon={FiUser}
                                placeholder="Nome"
                                name="nome"
                                type="text"
                            />
                        </MDBCol>
                        <MDBCol md="3" style={{ marginTop: 17 }}>
                            {tipo === "subcategoria" && (
                                <Select
                                    className="dropdown"
                                    placeholder="Categoria"
                                    onChange={handleChangeId}
                                    options={categorias}
                                    getOptionLabel={option => option.nome}
                                    getOptionValue={option => option.id}
                                    maxMenuHeight={160}
                                    theme={theme => ({
                                        ...theme,
                                        height: 100,
                                        borderRadius: 10,
                                    })}
                                />
                            )}
                        </MDBCol>
                        <MDBCol md="2" style={{ marginTop: 15 }}>
                            <ButtonRoxo
                                type="submit"
                                style={{ height: 40, marginTop: 0 }}
                            >
                                Confirmar
                            </ButtonRoxo>
                        </MDBCol>
                        <MDBCol md="1" style={{ marginTop: 15 }}>
                            <ButtonAzulEscuro
                                style={{
                                    height: 40,
                                    marginTop: 0,
                                    background: "white",
                                    color: "black",
                                }}
                                onClick={() => {
                                    setHiddenAdicionar(true);
                                }}
                            >
                                <strong>
                                    <FiX />
                                </strong>
                            </ButtonAzulEscuro>
                        </MDBCol>
                    </MDBRow>
                </Form>

                <Form
                    ref={formRef}
                    onSubmit={handleSubmitEditar}
                    hidden={hiddenInfosEditar}
                >
                    <MDBRow style={{ marginBottom: 20 }} center>
                        <MDBCol md="3" style={{ marginTop: 23 }}>
                            <FormReact.Check
                                inline
                                label="Categoria"
                                name="tipo"
                                type="radio"
                                id="categoria"
                                value="categoria"
                                onClick={handleClickTipo}
                            />
                            <FormReact.Check
                                inline
                                label="SubCategoria"
                                name="tipo"
                                type="radio"
                                id="subcategoria"
                                value="subcategoria"
                                onClick={handleClickTipo}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow style={{ marginBottom: 20 }} end>
                        {(tipo === "subcategoria" || tipo === "categoria") && (
                            <MDBCol md="3" style={{ marginTop: 17 }}>
                                <Select
                                    className="dropdown"
                                    placeholder="Categoria"
                                    onChange={handleChangeIdEditar}
                                    options={categorias}
                                    getOptionLabel={option => option.nome}
                                    getOptionValue={option => option.id}
                                    maxMenuHeight={160}
                                    theme={theme => ({
                                        ...theme,
                                        height: 100,
                                        borderRadius: 10,
                                    })}
                                />
                            </MDBCol>
                        )}
                        {tipo === "subcategoria" && (
                            <MDBCol md="3" style={{ marginTop: 17 }}>
                                <Select
                                    className="dropdown"
                                    placeholder="SubCategoria"
                                    onChange={handleChangeIdEditarSub}
                                    options={subcategoriasSelecionada}
                                    getOptionLabel={option => option.nome}
                                    getOptionValue={option => option.id}
                                    maxMenuHeight={160}
                                    theme={theme => ({
                                        ...theme,
                                        height: 100,
                                        borderRadius: 10,
                                    })}
                                />
                            </MDBCol>
                        )}
                        {(tipo === "subcategoria" || tipo === "categoria") && (
                            <>
                                {tipo === "subcategoria" && (
                                    <MDBCol md="3" style={{ marginTop: 17 }}>
                                        <InputPesquisa
                                            icon={FiUser}
                                            placeholder="Novo nome da subcategoria"
                                            name="nome"
                                            type="text"
                                        />
                                    </MDBCol>
                                )}
                                {tipo === "categoria" && (
                                    <MDBCol md="3" style={{ marginTop: 17 }}>
                                        <InputPesquisa
                                            icon={FiUser}
                                            placeholder="Novo nome da categoria"
                                            name="nome"
                                            type="text"
                                        />
                                    </MDBCol>
                                )}
                                <MDBCol md="2" style={{ marginTop: 15 }}>
                                    <ButtonRoxo
                                        type="submit"
                                        style={{ height: 40, marginTop: 0 }}
                                    >
                                        Confirmar
                                    </ButtonRoxo>
                                </MDBCol>
                                <MDBCol md="1" style={{ marginTop: 15 }}>
                                    <ButtonAzulEscuro
                                        style={{
                                            height: 40,
                                            marginTop: 0,
                                            background: "white",
                                            color: "black",
                                        }}
                                        onClick={() => {
                                            setHiddenInfosEditar(true);
                                        }}
                                    >
                                        <strong>
                                            <FiX />
                                        </strong>
                                    </ButtonAzulEscuro>
                                </MDBCol>
                            </>
                        )}
                    </MDBRow>
                </Form>

                <MDBTable responsive bordered striped>
                    <MDBTableHead className="text-center" dark>
                        <tr>
                            <th scope="col">Categoria</th>
                            <th scope="col">SubCategorias</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {categorias.map(categoria => (
                            <tr>
                                <td>{categoria.nome}</td>
                                <td>
                                    {subcategorias
                                        .filter(
                                            saida =>
                                                saida.idCategoriaPai ===
                                                categoria.id,
                                        )
                                        .map(retorno => (
                                            <h6>{retorno.nome}</h6>
                                        ))}
                                </td>
                            </tr>
                        ))}
                    </MDBTableBody>
                </MDBTable>
            </Content>
        </Container>
    );
};

export default Categorias;
