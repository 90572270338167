/* eslint-disable no-unused-vars */
import React, { useCallback, useRef } from "react";
import { FiMail, FiLock } from "react-icons/fi";

import { Link, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";

import { Form } from "@unform/web";
// eslint-disable-next-line no-unused-vars
import { FormHandles } from "@unform/core";

import { useAuth } from "../../hooks/auth";

import { Background, Container, Content, AnimationContainer } from "./styles";

import InputLogin from "../../components/InputLogin";
import ButtonAzul from "../../components/ButtonAzul";
import ButtonRoxo from "../../components/ButtonRoxo";

import logoImg from "../../assets/Logo.png";
import getValidationErrors from "../../utils/getValidationErrors";

interface SignInFormData {
    email: string;
    senha: string;
}

const Login: React.FC = () => {
    const formRef = useRef<FormHandles>(null);

    const { signIn } = useAuth();

    const history = useHistory();

    const handleCadastro = useCallback(async () => {
        history.push("/cliente");
    }, [history]);

    const handleSubmit = useCallback(
        async (data: SignInFormData) => {
            try {
                formRef.current?.setErrors({});

                const schema = Yup.object().shape({
                    email: Yup.string()
                        .required("E-mail obrigatório")
                        .email("Digite um e-mail válido"),
                    senha: Yup.string().required("Senha obrigatória"),
                });

                await schema.validate(data, {
                    // Faz com que o Yup não pare no primeiro erro. Por padrão é true.
                    abortEarly: false,
                });

                await signIn({
                    email: data.email,
                    senha: data.senha,
                });

                history.push("/");
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);

                    return;
                }

                if (err instanceof Error) {
                    toast.error(`Erro: ${JSON.parse(err.message).message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        },
        [signIn, history],
    );

    return (
        <Container>
            <Background />
            <Content>
                <ToastContainer />
                <AnimationContainer>
                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <InputLogin
                            icon={FiMail}
                            name="email"
                            placeholder="Email"
                            type="email"
                        />

                        <InputLogin
                            icon={FiLock}
                            name="senha"
                            placeholder="Senha"
                            type="password"
                        />

                        <ButtonAzul type="submit">Entrar</ButtonAzul>
                    </Form>

                    <a
                        href="forgot"
                        style={{
                            marginTop: 10,
                            color: "#2d2d2d",
                        }}
                    >
                        Esqueceu a senha?
                    </a>

                    <Link to="/cliente" style={{ textDecoration: "none" }}>
                        <ButtonRoxo type="submit" style={{ width: 200 }}>
                            Cadastre-se
                        </ButtonRoxo>
                    </Link>
                </AnimationContainer>
            </Content>
        </Container>
    );
};

export default Login;
